.curved-arrow {
  position: relative;
  top: 50px;
  left: 50px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid $color-bay-of-many;
  transform: rotate(135deg);
}

.curved-arrow::after {
  content: "";
  position: absolute;
  border: 0 solid transparent;
  border-top: 3px solid $color-bay-of-many;
  border-left: 3px solid $color-bay-of-many;
  border-radius: 40px 0 0;
  top: -24px;
  left: 1px;
  width: 36px;
  height: 36px;
  transform: rotate(45deg);
}
