/* stylelint-disable max-nesting-depth, declaration-no-important */

.import-generic-file {
  .top-left {
    &.step-2 {
      height: 250px !important;

      > ul > li {
        z-index: 2;
        height: 250px !important;

        .header-column {
          overflow: visible;

          > label {
            height: 210px !important;
            display: flex;
            justify-content: space-between;

            &::before,
            &::after {
              top: 18px;
            }

            .popup-with-label-container {
              .popup-label {
                .tooltip-icon {
                  padding-top: 7px;
                  margin-right: 10px;
                }

                .popup-container {
                  width: 200px;
                  height: 145px;
                  margin-top: 5px;
                  line-height: 1em;
                  right: 0;
                  white-space: pre-wrap;

                  .arrow-tooltip {
                    right: 8%;
                  }

                  .desc {
                    line-height: 24px;
                    padding: 10px;
                    text-transform: none;
                    font-weight: 400;
                    color: $font-color;
                    font-size: 18px;
                    font-family: Metric, sans-serif;
                    letter-spacing: normal;
                  }
                }

                .icon-warning {
                  > svg {
                    fill: $color-red-dark;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
