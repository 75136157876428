.generic-manager .body-container .table-intro-body-container {
  padding: 0 20px;

  > .lottie-animation {
    margin-bottom: 0;
  }

  > img {
    display: block;
    margin: 30px auto 0;
  }

  .description {
    width: 80%;
    margin: 0 auto 20px;
    text-align: left;
    white-space: pre-line;
  }
}
