/* stylelint-disable max-nesting-depth */
.dimensions-mapping-body-container {
  .description {
    margin: 20px auto;
    max-width: 550px;
    text-align: center;
    white-space: pre-line;
  }

  .adding-part {
    height: 63px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid $color-light-steel-gray;
    border-bottom: 1px solid $color-light-steel-gray;
    align-items: center;

    .predefined-label {
      color: $color-steel;
      margin-left: auto;
    }

    .sibp-button.add-new-dimension.with-border {
      height: 40px;
      margin-left: 20px;
      margin-bottom: 1px;
      width: auto;
      white-space: nowrap;
    }

    .predefined-fields {
      font-size: 18px;
      line-height: 21px;
      margin-left: 20px;
      margin-right: 10px;

      .predefined-description {
        margin-bottom: 20px;
        margin-left: 21px;
        margin-top: 15px;
      }

      .fields {
        margin-bottom: 10px;
        margin-left: 21px;
      }

      .field {
        color: $heading-border-color;
      }

      .arrow-tooltip {
        height: 17px;
        width: 17px;
        top: -9px;
        right: 15px;
      }

      .popup-label {
        display: flex;

        .tooltip-icon {
          &.popup-open {
            .reference {
              path {
                fill: $color-cerulean;
              }
            }
          }

          margin-right: 13px;
        }

        .popup-container {
          position: fixed;
          height: 214px;
          width: 245px;
          margin-top: 41px;
          z-index: 1;
        }
      }
    }
  }

  .sibp-checkbox > label::before {
    margin-left: 10px;
  }

  .checkbox-label span {
    color: $font-color;
    font-weight: inherit;
    font-size: 18px;
    line-height: 21px;
    padding: 0;
  }

  .sibp-checkbox > label.checked::before {
    border: 0;
  }

  .suggested-dimensions-grid-container {
    overflow: auto;
    overflow-x: hidden;
    height: 300px;
    width: 100%;
    padding-left: 20px;
    display: grid;
    column-gap: 15px;
    row-gap: 15px;
    grid-template-columns: 265px 265px;
    grid-auto-rows: 40px;

    .edit-checkbox {
      .edit-input {
        .input-container {
          text-align: left;

          .base-input-container {
            > input {
              height: 30px;
              width: 205px;
              background: transparent;
              border: none;
              outline: none;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .grid-item {
    text-align: center;
    margin-top: 15px;

    .sibp-checkbox {
      .edit-input-label {
        margin: 0;
      }
    }
  }

  .sibp-checkbox > label.checked {
    border: 1px solid $heading-border-color;
  }

  .sibp-checkbox.invalid-dimension {
    > label {
      background: $color-error-red-light;
      border: 1px solid $color-red;
    }
  }

  .dimensions-mapping {
    .edit-input {
      .base-input-container {
        width: inherit;
      }
    }
  }

  .sibp-checkbox > label {
    display: inherit;
    margin-bottom: 0;
    height: 40px;
    width: 260px;
    border: 1px solid $color-light-steel-gray;
    border-radius: 3px;
  }

  .edit-input-container {
    > svg {
      margin-right: 15px;
    }
  }
}
