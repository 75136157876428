/* stylelint-disable max-nesting-depth, selector-class-pattern */
$nav-height: 70px;
$footer-height: 78px;

.preview-step-container {
  .body-container {
    height: calc(100% - #{$footer-height});

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: $nav-height;
      border-bottom: 1px solid $color-light-steel-gray;

      .left-header-side {
        padding-left: 20px;
        display: flex;
        align-items: center;
        gap: 10px;

        .arrow-hierarchy {
          position: relative;
          bottom: 2px;
          font-size: 24px;
          color: $color-dark-grey-blue;

          .walk-through-tooltip {
            position: absolute;

            .popup-container {
              position: relative;
              width: 200px;
              height: auto;
              padding: 10px 15px;
              z-index: 1;
              left: -70px;

              .arrow-tooltip {
                right: 50%;
              }
            }

            &.popup-min-left {
              .popup-container {
                left: -35px;
              }
            }
          }
        }

        .dimension-select {
          bottom: 2px;
          position: relative;

          .select__control {
            border: none;
            outline: unset;
            box-shadow: unset;
            cursor: pointer;

            .select__value-container {
              padding-left: 0;
              padding-right: 0;
              margin-right: -15px;

              .select__single-value {
                display: contents;
                font-size: 23px;
              }

              input {
                width: 0;
              }
            }

            .select__indicator-separator {
              display: none;
            }

            .select__dropdown-indicator {
              padding: 0;
            }
          }

          .select__option {
            width: auto;
            cursor: pointer;
          }

          .select__option--is-selected {
            background-color: white;
          }

          .select__option--is-focused {
            background-color: $color-mint-tulip;
          }
        }

        .header-name {
          font-size: 23px;
          color: $color-dark-grey-blue;
        }

        .select__menu {
          min-width: 200px;
        }
      }

      .right-header-side {
        padding-right: 30px;
        display: flex;
        align-items: center;
        gap: 14px;

        .total-lines {
          display: flex;
          gap: 4px;
          align-items: center;

          svg {
            top: 3px;
            position: relative;
          }

          .popup-container {
            position: fixed;
            width: 250px;
            height: auto;
            z-index: 1;
            right: 152px;
            margin-top: 10px;

            .message {
              padding: 20px 21px 28px;
              max-width: 245px;
              min-height: 120px;
              max-height: 232px;
              overflow: auto;

              .download-section {
                margin-top: 20px;

                .link {
                  cursor: pointer;
                  color: $color-cerulean;
                }
              }
            }
          }

          .text {
            color: $color-dark-grey-blue;
          }
        }

        .divider {
          width: 1px;
          height: 30px;
          border: 1px solid $color-light-steel-gray;
        }

        .switch {
          top: 4px;
        }
      }
    }

    .budget-tree-body {
      display: flex;
      width: 100%;
      height: calc(100% - #{$nav-height});

      .single-budget-type-container {
        flex: 1;
        min-width: 200px;
        border-right: 1px solid $color-light-steel-gray;
        display: flex;
        flex-direction: column;

        .empty-lines {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 55px;
          height: 100%;
          overflow-y: scroll;

          .title {
            text-transform: uppercase;
            color: $color-steel;
            padding-top: 20px;
          }
        }

        .budget-type {
          flex: 0 0 auto;
          display: flex;
          align-items: center;
          height: 70px;
          border-bottom: 1px solid $color-light-steel-gray;
          font-size: 23px;
          color: $color-dark-grey-blue;
          padding-left: 20px;
        }

        .tree-container {
          overflow-y: scroll;
          overflow-x: hidden;
          padding-bottom: 20px;
          height: 100%;

          > .folder:first-child {
            margin-left: 0;
          }

          .folder {
            margin-left: 10px;

            .folder-label {
              cursor: default;

              svg {
                cursor: pointer;
                fill: rgb(135, 141, 148);
              }
            }
          }

          .tree-budget-line {
            display: flex;
            justify-content: space-between;
            width: 100%;
            overflow: hidden;

            .name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .total {
              overflow: hidden;
              white-space: nowrap;
              flex: 0 0 auto;
            }
          }
        }

        .file {
          margin-left: 35px;
          margin-top: 20px;
        }
      }
    }
  }

  .footer-container {
    height: $footer-height;
    min-height: $footer-height;
    position: absolute;
    bottom: 0;
    width: 100%;

    .footer-buttons {
      margin-right: 75px;
    }
  }
}
