/* stylelint-disable scss/at-import-partial-extension */
@import "sip-components/styles/reset";
@import "sip-components/styles/utils";
@import "sip-components/styles/variables";
@import "sip-components/styles/fonts";
@import "sip-components/styles/badge";
@import "../../styles/global";
@import "../../styles/variables";
@import "../../styles/page-container";
@import "../components/header/header";
@import "../components/body/body";
@import "../../components/nav-bar/nav-bar";
@import "../pages/adminUserList/adminUserList";
@import "../pages/companyList/companyList";
@import "../pages/companyEdit/companyEdit";
@import "../pages/companyAdd/companyAdd";
@import "../pages/profile/profile";
@import "../components/licenses/licenses";
@import "../pages/adminUserEdit/adminUserEdit";
@import "../pages/auditLoggingList/auditLoggingList";
@import "../pages/companyEdit/pages/companyUserList/companyUserList";
@import "../pages/companyEdit/pages/CompanyUserEdit/CompanyUserEdit";
@import "../pages/companyEdit/pages/companyDetails/companyDetails";
@import "../pages/companyEdit/pages/companyBudgetList/companyBudgetList";
@import "../pages/userBudgetList/userBudgetList";
@import "../pages/budgetSearch/budgetSearch";
@import "../pages/reports/reports";

// common components: start
@import "../../../common-react-components/profile/profile";
@import "../../../common-react-components/profile/pages/myDetails/myDetails";

// common components: end

body {
  min-height: 100vh;
  padding: 0;
  @include font(18);

  font-family: $default-font-family, sans-serif;
  color: $color-blue-3;
}

.admin-app-container {
  height: 100vh;
}

.app-footer {
  line-height: 10px;
}
