.budget-copy-wrappper {
  flex: 1;
  display: flex;
}

/* required for specificity */
.qtip.qtip.qtip .budget-copy-dropdown {
  padding: 2px 0;
}

.budget-copy-dropdown {
  display: none; /* required because of qtip behavior */

  * {
    color: var(--list-item-text-color);
  }

  ul li {
    padding: 9px 16px 10px;
    line-height: var(--list-item-line-height);

    &:hover {
      background-color: var(--list-item-hover-color);
    }
  }

  .header {
    font-weight: 500;
    letter-spacing: var(--bold-font-letter-spacing);
    margin: 10px 16px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-gray-101);
  }

  .name {
    font-weight: 500;
    letter-spacing: var(--bold-font-letter-spacing);
  }
}

.budget-copy-list {
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
}
