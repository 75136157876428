/* stylelint-disable declaration-no-important */
.ember-basic-dropdown-content {
  &.auto-width {
    width: auto !important; // use important in order to win the inline style
  }

  &.no-wrap {
    white-space: nowrap;
  }
}

.fit-to-line {
  display: block;
  width: 100%;

  &.with-transparent-input {
    border-bottom: 1px solid $color-white-light-grey;

    ul {
      input {
        @include flex(1);

        margin-top: 2px;
        border-width: 0;
      }
    }
  }

  .ember-power-select-trigger {
    width: 100%;
  }

  .ember-power-select-multiple-option {
    width: auto;
  }
}

.ember-power-select-trigger {
  &:focus {
    outline: none;
  }
}

.ember-power-select-options,
.power-action-select {
  .power-select-action-option {
    &.power-select-option-partition {
      margin-left: 15px;
      line-height: 40px;
    }
  }
}

.ember-power-select-options,
.action-select-container {
  .power-select-regular-option {
    font-style: italic;
    font-weight: 400;
  }

  .power-select-default-option {
    font-weight: 400;

    &.power-select-option-partition {
      font-weight: 400;
    }

    &.disabled {
      color: $color-silver;
    }
  }
}

.ember-power-select-dropdown {
  &.power-select-default {
    li:last-child {
      border-top: 1px solid $color-light-dark-grey;
      @include sticky;

      background-color: white;

      &:hover {
        background-color: $color-weird-green-light;
      }
    }
  }

  &.power-action-select-dropdown {
    &.mark-selected {
      .ember-power-select-option[aria-selected="true"] {
        background-color: rgba($color-weird-green, 0.25);
      }
    }

    &.no-padding {
      > ul {
        padding-top: 0;
        padding-bottom: 0;
        min-width: 180px;
      }
    }
  }

  &.power-select-option-ellipsis {
    .ember-power-select-options .ember-power-select-option {
      @include text-ellipsis;
    }
  }
}

.ember-power-select-options {
  .ember-power-select-option {
    .power-select-unselected-option {
      > span {
        color: $color-steel;
      }

      svg {
        fill: $color-steel;
      }
    }

    &[aria-disabled="true"] {
      .power-option-name {
        color: $color-light-dark-grey;
      }

      svg {
        fill: $color-light-dark-grey;
      }
    }
  }
}

.power-select-force-scroll {
  ul {
    @include force-mac-scroll;
  }

  // .tree-view-options-dropdown {
  //   .ember-power-select-options {
  //     padding: 0;
  //   }
  // }
}

.tree-view-options-dropdown {
  .ember-power-select-options {
    padding: 0;
  }
}
