/* stylelint-disable declaration-no-important */
.modal-container.add-model-to-budget-modal .modal {
  .modal-dialog {
    width: fit-content;
    min-width: 420px;
    max-width: 520px;

    .modal-content {
      .modal-body {
        padding: 10px 0;
      }

      .modal-header .modal-header-inner .modal-title {
        margin-left: 30px;
        margin-right: 30px;

        .modal-title-text {
          font-size: 36px;
          padding-right: 0;
        }
      }
    }
  }

  .budget-tree {
    max-height: 400px;
    overflow-y: auto;

    li {
      border: 1px solid transparent;
      padding: 0 30px;
      position: relative;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      @include flexbox;

      > span.line-name {
        flex-shrink: 100000;
        line-height: 38px;
        @include text-ellipsis;
      }

      .display-account-ids {
        padding-left: 8px;

        * {
          font-size: 16px;
          line-height: 38px;
        }
      }

      input {
        background: transparent;
        height: 36px !important;
        width: 100%;
        border-color: $color-dark-mint-two !important;
        color: $color-dark-mint-two;
      }

      .svg-icon {
        position: absolute;
        right: 20px;
        top: 6px;

        svg {
          height: 18px;
          fill: $color-dark-mint-two;
        }
      }

      &.budget-line {
        height: 38px;
        line-height: 36px;
      }

      &.insert-new {
        height: 3px;
      }

      &:not(.allowed) {
        cursor: not-allowed;
      }

      &.added,
      &.selected {
        background: $color-duck-egg-blue;
      }

      &.allowed:not(.new-line):hover {
        border: 1px solid $color-weird-green;

        &.insert-new {
          background: $color-weird-green;
        }
      }

      @for $i from 0 through 20 {
        &[data-level="#{$i}"] {
          padding-left: 30px + 12px * $i;
        }
      }
    }
  }
}
