/* stylelint-disable max-nesting-depth, declaration-block-no-redundant-longhand-properties, font-family-no-missing-generic-family-keyword, no-duplicate-selectors, declaration-no-important */
$data-row-height: 40px;
$data-rows-container-laptop-height: calc(#{$data-row-height} * 3.5); // 3.5 rows
$data-rows-container-height: calc(#{$data-row-height} * 5.5); // 5 rows

.main-content .inner-content form,
.modal-container .modal.add-employee-modal-dialog .modal-content,
.modal.edit-line-modal-dialog .modal-content,
.main-content[data-path="main.budget.accounts"] .accounts-dimensions {
  .budgeta-multi-select-alert {
    flex-wrap: wrap;
  }

  .budgeta-type-value {
    height: 40px;
    border-bottom: 1px solid $color-silver;
    width: auto !important;
    max-width: none !important;

    &:not(.budgeta-type-dropdown-extra-short) {
      min-width: 0;
    }

    &:hover {
      &:not(.budgeta-fix-assumptions):not(.budgeta-type-value-add):not(.budgeta-type-multi-value):not(
          .budgeta-type-currency-multi
        ):not(.budgeta-type-custom-amounts):not(.budget-without-highlight-value-border):not(.no-border-on-over) {
        @include highlight-value-border;
      }
    }

    &:hover {
      &:not(.budgeta-fix-assumptions):not(.budgeta-type-value-add):not(.budgeta-type-multi-value):not(
          .budgeta-type-currency-multi
        ):not(.budgeta-type-custom-amounts):not(.budgeta-type-value-design):not(.no-border-on-over) {
        @include highlight-value;
      }
    }

    label.error {
      position: absolute;
      bottom: -30px;
      left: 10px;
    }

    > div.currency.has-currency-selection {
      > input:not(:first-child) {
        width: 95px !important;
      }
    }

    .budgeta-type-value:not(.show-border) {
      border: none;
    }

    > * {
      display: block;
    }

    label {
      &:not(.error) {
        @include big-mid-text-steel;
      }

      height: 40px !important;
      line-height: 40px;
      width: auto;
      max-width: none;
      @include flex(0 0 auto);

      // background-color: rgba(#ff0000, 0.15);
      &.label-after {
        @include big-mid-text-grey-blue;

        line-height: 40px;
      }
    }

    &:not(.budgeta-type-value-function) {
      label {
        min-width: 100px;

        &.single-char {
          min-width: 20px;
        }
      }
    }

    input {
      min-width: 0;

      &:not(.show-border) {
        border: none;
      }

      &.show-border {
        border-top: none;
        border-left: none;
        border-right: none;
      }

      background-color: transparent;
      @include big-mid-text-grey-blue;

      height: 40px !important;

      &:focus {
        outline: 0;
      }

      &:-webkit-autofill {
        background: transparent;
        border-bottom: 1px solid $color-silver !important;
        box-shadow: 0 0 0 1000px #fff inset;
        -webkit-text-fill-color: $color-dark-grey-blue !important;
      }
    }

    .select2-container {
      border: none;
      padding-bottom: 0;
      padding-top: 0;

      .select2-default {
        color: $color-silver !important;
        font-size: 20px !important;
        font-family: Metric, sans-serif;
      }

      a:not(.select2-search-choice-close) {
        height: 40px;

        span.select2-chosen {
          line-height: 40px;
        }

        span.select2-arrow {
          b::after {
            top: 7px;
          }
        }
      }
    }

    &.has-placeholder {
      .select2-container {
        a * {
          color: $color-green;
        }
      }
    }

    &.limit-shrink {
      min-width: 345px;
    }

    .formula-editor-button {
      @include add-icon-list;

      float: right;
      bottom: 3px;

      .svg-icon {
        @include centerElement;

        .icon {
          fill: $color-white;
        }
      }

      & + label.error {
        bottom: -30px;
      }

      &:hover {
        .svg-icon {
          .icon {
            fill: $color-dark-grey-blue;
          }
        }
      }
    }

    &.has-label-after {
      position: relative;

      > input {
        display: inline-block;
        padding-right: 80px;
        width: 100%;
      }

      .label-after {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      &.has-formula-button {
        &.formula input {
          @include text-ellipsis;
        }

        > input {
          padding-right: 100px;
        }

        &.ignore-budgeta-style {
          .label-after {
            right: 5px;
          }
        }

        &:not(.ignore-budgeta-style) {
          .label-after {
            right: 50px;
          }

          &.has-attached {
            .budgeta-type-dropdown {
              min-width: 120px;
              margin-right: 20px;
            }

            label.label-after {
              right: 160px;
            }

            &[data-key="interestRate"] {
              label.label-after {
                min-width: 15px;
              }

              input {
                padding-right: 20px;
              }
            }
          }
        }
      }

      > .budgeta-type-dropdown {
        @media screen and (max-width: 1500px) {
          max-width: 120px !important;
        }
      }
    }

    &.has-attached {
      > input {
        width: 150px;
      }
    }

    &.budgeta-type-value-design {
      margin-bottom: 20px;

      label {
        width: 150px;
        display: inline-block;
      }

      > div {
        display: inline-block;
      }
    }

    &.budgeta-type-dropdown {
      border: none;

      &.budgeta-type-dropdown-ellipsis {
        @include text-ellipsis;

        .budget-select-lazy {
          @include text-ellipsis;
        }
      }

      .dropdown-select {
        position: relative;

        .path-list-element-lazy,
        .path-list-element,
        .path-item-name,
        .placeholder {
          font-size: 20px;
        }
      }

      .ember-selectize {
        .selectize-input {
          padding-right: 40px;
        }
      }

      .ember-selectize {
        .selectize-input {
          input {
            @include text-ellipsis;
          }
        }
      }

      label:not(.label-after) {
        display: inline-block;
      }

      &:not(.budgeta-type-value-function):not(.budgeta-type-custom-amounts):not(.budgeta-type-value-add-block):not(
          .budgeta-type-currency-multi
        ) {
        border: none;
        border-bottom: 1px solid $color-silver;
      }

      .add-margin-top {
        @include flex(0 0 24px);
      }

      .attribute-modifier-content {
        label {
          min-width: auto;
        }

        input {
          width: calc(100% - 145px) !important;
        }
      }

      .select2-container,
      .dropdown {
        border: none;
        height: 40px !important;

        a {
          * {
            @include big-mid-text-grey-blue;
          }

          b::after {
            color: $color-dark-grey-blue;
          }
        }

        &.select2-container-active {
          outline: 0;
        }

        &.pct-fixed-select {
          max-width: 100px;
          width: 100%;

          &.dropdown-readonly {
            > span {
              visibility: hidden;
            }
          }
        }

        [data-toggle="dropdown"] {
          &::after {
            right: 5px;
          }
        }
      }

      &.align-top {
        .select2-container {
          vertical-align: top;
        }
      }

      .dropdown {
        line-height: 40px !important;

        a {
          outline: 0;
          border: none;
          height: 100%;

          &::after {
            top: 4px;
            color: $color-dark-grey-blue;
          }
        }
      }
    }

    &.budgeta-type-dropdown-max {
      .select2-container {
        a.select2-choice {
          span.select2-chosen {
            max-width: 275px;
          }
        }
      }
    }

    &.budgeta-type-custom-amounts {
      border: none;
      height: auto;

      .custom-amount {
        .formula input {
          @include text-ellipsis;
        }

        &:not(.no-border) {
          border-bottom: 1px solid $color-silver;
        }

        position: relative;

        p {
          bottom: -30px;
        }

        &:hover {
          @include highlight-value-border;
          @include highlight-value;
        }

        label {
          @include flex(0 0 110);
        }

        div.currency {
          @include flex(1 1);
        }

        input {
          border: none;
        }
      }

      .full {
        min-width: 80%;
      }

      &:not(.without-currency-type) {
        .custom-amount {
          @include flexbox-important;

          width: calc(50% - 20px);
          max-width: calc(50% - 20px);
          margin-left: 36px;

          &:nth-child(n + 3) {
            margin-top: 10px;
          }

          &:nth-child(2n + 1) {
            margin-left: 0;
          }
        }
      }

      &.without-currency-type {
        .custom-amount {
          @include flexbox-important;

          width: calc(33% - 22px);
          max-width: calc(33% - 22px);
          margin-left: 36px;

          &:nth-child(n + 4) {
            margin-top: 10px;
          }

          &:nth-child(3n + 1) {
            margin-left: 0;
          }
        }

        .full {
          margin-left: 36px;
        }
      }
      @media screen and (max-width: 1500px) {
        &:not(.without-currency-type) {
          .custom-amount {
            @include flexbox-important;

            width: calc(50% - 18px);
            max-width: calc(50% - 18px);
            margin-left: 24px;
          }

          .full {
            margin-left: 24px;
          }
        }

        &.without-currency-type {
          .custom-amount {
            @include flexbox-important;

            width: calc(33% - 17px);
            max-width: calc(33% - 17px);
            margin-left: 24px;
          }

          .full {
            margin-left: 24px;
          }
        }
      }
    }

    &.budgeta-type-value-add:not(.budgeta-type-value-add-block) {
      border: none;
      height: auto;
      padding-bottom: 45px;

      &:not(.budgeta-type-value-add-block) {
        > label {
          border-bottom: 1px solid $color-silver;
        }
      }

      .list-add-line {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid $color-silver;

        .add-icon-list {
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      &.single-value {
        &:hover {
          @include highlight-value-border;
          @include highlight-value;
        }
      }

      .data-rows-wrapper {
        width: 100%;

        .data-row {
          border-bottom: 1px solid $color-silver;
          height: 40px;
          width: 100%;
          margin-bottom: 7px;

          input {
            width: 100%;

            &:focus {
              @include highlight-value-border;
              @include highlight-value;
            }
          }

          label.wide {
            min-width: 180px;
          }

          &:hover {
            @include highlight-value-border;
            @include highlight-value;
          }
        }

        .add-icon-list {
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }

    &.budgeta-type-value-add-block {
      height: 100%;

      > div {
        margin-left: 40px;
      }

      &.single-allocated {
        > label,
        .select2-container {
          border-bottom: 1px solid $color-silver;
        }
      }

      .data-rows-wrapper {
        max-width: 40%;

        .data-row {
          border-bottom: 1px solid $color-silver;

          label.symbol {
            min-width: auto;
          }

          .remove-line {
            width: 55px;
            right: -55px;
          }
        }
      }

      .add-icon-wrapper {
        margin-left: 60px;
        height: 40px;
      }
    }

    &.budgeta-type-checkbox {
      border: none;

      label {
        @include flex(0 0 100%);

        width: 100%;
        max-width: 100%;

        &[data-ember-action] {
          input[type="checkbox"] {
            pointer-events: none;
          }
        }
      }
    }

    &.budget-type-multi {
      min-height: 40px;
      height: auto !important;

      .select2-container {
        border: none;
        padding-top: 1px;
        height: auto !important;

        input {
          border: none;
        }

        ul.select2-choices {
          border: none;
          margin-top: -2px;
          @include box-shadow(none);

          li.select2-search-choice {
            border: none;
            background-image: none;
            background-color: $color-white;
            height: 35px;
            line-height: 35px;

            div {
              @include big-mid-text;
            }

            a {
              @include centerVElement;
            }
          }

          li.select2-search-field {
            input {
              outline: 0;
              @include box-shadow(none);

              &:focus {
                @include box-shadow(none);
              }
            }
          }
        }

        &.select2-container-active {
          outline: 0;
          @include box-shadow(none);
          @include transition(none);
        }
      }

      .tag-list {
        @include flex-important(1 1 auto);

        &.empty {
          display: none !important;
        }
      }
    }

    &.budgeta-type-datepicker {
      border: none;

      label:not(.error) {
        border-bottom: 1px solid $color-silver;
      }

      .month-picker-wrapper {
        display: block !important;

        > * {
          .month-picker {
            width: 100%;

            input {
              border-bottom: 1px solid $color-silver;
              text-align: right;
            }
          }
        }

        .year-picker-to {
          float: right;
        }
      }
    }

    &.budgeta-type-datepicker-start-end {
      border: none;

      .month-picker-wrapper {
        @include flexbox-important;

        .year-picker-from,
        .year-picker-to {
          @include flex(1 1);
          @include flexbox-important;

          > label {
            float: left;
          }

          > .month-picker {
            @include flex(1 1);

            width: auto;
            display: inline-block;

            input {
              width: 100%;
            }
          }
        }
      }
    }

    &.budgeta-name-value {
      border: none;

      .data-row {
        > .assumption-name,
        > .budgeta-type-dropdown {
          margin-right: 36px;
        }

        > .budgeta-type-dropdown {
          border-bottom: 1px solid $color-silver;
        }
      }

      &:not(.ignore-budgeta-style) {
        .data-row {
          @media screen and (max-width: 1500px) {
            > .assumption-name,
            > .budgeta-type-dropdown {
              margin-right: 24px;
            }
          }
        }
      }
    }

    &.budgeta-type-textarea {
      border: none;
      height: auto;

      label {
        margin-bottom: 0;
        height: 87px !important;
        border-bottom: 1px solid $color-silver;
      }

      &.budgeta-type-textarea-model-line {
        label {
          height: 40px !important;
        }
      }

      &:not(.budgeta-type-textarea-model-line) {
        label {
          height: 87px !important;
        }
      }

      textarea {
        padding: 7px 0;
        border: none;
        border-bottom: 1px solid $color-silver;
        resize: none;
        outline: 0;
        @include big-mid-text-grey-blue;
      }
    }

    &.budgeta-type-value-function {
      border-bottom: 1px solid $color-silver;

      label {
        min-width: none;
      }

      > div {
        width: 100%;
        position: relative;

        * {
          border: none;
        }

        > input:first-child {
          width: calc(100% - 140px) !important;

          &[name="newAmount"] {
            width: calc(100% - 130px) !important;

            + .form-control .select2-arrow {
              right: 10px;
            }
          }
          @include text-ellipsis;
        }

        .formula-editor-button {
          @include add-icon-list;

          .svg-icon {
            margin: 0 0 0 2px;
            @include centerElement;
          }

          position: absolute;
          right: 0;
          bottom: 3px;
        }

        .dropdown.pct-fixed-select {
          a {
            padding-right: 0;
            min-width: 75px;
          }
        }
      }
    }

    &.budgeta-type-datepicker-singel-label {
      > label {
        min-width: 70px;
      }

      .month-picker-wrapper.without-to {
        .year-picker-from {
          width: 100%;
        }
      }

      .month-picker-wrapper:not(.without-to) {
        .year-picker-from,
        .year-picker-to {
          width: calc(50% - 18px);
        }
      }

      .year-picker-from,
      .year-picker-to {
        .month-picker {
          input {
            width: 100%;
          }
        }
      }
      @media screen and (max-width: 1500px) {
        .month-picker-wrapper:not(.without-to) {
          .year-picker-from,
          .year-picker-to {
            width: calc(50% - 12px);
          }
        }
      }
    }

    &.budgeta-fix-assumptions {
      height: auto;
      margin: 0;
      width: 100% !important;

      .data-rows-wrapper {
        max-height: $data-rows-container-height;

        @media screen and (max-width: 1200px) {
          max-height: $data-rows-container-laptop-height;
        }

        overflow-y: auto;
        padding-bottom: 10px;
      }

      .svg-icon.add {
        line-height: 50px;
      }

      .panel-heading {
        line-height: 50px;
        width: 100%;
        @include flexbox-important;

        > a {
          @include flex(0 0 auto);

          .svg-icon {
            svg {
              fill: $color-dark-mint;
            }
          }
        }

        .right {
          @include flex(0 0 auto);
        }

        .assumptions-string {
          @include flex(1 1);

          padding-left: 30px;
          color: $color-steel;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          vertical-align: middle;
        }
      }

      &.collapsed {
        .panel-heading {
          > a .svg-icon {
            @include transform(rotate(-90deg));
          }
        }
      }

      .data-row {
        height: $data-row-height;
        cursor: grab;

        &.assumption-public {
          .external-toggle {
            svg {
              fill: $color-weird-green;
            }
          }
        }

        &.new-missing-assumption {
          @include border-radius(3px);

          background-color: rgba($color-error, 0.5);

          .help-iq {
            width: 20px;
          }
        }

        width: calc(100% - 20px);
        padding-left: 30px;

        .missing-ref {
          position: absolute !important;
          left: 0;
          top: 5px;
          z-index: 1;
          width: 25px;
          height: 25px;
        }

        .drag-handle {
          position: absolute !important;
          left: 0;
          top: 20px;
          display: none !important;
        }

        &:hover {
          .drag-handle {
            display: block !important;
          }
        }

        label.row-label,
        input,
        .select2-container {
          border-bottom: 1px solid $color-silver;
        }

        > div:not(:last-child) {
          margin-right: 36px;
        }
        @media screen and (max-width: 1500px) {
          > div:not(:last-child) {
            margin-right: 24px;
          }
        }

        .svg-icon {
          @include flex(0 0 24px);

          position: relative;

          .icon {
            @include centerVElement;
          }
        }

        .select2-container {
          height: 40px;
        }

        .attribute-modifier-content {
          @include flexbox-important;

          label.formula-output {
            border-bottom: 1px solid $color-silver;
            line-height: 40px;
          }

          label.error {
            bottom: -30px;
          }
        }

        .fix-assumption-actions {
          @include flex(0 0 80px);

          min-width: 80px;

          > div {
            min-width: 24px;
            height: 40px;
          }

          .svg-icon.disable {
            cursor: not-allowed;

            .icon {
              fill: $color-silver !important;
            }

            &:hover {
              .icon {
                fill: $color-silver !important;
              }
            }
          }
        }

        .fix-assumption-input {
          @include flex(1 1);
          @include flexbox-important;

          > *:not(.help-iq) {
            float: left;
          }

          .help-iq {
            width: 25px;
          }

          label {
            min-width: 0 !important;

            &.model-line-label {
              margin-left: 3px;
              @include flex(0 0 auto !important);

              height: 24px !important;
              margin-top: 10px;
              line-height: 24px;
              padding: 0 12px;
              @include border-radius(3px);

              &.linked {
                background-color: $color-white-light-grey;
                color: $color-steel;
              }

              &.input {
                background-color: $color-blue-changes;
                color: white;
              }

              &.error,
              &.reference,
              &.loop {
                background-color: $color-red;
                color: white !important;
                font-size: 14px !important;
                padding: 0 3px;
              }
            }
          }

          input {
            padding-left: 16px;
          }

          div {
            @include flex(1 1);

            .attribute-modifier-content {
              @include flexbox-important;

              input {
                width: calc(100% - 100px);
                @include flex(1 1);
              }
            }
          }

          &.fix-assumption-input-comment {
            margin-right: 0;
          }

          &:hover {
            @include highlight-value-border;
            @include highlight-value;
          }
        }
      }

      .add-icon-list {
        margin-top: 5px;
      }
    }

    &.budgeta-type-dropdown-short {
      .select2-container {
        border-bottom: 1px solid $color-silver;
      }

      &.budgeta-type-dropdown-extra-short {
        > label {
          min-width: auto;
        }
      }

      &[data-key="filterType"],
      &[data-key="useCalculation"] {
        height: auto;
        min-height: 40px;

        .select2-container.ember-view.form-control.input-md.wrap {
          border-color: transparent;

          &:hover {
            border-color: transparent !important;
          }
        }

        &:hover .select2-container.ember-view.form-control.input-md.wrap {
          border-color: transparent !important;
        }
      }
    }

    &.budgeta-type-multi-value {
      height: auto;
      border: none;

      .data-row {
        border-bottom: 1px solid $color-silver;

        &:hover {
          @include highlight-value-border;
          @include highlight-value;
        }
      }
    }

    &.budgeta-type-currency-multi {
      height: auto;
      border: none;
      position: relative;

      &:not(.ignore-budgeta-style) {
        padding-bottom: 45px;
      }

      > label {
        border-bottom: 1px solid $color-silver;
      }

      .currency {
        height: 40px;
        @include flex(1 1 !important);

        border-bottom: 1px solid $color-silver;

        &:hover {
          @include highlight-value;
          @include highlight-value-border;
        }
      }

      .data-row {
        position: relative;
        border-bottom: 1px solid $color-silver;

        &:hover {
          @include highlight-value-border;
        }

        .currency {
          border: none;

          input {
            border: none;
          }
        }

        .formula-editor-button {
          position: absolute;
          right: 0;
          bottom: 3px;
        }

        .right {
          margin-right: 40px;

          > * {
            float: left;
          }

          label {
            min-width: auto;
          }

          .month-picker-wrapper {
            height: 40px;
          }

          .error {
            &:not(.has-error) {
              display: none;
            }

            position: absolute;
            top: calc(100% - 5px);
            right: 40px;
          }
        }
      }

      .amount-change {
        > .right {
          > label {
            width: 80px !important;
          }
        }
      }

      .add-icon-wrapper {
        margin-bottom: -5px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    &.budgeta-geo-multi {
      .data-row {
        margin-top: 0;

        > *:not(.svg-icon):not(p) {
          border-bottom: 1px solid $color-silver;
          height: 40px;
        }

        > label {
          min-width: auto;
        }

        &:hover {
          @include highlight-value-border;
        }

        .geo-label:hover {
          + .geo-wrapper {
            @include highlight-value;
          }
        }

        .geo-wrapper:hover {
          @include highlight-value;
        }

        .second-label:hover {
          + .second-input {
            @include highlight-self-value;
          }
        }

        .second-input:hover {
          @include highlight-self-value;
        }
      }

      .add-icon-wrapper {
        margin-top: 5px;

        .add-icon-list {
          float: right;
        }
      }
    }

    &.budgeta-type-inner {
      .budgeta-type-value {
        @include flex(1 1);
      }
    }

    &.budgeta-type-empty {
      display: none !important;
    }

    &.budgeta-type-model-line {
      .attribute-modifier-content {
        > input {
          float: left;
        }
      }
    }

    &.data-table-wrapper {
      margin-top: 30px !important;
      margin-bottom: 15px !important;
      width: 100% !important;
      max-width: 100% !important;
      @include flex(1 1 auto !important);

      display: block !important;
      height: auto !important;

      .data-table-content {
        width: 66%;
        min-width: 600px;
        border-bottom: 2px solid $color-silver;

        .data-table-row {
          &.data-table-header {
            border-top: 1px solid $color-silver;
            border-bottom: 2px solid $color-silver;

            * {
              font-size: 16px;
              color: $color-steel;
              line-height: 48px;
            }
          }

          &:nth-child(odd):not(.data-table-header) {
            background-color: rgba($color-white-soft-grey, 0.5);
          }
          @include flexbox-important;

          .data-table-column {
            position: relative;
            vertical-align: middle;
            @include flex(1);

            > label {
              margin-left: 10px;
            }

            input {
              padding: 0;
            }

            &:hover {
              @include highlight-value;

              cursor: default;
            }

            .budgeta-type-value-add {
              height: auto;
              padding-bottom: 0;
              width: calc(100% - 50px) !important;

              label {
                display: none;
              }

              .list-add-line {
                input {
                  width: 100%;
                }
              }

              .remove-line {
                right: 0;
                left: auto;
              }

              .add-icon-list {
                right: 0;
                left: auto;
                height: 30px;
                width: 30px;
                bottom: 5px;
              }
            }
          }

          &:last-child {
            .budgeta-type-value-add {
              .list-add-line {
                border: none !important;
              }
            }
          }
        }
      }
    }

    &.add-logic-checkbox {
      display: grid !important;
      grid-template-areas:
        "label-text dropdown"
        "checkbox dropdown";
      grid-template-columns: 0fr 1fr;
      grid-template-rows: 0fr 1fr;

      > label {
        grid-area: label-text;
      }

      > .select2-container {
        grid-area: dropdown;
      }

      > .budgeta-checkbox {
        grid-area: checkbox;
      }
    }
  }

  .panel-body {
    .sticky-wrapper {
      margin-right: 0 !important;
    }

    .grid-head {
      margin: 0;
      background-color: transparent;
      border-top: 1px solid $color-silver;
      border-bottom: 2px solid $color-silver;
      height: 48px;

      .attr-cols-wrapper {
        width: 100%;
      }

      label {
        background-color: transparent;
        @include small-text-steel;

        height: 48px;
        line-height: 48px !important;
        padding: 0 0 0 10px !important;
        width: auto;
        max-width: none;
      }
    }

    .grid-row {
      padding: 0;
      margin: 0 !important;
      margin-right: 0 !important;

      > .budget-attributes-wrapper {
        width: 100%;

        > div {
          @include flex-wrap(nowrap);

          > * {
            margin: 0;
          }
        }
      }

      &:not(.grid-head) {
        * {
          font-size: 16px;
        }

        a * {
          font-size: 16px !important;
        }

        &:nth-child(odd) {
          background-color: rgba($color-white-soft-grey, 0.5);
        }

        height: 45px;

        input {
          background-color: transparent;
        }
      }

      input {
        outline: 0;
        border: none !important;
        border-bottom: none !important;
      }

      .budgeta-type-value {
        border: none !important;
        border-bottom: none !important;

        > label {
          display: none;
        }

        * {
          border: none !important;
          border-bottom: none !important;
        }

        &.budgeta-type-datepicker .month-picker-wrapper {
          > * {
            width: calc(50% - 5px);
          }
        }

        &.has-label-after > input {
          padding: 10px;
        }
      }
    }
  }
}

.main-content .inner-content form {
  .children-grid {
    .currency.has-currency-selection {
      > input:first-child {
        width: 100% !important;
      }
    }
  }
}

.main-content .inner-content form {
  div.panel-body,
  div:not(.section-row) {
    > .budgeta-type-value:not(.ignore-budgeta-style) {
      &:not(.budgeta-type-textarea):not(.budgeta-type-custom-amounts):not(.budgeta-fix-assumptions):not(
          .budgeta-type-value-add-block
        ):not(.budgeta-type-value-function):not(.budgeta-type-datepicker-singel-label) {
        @include size-2-6;
      }

      &.budgeta-type-dropdown {
        &:not(.budgeta-type-dropdown-short):not(.budgeta-type-currency):not(.budgeta-type-custom-amounts):not(
            .budgeta-type-value-add-block
          ):not(.budgeta-type-value-function) {
          @include size-3-6;
        }
      }

      &.budgeta-type-checkbox {
        @include size-2-6;
      }

      &.budgeta-type-datepicker:not(.budgeta-type-datepicker-singel):not(.budgeta-type-datepicker-singel-label) {
        @include size-3-6;

        .month-picker-wrapper {
          &:not(.without-to) {
            > * {
              width: calc(50% - 18px);
            }
            @media screen and (max-width: 1500px) {
              > * {
                width: calc(50% - 12px);
              }
            }
          }
        }
      }

      &.budgeta-type-value-add-block {
        @include size-2-6;
      }

      &.budgeta-type-datepicker-singel {
        &:not(.budgeta-type-datepicker-singel-label) {
          @include size-2-6;
        }

        .year-picker-from,
        .year-picker-to {
          width: 100%;

          .month-picker {
            > * {
              width: 100%;
            }
          }
        }
      }

      &.budgeta-type-textarea {
        @include size-6-6;
      }

      &.budgeta-type-value-function {
        @include size-2-6;

        &:not(.budgeta-type-currency):not(.budgeta-type-model-line) {
          @include size-3-6;
        }
      }

      &.budgeta-type-currency:not(.budgeta-type-currency-multi) {
        @include size-2-6;
      }

      &.budgeta-type-dropdown-short {
        @include size-2-6;
      }

      &.budgeta-type-dropdown-long {
        @include size-3-6;
      }

      &.budgeta-type-datepicker-singel-label {
        @include size-2-7;
      }

      &.budgeta-fix-assumptions {
        @include size-6-6;
      }

      &.budgeta-type-custom-amounts {
        &:not(.without-currency-type) {
          @include size-4-6;
        }

        &.without-currency-type {
          @include size-6-6;
        }
      }

      &.budgeta-type-currency-multi {
        @include size-2-6;
      }

      &.budgeta-geo-multi {
        @include size-3-6;
      }
    }
  }

  div.section-row {
    .budgeta-type-value:not(.ignore-budgeta-style) {
      @include size-2-6;

      &.budgeta-type-custom-amounts {
        @include size-4-6;
      }

      &.budgeta-geo-multi {
        @include size-3-6;
      }

      &.budgeta-type-dropdown-long {
        @include size-3-6;
      }

      &.budgeta-type-dropdown-short {
        @include size-2-6;
      }

      &.budgeta-type-datepicker-singel-label {
        @include size-2-6;
      }
    }
  }
  @media screen and (max-width: 1500px) {
    div.panel-body,
    div:not(.section-row) {
      > .budgeta-type-value:not(.ignore-budgeta-style) {
        &:not(.budgeta-type-textarea) {
          @include size-2-4;
        }

        .formula-editor-button {
          .svg-icon {
            .icon {
              fill: $color-steel;
            }
          }
        }

        &.budgeta-type-dropdown {
          &:not(.budgeta-type-dropdown-short):not(.budgeta-type-currency):not(.budgeta-type-custom-amounts):not(
              .budgeta-type-inner
            ) {
            @include size-2-4;
          }

          &.budgeta-type-inner:not(.budgeta-type-dropdown-short) {
            @include size-3-4;
          }
        }

        &.budgeta-type-checkbox {
          @include size-2-4;
        }

        &.budgeta-type-datepicker:not(.budgeta-type-datepicker-singel):not(.budgeta-type-datepicker-singel-label) {
          @include size-2-4;

          .month-picker-wrapper {
            &:not(.without-to) {
              > * {
                width: calc(50% - 18px);
              }
              @media screen and (max-width: 1500px) {
                > * {
                  width: calc(50% - 12px);
                }
              }
            }
          }
        }

        &.budgeta-type-datepicker-start-end {
          @include size-2-4;
        }

        &.budgeta-type-datepicker-singel {
          @include size-2-4;

          .year-picker-from,
          .year-picker-to {
            width: 100%;

            .month-picker {
              > * {
                width: 100%;
              }
            }
          }
        }

        &.budgeta-type-textarea {
          @include size-4-4;
        }

        &.budgeta-type-value-function {
          &:not(.budgeta-type-currency) {
            @include size-2-4;
          }
        }

        &.budgeta-type-currency {
          @include size-2-4;
        }

        &.budgeta-type-dropdown-short {
          @include size-2-4;
        }

        &.budgeta-fix-assumptions {
          @include size-4-4;
        }

        &.budgeta-type-currency-multi {
          @include size-2-4;
        }

        &.budgeta-geo-multi {
          @include size-3-4;
        }

        &.budgeta-type-custom-amounts {
          @include size-4-4;
        }
      }
    }

    div.section-row:not(.ignore-budgeta-style) {
      .budgeta-type-value {
        @include size-2-4;

        &.budgeta-geo-multi {
          @include size-3-4;
        }

        &.budgeta-type-dropdown-short {
          @include size-2-4;
        }
      }
    }
  }

  @media screen and (max-height: 900px) {
    .budgeta-type-value:not(.ignore-budgeta-style) {
      &.budgeta-fix-assumptions {
        height: auto;
        margin: 0;
        width: 100% !important;
        flex-basis: 100%;

        .data-rows-wrapper {
          overflow-y: auto;
        }
      }
    }
  }
}

.main-content .main-content-overflow .inner-content #grid-flyout-wrapper form .budget-attributes-wrapper.short-view {
  .budgeta-type-value,
  .budgeta-type-value.budgeta-type-value-even,
  .budgeta-type-value.budgeta-type-dropdown,
  .budgeta-type-value.budget-type-multi,
  .budgeta-type-value.budgeta-type-value-odd {
    @include full-flex-values(1, calc(50% - 50px));

    max-width: calc(50% - 50px);

    .dropdown a::after {
      top: 0;
      right: 15px;
    }
  }

  .budgeta-type-value.budgeta-type-custom-amounts {
    @include full-flex-values(1, calc(100% - 50px));
  }

  .section-dimensions {
    .budgeta-type-value .data-rows-wrapper {
      flex: 1;
      max-width: 55%;
    }

    [data-dimension] {
      flex-wrap: wrap;
    }

    .allocate-by-headcount {
      flex-basis: 100%;
      margin-bottom: 5px;
      margin-left: 0;
    }
  }

  .section-scenario .panel-body .budgeta-type-value {
    flex: 1 45%;
  }

  .section-advanced {
    [data-key="advancedRevenue"] {
      flex-basis: 100%;
    }

    [data-key="advancedRevenuePeriod"] {
      flex-grow: 0;
      flex-basis: calc(50% - 38px);

      @media screen and (max-width: 1500px) {
        flex-basis: calc(50% - 30px);
      }
    }
  }

  .section-general [data-key="startDate"] {
    flex-basis: 100%;
  }

  [data-key="paymentAfter"] input {
    min-width: 40px;
  }

  .section-depreciation {
    [data-key="amortizeOver"] {
      width: 50% !important; /* may be needed for browsers other than chrome */
      flex-basis: 50%;
    }
  }
}

.main-content
  .main-content-overflow
  .inner-content
  form
  div.fly-out-modal
  .budget-attributes-wrapper.short-view
  .panel
  .panel-collapse
  div.panel-body {
  .budgeta-type-value,
  .budgeta-type-value.budgeta-type-value-even,
  .budgeta-type-value.budgeta-type-dropdown,
  .budgeta-type-value.budget-type-multi,
  .budgeta-type-value.budgeta-type-value-odd {
    @include full-flex-values(0, calc(50% - 50px));

    max-width: calc(50% - 50px);

    &.budgeta-type-custom-amounts {
      @include full-flex-values(0, calc(100% - 50px));

      .custom-amount {
        width: calc(50% - 22px);
        max-width: calc(50% - 22px);
        margin-left: 0;

        &:nth-child(2n) {
          margin-left: 36px;
        }

        &:nth-child(n + 1) {
          margin-top: 10px;
        }
      }
    }

    .dropdown a::after {
      top: 0;
      right: 15px;
    }
  }
}

.app-view {
  .main-content .inner-content form {
    @media screen and (max-width: 1350px) {
      .budgeta-type-value {
        label {
          min-width: 80px;
        }
      }

      div.panel-body,
      div:not(.section-row) {
        > .budgeta-type-value:not(.ignore-budgeta-style) {
          &.budgeta-type-custom-amounts {
            @include size-4-4-small;

            .custom-amount {
              width: calc(50% - 21px);

              &:nth-child(2n) {
                margin-left: 24px;
              }

              &:nth-child(2n + 1) {
                margin-left: 0;
              }

              &:nth-child(n + 3) {
                margin-top: 10px;
              }
            }
          }
        }
      }

      div.section-row:not(.ignore-budgeta-style) {
        .budgeta-type-value {
          &.budgeta-type-custom-amounts {
            @include size-4-4-small;
          }
        }
      }
    }
  }
}

.main-content.main-content-open-tree .inner-content form {
  div.panel-body,
  div:not(.section-row) {
    > .budgeta-type-value:not(.ignore-budgeta-style) {
      &.budgeta-type-value-add-block {
        @include size-3-6;
      }

      &.budgeta-type-value-function {
        @include size-3-6;
      }
    }
  }

  div.section-row {
    .budgeta-type-value:not(.ignore-budgeta-style) {
      &.budgeta-type-value-function {
        @include size-3-6;
      }

      &.budgeta-type-inner {
        @include size-3-6;
      }
    }
  }

  @media screen and (max-width: 1500px) {
    div.panel-body,
    div:not(.section-row) {
      > .budgeta-type-value:not(.ignore-budgeta-style) {
        &.budgeta-type-dropdown-long.budgeta-type-value-function {
          @include size-3-4;
        }
      }
    }

    div.section-row:not(.ignore-budgeta-style) {
      .budgeta-type-value {
        &.budgeta-type-dropdown-long.budgeta-type-value-function {
          @include size-3-4;
        }
      }
    }
  }
}

.modal-container .modal.add-employee-modal-dialog .modal-content {
  .budgeta-type-value:not(.ignore-budgeta-style) {
    &:not(.budgeta-type-textarea):not(.budgeta-type-custom-amounts):not(.attribute-group-hidden):not(
        .attribute-hidden
      ) {
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

.main-content[data-path="main.budget.accounts"] .accounts-dimensions {
  .section-row {
    @include flexbox;

    width: 800px;

    .budgeta-type-value {
      @include flexbox;

      &.budgeta-type-dropdown {
        .budget-select-lazy {
          @include flex(1 1);

          > span {
            position: relative;
            display: block;
            width: 100%;
            padding-left: 10px;
            height: 100%;
            line-height: 40px;
            font-size: 20px;

            &.enabled::after {
              content: "\f107";
              color: #adadad;
              font-family: FontAwesome;
              display: block;
              position: absolute;
              right: 10px;
              top: 0;
              transition: all 0.4s ease;
              font-weight: bold;
              font-size: 16px;
              line-height: 45px;
            }
          }
        }
      }

      &.budgeta-type-value-even {
        margin-right: 36px;
      }
    }
  }
}

.budget-attributes-wrapper {
  .budget-select-lazy {
    &.read-only {
      > span,
      span.select-2-lazy-inactive-label {
        cursor: default;
      }
    }

    &:not(.read-only) {
      > span,
      span.select-2-lazy-inactive-label {
        &::after {
          content: "\f107";
          color: $color-dark-grey-blue;
          font-family: FontAwesome;
          font-weight: bold;
          font-size: 16px;
          line-height: 40px;
          display: block;
          position: absolute;
          right: 10px;
          top: 0;
          transition: all 0.4s ease;
          pointer-events: none;
        }
      }
    }

    > span,
    span.select-2-lazy-inactive-label {
      font-size: 20px;
      line-height: 40px;
      padding-left: 10px;
      display: inline-block;
      width: 100%;
      @include text-ellipsis;

      padding-right: 25px;

      &.prompt {
        color: $color-silver;
      }
    }
  }
}

.modal-container .modal.add-employee-modal-dialog .modal-content,
.modal.edit-line-modal-dialog .modal-content,
.main-content[data-path="main.budget.accounts"] .accounts-dimensions {
  .budgeta-type-value:not(.ignore-budgeta-style) {
    > input {
      padding-left: 10px;
    }

    .attribute-modifier-content > input {
      padding-left: 10px;
    }

    label.label-after {
      display: none;
    }

    &[data-key="workingFor"] {
      label.label-after {
        display: block;
        position: relative;
      }
    }

    &:not(.budgeta-type-textarea):not(.budgeta-type-custom-amounts):not(.attribute-group-hidden):not(
        .attribute-hidden
      ):not(.dimension-mapping) {
      &.budgeta-type-value-odd {
        margin-right: 0;
      }

      @include full-flex-values(0, calc(50% - 18px));
    }

    .formula-editor-button {
      .svg-icon {
        .icon {
          fill: $color-steel;
        }
      }
    }

    &.budgeta-type-textarea,
    &.budgeta-type-custom-amounts {
      @include full-flex-values(0, 100%);
    }

    &.budgeta-type-custom-amounts {
      margin-right: 0 !important;

      > .custom-amount {
        width: calc(50% - 18px);
        max-width: calc(50% - 18px);

        label {
          margin-bottom: 0;
        }

        &:nth-child(2n) {
          margin-left: 36px;
        }

        &:nth-child(2n + 1) {
          margin-left: 0;
        }

        &:nth-child(n + 3) {
          margin-top: 10px;
        }
      }
    }

    &.budgeta-type-datepicker:not(.budgeta-type-datepicker-singel):not(.budgeta-type-datepicker-singel-label) {
      .month-picker-wrapper {
        &:not(.without-to) {
          > * {
            width: calc(50% - 12px);
          }
        }
      }
    }

    &.budgeta-type-datepicker-singel {
      .year-picker-from,
      .year-picker-to {
        width: 100%;

        .month-picker {
          > * {
            width: 100%;
          }
        }
      }
    }
  }
}

body:not(.hiddenscroll) {
  .modal.edit-line-modal-dialog .modal-content {
    .budgeta-type-value:not(.ignore-budgeta-style) {
      &:not(.budgeta-type-textarea):not(.budgeta-type-custom-amounts):not(.attribute-group-hidden):not(
          .attribute-hidden
        ) {
        @include full-flex-values(0, 425px);
      }
    }
  }
}

body.hiddenscroll {
  .modal.edit-line-modal-dialog .modal-content {
    .budgeta-type-value:not(.ignore-budgeta-style) {
      &:not(.budgeta-type-textarea):not(.budgeta-type-custom-amounts):not(.attribute-group-hidden):not(
          .attribute-hidden
        ) {
        @include full-flex-values(0, 430px);
      }
    }
  }
}

.main-content .inner-content form {
  overflow-y: scroll;
}

.modal-container .modal.add-employee-modal-dialog .modal-content {
  overflow-y: hidden;
}

.main-content .line-edit-wrapper,
.main-content .grid-flyout-wrapper {
  .budgeta-type-value .select2-container {
    min-width: 0;
  }
}
