.my-details {
  li {
    @include flexbox;

    margin-bottom: 10px;

    > * {
      margin-left: 5px;
    }
  }

  .input-container {
    margin-bottom: 20px;
  }

  .header {
    @include flexbox;

    justify-content: space-between;
    align-items: center;

    .right-side {
      @include flexbox;
    }
  }
}
