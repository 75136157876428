/* stylelint-disable max-nesting-depth, prettier/prettier, declaration-block-no-duplicate-properties, declaration-block-no-shorthand-property-overrides, font-family-no-missing-generic-family-keyword, function-linear-gradient-no-nonstandard-direction, no-duplicate-selectors, selector-class-pattern, declaration-no-important */
$input-height-not-radio-or-checkbox: 40px;

.main-content {
  clear: both;
  height: calc(100% - 80px);
  position: relative;
  overflow-y: visible;
  overflow-x: hidden;
  margin-top: 0;
  @include transition(margin-top 0.3s);

  &[data-path="main.budget.actuals-accounts"] {
    .inner-content {
      background-color: $color-light-mid-opacity-grey;
    }
  }

  &[data-path="main.budget.dashboard"] {
    &.interactive-dashboard {
      &.interactive-collapse {
        height: calc(100% - 281px);
      }

      &.interactive-height1 {
        height: calc(100% - 326px);
      }

      &.interactive-height2 {
        height: calc(100% - 361px);
      }

      &.interactive-height3 {
        height: calc(100% - 396px);
      }

      &.interactive-max-height {
        height: calc(100% - 378px);
      }
    }

    &:not(.interactive-dashboard) {
      height: calc(100% - 213px);
    }
  }

  &:not([data-path="main.budget.dashboard"]) {
    &.main-toolbar-without-headers {
      height: calc(100% - 118px);
    }

    &.main-toolbar-full-header {
      height: calc(100% - 203px);
    }

    &.full-size-forecast {
      margin-top: -85px;
      height: calc(100% - 118px);
      @include transition(margin-top 0.3s);
    }
  }

  &.ie9 {
    position: absolute;
    top: 65px;
    bottom: 25px;
    right: 25px;
    left: 25px;
    background: #cfcfcf;
    text-align: center;
    padding: 10% 0;

    * {
      font-size: 16px;
    }

    h1 {
      font-family: "Arial Black", Gadget, sans-serif;
      font-size: 26px;
      font-weight: 800;
      font-weight: boldest;
    }
  }

  &[data-path="main.budget.index"] {
    .inner-content .scrollable {
      &.preview-open {
        &.has-preview {
          height: calc(100% - 297px);
        }

        &.has-large-preview {
          height: calc(100% - 295px);
        }

        &.has-pnl-preview {
          height: calc(100% - 387px);
        }
      }
    }
  }

  .main-content-overflow {
    width: 100%;
    height: 100%;
    @include flexbox-important;

    > .inner-content {
      .line-edit-wrapper-overlay,
      .grid-flyout-overlay {
        cursor: default;
        position: fixed;
        z-index: 20;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color-charcoal-grey, 0.9);
        animation: overlay-fade-in 0.3s;

        &.closing-modal {
          animation: overlay-fade-out 2s;
        }
      }

      .form-wrapper,
      .form-flyout-scroll {
        height: 100%;
      }

      .grid-flyout-wrapper {
        &.form-wrapper.fly-out-modal.short-view {
          .input-header-content .left {
            flex-wrap: wrap;

            .name {
              max-width: 100%;
              margin-bottom: 5px;
            }

            .type-wrapper {
              flex-basis: 50%;
              margin-bottom: 5px;
            }
          }
        }

        .bottom-bar-grid-flyout {
          padding-left: 43px;
          padding-right: 43px;
          border-top: 1px solid $color_silver;
          justify-content: flex-end;

          #undo-btn {
            padding-right: 20px;
            padding-left: 0;
          }

          #cancel-btn {
            text-align: left;
            padding-right: 20px;
          }
        }
      }

      .line-edit-wrapper,
      .grid-flyout-wrapper {
        position: fixed;
        width: 83%;
        background: white;
        z-index: 30;
        padding-bottom: 0;
        height: 100%;
        max-height: 100%;
        right: 0;
        top: 0;
        margin: 0;
        transition: width 0.5s;
        animation: open-fly-view 0.5s linear 0s 1 normal forwards;

        .breadcrumbs-header-with-dropdown {
          display: flex;

          &.open-dropdown {
            .dropdown-arrow {
              transform: rotateX(180deg);

              svg {
                height: 23px;
              }
            }
          }

          .dropdown-arrow {
            cursor: default;

            .svg-icon {
              cursor: pointer;

              svg {
                width: 18px;
                margin-top: 6px;
              }
            }
          }

          .basic-multi-select {
            position: absolute;
            width: 100%;
            z-index: 1000;

            .select__menu {
              box-shadow: 0 7px 9px 0 $color-shadow-box-light;
              margin-top: 0;
              border-radius: 0;

              .select__option {
                color: inherit;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                direction: rtl;
                text-align: left;
                background-color: unset;

                &:hover {
                  background-color: $color-green-background;
                }
              }
            }

            .select__control {
              border: 0;
              border-radius: 0;
              border-bottom: 1px solid $color-grey-border;

              .select__value-container {
                height: 45px;
                box-shadow: -1px 3px 11px 0 $color-shadow-box;

                .select__input {
                  input {
                    height: unset;
                  }
                }
              }
            }

            .select__control--is-focused {
              border: 0;
              box-shadow: none;
              border-bottom: 1px solid $color-grey-border;
            }
          }

          .breadcrumbs {
            .breadcrumbs-bar {
              max-width: unset;
            }
          }

          .dropdown {
            outline: none;
            border: none;
            margin-top: 15px;
            min-width: 40%;
          }
        }

        .line-edit-scroll,
        .form-flyout-scroll {
          animation: view-fade-in 1.5s;
        }

        &.short-view {
          transition: width 0.5s;
          width: 50%;

          .line-edit-scroll,
          .form-flyout-scroll {
            animation: small-view-fade-in 1.5s;
          }

          .modal-width-toggle {
            &::after {
              content: "\f104";
              right: 7px;
            }
          }

          .panel.section-dimensions {
            .data-rows-wrapper {
              margin-left: 0;
              width: calc(50% - 165px);
              min-width: calc(50% - 165px);
            }
          }
        }

        &.closing-modal {
          animation: close-fly-view 0.5s linear 0s 1 normal forwards;

          .line-edit-scroll,
          .form-flyout-scroll {
            animation: view-fade-out 0.5s;
          }
        }

        @keyframes small-view-fade-in {
          0% {
            opacity: 0;
          }

          50% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        @keyframes view-fade-in {
          0% {
            opacity: 0;
          }

          50% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        @keyframes view-fade-out {
          0% {
            opacity: 1;
          }

          50% {
            opacity: 0;
          }

          100% {
            opacity: 0;
          }
        }

        @keyframes overlay-fade-in {
          0% {
            opacity: 0;
          }

          30% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        @keyframes overlay-fade-out {
          0% {
            opacity: 1;
          }

          30% {
            opacity: 1;
          }

          100% {
            opacity: 0;
          }
        }

        @keyframes open-fly-view {
          0% {
            transform: translateX(100%);
          }

          50% {
            transform: translateX(100%);
          }

          100% {
            transform: translateX(0);
          }
        }

        @keyframes close-fly-view {
          0% {
            transform: translateX(0);
          }

          50% {
            transform: translateX(110%);
          }

          100% {
            transform: translateX(110%);
          }
        }

        .modal-width-toggle {
          background: $font-color-white;
          width: 20px;
          height: 25px;
          position: absolute;
          left: -25px;
          top: 15px;
          border-top-left-radius: 15%;
          border-bottom-left-radius: 15%;
          border: 1px solid $color-steel;
          border-right-width: 0;
          color: $color-steel;

          &::after {
            content: "\f105";
            font-family: FontAwesome;
            font-size: 22px;
            display: block;
            position: absolute;
            right: 5px;
          }

          &:hover {
            color: $color-dark-grey-blue;
          }
        }

        .line-edit-scroll {
          display: grid;
          grid-template-rows: 30px 70px auto 70px;

          .line-edit-scroll-header {
            display: flex;
            padding-left: 43px;
            line-height: initial;
            margin-top: 16px;

            .inline-edit {
              height: 100%;
              padding-left: 0;

              &.readonly {
                display: flex;
                align-items: center;
                font-size: 35px;
              }

              * {
                font-size: 35px;
              }

              .show-value {
                display: flex;
                align-items: center;
                height: 100%;
                line-height: 35px;

                .svg-icon {
                  display: flex;
                  height: 100%;
                  align-items: center;
                }
              }
            }
          }

          .line-edit-scroll-section {
            padding-left: 43px;
            overflow-y: auto;
          }

          .line-edit-scroll-bottom {
            padding-left: 43px;
            padding-right: 43px;
            border-top: 1px solid $color-silver;
            justify-content: flex-end;

            #undo-btn {
              padding-left: 0;
            }
          }

          height: 100%;

          > .budget-attributes-wrapper {
            padding-bottom: 0;
            padding-top: 0;
          }
        }

        @include box-shadow(0 -2px 5px rgba($color-forecast-border, 0.45));

        .close {
          position: absolute;
          right: 12px;
          top: 12px;
        }

        .panel {
          margin-bottom: 0;

          .panel-heading {
            margin-top: 30px;
          }

          .panel-body {
            @include flexbox;
          }

          &.section-Comments {
            .panel-body {
              display: block;
            }
          }

          &.section-Comments.model-line-comments {
            .panel-heading .accordion-toggle {
              text-transform: none;
              white-space: nowrap;
              @include big-mid-text;

              color: $color-steel !important;
              letter-spacing: 0;
            }

            .panel-body {
              display: block;

              .budgeta-type-value {
                margin-right: 40px;
              }

              .comment {
                margin-bottom: 10px;

                .delete-comment {
                  margin-right: 5px;
                }

                .comment-created {
                  height: 40px !important;
                }

                input {
                  @include flex(1);

                  border: none;
                  border-bottom: 1px solid $color-silver;
                  resize: none;
                  outline: 0;
                  @include mid-text-grey-blue;

                  padding-bottom: 16px;
                }
              }
            }
          }

          &.section-dimensions {
            .data-rows-wrapper {
              margin-left: 0;
              width: calc(33% - 144px);
              min-width: 250px;
            }
          }
        }

        .edit-line-design {
          border-bottom: none;
          margin-top: 10px;
          margin-bottom: 30px;
          margin-right: 10px;

          @media screen and (min-width: 1280px) {
            margin-right: 40px;
          }

          > label {
            width: auto;
          }

          .design-icons {
            display: flex;
            align-items: center;

            > div {
              height: 35px;
              width: 35px;
              @include border-radius(50%);

              margin-left: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: -10px;

              &.active:not(.bold-design) {
                color: $color-softer-grey;
              }

              &:not(.active) {
                opacity: 0.5;

                .svg-icon {
                  display: none;
                }
              }

              &:hover {
                opacity: 1;

                .svg-icon {
                  display: block;
                }
              }

              &.bold-design {
                @include user-select(none);

                font-size: 23px;

                &:not(.active):not(:hover) {
                  color: $color-steel;
                  border: 2px solid $color-steel;
                }

                &.active,
                &:hover {
                  color: $color-weird-green;
                  font-weight: 500;
                  border: 2px solid $color-weird-green;
                }
              }

              &.none-design {
                &:not(.active) {
                  border: 2px solid $color-steel;
                }

                &.active,
                &:hover {
                  border: 2px solid $color-weird-green;
                }

                .svg-icon {
                  .icon {
                    fill: $color-weird-green;
                  }
                }
              }

              &:not(.none-design) {
                .svg-icon {
                  .icon {
                    fill: white;
                  }
                }
              }

              &.turquoise-design {
                background-color: $color-turquoise;
              }

              &.olive-design {
                background-color: $color-olive;
              }

              &.orange-design {
                background-color: $color-orange;
              }

              &.purple-design {
                background-color: $color-purple;
              }

              &.pink-design {
                background-color: $color-pink;
              }
            }
          }
        }
      }

      .subnav {
        display: block !important;
        height: auto;
        padding: 0;

        > .left {
          padding: 0 8px;
        }

        .report-view-wrapper,
        .filter-view-wrapper {
          height: 35px;
          padding: 0 8px;
        }

        .report-view-wrapper {
          background-color: $color-mercury;

          .reportType {
            a {
              font-weight: bold;
            }
          }

          .scenario-compare {
            display: inline-block;
            height: 100%;
          }
        }

        .filter-view-wrapper {
          background-color: $color-light-gray;
          border-top: 1px solid $color-dark-gray;

          label {
            line-height: 35px;
          }

          > *:first-child {
            margin-right: 15px;
          }
        }

        .input-header {
          height: 84px;
          position: relative;
          background-color: #fff;

          &.input-header-model-group {
            .input-header-content {
              .left {
                .name {
                  max-width: calc(100% - 550px);
                }

                .filter-divider {
                  margin-top: 25px;
                }
              }
            }
          }

          &:not(.input-header-model-group) {
            .input-header-content {
              .left {
                .name {
                  max-width: calc(100% - 750px);
                }

                .filter-divider {
                  margin: 27px 30px 0;

                  &::after {
                    background-color: rgba(0, 0, 0, 0.25);
                  }
                }
              }
            }
          }

          .input-header-content {
            width: 100%;

            .left {
              margin-left: 38px;

              .name {
                height: auto;

                .budget-name-input-header {
                  max-width: 100%;
                  line-height: 35px !important;
                  height: 36px;

                  &.editing {
                    height: $input-height-not-radio-or-checkbox;
                  }

                  .show-value {
                    height: inherit;
                  }
                }

                .name-suffix {
                  color: $color-steel;
                  font-size: 24px;
                }

                > div {
                  line-height: 40px;
                }

                .inline-edit {
                  padding-right: 40px;
                }
              }

              > div {
                display: inline-block;
              }

              .new-budget-line {
                .new-budget-line-label {
                  padding: 5px 10px;
                  margin-top: 9px;
                  @include small-14-text;

                  color: $color-dark-grey-blue !important;
                  background-color: $color-light-dark-grey;
                  @include border-radius(4px);

                  line-height: 18px;
                }
              }

              .type-wrapper {
                position: relative;
                width: calc(100% - 380px);
                max-width: 400px;
                margin-bottom: 2px;

                .type {
                  > div {
                    display: inline-block;
                    font-size: 18px;
                  }

                  .svg-icon {
                    margin-bottom: 5px;
                  }

                  .type-header {
                    color: $color-steel;
                  }
                }
              }
            }

            .rightElement {
              top: -25px;
              margin-right: 10px;

              .budget-input-actions {
                .grid-toggle-area {
                  .toggle-switch {
                    input {
                      width: 30px;
                      height: 17px;
                    }
                  }
                }
              }

              > div.change-log {
                margin-right: 0;
                border: 1px solid $color-silver;
                @include border-radius(3px);

                padding: 0 18px;
                @include mid-text-silver;

                &:hover {
                  @include mid-text-grey-blue;

                  border-color: $color-dark-grey-blue;
                  cursor: pointer;
                }
              }
            }

            &.no-breadcrumbs-bar {
              .rightElement {
                top: 16px;
              }
            }
          }
        }
      }

      .empty-budget-view {
        .subnav {
          background-color: rgba(230, 230, 230, 0.5);

          .report-view-wrapper,
          .filter-view-wrapper {
            background-color: transparent;

            .svg-icon .icon {
              fill: rgba(89, 89, 89, 0.5);
            }
          }

          * {
            color: rgba(29, 29, 29, 0.5);
          }
        }

        .demo-table {
          width: 100%;
          opacity: 0.5;

          .demo-table-row:nth-child(even) {
            .demo-table-cell.middle:not(.first) {
              background: #e6e6e6;
            }
          }

          .demo-table-row:nth-child(odd) {
            .demo-table-cell.middle:not(.first) {
              background: #fdfdfd;
            }
          }

          .demo-table-row {
            border-bottom: 1px solid #ededed;
            height: 35px;

            .demo-table-cell {
              height: 35px;

              div.header-wrapper {
                height: 100%;
                overflow: hidden;
                width: 9000px;

                div {
                  display: inline-block;
                  height: 40px;
                  width: 110px;
                  padding-top: 5px;
                  font-weight: 600;
                }
              }
            }
          }

          .demo-table-row:first-child {
            height: 40px;
            max-height: 40px;
            border-bottom: 1px solid #ededed;

            .demo-table-cell {
              border-bottom: 1px solid #ededed;
              background-color: #fff !important;
              height: 40px;
              max-height: 40px;

              &.right {
                float: right;
                padding-top: 5px;
                text-align: center;

                div {
                  font-weight: 600;
                }
              }
            }
          }

          .demo-table-cell {
            display: inline-block;

            &.middle {
              overflow: hidden;
              width: calc(100% - 338px);
              max-width: calc(100% - 338px);
            }

            &.left {
              width: 220px;
              min-width: 220px;
              position: relative;

              &::after {
                content: "";
                display: block;
                position: absolute;
                width: 30px;
                height: 100%;
                top: 0;
                right: -30px;
                background: linear-gradient(
                  right,
                  rgba(255, 255, 255, 0) 0%,
                  rgba(170, 170, 170, 0.1) 40%,
                  rgba(100, 100, 100, 0.1) 70%,
                  rgba(100, 100, 100, 0.3) 100%
                );
                z-index: 1;
              }
            }

            &.right {
              width: 110px;
              min-width: 110px;
              position: relative;

              &::before {
                content: "";
                display: block;
                position: absolute;
                width: 30px;
                height: 100%;
                top: 0;
                left: -30px;
                background: linear-gradient(
                  left,
                  rgba(255, 255, 255, 0) 0%,
                  rgba(170, 170, 170, 0.1) 40%,
                  rgba(100, 100, 100, 0.1) 70%,
                  rgba(100, 100, 100, 0.3) 100%
                );
                z-index: 1;
              }
            }
          }
        }
      }

      .model-group {
        div[data-budget-module="group-model"] {
          .section-dimensions,
          .section-modelline {
            margin-left: 25px;
          }
        }

        div[data-budget-module="group-model-parent"] {
          .section-dimensions,
          .section-modelline {
            margin-top: 0 !important;
          }
        }

        .panel .panel-heading {
          margin-bottom: 10px;
        }
      }
    }
  }

  div.divSeparator {
    height: 100%;
    width: 3px;
    cursor: ew-resize;
    background-color: #fff;
    border-top: 1px $color-light-steel-gray solid;
  }

  .main-content-overflow {
    transition: all 0.3s ease 0.1s;
  }

  &.level-0 {
    .subnav {
      .dropdown {
        a[data-toggle="dropdown"] {
          max-width: 210px;
        }
      }
    }
  }

  &.level-0 aside {
    h2:not(.expanded) {
      height: 275px;
    }

    ol.tree {
      height: calc(100% - 310px);
    }

    &.has-sub-sub {
      ol.tree {
        height: calc(100% - 345px);
      }
    }

    .select-root {
      @include transform(rotate(180deg));
    }
  }

  .inner-content {
    padding: 0;
    background: #fff;
    width: calc(100% - 320px);
    height: 100%;
    min-width: 670px;
    position: relative;
    border-top: 1px solid $color-light-steel-gray;

    .subnav {
      .show-less {
        margin-left: 15px;
      }
    }

    .forecast-view {
      @media screen and (max-width: 1250px) {
        .subnav {
          .dropdown [data-toggle="dropdown"] {
            padding: 0 20px 0 5px;

            &::after {
              right: 5px;
            }
          }

          .scenario-highlight {
            label {
              display: none;
            }
          }

          .left * {
            font-size: 12px;
          }

          .right .rightHeaderText {
            display: none;
          }
        }
      }
    }

    > div {
      height: 100%;
      overflow: hidden;
    }

    .import {
      .scrollable {
        height: calc(100% - 80px);
        position: relative;
      }
    }

    .model-preview-top-right {
      margin-bottom: 10px;
      height: 40px;

      > * {
        float: right;
      }

      .select2-container {
        width: 130px;

        &.comments-filter {
          width: 170px;
        }
      }

      &.notes-shown {
        .select2-container {
          background-color: $color-highlight-light;
        }
      }

      .view-data-type {
        margin-right: 20px;
      }
    }

    .preview {
      &.model-preview {
        width: calc(100% + 75px);
        height: calc(100% - 90px);
        position: relative;
        right: 65px;
        border-top: 1px solid $color-light-mid-grey;
        margin-bottom: 0;

        &.model-group-preview {
          right: 41px;

          .forecast-preview {
            height: 100%;
          }
        }

        &:not(.model-group-preview) .ember-list-item-view {
          position: relative;
          height: 45px;

          .draggable {
            cursor: grab;
          }
        }

        &.readonly-mode {
          .forecast-view .forecast-wrapper .forecast-table .forecast-row:hover .column.name .actions {
            display: none !important;
          }

          .forecast-view .forecast-wrapper .forecast-table .forecast-row .column.name .svg-icon {
            pointer-events: none;
          }
        }
      }

      &:not(.model-preview) {
        min-height: 170px;
        background-color: rgba($color-white-soft-grey, 0.35);

        .forecast-view {
          .forecast-wrapper {
            &:not(.forecast-salary-details) {
              .column.name {
                margin-left: 0 !important;
              }
            }

            .column.name {
              i.spacer {
                display: none;
              }
            }

            .total-column {
              &::before {
                content: "";
                display: block;
                position: absolute;
                width: 6px;
                height: 100%;
                top: 0;
                left: -6px;
                background: rgba($color-mid-grey, 0.25);
                z-index: 1;
              }
            }

            .forecast-row {
              background-color: transparent;
            }

            &.rolling-forecast {
              .forecast-header {
                .scroll-columns,
                .total-column {
                  height: 60px;
                }
              }
            }
          }
        }
      }

      &.summary {
        .forecast-view {
          .forecast-wrapper {
            &:not(.has-num-employees) {
              .scrollable {
                display: none;
              }
            }

            &.has-num-employees {
              .scrollable {
                height: 45px !important;

                .forecast-table {
                  > .ember-list-view > div {
                    overflow: hidden !important;
                  }
                }
              }
            }
          }
        }
      }

      transition: all 0.2s;

      // height: 174px;
      // &.large-preview {
      // 	height: 165px;
      // }

      //    &.pnl-preview {
      //      height: 190px;
      //    }

      > .svg-icon.center {
        position: absolute;
        top: -6px;
        left: calc(50% - 9px);
        display: none;

        svg {
          height: 10px;
          fill: $color-darker-green;
        }
      }

      .dropdown {
        border: 0;

        & > a {
          font-weight: 600;
          padding-left: 0;

          &,
          &::after {
            color: #515151;
          }

          &::after {
            line-height: 35px;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .preview-controllers {
        position: relative;
        height: 84px;
        border-bottom: 1px solid $color-light-steel-gray;

        .preview-controllers-content {
          width: 100%;

          > div {
            display: inline-block;
            font-size: 18px;

            * {
              font-size: 18px;
            }
          }

          .right {
            float: right;
            margin-right: 72px;

            span {
              line-height: 30px;
            }

            > span {
              color: $color-steel;
              font-weight: 400;
            }

            .select2-container {
              padding-top: 0;
              padding-bottom: 0;
            }
          }

          .preview-type-select {
            display: inline-block;
            max-width: 230px;
            width: auto;

            &.preview-budget-type {
              margin-left: 30px;
            }
          }
        }
      }

      .forecast-view {
        > .ember-view {
          display: none;
        }

        &:not(.model-view) {
          .forecast-wrapper {
            &:not(.has-2-children):not(.has-3-children):not(.has-4-children) {
              .scrollable {
                max-height: 45px !important;
              }
            }

            &.has-2-children {
              .scrollable {
                max-height: 90px !important;
              }
            }

            &.has-3-children {
              .scrollable {
                max-height: 135px !important;
              }
            }

            &.has-4-children {
              .scrollable {
                max-height: 180px !important;
              }
            }
          }
        }

        .forecast-wrapper {
          padding: 0;
          border: none;

          .scrollable {
            .forecast-table {
              .total-column,
              .scroll-columns {
                &.bold-line-display {
                  * {
                    font-weight: 600;
                  }
                }

                &.turquoise-line-display {
                  background-color: $color-turquoise-light;

                  &.total-column::before {
                    background-color: $color-turquoise-dark !important;
                  }

                  .scroll-columns,
                  &.scroll-columns {
                    .forecast-row {
                      background: transparent;
                    }
                  }

                  .column {
                    background-color: $color-turquoise-light !important;
                  }
                }

                &.olive-line-display {
                  background-color: $color-olive-light;

                  &.total-column::before {
                    background-color: $color-olive-dark !important;
                  }

                  .scroll-columns,
                  &.scroll-columns {
                    .forecast-row {
                      background: transparent;
                    }
                  }

                  .column {
                    background-color: $color-olive-light !important;
                  }
                }

                &.orange-line-display {
                  background-color: $color-orange-light;

                  &.total-column::before {
                    background-color: $color-orange-dark !important;
                  }

                  .scroll-columns,
                  &.scroll-columns {
                    .forecast-row {
                      background: transparent;
                    }
                  }

                  .column {
                    background-color: $color-orange-light !important;
                  }
                }

                &.purple-line-display {
                  background-color: $color-purple-light;

                  &.total-column::before {
                    background-color: $color-purple-dark !important;
                  }

                  .scroll-columns,
                  &.scroll-columns {
                    .forecast-row {
                      background: transparent;
                    }
                  }

                  .column {
                    background-color: $color-purple-light !important;
                  }
                }

                &.pink-line-display {
                  background-color: $color-pink-light;

                  &.total-column::before {
                    background-color: $color-pink-dark !important;
                  }

                  .scroll-columns,
                  &.scroll-columns {
                    .forecast-row {
                      background: transparent;
                    }
                  }

                  .column {
                    background-color: $color-pink-light !important;
                  }
                }
              }

              .fixed-columns {
                &.bold-line-display {
                  *:not(.model-line-label) {
                    font-weight: 500;
                  }
                }

                &.turquoise-line-display {
                  .forecast-row {
                    background-color: $color-turquoise-light !important;

                    .model-line-label.has-label {
                      background-image: linear-gradient(0deg, $color-turquoise-light, $color-turquoise-light);
                      background-blend-mode: multiply;
                    }
                  }
                }

                &.olive-line-display {
                  .forecast-row {
                    background-color: $color-olive-light !important;

                    .model-line-label.has-label {
                      background-image: linear-gradient(0deg, $color-olive-light, $color-olive-light);
                      background-blend-mode: multiply;
                    }
                  }
                }

                &.orange-line-display {
                  .forecast-row {
                    background-color: $color-orange-light !important;

                    .model-line-label.has-label {
                      background-image: linear-gradient(0deg, $color-orange-light, $color-orange-light);
                      background-blend-mode: multiply;
                    }
                  }
                }

                &.purple-line-display {
                  .forecast-row {
                    background-color: $color-purple-light !important;

                    .model-line-label.has-label {
                      background-image: linear-gradient(0deg, $color-purple-light, $color-purple-light);
                      background-blend-mode: multiply;
                    }
                  }
                }

                &.pink-line-display {
                  .forecast-row {
                    background-color: $color-pink-light !important;

                    .model-line-label.has-label {
                      background-image: linear-gradient(0deg, $color-pink-light, $color-pink-light);
                      background-blend-mode: multiply;
                    }
                  }
                }
              }
            }
          }

          .forecast-row {
            &.model-line {
              .column.name {
                .column-content {
                  width: 100%;

                  .name-text {
                    @include text-ellipsis;

                    max-width: calc(100% - 60px);

                    span {
                      @include text-ellipsis;

                      &.display-account-ids * {
                        font-size: 14px;
                      }
                    }
                  }

                  .actions.model-public {
                    .model-public-toggle {
                      svg {
                        fill: $color-weird-green;
                      }
                    }
                  }
                }

                &:hover {
                  .column-content {
                    .name-text {
                      max-width: calc(100% - 100px);
                    }
                  }
                }
              }
            }
          }

          .selected-row {
            border: 1px solid $color-dark-sky-blue;
            background: rgba($color-dark-sky-blue, 0.15);
            width: 100%;
            height: calc(100% - 1px);
            position: absolute;
            z-index: 1;
            pointer-events: none;
          }

          .column.name {
            display: flex !important;
            @include long-text(100%);

            padding: 0 10px;

            a {
              padding-left: 0;
              color: $color-dark-grey-blue !important;
            }
          }

          .forecast-header {
            .forecast-row {
              height: 40px;

              .collapse_expand_wrapper {
                display: none;
              }
            }

            .scroll-columns {
              height: 40px;
            }

            &.past-data-header {
              .forecast-row,
              .scroll-columns {
                height: 60px;
              }
            }
          }

          &.rolling-forecast {
            .forecast-header {
              .scroll-columns {
                height: 60px;
              }
            }
          }

          .forecast-footer {
            border: none;
            border-bottom: none;
            border-top: none;

            .forecast-row:not(:first-child) {
              display: none;
            }

            > div:not(.total-column) {
              * {
                font-weight: 400;
              }
            }
          }

          .bottom-bar {
            display: none !important;
          }

          .scrollable {
            margin-bottom: 0;

            .empty-row {
              display: none;
            }
          }

          &:not(.has-children) {
            .scrollable,
            .fixed-columns {
              display: none;
            }

            .scroll-columns {
              width: calc(100% - 145px);
            }
          }
        }
      }

      &:not(.all-lines):not(.model-preview) {
        .forecast-view {
          .forecast-wrapper {
            &:not(.has-2-children):not(.has-3-children):not(.has-4-children) {
              .scrollable {
                .forecast-table .ember-list-view-list {
                  height: 45px !important;
                }
              }
            }

            &.has-2-children {
              .scrollable {
                .forecast-table .ember-list-view-list {
                  height: 90px !important;
                }
              }
            }

            &.has-3-children {
              .scrollable {
                .forecast-table .ember-list-view-list {
                  height: 135px !important;
                }
              }
            }

            &.has-4-children {
              .scrollable {
                .forecast-table .ember-list-view-list {
                  height: 180px !important;
                }
              }
            }

            &.y-scroll {
              .scrollable {
                overflow-y: scroll;
              }
            }
          }
        }
      }

      &.all-lines {
        .forecast-view {
          .forecast-wrapper {
            .scrollable {
              display: block;
              max-height: 225px !important;

              .forecast-table .ember-list-view-list {
                .total-column {
                  * {
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }

    .main-budget-index-wrapper {
      height: 100%;
    }

    form {
      &:not(.trx-table) {
        height: 100%;
        padding-bottom: 56px;
      }

      @include placeholder-color($color-green);

      > .scrollable:not(.table-scrollable):not(.table-body) {
        overflow-y: visible;
        position: relative;
        padding: 0 36px 0 40px;

        &:not(.model-group) {
          margin-bottom: 55px;
        }

        &.preview-open {
          &.has-preview {
            height: calc(100% - 260px);
          }

          &.has-large-preview {
            height: calc(100% - 295px);
          }
        }

        &.with-status-bar,
        &.readonly-mode {
          &.readonly-mode {
            padding-bottom: 20px;
          }

          &.preview-open {
            &.has-preview {
              height: calc(100% - 295px);
            }

            &.has-large-preview {
              height: calc(100% - 330px);
            }
          }
        }

        &.readonly-mode {
          &:not(.model-group) {
            height: calc(100% - 179px);
          }
        }

        &.disabled {
          overflow-y: hidden;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: #fff;
            opacity: 0.5;
          }
        }

        &.has-preview {
          .populate-btn-section {
            @include flexbox;

            justify-content: flex-end;
            margin: 25px 50px 0 0;
          }
        }
      }

      .sticky-wrapper {
        .forecast-header {
          background-color: $color-regular-white;
        }

        .forecast-row {
          &.header {
            .sub-header {
              .sub-header-text-wrapper {
                border-bottom: 0;
              }
            }
          }
        }

        .stuck {
          position: absolute;
          width: 100%;
          z-index: 11;
          top: 0;
          background-color: $color-light-mid-grey;
          border-bottom: 5px solid rgba($color-light-mid-opacity-grey, 0.45);
          background-clip: padding-box;

          &.forecast-header {
            background-color: $color-regular-white;
          }
        }
      }

      .null-placeholder {
        @include placeholder-color(rgba(255, 255, 255, 0));
      }

      > div.center {
        position: relative;
        width: 100%;
        text-align: center;
        line-height: 1;
      }

      label {
        margin-bottom: 0;

        &:not(.error) {
          &.small {
            @include flex(0 0 20px);

            width: 20px;
            max-width: 20px;
            min-width: 20px !important;
          }

          &.wide {
            @include flex(0 0 160px);

            width: 160px;
            max-width: 160px;

            &.flex-auto {
              @include flex(0 0 auto);

              width: auto;
              min-width: 160px;
              max-width: 170px;
            }
          }

          &.ellipsis {
            @include text-ellipsis;
          }
        }

        &.symbol {
          min-width: 30px;
          max-width: 30px !important;
        }

        &.error {
          display: block;
        }

        &.checkbox {
          cursor: pointer;
          margin-top: 0;
          background-color: transparent;
          line-height: 40px;
          padding: 0 20px;

          input[type="checkbox"] {
            margin-top: 0;
            margin-left: -15px;
          }
        }
      }

      label.label-after {
        display: none;
      }

      div.bs-switch {
        border: 1px solid #e8e8e7;
        padding-top: 4px;
        text-align: center;

        .bootstrap-switch {
          .bootstrap-switch-container {
            line-height: 1;

            .bootstrap-switch-handle-on.bootstrap-switch-primary {
              background: $color-green;
            }
          }
        }
      }

      p.input-error {
        font-size: 0.8em;
        left: 0;
        bottom: -20px;
      }

      .has-label-after {
        label.label-after {
          display: inline-block;
          @include big-mid-text-steel;

          text-align: end;
          text-align: right;
        }
      }

      .twitter-typeahead {
        width: 100%;

        input {
          width: 100%;
        }
      }

      .type-select {
        margin-top: 15px;
      }

      .attribute-allocated {
        .data-rows-wrapper {
          width: calc(100% - 100px);
        }

        .add {
          max-width: 75px;
        }
      }

      div[id^="attribute-budgetLine"] {
        height: auto !important;
      }

      div[id^="attribute-assumptions"] {
        flex-wrap: wrap;

        .full {
          width: 100%;
          @include flex(0 0 auto);
        }

        .data-rows-wrapper {
          width: 100%;
          flex-wrap: wrap;
        }
      }

      div[id^="attribute-accountId"],
      div[id^="attribute-costCenter"] {
        height: 100% !important;

        .data-row div.remove-line {
          right: -20px;
          left: auto;
        }
      }

      input:not([type="radio"]):not([type="checkbox"]) {
        height: $input-height-not-radio-or-checkbox;

        &.percent {
          width: 60px;
        }
      }

      div[id^="attribute-"] {
        &:not(.wrap):not(.full) {
          height: 40px;
        }

        div:not(.drop-down):not(.currency):not(.month-picker-wrapper):not(.month-picker) {
          display: inline-block;
        }
      }

      div[id^="attribute-assumptions"] {
        .modifier {
          @include flexbox-important;
        }
      }

      input[type="number"] {
        height: 35px;
      }

      .custom-amount {
        input {
          width: 100%;
        }

        input[type="checkbox"] {
          width: 20px;
        }

        .has-formula-preview {
          @include flexbox;

          * {
            @include flex(1 1 auto !important);

            width: auto !important;
            min-width: 50px !important;
            max-width: none !important;
          }
        }

        .add {
          line-height: 35px;
          padding-left: 5px;
        }
      }

      .scrollable {
        .dropdown {
          width: 50%;
          height: 35px;

          a[data-toggle] {
            line-height: 34px;
          }
        }

        .styled-select {
          width: 50%;
          height: 35px;

          select {
            line-height: 33px;
          }

          &::after {
            line-height: 35px;
            right: 10px;
          }
        }
      }

      &.login {
        > div {
          margin-top: 15px;
        }

        input {
          width: auto;
        }
      }

      .comments {
        .comment {
          margin-right: 10px;

          @media screen and (min-width: 1280px) {
            margin-right: 40px;
          }

          @include flexbox;

          label {
            @include flex(0 auto);

            height: 73px !important;
            border-bottom: 1px solid $color-silver;
          }

          textarea {
            @include flex(1);

            border: none;
            border-bottom: 1px solid $color-silver;
            resize: none;
            outline: 0;
            @include mid-text-grey-blue;
          }

          &:hover {
            @include highlight-value-border;
            @include highlight-value;
          }

          .leftElement {
            left: 10px;
          }
        }
      }

      .comments + .add-icon-list {
        margin-top: 5px;
      }
    }

    :not(li).bottom-bar {
      @include flexbox-important;

      -ms-flex-pack: center;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      height: 55px;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      border-top: 1px solid #f8f9f9;
      z-index: 11;
      padding-right: 100px;

      &:not(.changed) {
        [type="submit"] {
          background: $color-mercury;
        }

        .cancel {
          color: $color-mercury;
        }
      }

      button.left {
        left: 12px;
      }

      button {
        .svg-icon {
          width: 18px;

          svg {
            fill: white;
            height: 18px;
          }
        }
      }

      .total {
        margin-right: 50px;

        label {
          color: $color-steel;
        }
      }
    }
  }

  &.maximize-left {
    aside {
      width: 500px !important;
    }

    .inner-content {
      width: calc(100% - 500px) !important;
    }
  }

  &.maximized {
    .inner-content .scrollable.preview-open .budget-attributes-wrapper .sticky-wrapper .stuck {
      position: fixed !important;
      top: 255px;
      z-index: 100;
    }

    aside.secondary {
      display: none;
    }
  }

  .inner-bar {
    padding: 0;
    height: 49px;
    @include flexbox-important;

    a.tab {
      display: inline-block;
      position: relative;
      line-height: 40px;
      margin-top: 5px;
      color: #fff;
      font-weight: 400;
      text-align: center;
      padding: 0 10px;
      width: auto;
      @include border-radius-top(3px);

      a.close {
        opacity: 1;

        &::before {
          content: "\2715";
          display: block;
          position: absolute;
          top: 6px;
          right: 6px;
          font-size: 9px;
          line-height: 9px;
          color: #fff;
          font-weight: 300;
        }
      }

      &.active {
        background: $color-green;
        cursor: default;
      }
    }

    > div {
      &.center {
        @include flex(1 1);

        text-align: left;
        min-width: 370px;
      }

      &.left {
        @include flexbox;

        align-items: center;
        @include flex(1 1);

        * {
          @include font-sub-header;

          color: #fff;
          line-height: 1;
        }

        .type {
          font-size: 12px;
          line-height: 1;
          margin-top: 6px;
          margin-left: 10px;
          font-weight: 400;
        }

        margin-top: 5px;
        padding: 0 15px;
      }

      &.right {
        @include flex(1 1);

        * {
          color: #fff;
        }

        .tab {
          padding-right: 20px;
        }

        text-align: right;
        line-height: 45px;
        padding-right: 15px;
      }
    }
  }

  .import,
  .models {
    .inner-bar {
      border-color: $color-san-marino;

      a.tab.active {
        background: $color-san-marino;
        padding-right: 20px;
      }
    }
  }

  &:not([data-path="main.budget.index"]) {
    .inner-bar .left * {
      display: none;
    }

    > .main-content-overflow {
      > .divSeparator {
        display: none;
      }

      > aside.secondary {
        max-width: 320px;
      }

      > div.inner-content {
        width: 100% !important;
      }
    }
  }

  aside.secondary {
    border-right: 1px solid $color-light-dark-grey;
    border-top: 1px solid #ccc;
    overflow-y: clip;
  }

  @media screen and (max-width: 1600px) {
    &.main-content-open-tree {
      .main-content-overflow > .inner-content .subnav .input-header .input-header-content {
        .left {
          .inline-edit-select .select2-container {
            max-width: 150px;
          }

          .name {
            max-width: 250px;
          }
        }

        .rightElement {
          right: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 1450px) {
    &.main-content-open-tree {
      .main-content-overflow > .inner-content .subnav .input-header .input-header-content {
        .rightElement {
          display: none;
        }
      }
    }

    .main-content-overflow > .inner-content .subnav .input-header .input-header-content {
      .left {
        .name {
          max-width: 250px;
        }
      }
    }
  }
}

body {
  &:not(.hiddenscroll) {
    .main-content {
      .inner-content {
        form {
          .scrollable {
            &.has-preview {
              .budget-attributes-wrapper .forecast-preview:not(.model-view) .sticky-wrapper .stuck {
                position: fixed !important;
                top: 269px;
                z-index: 100;
                background-color: #fff;
              }

              .budget-attributes-wrapper .forecast-preview.model-view .sticky-wrapper .stuck {
                top: 168px !important;
                position: fixed !important;
                width: calc(100% - var(--left-aside-width, 325px));
                min-width: 667px;
              }
            }
          }
        }
      }
    }
  }

  &.hiddenscroll {
    .main-content {
      .inner-content {
        form {
          .scrollable {
            &.has-preview {
              .budget-attributes-wrapper .forecast-preview:not(.model-view) .sticky-wrapper .stuck {
                position: fixed !important;
                top: 254px;
                z-index: 100;
                background-color: #fff;
              }

              .budget-attributes-wrapper .forecast-preview.model-view .sticky-wrapper .stuck {
                top: 168px !important;
                position: fixed !important;
                width: calc(100% - var(--left-aside-width, 325px));
                min-width: 667px;
              }
            }
          }
        }
      }
    }
  }
}
