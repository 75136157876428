/* stylelint-disable max-nesting-depth, no-duplicate-selectors, declaration-no-important */
// old file
.modal-container.intacct-wizard {
  .modal-dialog {
    width: 530px;
  }

  &.intacct-wizard.extra-wide {
    .modal-dialog {
      width: 860px;
    }
  }
}

.modal-container.intacct-wizard {
  .modal-dialog {
    height: 580px;

    .modal-content {
      height: 100%;
      @include flexbox;
      @include flex-flow(column);

      > .modal-header {
        @include flex(0 0 75px);
      }

      > .modal-body {
        @include flex(1 1);
      }

      > .modal-header {
        @include flex(0 0 58px);
      }
    }
  }
}

.modal-container.intacct-wizard {
  .modal-dialog {
    .modal-content {
      > .modal-body {
        .textarea-auto-height-wrapper {
          padding-top: 9px;
          border-bottom: 1px solid #eee;

          textarea {
            height: 24px;
            overflow-y: hidden;
          }
        }

        .budgeta-progress-bar {
          margin-top: 24px;
        }

        .empty-month-list {
          max-height: 135px;
          margin-bottom: 0;
          overflow: auto;
          margin-left: 40px;
        }

        .custom-date-range {
          margin-left: 40px;
        }

        .import-actuals-months {
          max-height: 320px;
          overflow-y: auto;

          > div {
            margin-bottom: 8px;
            font-size: 18px;
          }
        }

        .new-dimension-values-table-body {
          max-height: 400px;
        }
      }
    }
  }
}

.modal-container.intacct-wizard {
  .modal-dialog {
    .modal-content {
      > .modal-body {
        .wizard-question-selection > .budgeta-checkbox {
          height: 39px;
          display: flex;
          align-items: center;
        }

        label.error {
          width: 100%;
          background-color: $color-red-dark;
          padding: 0 32px;
          color: white !important;
          bottom: 0;
          margin-top: 0;
          margin-bottom: 0;
          line-height: 32px;
          left: 0;
        }
      }
    }
  }
}

.modal-container.intacct-wizard {
  .modal-dialog {
    .modal-content {
      .subtitle:not(.no-margin) {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .flex {
        > div {
          line-height: 40px;
        }

        &.black-white-list {
          &:nth-child(2n + 1) {
            background: #f3f3f3;

            input {
              background: #f3f3f3;
            }
          }

          > .flex-part {
            > label {
              padding-left: 10px;
            }
          }
        }
      }

      .scrollable.export-missing-budget-line-account {
        max-height: 350px;
      }

      .wizard-question-single,
      .wizard-question-selection .budgeta-checkbox label,
      .wizard-question.wizard-question-single .wizard-question-selection > * > label,
      .wizard-question-radio.budgeta-checkbox {
        &.wizard-question-single {
          font-weight: 300;
        }

        .wizard-question-single-title {
          letter-spacing: 0.2px;
          color: $color-steel;
        }

        .wizard-question-single-title + .wizard-question-selection {
          margin-top: 32px;
        }

        font-size: 18px !important;
        letter-spacing: 0.2px;
      }

      .intacct-account-list {
        .table-header {
          @include flexbox;

          .column {
            &.checkbox-column {
              @include flex(0 0 40px);
            }

            &.name-status-column {
              @include flex(0 0 240px);
            }

            &.mapping {
              position: relative;
            }
          }
        }

        .table-body {
          .ember-list-view {
            > div > div > div {
              input {
                letter-spacing: 0.2px;
              }

              .mapping-column {
                .select2-chosen {
                  font-weight: 300;
                  letter-spacing: 0.2px;
                }
              }

              .status-column {
                width: 70px;

                input {
                  font-weight: 500;
                }
              }

              .name-column,
              .mapping-column {
                input,
                .select2-chosen {
                  font-weight: 300;
                }
              }
            }

            .table-edit-line {
              .table-scrollable {
                span.twitter-typeahead input:first-child {
                  background-color: transparent !important;
                }
              }
            }
          }
        }
      }

      .table-body {
        .ember-list-view {
          > div > div {
            .table-edit-line {
              @include flexbox;

              .column {
                &.status-column {
                  @include flex(0 0 70px);
                }

                &.name-column {
                  @include flex(0 0 170px);
                }

                &.checkbox-column {
                  @include flex(0 0 40px);
                }
              }
            }

            .scrollable {
              overflow-y: hidden;
            }
          }
        }
      }

      .multi-select-dropdown {
        > div {
          line-height: 40px;

          &.placeholder {
            font-weight: 300;
          }
        }

        border-bottom: 1px solid #eee;
      }
    }
  }
}

.modal-container.intacct-wizard {
  .modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding-bottom: 10px;
        }
      }
    }
  }
}
