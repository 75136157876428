/* stylelint-disable max-nesting-depth, declaration-no-important */
.elegant-modal.group-company-intacct .step-dimensions {
  .import-dimensions-wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    overflow-x: hidden;

    .left-subtitle {
      color: $color-steel;
      width: 100%;
      padding-top: 16px;
      padding-bottom: 8px;
      padding-left: 32px;
    }

    .secondary-text {
      color: $color-steel;
    }

    .select-dimension {
      display: grid;
      grid-template-rows: 60px auto;
      max-height: 100%;
      overflow: hidden;

      &.has-selection {
        .left-subtitle {
          border-right: 1px solid $color-soft-grey;
        }

        .wizard-question-selection {
          .dimension-line {
            &:not(.select-highlight) {
              border-right: 1px solid $color-soft-grey;
            }
          }

          .missing-part {
            border-right: 1px solid $color-soft-grey;
          }
        }
      }

      .wizard-question-selection {
        @include flexbox;
        @include flex-flow(column);

        .dimension-line {
          height: 40px;
          @include flexbox;

          padding-left: 32px;

          &.disabled {
            pointer-events: none;
          }

          &:hover {
            &:not(.selected, .select-highlight) {
              background-color: $color-topaz-highlight-lighter;
            }
          }

          &.select-highlight {
            background-color: $color-mint-tulip;
            position: relative;

            .dimension-line-border {
              position: absolute;
              z-index: 900;
              right: 0;
              height: 100%;
              width: 1px;
              background-color: $color-soft-grey;
            }

            &::after,
            &::before {
              content: "";
              display: block;
              position: absolute;
              right: 0;
              width: 0;
              height: 0;
              border-style: solid;
              z-index: 1000;
            }

            &::after {
              top: 12px;
              border: 2px solid;
              border-color: transparent white transparent transparent;
              border-width: 8px;
            }

            &::before {
              top: 11px;
              border-color: transparent $color-soft-grey transparent transparent;
              border-width: 9px;
            }
          }

          .dimension-name {
            line-height: 40px;
            width: 100%;

            .help-icon {
              line-height: 40px;
              padding-left: 3px;

              svg {
                margin-top: -4px;
              }
            }
          }

          .budgeta-checkbox {
            margin-top: 10px;
          }
        }

        .missing-part {
          @include flex(1);
        }
      }
    }

    .select-dimension-values {
      display: grid;
      grid-template-rows: 44px 42px auto;

      &.has-load-more {
        grid-template-rows: 44px 42px auto 30px;

        .load-more-button {
          line-height: 30px;
        }
      }

      .search {
        border-bottom: 1px solid $color-soft-grey;
        margin-right: 32px;
        margin-left: 16px;
        padding-top: 8px;
        display: flex;

        @include placeholder-color($color-steel);

        input {
          border: 1px solid transparent;

          &:focus {
            outline: none;
          }
        }

        .svg-icon {
          line-height: 30px;
          margin-top: 3px;
        }
      }

      .selection-options {
        margin-left: 16px;
        margin-bottom: 16px;

        a {
          font-size: 16px;

          &.disabled {
            color: $color-steel;
          }
        }

        .filter-divider {
          margin-right: 2px;
          margin-left: 2px;

          &::after {
            top: 23px !important;
            height: 13px;
            background-color: $color-soft-grey;
          }
        }
      }

      .dimension-values {
        position: relative;
        display: grid;
        grid-template-rows: auto 30px;
        margin-left: 16px;
        @for $i from 1 to 20 {
          div[data-level="#{$i}"] {
            padding-left: $i * 20px;
          }
        }

        .budgeta-checkbox {
          label {
            @include text-ellipsis;
          }
        }
      }
    }
  }
}
