.file-upload-body-container {
  .lottie-animation {
    margin-bottom: 20px;
  }

  > svg {
    display: block;
    margin: 20px auto;
  }

  .drop-file {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
    gap: 20px;
    width: 100%;
    height: 150px;

    .input-file {
      width: 100%;
      height: 150px;
      background-color: $color-mint-tulip;

      .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      > p {
        text-align: center;
        margin-top: 40px;
        color: $font-color;
        margin-bottom: 10px;
      }

      .inputfile-label {
        display: flex;
        justify-content: space-between;
        border: 2px solid $heading-border-color;
        height: 35px;
        padding-left: 10px;
        font-size: 18px;
        font-weight: 400;
        color: $font-color;
        background-color: white;
        cursor: pointer;
        margin: 0 0 15px 80px;
        width: 400px;

        .name {
          line-height: 28px;
          width: 350px;
          @include text-ellipsis;
        }

        > svg {
          position: absolute;
          left: 470px;
          margin-top: 3px;
        }
      }
    }
  }
}
