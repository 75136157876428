/* stylelint-disable max-nesting-depth */
.budget-menu-dropdown,
.change-log-dropdown {
  display: none; /* required because of qtip behavior */
}

.budget-menu-wrapper.qtip {
  padding: 2px 0;

  .qtip-content .budget-menu-dropdown {
    padding: 0;

    .budget-menu-dropdown-list {
      margin: 0;
      padding: 0;
      width: 200px;
      max-height: 420px;
      overflow-y: auto;
      overflow-x: hidden;

      &.with-help-iq {
        width: 230px;
      }

      li {
        padding: 10px 16px;
        cursor: pointer;
        display: flex;

        * {
          line-height: var(--list-item-line-height);
          color: var(--list-item-text-color);
          letter-spacing: (--bold-font-letter-spacing);
        }

        &.disable {
          cursor: not-allowed;

          * {
            color: rgba(0, 0, 0, 0.3);
          }

          .icon {
            svg {
              fill-opacity: 0.3;
            }
          }
        }

        &:not(:first-child) {
          margin-top: 8px;
        }

        &:hover {
          background-color: var(--list-item-hover-color);
        }

        &:active {
          background-color: var(--list-item-active-color);
        }

        .icon {
          display: flex;
          align-items: center;
          margin-right: 8px;

          & > div {
            display: flex;
            align-items: center;
          }

          svg {
            fill: #000;
            fill-opacity: 0.65;
          }
        }
      }
    }
  }
}

.change-log-dropdown-wrapper.qtip {
  max-width: 800px;
}

.qtip.qtip.qtip .change-log-dropdown {
  padding: 2px 0;
}
