/* stylelint-disable selector-class-pattern */
.budgeta-video {
  background-color: #fff;
  padding: 10px 15px;
  @include flexbox;

  margin-bottom: 0;
  max-height: 100%;

  .active-video {
    @include flex(1 1);

    .video-title {
      margin-top: 10px;
      font-size: 30px;
    }
  }

  .next-videos {
    margin-left: 20px;
    @include flex(0 0 350px);
    @include flexbox;
    @include flex-direction(column);

    .thumbnail-video {
      @include flexbox;

      padding-bottom: 10px;

      &.next-video {
        margin-bottom: 10px;
        padding-bottom: 15px;
        border-bottom: 2px solid $color-silver;
      }

      img {
        @include flex(0 0 120px);

        width: 120px;
        height: 70px;
      }

      .thumbnail-video-title {
        padding-left: 10px;
        margin-bottom: 0;
        font-size: 24px;
        line-height: 20px;
        @include flex(1 1);
      }
    }

    .thumbnail-video-wrapper {
      @include flex(0 0 114px);
    }

    .next-video-header {
      font-size: 28px;
      font-weight: 600;
      padding-bottom: 10px;
      color: $color-steel;
    }

    .searchVideoInput {
      font-size: 24px;
    }

    .suggestions-list-title {
      padding-bottom: 8px;
    }

    .suggestions-list {
      @include flex(1 1);

      padding-top: 10px;
      margin-top: 5px;
      overflow-y: scroll;
      margin-bottom: 40px;
    }
  }
}
