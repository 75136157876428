.intacct-select-stat-accounts {
  .big-font-subtitle {
    color: $color-steel;
    height: 80px;
    width: 100%;
    list-style: 1;
    padding-top: 16px;
    margin-bottom: 10px;
  }

  .subtitle-addition {
    margin-top: -15px;
    padding-top: 0;
  }

  .table-header,
  .table-row {
    display: grid;
    grid-template-columns: 35px 1fr;
    padding: 0 29px;
  }

  .view-table-edit.short-content .table-editor.full-size-table-editor .table-header {
    border-bottom: 1px solid $color-light-steel-gray;

    .account-column-name {
      line-height: 37px;
    }
  }

  .table-row {
    .account-column {
      font-size: 18px;
      line-height: 35px;

      .account-id {
        font-weight: 500;
        margin-right: 7px;
      }
    }

    &:hover {
      &.hightlight-hover {
        background-color: $color-topaz-highlight-lighter;
      }
    }
  }

  .table-body {
    padding-top: 5px;
  }

  .checkbox-column-name,
  .checkbox-column {
    height: 40px;
  }
}
