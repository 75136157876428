/* stylelint-disable max-nesting-depth, scss/at-extend-no-missing-placeholder, declaration-no-important */
$line-height: 6px;
$half-height: ($line-height / 2);
$line-offset: 2px;
$background-line: $color-ivory;
$line-color: red;

.modal-container.formula-editor-modal {
  .modal {
    .modal-dialog {
      width: 700px;
      height: 450px;

      .modal-body {
        background-color: $color-ivory;
        border-bottom: 1px solid $color-silver;
        padding: 12px 36px 0;

        label.error {
          margin-top: 0;
          color: $color-steel !important;
          bottom: -30px;
        }

        .svg-icon.clear {
          position: absolute;
          top: 15px;
          left: 10px;
          margin-bottom: 0;

          svg {
            height: 18px;
            opacity: 0.6;
          }
        }

        .formula-editable-content {
          position: relative;

          .formula-editable-value {
            display: inline-block;
            width: calc(100% - 40px);
            position: relative;

            .floating-error {
              position: absolute;
              height: 0;
              width: 0;
              z-index: 1;
              left: 0;
              right: 0;
              bottom: 0;

              &::before,
              &::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
              }

              &::before {
                height: ($line-height - $line-offset);
                top: 4px;
                background: linear-gradient(-135deg, $line-color $half-height, transparent 0) 0 $half-height,
                  linear-gradient(135deg, $line-color $half-height, transparent 0) 0 $half-height;
                background-position: top left;
                background-repeat: repeat-x;
                background-size: $line-height $line-height;
              }

              &::after {
                height: $line-height;
                top: 2px;
                background: linear-gradient(-135deg, $background-line $half-height, transparent 0) 0 $half-height,
                  linear-gradient(135deg, $background-line $half-height, transparent 0) 0 $half-height;
                background-position: top left;
                background-repeat: repeat-x;
                background-size: $line-height $line-height;
              }
            }
          }

          .formula-editable-content-inner {
            > .svg-icon {
              float: right;
              cursor: auto;
            }

            &.formula-error {
              > .svg-icon {
                .icon {
                  fill: $color-silver;
                }
              }
            }

            &:not(.formula-error) {
              > .svg-icon {
                .icon {
                  fill: $color-weird-green;
                }
              }

              .floating-error {
                display: none;
              }
            }

            .copy-paste-enabled {
              .formula-textarea {
                padding-right: 70px;
              }

              .formula-copy-paste {
                position: absolute;
                right: 10px;
                top: 10px;

                svg {
                  fill: $color-steel;
                }

                .copy-icon,
                .paste-icon {
                  &:hover svg {
                    fill: $color-dark-grey-blue;
                  }
                }

                .copy-icon {
                  margin-left: 6px;
                }
              }
            }
          }

          .formula-textarea {
            outline: none;
            border: 2px solid $color-silver;
            @include border-radius(5px);

            padding: 8px 3px 7px;
            min-height: 48px;

            p {
              line-height: 28px;
              display: inline;
              margin: 0;
              font-size: 23px;
            }

            span {
              display: inline-block;
              line-height: 26px;
              padding: 0 10px;
              @include border-radius(3px);

              color: #fff;
              margin: 0 5px 5px;

              &:not(.missing) {
                background: $color-dark-mint;
              }

              &.missing {
                background: $color-error;
              }
            }
          }
        }

        textarea {
          border: none;
          border-bottom: 3px solid transparent;
          background-color: transparent;
          margin-bottom: -9px;
          color: $color-steel;
          padding: 0 0 8px;

          &:not(.small-text) {
            font-size: 27px;
            font-weight: 300;
            line-height: 32px;
          }

          .small-text {
            font-size: 16px;
            line-height: 18px;
          }

          &:focus {
            outline: none !important;
            border-bottom: 3px solid $color-dark-mint;
          }
        }

        .formula-actions-content {
          @include flexbox;

          * {
            user-select: -moz-none;
            user-select: none;
            user-select: none;

            /*
              Introduced in IE 10.
              See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
            */
            user-select: none;
            user-select: none;
          }

          .assumption-list {
            @include flex(1);

            height: 250px;
            overflow-y: hidden;
            margin-top: 25px;

            .assumption-models-header {
              line-height: 34px;
              color: $color-steel;
              margin-bottom: 9px;
            }

            .assumption-list-wrapper {
              height: calc(100% - 40px);
              overflow-y: auto;

              .model-type-section {
                color: $color_dark_mint;
                -webkit-text-stroke: 0.5px $color_dark_mint;
                font-size: 18px;
                text-transform: uppercase;
                line-height: 21px;
                margin-bottom: 10px;
              }

              .model-title {
                margin-bottom: 10px;

                .model-title-text {
                  text-transform: uppercase;
                }
              }

              ul {
                li {
                  @include border-radius(3px);

                  padding: 0 10px;
                  line-height: 33px;
                  width: auto;
                  display: inline-block;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  background: $color-white;
                  color: $color-steel;
                  margin: 0 5px 5px 0;

                  &.line {
                    // background: $color-dark-mint;
                    // color: #fff;
                  }

                  &.disabled {
                    display: none;
                  }

                  &:hover:not(.disabled) {
                    @include box-shadow(inset 0 0 100px 100px rgba(0, 0, 0, 0.2));
                  }
                }
              }
            }
          }

          .calculator {
            @include flex(0 0 240px);

            margin-top: 25px;
            margin-left: 15px;

            .calculator-line {
              height: 37px;

              .calculator-btn {
                display: inline-block;
                @include large-text-grey-blue;

                outline: 0;
                border: none;
                width: 37px;
                float: left;

                // margin: 0 5px 5px 0;
                background-color: transparent;

                &.disabled {
                  opacity: 0.2;
                }

                .calculator-btn-icon-wrapper {
                  position: relative;
                }

                &:not(.function-btn) {
                  line-height: 30px !important;
                }

                &.function-btn {
                  width: auto;
                  font-size: 18px;
                  padding: 0 5px;
                  margin-right: 5px;
                  @include border-radius(3px);

                  line-height: 35px !important;

                  &:hover {
                    background-color: $color-weird-green;
                    color: #fff;
                  }
                }

                &.divide {
                  margin-left: -9px;
                }

                &.operand {
                  @include large-text-grey-blue;
                }

                &.empty {
                  width: 49px;
                  margin-left: -4px;
                  cursor: crosshair;
                }

                &:not(.empty):not(.function-btn) {
                  @include border-radius(50%);

                  &:hover {
                    background-color: $color-weird-green;
                    color: #fff;

                    .svg-icon {
                      .icon {
                        fill: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

body.non-mac {
  .formula-editor-modal {
    .modal {
      .modal-dialog {
        .modal-body {
          textarea {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
