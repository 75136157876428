/* stylelint-disable scss/double-slash-comment-whitespace-inside */
//flex
.flexbox {
  @include flexbox;
}

.flexbox-column {
  @include flex-direction(column);
}

.flex-auto {
  @include flex(auto);
} //1 1 auto
.flex-none {
  @include flex(none);
} //0 0 auto
.flex-1 {
  @include flex(1);
} //1  1 0px
.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

//-------------------------------------------------------------------------------------//
//text
.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

//-------------------------------------------------------------------------------------//
//box

/*
    generate spacing classes
    examples: 
    .p-5 { padding: 5px; }
    .pl-5 { padding-left: 5px; }
    .pr-5 { padding-right: 5px; }
    .pt-5 { padding-top: 5px; }
    .pb-5 { padding-bottom: 5px; }
    .plr-5 { padding-left: 5px; padding-right: 5px; }
    .ptb-5 { padding-top: 5px; padding-bottom: 5px; }
*/
$sides: "top", "bottom", "left", "right";
$spacers: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100, 4, 8, 12, 16, 24, 28); //add pixel values if required

@each $spacer in $spacers {
  .m-#{$spacer} {
    margin: #{$spacer}px;
  }
  .p-#{$spacer} {
    padding: #{$spacer}px;
  }

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$spacer} {
      margin-#{$side}: #{$spacer}px;
    }
    .p#{str-slice($side, 0, 1)}-#{$spacer} {
      padding-#{$side}: #{$spacer}px;
    }
  }

  .mtb-#{$spacer} {
    margin-top: #{$spacer}px;
    margin-bottom: #{$spacer}px;
  }
  .mlr-#{$spacer} {
    margin-left: #{$spacer}px;
    margin-right: #{$spacer}px;
  }
  .ptb-#{$spacer} {
    padding-top: #{$spacer}px;
    padding-bottom: #{$spacer}px;
  }
  .plr-#{$spacer} {
    padding-left: #{$spacer}px;
    padding-right: #{$spacer}px;
  }
}

//-------------------------------------------------------------------------------------//
//height & width
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.minw-100px {
  min-width: 100px;
}

//-------------------------------------------------------------------------------------//
//position
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
