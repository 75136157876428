/* stylelint-disable max-nesting-depth, declaration-no-important */
.notes-modal {
  .modal {
    .modal-dialog {
      .modal-header {
        display: none;
      }

      .modal-content .modal-body {
        padding-top: 40px;

        textarea.notes-text {
          border: none;
          border-bottom: 0.5px solid $color-silver;
          background-color: transparent;
          margin-bottom: -9px;
          color: $color-steel;
          padding: 0 0 8px;

          &:focus {
            outline: none !important;
            border-bottom: 0.5px solid $color-dark-mint;
          }
        }
      }
    }
  }
}
