/* stylelint-disable max-nesting-depth, scss/double-slash-comment-whitespace-inside, scss/comment-no-empty, font-family-no-missing-generic-family-keyword, function-url-quotes, selector-class-pattern, selector-pseudo-class-no-unknown, selector-pseudo-element-no-unknown, declaration-no-important */
.main-content,
.wizard-modal .modal-dialog .modal-content {
  .top-bar-buttons-line {
    .right-side {
      max-width: calc(100% - 550px);
      @include flexbox;

      align-items: center;
      justify-content: center;
      right: 72px;

      .table-filters {
        @include flexbox;

        align-items: center;
        flex-wrap: wrap;
        @include flex(1 1);
      }

      .filter-select {
        display: inline-block;
        vertical-align: middle;
        height: 25px;

        .filter-divider {
          margin-left: 10px;
          margin-right: 0;
        }

        .select2-container {
          border: none;
          padding: 0;
          height: 25px;
        }

        .select-filter-dropdown {
          height: 25px;

          &.has-filter-option {
            margin: 0 5px;
          }

          .select2-container {
            margin-top: -2px;
          }
        }

        .visibility-filter {
          margin-left: 10px;
          margin-right: 10px;
          margin-top: -2px;
        }
      }

      .add-emp-btn {
        &:not(.disabled) {
          background-color: $color-weird-green !important;
          color: $color-white !important;

          svg.icon {
            margin-bottom: 4px;
            margin-right: 5px;
            fill: $color-white;
          }
        }

        &.disabled {
          background-color: $color-silver !important;
          color: $color-steel !important;

          .svg-icon.icon {
            margin-bottom: 4px;
            margin-right: 5px;
            fill: $color-steel;
          }
        }
      }
    }
  }

  .table-editor {
    &.trx-table {
      .table-body:not(.accounts-table-body):not(.dimension-values-table-body)
        .table-body-wrapper
        .table-edit-line
        .scrollable-wrapper {
        height: 41px;
      }
    }

    &:not(.full-size-table-editor) {
      &:not(.read-only) {
        height: calc(100% - 85px);
      }

      &.read-only {
        height: calc(100% - 120px);
      }
    }

    &.full-size-table-editor {
      height: 100%;
    }

    &.employees-table {
      .table-header {
        .column {
          .column-text::after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 5px;
          }

          &.sort-ascending .column-text::after {
            width: 5px;
            height: 40px;
            content: "\f175";
            font-family: FontAwesome;
          }

          &.sort-descending .column-text::after {
            width: 5px;
            height: 40px;
            content: "\f176";
            font-family: FontAwesome;
          }

          &.name-column,
          input {
            width: 250px !important;

            .svg-icon {
              &.search-icon {
                &.inactive {
                  margin-left: 151px !important;
                }
              }
            }

            input.search-input {
              width: 190px !important;
            }
          }
        }
      }

      .table-body {
        .column {
          &.name-column {
            background-color: $color-ivory;
            width: 250px;

            .emp-name-input {
              background-color: inherit;
            }
          }
        }
      }

      .table-footer {
        height: 16px;

        .first-column-footer {
          width: 40px + 250px;
          height: 100%;
          float: left;
        }

        .scrollable-wrapper {
          height: 100%;
          float: left;
          width: calc(100% - 40px - 375px - 250px - 16px);

          .scrollable {
            .main-columns-footer {
              height: 2px;
            }
          }
        }

        .actions-footer {
          &:not(.dual-actions) {
            width: 90px;
          }

          height: 100%;
          float: left;
        }

        .dual-actions {
          width: 75px;
        }
      }

      .location-column {
        .ember-power-select-selected-item {
          list-style-type: none;
          margin-left: 0;

          li {
            @include text-ellipsis;
          }
        }
      }
    }

    &:not(.new-accounts-table-wizard) {
      .column {
        width: 160px;
      }
    }

    .column {
      float: left;
      min-height: 40px;

      &.center {
        text-align: center;
      }

      &.location-column,
      &.benefits-column {
        width: 250px;
      }

      &.num-of-employees-column,
      &.salary-column {
        width: 120px;
      }

      &.status-column,
      &.currency-column,
      &.term-column,
      &.rate-column,
      &.extras-column {
        width: 100px;
      }

      &.per-column {
        width: 150px;
      }

      &.start-date-column,
      &.end-date-column {
        width: 110px;
      }

      &.employee-id-column {
        width: 115px;
      }

      &.allocation-column {
        width: 125px;
      }

      &.month-column {
        width: 71px;
      }

      &.type-column {
        width: 170px;
      }

      //&.bonus-column {
      //  width: 90px;
      //}
      &.tags-column {
        width: 250px;

        &:not(.expand-enabled) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.expand-enabled {
          height: auto !important;
        }

        .inherited-tags {
          color: $heading-border-color;
        }

        span {
          line-height: 40px;
        }

        .select2-default {
          color: $color-green !important;
        }
      }

      &.with-max-height {
        max-height: 300px;

        .multi-select-dropdown {
          max-height: 300px;
          overflow-y: auto;
        }

        .multi-budget-lines-mapping {
          &::after {
            right: 25px;
          }
        }
      }

      input:not([type="checkbox"]) {
        width: 100%;
        border: 0;
        line-height: 38px !important;
        height: 38px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 10px;
        background-color: transparent;

        &:disabled {
          background-color: #fff;
          cursor: not-allowed;
        }

        &:focus {
          background-color: rgba(56, 218, 149, 0.25) !important;
          outline: 1.5px solid $color-weird-green;
        }

        &::input-placeholder {
          color: $heading-border-color;
        }

        &:placeholder {
          /* Firefox 18- */
          color: $heading-border-color;
        }

        &::placeholder {
          /* Firefox 19+ */
          color: $heading-border-color;
        }

        &:input-placeholder {
          color: $heading-border-color;
        }
      }

      &.name-column:not(.employees-table) {
        width: 375px;

        input {
          width: calc(100% - 115px);
        }

        input.width-100 {
          width: 100%;
        }

        .red-label {
          background-color: $color-red;
          color: white;
          @include border-radius(3px);

          padding: 0 5px;
          float: right;
          margin: 8px 12px 0 2px;
        }
      }

      &.employee-name {
        width: 250px;
      }

      &.actions-column {
        width: 90px;
        text-align: center;
        background-color: rgba(234, 234, 234, 0.35);

        &:not(.actions-footer) {
          &::before {
            content: "";
            height: 40px;
            width: 6px;
            background-color: rgba(216, 216, 216, 0.25);
            mix-blend-mode: multiply;
            float: left;
            margin-left: -5px;
          }
        }

        .svg-icon {
          opacity: 1;
          height: 25px;
          width: 25px;
          vertical-align: sub;
          vertical-align: -webkit-baseline-middle;

          .icon {
            fill: $color-silver;
            height: 18px;
            width: 18px;
          }

          &:hover {
            .icon {
              fill: $color-dark-grey-blue;
            }
          }

          &.active {
            .icon {
              fill: $color-dark-mint;
            }
          }
        }
      }

      &.checkbox-column {
        width: 40px;
        padding: 0;

        &:not(.missing-background) {
          background-color: $color-regular-white;
        }

        .budgeta-checkbox {
          height: 40px;

          .full.checkbox {
            margin: 0;
            padding: 0;
            height: 40px;

            &::before {
              left: 10px;
            }

            &::after {
              left: 7px;
            }
          }
        }
      }

      &.dimension-column {
        .dimension-value-lazy-clear {
          display: block;
          width: 12px;
          height: 13px;
          position: absolute;
          right: 20px;
          top: 12px;
          font-size: 1px;
          outline: none;
          background: url(select2.png) right top no-repeat;
        }

        .dimension-value-lazy-label {
          width: calc(100% - 34px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.placeholder {
            color: $heading-border-color;
            width: calc(100% - 18px);
          }
        }
      }

      .select-2-lazy-inactive-label {
        padding: 0 8px;
      }

      &.type-column,
      &.currency-column,
      &.dimension-column {
        .ember-view {
          height: 100%;

          .select-2-lazy-inactive-label {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 8px;
            position: relative;

            &::after {
              content: "\f107";
              color: #adadad;
              font-family: FontAwesome;
              font-weight: bold;
              font-size: 16px;
              display: block;
              position: absolute;
              right: 5px;
              top: 7px;
              transition: all 0.4s ease;
              pointer-events: none;
            }

            &.placeholder {
              color: $heading-border-color;
            }
          }
        }
      }

      &.location-column,
      &.status-column {
        .ember-view {
          height: 100%;
        }
      }

      &.bonus-column {
        .currency-with-type {
          .ember-view.currency {
            display: inline-block;
            width: 60%;
          }

          .select2-container {
            display: inline-block;
            width: 35%;
          }
        }
      }

      .select2-container {
        border: none;
        height: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.select2-dropdown-open {
          background-color: rgba(56, 218, 149, 0.25);
          border: solid 1.5px $color-weird-green;
        }
      }

      .type-ahead-lazy-inactive-label {
        margin-left: 10px;
        height: 40px;
        line-height: 40px;
        white-space: nowrap;
        cursor: text;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;

        &.placeholder {
          color: $heading-border-color;
        }
      }

      .month-picker {
        input.invalid {
          outline: 1.5px solid red;

          &:focus {
            background-color: rgba(255, 0, 0, 0.25) !important;
          }
        }
      }
    }

    .scrollable-wrapper {
      overflow-x: hidden;
      overflow-y: hidden;

      .scrollable {
        height: 100%;

        &.read-only {
          .column {
            pointer-events: none;
          }
        }
      }
    }

    .scrollable-wrapper:not(.footer-scrollable-wrapper) {
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
    }

    .table-header {
      &.has-top-border {
        border-top: 1px solid #d1d1d1;
      }

      &:not(.missing-border) {
        .column {
          border-bottom: 1px solid #d1d1d1;

          &:not(.checkbox-column) {
            &::after {
              content: "";
              height: 20px;
              width: 1px;
              background-color: #dfdfdf;
              float: right;
              margin-top: 10px;
              margin-right: -10px;
            }

            &:last-child::after {
              content: none;
            }
          }
        }
      }

      .column {
        background-color: #fff;
        padding: 0 10px;
        position: relative;

        &.checkbox-column {
          padding: 0;

          .budgeta-checkbox label {
            font-weight: normal;
          }
        }

        &.dimension-column {
          > span {
            max-width: 135px;
            width: 135px;
            display: inline-block;
            position: relative;
          }

          height: 41px;

          .column-text::after {
            right: 0;
          }
        }

        &.name-column {
          .svg-icon {
            opacity: 1;
            height: 25px;
            width: 15px;

            .icon {
              fill: $color-steel;
              height: 18px;
              width: 18px;
            }

            &.search-icon {
              &.active .icon {
                fill: $color-dark-grey-blue;
              }

              &:not(.employees-table) {
                &.inactive {
                  margin-left: 276px;
                }
              }

              //&:after {
              //  content: '';
              //  height: 20px;
              //  width: 1px;
              //  background-color: #dfdfdf;
              //  float: right;
              //  margin-top: -30px;
              //  margin-right: -31px
              //}
            }

            &.cancel-icon .icon {
              height: 13px;
              width: 13px;
            }

            &:hover {
              .icon {
                fill: $color-dark-grey-blue;
              }
            }
          }

          input.search-input {
            &:not(.employees-table) {
              width: 315px;
            }

            padding-left: 0;
            height: 38px;
            line-height: 38px !important;
            color: $color-dark-grey-blue;

            &:focus {
              background-color: transparent !important;
              outline: none;
            }

            &::input-placeholder {
              color: $color-steel;
            }

            &:placeholder {
              /* Firefox 18- */
              color: $color-steel;
            }

            &::placeholder {
              /* Firefox 19+ */
              color: $color-steel;
            }

            &:input-placeholder {
              color: $color-steel;
            }
          }
        }

        &.actions-column {
          &:not(.global-actions) {
            &::before {
              margin-left: -15px;
            }
          }

          .svg-icon {
            vertical-align: baseline;
            height: 100%;
          }

          &.global-actions {
            text-align: right;

            &.dual-actions {
              text-align: center;
            }

            &:not(.narrow-actions) {
              padding-right: 13px;
            }

            &.narrow-actions {
              padding: 0;
            }

            background-color: rgba(56, 218, 149, 0.25);

            .svg-icon {
              .icon {
                fill: $color-dark-grey-blue;
              }

              &.not-allowed {
                pointer-events: none;

                .icon {
                  fill: $color-silver;
                }
              }
            }
          }
        }
      }

      * {
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 14px;
        line-height: 40px;
        color: $color-steel;
        text-transform: uppercase;
      }

      .scrollable-wrapper {
        height: 41px;
        float: left;
      }
    }

    .table-header,
    .table-edit-line {
      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .table-body {
      position: relative;
      max-height: calc(100% - 35px - 16px);
      overflow-y: hidden;

      &:not(.accounts-table-body):not(.dimension-values-table-body) {
        .table-body-wrapper {
          .table-edit-line {
            &:not(.ember-view) {
              height: 40px;
            }
          }
        }
      }

      .table-body-wrapper {
        .table-edit-line {
          > .ember-view {
            height: auto;
          }

          .scrollable-wrapper {
            height: 40px;
            float: left;
          }

          &:not(.line-border):not(.missing-border) {
            .column {
              border-bottom: 1px solid #dfdfdf;
            }
          }

          &.line-border {
            border-bottom: 1px solid #dfdfdf;
            height: auto !important;

            .column:not(.expand-enabled) {
              height: 40px;
            }
          }

          &:not(.selected) {
            .column.checkbox-column.with-hover {
              .budgeta-checkbox {
                display: none;
              }
            }
          }

          &:hover {
            &.hightlight-hover {
              &:not(.selected) {
                background-color: $color-topaz-highlight-lighter !important;

                input {
                  background-color: $color-topaz-highlight-lighter !important;
                }
              }
            }

            .column {
              &.checkbox-column.with-hover {
                .budgeta-checkbox {
                  display: block;
                }
              }
            }
          }

          .column {
            height: 100%;
            position: relative;

            &.checkbox-column {
              border-right: none;
            }

            &.name-column {
              &:not(.missing-background, .account) {
                background-color: $color-regular-white;
              }

              &.bold {
                span.budget-line-name,
                span.wrapper,
                span.spliter,
                span.value-display,
                span.value-display-first,
                input {
                  font-weight: 600;
                }
              }
            }

            &.num-of-employees-column {
              position: relative;

              .num-of-emp {
                padding: 0 10px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            &.type-column {
              position: relative;

              .line-type {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
              }
            }

            &.status-column {
              position: relative;

              .text-status {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
              }

              .select2-container {
                .select2-choice {
                  padding-left: 10px;
                }
              }
            }

            &.total-column {
              > span {
                padding: 0 8px;
              }

              span {
                line-height: 40px;
              }
            }

            &.budgetLines {
              .multi-select-dropdown {
                padding-left: 8px;
                border: solid 1.5px transparent;

                &.active {
                  background-color: rgba(56, 218, 149, 0.25);
                  border: solid 1.5px $color-weird-green;
                }

                .selection {
                  padding-right: 12px;
                }
              }
            }
          }

          &.selected {
            .column {
              background-color: rgba(56, 218, 149, 0.25) !important;

              input {
                background-color: transparent !important;
              }
            }
          }

          &.scenario-line {
            .column.name-column input {
              color: #44a3e5;
            }
          }

          &.scenario-line-added {
            .column.name-column input {
              color: #44a3e5;
              font-weight: bold;
            }
          }

          &.scenario-line-removed {
            .column {
              pointer-events: none;

              &.name-column {
                &::before {
                  content: "";
                  display: block;
                  width: 100%;
                  top: 45%;
                  left: 0;
                  position: absolute;
                  border-bottom: 2px solid rgba(52, 124, 208, 0.8);
                  z-index: 999;
                }
              }
            }
          }

          .select-2-type-ahead-lazy {
            line-height: 40px;
            height: 40px;

            .select-2-lazy-inactive-label {
              line-height: 40px;
              height: 40px;

              &.placeholder {
                color: $heading-border-color;
              }
            }
          }
        }
      }
    }

    .table-footer {
      height: 16px;

      .first-column-footer {
        width: 40px + 375px;
        height: 100%;
        float: left;
      }

      .scrollable-wrapper {
        height: 100%;
        float: left;
        width: calc(100% - 40px - 375px - 90px - 16px);

        .scrollable {
          .main-columns-footer {
            height: 2px;
          }
        }
      }

      .actions-footer {
        &:not(.dual-actions) {
          width: 90px;
        }

        height: 100%;
        float: left;
      }

      .dual-actions {
        width: 75px;
      }
    }
    @for $i from 0 through 9 {
      .table-edit-line[data-level="#{$i}"] {
        .column.name-column {
          .display-accounts .budget-line-name,
          input {
            padding-left: 12px * ($i + 1);
            flex-shrink: 10000;
          }
        }
      }
    }

    .table-edit-line {
      &.root-budget-line,
      &.disable-duplicate {
        .actions-column {
          .duplicate,
          .delete_budget {
            pointer-events: none;

            &:hover {
              .icon {
                fill: $color-silver;
              }
            }
          }
        }
      }

      .actions-column {
        .svg-icon {
          &.disabled {
            pointer-events: none;
            background-color: transparent;
            opacity: 0.4;
            padding: 0;
          }
        }
      }
    }

    &.read-only {
      .table-header {
        .column.checkbox-column {
          pointer-events: none;
        }
      }

      .table-body {
        .table-edit-line {
          .column {
            pointer-events: none;
          }
        }
      }
    }

    &.sync-accounts-table,
    &.delete-accounts-table {
      .table-header,
      .sync-account-row,
      .delete-account-row {
        display: flex;
      }

      .sync-account-row,
      .delete-account-row {
        border-bottom: 1px solid $color-soft-grey;
      }

      .column {
        overflow: hidden;
        flex: 1;
        padding: 0 10px;

        &.intacct-account-column,
        &.planning-account-column {
          flex: 0.7;
        }

        .ember-power-select-selected-item,
        .multi-select-dropdown .selection,
        .cell {
          line-height: 38px;
        }

        .ember-basic-dropdown-trigger {
          @include text-ellipsis;
        }
      }

      .accounts-table-body {
        max-height: 313px;

        .column {
          .ember-power-select-selected-item,
          .multi-select-dropdown .selection,
          .multi-select-dropdown .path-list-element,
          .account-mapping-menu .mapping-display,
          .select2-container .select2-chosen {
            line-height: 38px;
          }

          .ember-basic-dropdown-trigger {
            @include text-ellipsis;
          }

          input,
          span,
          .mapping-display,
          .select2-choice {
            padding: 0;
          }

          &.mapping-type-column {
            height: 40px;
          }

          .select2-container .select2-arrow b::after {
            top: 8px;
          }

          .select-2-lazy-inactive-label {
            width: 100%;
            position: relative;

            &::after {
              right: 5px;
            }
          }
        }
      }

      .sync-account-row:not(.update-description) .planning-account-column {
        @include dropdown-sign;
      }

      .sync-account-row:not(.create-new-account),
      .delete-account-row {
        .account-mapping-menu .mapping-display::after,
        .budget-lines-column .multi-budget-lines-mapping::after,
        .dimensions-column .multi-budget-lines-mapping::after,
        .select-2-lazy-inactive-label::after {
          display: none;
        }
      }

      .table-header .column {
        background-color: $color-light-soft-grey;
      }
    }

    &.delete-accounts-table {
      .column {
        @include text-ellipsis;

        &.checkbox-column {
          flex: none;
          width: 40px;
          padding: 0;

          .budgeta-checkbox {
            height: auto;
          }
        }

        &.actions-column {
          flex: none;
          width: 80px;

          &::before {
            display: none;
          }
        }
      }

      .delete-account-row.selected .column {
        background-color: $color-dropdown-hover;
      }
    }
  }

  .view-table-edit {
    &.role-column-fixed {
      .table-editor {
        .table-body {
          .table-edit-line.employee-edit-line {
            .column.role-column input {
              background-color: rgba(234, 234, 234, 0.35);
            }

            .emp-name-input {
              background-color: inherit;
            }
          }
        }

        .table-footer {
          .first-column-footer {
            width: 40px + 250px + 160px;
          }

          .scrollable-wrapper {
            width: calc(100% - 40px - 250px - 90px - 16px - 160px);
          }
        }
      }
    }

    &.integration-wizard-dimension-values-mapping-table {
      .trx-table-header {
        .scrollable-wrapper {
          width: calc(100% - 40px - 250px) !important;
        }
      }

      .trx-table-body {
        height: 390px;

        .table-edit-line {
          .trx-row-content {
            .column {
              &.dimension-value-name {
                width: 375px;
                background-color: rgba(234, 234, 234, 0.35);
              }
            }

            .scrollable-wrapper {
              width: calc(100% - 40px - 250px) !important;

              .scrollable {
                .column {
                  justify-content: flex-start;
                  padding-left: 10px;
                  font-size: 18px;

                  .multi-select-dropdown {
                    width: 100%;
                    padding-left: 10px;
                    border-bottom: none;

                    .selection {
                      font-size: 18px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.short-content {
      .table-editor {
        .table-body.accounts-table-body .trx-row-content {
          .column.checkbox-column {
            label {
              height: 100%;
            }
          }
        }

        .table-body.accounts-table-body .trx-row-content,
        .table-body > .ember-list-view > div > div > div,
        .table-body.user-select-none .ember-list-view > div > div > div {
          .scrollable-wrapper {
            > .scrollable {
              width: 100% !important;
              @include flexbox;

              > .column {
                @include flex(1);

                &.location-column {
                  @include flex(0 0 250px);
                }
              }
            }
          }
        }

        .table-header {
          .scrollable-wrapper {
            > .scrollable {
              width: 100% !important;
              @include flexbox;

              > .column {
                @include flex(1);

                &.location-column {
                  @include flex(0 0 250px);
                }
              }
            }
          }
        }
      }
    }
  }
}

body.hiddenscroll {
  .main-content,
  .modal-content {
    .table-editor {
      //.table-body {
      //  max-height: calc(100% - 35px);
      //
      //}
      .table-footer {
        .scrollable-wrapper {
          .scrollable {
            height: 15px;
          }
        }
      }
    }

    .view-table-edit {
      &:not(.accounts-table) {
        .table-editor:not(.employees-table) {
          .scrollable-wrapper {
            width: calc(100% - 40px - 375px - 90px);
          }
        }

        .employees-table {
          .scrollable-wrapper {
            width: calc(100% - 40px - 250px - 90px);
          }
        }
      }

      &.role-column-fixed {
        .table-editor {
          .scrollable-wrapper {
            width: calc(100% - 40px - 250px - 90px - 160px);
          }
        }
      }
    }
  }
}

body:not(.hiddenscroll) {
  .main-content,
  .modal-content {
    .table-editor .table-footer .scrollable-wrapper {
      overflow-x: scroll;
    }

    .view-table-edit {
      &:not(.accounts-table) {
        &.hasRightScroll {
          .table-editor:not(.employees-table) {
            .table-header,
            .table-footer {
              margin-right: 15px;

              .scrollable-wrapper {
                width: calc(100% - 40px - 375px - 90px);
              }

              .narrow-actions {
                width: 90px;

                &.global-actions {
                  padding-right: 6px;
                }
              }
            }

            .table-body {
              .scrollable-wrapper {
                width: calc(100% - 40px - 375px - 90px);
              }
            }
          }

          .employees-table {
            .table-header,
            .table-footer {
              margin-right: 15px;

              .scrollable-wrapper {
                width: calc(100% - 40px - 250px - 90px);
              }

              .narrow-actions {
                width: 90px;

                &.global-actions {
                  padding-right: 6px;
                }
              }
            }

            .table-body {
              .scrollable-wrapper {
                width: calc(100% - 40px - 250px - 90px);
              }
            }
          }
        }

        &:not(.hasRightScroll) {
          .table-editor {
            .scrollable-wrapper {
              width: calc(100% - 40px - 250px - 90px);
            }
          }
        }
      }

      &.accounts-table {
        &.hasRightScroll {
          .table-editor {
            .table-header {
              .actions-column {
                width: 80px !important;

                &.dual-actions {
                  width: 75px !important;
                }
              }
            }

            .table-header,
            .table-footer {
              .scrollable-wrapper {
                width: calc(100% - 40px - 180px - 77px - 15px);
              }
            }

            .table-body {
              .scrollable-wrapper {
                width: calc(100% - 40px - 180px - 77px);
              }
            }
          }
        }

        &:not(.hasRightScroll) {
          .table-editor {
            .scrollable-wrapper {
              width: calc(100% - 40px - 180px - 80px);
            }

            .actions-column {
              width: 80px !important;
            }
          }
        }
      }

      // if the role column is fixed
      &.role-column-fixed {
        &.hasRightScroll {
          .table-editor {
            .table-header,
            .table-footer {
              .scrollable-wrapper {
                width: calc(100% - 40px - 250px - 90px - 160px);
              }
            }

            .table-body {
              .scrollable-wrapper {
                width: calc(100% - 40px - 250px - 90px - 160px);
              }
            }
          }
        }

        &:not(.hasRightScroll) {
          .table-editor {
            .scrollable-wrapper {
              width: calc(100% - 40px - 250px - 90px - 160px);
            }
          }
        }
      }
    }
  }
}

body.non-mac {
  .main-content,
  .modal-content {
    .table-editor {
      .table-footer {
        height: 18px;

        .scrollable-wrapper {
          .scrollable {
            height: 18px;
          }
        }
      }
    }
  }
}

.wizard-modal .modal-dialog .modal-content .table-editor {
  .column.name-column {
    &:not(.missing-background) {
      background-color: rgba(234, 234, 234, 0.35);
    }
  }

  .table-body {
    max-height: 500px;
    overflow-y: auto;

    .column.name-column {
      height: 48px;

      input {
        background: transparent;
      }
    }
  }

  .table-footer {
    .column {
      min-height: 10px !important;
      background: transparent !important;
    }

    .scrollable {
      height: 15px;
    }
  }

  .scrollable-wrapper {
    width: 550px !important;

    .scrollable {
      margin-top: 0 !important;
    }
  }

  .new-accounts-table-wizard {
    .column {
      &.name-column {
        width: 180px;
      }
    }

    &.dimension-value-table-wizard {
      .scrollable-wrapper {
        width: calc(100% - 180px) !important;

        .scrollable {
          @include flexbox;

          > .column {
            @include flex(1 1);
          }
        }
      }
    }

    &:not(.dimension-value-table-wizard) {
      .scrollable-wrapper {
        width: calc(100% - 180px) !important;

        .scrollable {
          @include flexbox;

          > .column:first-child {
            width: 180px;
          }

          > .column:nth-child(2) {
            @include flex(1 1);
          }
        }
      }
    }

    .table-body {
      max-height: 300px;
    }

    .trx-row {
      .trx-row-content {
        @include flexbox;

        .scrollable-wrapper {
          @include flex(1 1);

          // height: auto;
          min-height: 40px;

          .scrollable {
            margin-top: 0;
            overflow-y: hidden;

            > .column {
              position: relative;

              .mapping-display {
                font-size: 18px;
              }

              .budget-line-mapping {
                .multi-budget-lines-mapping {
                  line-height: 40px;
                  padding: 0 8px;

                  .budget-select-lazy {
                    .select2-chosen {
                      height: 40px;
                      line-height: 40px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
