/* stylelint-disable max-nesting-depth, no-duplicate-selectors, declaration-no-important */
.budget-navigator {
  width: 100%;

  .budget-navigator-wrapper {
    position: relative;
    height: 60px;
    padding: 0 24px;
    background: $color-black-darker;
    z-index: 1;

    &.with-snow {
      background: transparent !important;
    }

    > div {
      display: inline-block;

      span,
      div,
      a {
        color: $color-white;
        font-weight: 400;
      }
    }

    .svg-icon {
      svg.icon {
        fill: $color-white;
      }

      &:hover {
        svg.icon {
          fill: $color-green-sage-logo;
        }
      }
    }

    .left {
      left: 24px;
      width: 35vw;
      overflow: hidden;
      @media only screen and (max-width: 1500px) {
        width: 32vw;
      }
      @media only screen and (max-width: 1200px) {
        width: 29vw;
      }
      @media only screen and (max-width: 1050px) {
        width: 26vw;
      }

      .budget-title {
        margin-left: 30px;
        margin-top: -2px;

        * {
          font-size: 22px;
        }
        @include text-ellipsis;
      }

      input:focus {
        background-color: $color-dark-blue-grey;
        border: 3px solid $color-dark-mint;
        color: $color-weird-green;
        @include box-shadow(none);

        outline: none;
      }

      div.svg-icon {
        top: 44%;
      }
    }

    .navigator-headers {
      height: 100%;

      > div {
        display: inline-block;
        text-align: center;
        height: 100%;

        &:not(:last-child) {
          margin-right: 48px;
        }

        div.navigator-header-text.active {
          font-weight: 500;
        }

        div.navigator-header-text:hover {
          cursor: pointer;
          color: $color-green-sage-logo;
        }

        div.navigator-header-text.active {
          color: $color-green-sage-logo;
        }

        div.navigator-header-text {
          height: 100%;
          font-size: 18px;
          width: auto;
          line-height: 60px;
          display: inline-block;
        }
      }
    }

    .right {
      right: 0;
      padding-right: 25px;

      .svg-icon {
        margin: 12px;
      }

      .intacct-icon {
        img {
          margin: 12px;
        }

        display: inline-block;
        width: 50px;
        height: 50px;
        margin-top: -12px;
        background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 60%);
      }

      #more-budget-actions {
        margin-right: 0;
      }

      #more-budget-actions.displayed {
        color: $color-green-sage-logo;

        svg {
          fill: $color-green-sage-logo;
        }
      }

      .setup-guide-icon {
        position: relative;

        &:not(.complete) {
          &::after {
            width: 10px;
            height: 10px;
            @include border-radius(50%);

            background-color: $color-red;
            content: "";
            position: absolute;
            top: 1px;
            right: -2px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .budget-navigator {
    .budget-navigator-wrapper {
      .right {
        padding-right: 36px;
      }
    }
  }
}

// Rebrand 2023
.budget-root-menu-wrapper {
  display: none; /* required because of qtip behavior */
}

// need to remove the nesting when qtip.scss will be deleted
.qtip.qtip .qtip-content .budget-root-menu-wrapper {
  padding: 0;
}

.budget-root-menu {
  .search-bar-wrapper {
    padding: 8px;
    border-bottom: 1px solid var(--color-gray-101);

    // text of search input
    ::placeholder {
      color: var(--color-gray-103);
    }

    .search-bar {
      height: 40px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid var(--color-blue-101);
      padding: 0 12px;

      input {
        margin-right: 12px;
        font-size: 14px;
        line-height: var(--list-item-line-height);
        color: var(--color-gary-103);
        border: none;

        &:focus {
          outline: none;
        }
      }

      .icon {
        align-self: end;

        svg {
          fill: var(--list-item-icon-color);
        }
      }
    }
  }

  .sub-menu {
    display: flex;
    align-items: flex-end;
    padding: 8px 0;
    border-top: 1px solid var(--color-gray-101);

    // disable 'new-budget' button on scenario and versions
    &#add-new-budget.disabled {
      cursor: not-allowed;

      &:hover {
        background-color: inherit;

        * {
          cursor: not-allowed;
        }
      }

      .new-item-icon {
        svg {
          fill: var(--color-gray-103);
        }
      }

      .sub-menu-heading {
        color: var(--color-gray-103);
      }
    }

    .sub-menu-heading {
      font-weight: 500;
      cursor: pointer;
      line-height: var(--list-item-line-height);
      font-size: var(--list-item-font-size);
      color: var(--list-item-text-color);
      padding: 8px 8px 8px 16px;
    }

    .new-item {
      color: var(--color-green-101);
    }

    .new-item-icon {
      cursor: pointer;

      svg {
        fill: var(--color-green-101);
      }
    }

    .icon {
      cursor: pointer;

      svg {
        fill: var(--list-item-icon-color);
      }
    }

    &:hover {
      background-color: var(--list-item-hover-color);
    }
  }

  .budget-root-menu-wrapper {
    .budget-menu-list {
      padding: 8px 0;
      margin-bottom: 0;
      width: 240px;
      max-height: 210px;
      overflow-y: auto;
      overflow-x: hidden;

      li {
        cursor: pointer;

        div {
          line-height: var(--list-item-line-height);
          font-size: var(--list-item-font-size);
          color: var(--list-item-text-color);
          padding: 10px 16px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
        }

        &:hover {
          background-color: var(--list-item-hover-color);
        }
      }
    }
  }
}
