.step-account-category-error-container {
  .modal-base {
    z-index: 0;
  }

  .header-container {
    align-items: center;
    flex-direction: row;

    .title {
      width: 300px;
      height: 80px;
      text-align: left;
    }
  }

  .body-container {
    padding: 5px 20px 0;

    .description {
      width: 80%;
      margin: 10px auto 20px;
      text-align: left;
    }
  }
}

.footer-container {
  .footer-buttons {
    .primary {
      background-color: $color-weird-green;
      color: white;
      line-height: 40px;
      padding: 0 15px;
    }
  }
}
