.react-section {
  &.table-view {
    .noty-container {
      left: 60%;

      .noty-text span {
        color: white;
        display: flex;

        &:last-child {
          font-weight: 500;
        }
      }
    }
  }
}
