#my-profile-root {
  height: calc(100% - 48px);
  padding: 20px 0;
  background-color: $font-color-white;

  .user-profile-container {
    .sibp-button {
      border-radius: 5px;
    }

    .secondary {
      color: $color-gray-3;
      background-color: $color-white-1;
    }

    .footer {
      margin: 0 50px 0 20px;
      border-top: 2px solid $color-gray-4;
      padding: 20px 30px 0 0;
    }

    .profile-details {
      margin-bottom: 0;
    }

    .input-container {
      margin-top: 20px;
    }

    .sibp-checkbox {
      margin-top: 20px;

      label {
        display: flex;
      }

      .label {
        color: $color-dark-grey-blue;
      }
    }
  }
}
