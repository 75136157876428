/* stylelint-disable max-nesting-depth, no-duplicate-selectors, selector-class-pattern, declaration-no-important */
.modal.add-employee-modal-dialog {
  .modal-dialog {
    width: 1020px;
    height: 80%;
    overflow: auto;

    .modal-content {
      .modal-body {
        padding-bottom: 0;

        .employee-inputs {
          margin-bottom: 45px;
          @include flexbox;
          @include flex-wrap(wrap);

          .budget-module-employee {
            width: 100%;
          }
        }

        .show-more-btn {
          color: $color-dark-mint;

          .svg-icon {
            svg.icon {
              fill: $color-dark-mint;
              width: 22px;
            }
          }

          &.open {
            .svg-icon {
              @include transform(rotate(90deg));
            }
          }
        }

        hr {
          margin-top: 40px;
        }

        .add-another-wrapper {
          text-align: right;

          .budgeta-checkbox {
            label.checkbox {
              padding: 0;

              &::before {
                right: 180px;
                left: inherit;
              }

              &::after {
                right: 24px;
              }
            }
          }
        }

        &:not(.trx-table) {
          height: 100%;
        }

        @include placeholder-color($color-green);

        .scrollable {
          overflow-y: visible;
          position: relative;

          &:not(.model-group):not(.table-scrollable) {
            margin-bottom: 55px;
          }

          &.preview-open {
            &.has-preview {
              height: calc(100% - 260px);
            }

            &.has-large-preview {
              height: calc(100% - 295px);
            }
          }

          &.with-status-bar,
          &.readonly-mode {
            &.readonly-mode {
              padding-bottom: 20px;
            }

            &:not(.model-group) {
              height: calc(100% - 179px);
            }

            &.preview-open {
              &.has-preview {
                height: calc(100% - 295px);
              }

              &.has-large-preview {
                height: calc(100% - 330px);
              }
            }
          }

          &.disabled {
            overflow-y: hidden;

            &::before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
              background: #fff;
              opacity: 0.5;
            }
          }
        }

        .null-placeholder {
          @include placeholder-color(rgba(255, 255, 255, 0));
        }

        > div.center {
          position: relative;
          width: 100%;
          text-align: center;
          line-height: 1;
        }

        label {
          margin-bottom: 0;

          &:not(.error) {
            &.small {
              @include flex(0 0 20px);

              width: 20px;
              max-width: 20px;
              min-width: 20px !important;
            }

            &.wide {
              @include flex(0 0 160px);

              width: 160px;
              max-width: 160px;
            }
          }

          &.symbol {
            min-width: 30px;
            max-width: 30px !important;
          }

          &.error {
            display: block;
          }

          &.checkbox {
            cursor: pointer;
            margin-top: 0;
            background-color: transparent;
            line-height: 40px;
            padding: 0 20px;

            input[type="checkbox"] {
              margin-top: 0;
              margin-left: -15px;
            }
          }
        }

        label.label-after {
          display: none;
        }

        div.bs-switch {
          border: 1px solid #e8e8e7;
          padding-top: 4px;
          text-align: center;

          .bootstrap-switch {
            .bootstrap-switch-container {
              line-height: 1;

              .bootstrap-switch-handle-on.bootstrap-switch-primary {
                background: $color-green;
              }
            }
          }
        }

        p.input-error {
          font-size: 0.8em;
          left: 0;
          bottom: -20px;
        }

        .has-label-after {
          label.label-after {
            display: inline-block;
            @include big-mid-text-steel;

            text-align: end;
            text-align: right;
          }
        }

        .twitter-typeahead {
          width: 100%;

          input {
            width: 100%;
          }
        }

        .attribute-allocated {
          .data-rows-wrapper {
            width: calc(100% - 100px);
          }

          .add {
            max-width: 75px;
          }
        }

        div[id^="attribute-budgetLine"] {
          height: auto !important;
        }

        div[id^="attribute-assumptions"] {
          flex-wrap: wrap;

          .full {
            width: 100%;
            @include flex(0 0 auto);
          }

          .data-rows-wrapper {
            width: 100%;
            flex-wrap: wrap;
          }
        }

        div[id^="attribute-accountId"],
        div[id^="attribute-costCenter"] {
          height: 100% !important;

          .data-row div.remove-line {
            right: -20px;
            left: auto;
          }
        }

        input:not([type="radio"]):not([type="checkbox"]) {
          height: 40px;

          &.percent {
            width: 60px;
          }
        }

        div[id^="attribute-"] {
          &:not(.wrap):not(.full) {
            height: 40px;
          }

          &:not(.budget-without-highlight-value-border) {
            div:not(.drop-down):not(.currency):not(.month-picker-wrapper):not(.month-picker) {
              display: inline-block;
            }
          }
        }

        div[id^="attribute-assumptions"] {
          .modifier {
            @include flexbox-important;
          }
        }

        input[type="number"] {
          height: 35px;
        }

        .custom-amount {
          input {
            width: 100%;
          }

          input[type="checkbox"] {
            width: 20px;
          }

          .has-formula-preview {
            @include flexbox;

            * {
              @include flex(1 1 auto !important);

              width: auto !important;
              min-width: 50px !important;
              max-width: none !important;
            }
          }

          .add {
            line-height: 35px;
            padding-left: 5px;
          }
        }

        .scrollable {
          .dropdown {
            width: 50%;
            height: 35px;

            a[data-toggle] {
              line-height: 34px;
            }
          }

          .styled-select {
            width: 50%;
            height: 35px;

            select {
              line-height: 33px;
            }

            &::after {
              line-height: 35px;
              right: 10px;
            }
          }
        }

        .comments {
          .comment {
            margin-right: 10px;

            @media screen and (min-width: 1280px) {
              margin-right: 40px;
            }

            @include flexbox;

            label {
              @include flex(0 auto);

              height: 73px !important;
              border-bottom: 1px solid $color-silver;
            }

            textarea {
              @include flex(1);

              border: none;
              border-bottom: 1px solid $color-silver;
              resize: none;
              outline: 0;
              @include mid-text-grey-blue;
            }

            &:hover {
              @include highlight-value-border;
              @include highlight-value;
            }

            .leftElement {
              left: -30px;
            }
          }
        }

        .comments + .add-icon-list {
          margin-top: 5px;
        }

        .budget-attributes-wrapper {
          .panel-default {
            border: none;

            &.section-general {
              .panel-heading {
                display: none;
              }
            }
          }
        }
      }

      .modal-footer {
        padding-right: 48px;
      }
    }
  }
}
