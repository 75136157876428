/* stylelint-disable scss/at-extend-no-missing-placeholder */
.dimension-mapping-wrapper {
  * {
    @include big-mid-text;
  }

  .power-select-lazy {
    .ember-power-select-placeholder {
      margin-left: 0;
      padding-left: 15px;
      width: 200px;
      color: $heading-border-color;
      height: 100%;
      line-height: 40px;
      @include text-ellipsis;
    }

    .selected-text {
      padding-left: 15px;
      width: 200px;
      @include text-ellipsis;

      &.inactive {
        width: 240px;
      }

      &.placeholder {
        color: $heading-border-color;
      }
    }
  }
}

.ember-basic-dropdown-content {
  &.dimension-mapping {
    .selected-text {
      @include text-ellipsis;

      display: block;
    }
  }
}
