/* stylelint-disable max-nesting-depth, declaration-no-important */
.modal-container.add-model-dashboard-modal {
  .modal-body {
    padding: 0 48px !important;
    max-height: 575px;
    overflow-y: auto;

    .tab-content {
      .tab-pane {
        padding: 0 !important;
      }
    }

    .chart-model {
      position: relative;

      .delete-chart {
        position: absolute;
        left: -30px;
        top: 5px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
        border-bottom: 2px solid $color-silver;
      }

      .dashboard-list {
        .budgeta-checkbox {
          label.checkbox {
            margin: 0;
            border: none !important;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .modal-body {
      max-height: 400px;
    }
  }
}
