/* stylelint-disable max-nesting-depth, no-duplicate-selectors, declaration-no-important */
.elegant-modal.wizard-modal.group-company-intacct {
  .intacct-account-list {
    .table-header {
      @include flexbox;

      padding: 0 24px;
      border-bottom: 1px solid #d1d1d1;

      .column {
        &.checkbox-column {
          align-self: flex-end;
          @include flex(0 0 40px);
        }

        &.name-status-column {
          @include flex(0 0 290px);
        }

        &.mapping {
          position: relative;
        }
      }
    }

    .table-body {
      .ember-list-view {
        > div > div > div {
          input {
            letter-spacing: 0.2px;
          }

          .mapping-column {
            .select2-chosen {
              font-weight: 300;
              letter-spacing: 0.2px;
            }
          }

          .status-column {
            width: 70px;

            input {
              font-weight: 500;
            }
          }

          .name-column,
          .mapping-column {
            input,
            .select2-chosen {
              font-weight: 300;
            }
          }
        }

        .table-edit-line {
          .table-scrollable {
            span.twitter-typeahead input:first-child {
              background-color: transparent !important;
            }
          }
        }
      }
    }

    .table-body {
      .ember-list-view {
        > div > div {
          .table-edit-line {
            @include flexbox;

            padding: 0 24px;

            .column {
              &.status-column {
                @include flex(0 0 70px);
              }

              &.name-column {
                @include flex(0 0 220px);
              }

              &.checkbox-column {
                @include flex(0 0 40px);
              }
            }
          }

          .scrollable {
            overflow-y: hidden;
          }
        }
      }
    }
  }
}
