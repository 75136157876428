/* stylelint-disable max-nesting-depth, selector-class-pattern, declaration-no-important */
.dimension-tree-values {
  @for $i from 1 through 20 {
    li[data-level="#{$i}"] {
      margin-left: ($i * 35px);
    }
  }

  li {
    > .dimension-content {
      @include flexbox;

      .dimension-display {
        @include flex(1 1);

        .inline-edit {
          &.empty-value {
            &.editing input {
              height: 25px;
            }

            input {
              line-height: 25px !important;
              height: 25px;
            }
          }
        }
      }

      .dimension-actions {
        @include flex(0 0 auto);

        > * {
          display: none;
        }
      }
    }

    &:hover,
    &.active {
      > .dimension-content {
        .dimension-display {
          .inline-edit {
            font-size: 600;
          }
        }

        .dimension-actions {
          *:not(.drop-down) {
            display: inline-block;
          }
        }
      }
    }
  }

  &:not(.dimensions-tree) {
    li {
      .dimension-content {
        @include flex-v;

        height: 30px;

        .dimension-display {
          @include flex-v;
        }

        .svg-icon {
          &.collapse-expend-icon {
            &:not(.expend) {
              @include transform(rotate(-90deg) translateX(-2px) translateY(2px));
            }
          }
        }
      }
    }
  }

  &.draggable-list {
    li {
      position: relative;

      .dimension-content {
        &:not(.hidden) {
          border-top: 2px solid transparent;
          border-bottom: 2px solid transparent;
        }
      }

      &:hover {
        background-color: rgba($color-weird-green, 0.25);
      }

      &.dropIn {
        .dimension-content {
          background-color: $color-move;
        }
      }

      &.moveDown {
        .dimension-content {
          border-bottom: 2px solid $color-move;
        }
      }

      &.moveUp {
        .dimension-content {
          border-top: 2px solid $color-move;
        }
      }
    }
  }
}
