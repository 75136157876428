.change-log-list-wrapper {
  min-width: 250px;
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    padding: 10px 16px 9px;

    &:hover {
      background-color: var(--list-item-hover-color);
    }

    * {
      font-size: 16px;
      color: var(--list-item-text-color);
      line-height: var(--list-item-line-height);
    }

    .change-value {
      font-weight: 500;
      letter-spacing: var(--bold-font-letter-spacing);
    }

    .change-details {
      flex-grow: 1;
    }

    .change-time {
      margin-left: 8px;
    }
  }
}
