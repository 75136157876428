/* stylelint-disable max-nesting-depth, declaration-no-important */
.trx-filter,
.table-filter {
  i {
    color: $color-steel;
    margin-right: 10px;

    &.filter-on {
      color: $color-topaz-highlight;
    }
  }

  display: inline-block;
  height: 40px;
  position: absolute;
  right: 0;
  cursor: pointer;
  width: 100%;
  text-align: right;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid $color-steel;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 5px;
    right: 10px;
  }

  &.sort-ascending,
  &.sort-descending,
  &.has-filter {
    &::before {
      display: none;
    }
  }
}

.drop-down.trx-filter-dropdown {
  &:not(.multi-select-dropdown) {
    width: 200px;
  }

  &.wide {
    width: 100%;
  }

  .filter-header {
    margin: 10px 10px 0;

    .filter-header-controller {
      &.without-sorting {
        input {
          width: 100%;
          max-width: calc(100% - 0px);
        }
      }

      &:not(.without-sorting) {
        input {
          max-width: calc(100% - 34px);
        }
      }

      input {
        display: inline-block;

        &:focus {
          outline: 1px solid $color-weird-green !important;
          background-color: rgba($color-weird-green, 0.25) !important;
        }
      }

      .filter-sort-action {
        display: inline-block;
        width: 30px;
        padding-left: 4px;

        .sort-descending {
          color: $color-green;
        }

        .sort-ascending {
          color: $color-green;
        }
      }
    }

    .no-filter,
    a {
      height: 22px;
      width: 100%;
    }
  }

  ul {
    max-height: 400px;
    overflow-y: auto;

    li {
      &:not(.path-list-element) {
        @include flexbox;
      }

      height: 40px;
      padding: 0 10px;

      &.divider {
        border-bottom: 1px solid $color-soft-grey;
      }

      &.selected {
        background-color: rgba($color-weird-green, 0.25) !important;
      }

      .budgeta-checkbox {
        label {
          height: 40px;

          &:hover {
            background: transparent;
          }
        }
      }

      .trx-filter-name {
        label {
          font-size: 1em;
          vertical-align: top;
          line-height: 40px;
          margin: 0;
          padding-top: 0;
          padding-bottom: 0;
          @include text-ellipsis;
        }

        line-height: 40px;
        @include flex(1);

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
