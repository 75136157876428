/* stylelint-disable max-nesting-depth, no-duplicate-selectors, selector-class-pattern */
.modal-container {
  .modal.dependencies-modal-dialog {
    .modal-dialog {
      width: 800px;
      max-width: 80%;
      height: 750px;
      max-height: 80%;

      .modal-content {
        height: 100%;

        .modal-body {
          height: calc(100% - 60px);
        }

        .scrollable {
          height: calc(100% - 90px);
          overflow-y: auto;
        }
      }

      .modal-content {
        .modal-body {
          padding: 0;
        }

        .modal-footer {
          border-top: 1px solid $color-mid-grey;
        }

        .scrollable {
          padding: 20px 72px;
          margin-bottom: 0;

          > .dependencies-section {
            &:not(:first-child) {
              margin-top: 20px;
            }
          }

          .dependencies-type-section {
            &.collapsed {
              .svg-icon {
                @include transform(rotate(-90deg));
              }
            }

            .dependencies-type-section-header {
              .svg-icon {
                .icon {
                  fill: $color-weird-green;
                }

                &:hover {
                  fill: $color-weird-green;
                }
              }

              label {
                color: $color-weird-green;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                text-transform: uppercase;
                width: auto;
              }

              border-bottom: 1px solid $color-mid-grey;
              padding-bottom: 5px;
            }

            .dependencies-element {
              .dependencies-element-content {
                @include flexbox;

                height: 45px;

                .dependencies-element-title {
                  @include flex(1 1);

                  line-height: 45px;
                  padding-left: 10px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }

                .dependencies-element-title-actions {
                  @include flex(0 0 200px);

                  display: none;
                  text-align: right;

                  span {
                    color: $color-silver;
                    line-height: 45px;
                    margin: 0 15px;

                    &:hover {
                      color: $color-steel;
                    }
                  }
                }

                &:hover {
                  .dependencies-element-title-actions {
                    display: block;
                  }

                  background-color: $color-light-mid-grey;
                }

                .svg-icon {
                  @include flex(0 0 30px);
                }
              }
            }

            &:not(:first-child) {
              margin-top: 15px;
            }
          }

          .dependencies-section-title {
            text-align: center;
            font-weight: 500;
            margin-bottom: 10px;
          }
        }

        .current {
          background-color: $color-weird-green;
          margin: 0;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          position: relative;

          .budget-map-logo {
            left: 0;
            top: 3px;
            position: absolute;
            zoom: 1.2;

            > div {
              float: left;
            }

            > .svg-icon {
              .icon {
                fill: #baf2da;
              }

              &:hover {
                cursor: auto;

                .icon {
                  fill: #baf2da;
                }
              }
            }

            .budget-map-logo-text {
              .budget-map-logo-budget {
                color: #baf2da;
              }

              .budget-map-logo-map {
                color: #baf2da;
                font-size: 26px;
              }
            }
          }

          .dependencies-element {
            padding: 20px 0;

            .dependencies-element-title,
            .dependencies-element-sub-title {
              color: white;
              text-align: center;
            }

            .dependencies-element-title {
              font-size: 30px;
              font-weight: 500;
            }

            .dependencies-element-sub-title {
              text-transform: uppercase;
              font-size: 14px;
              letter-spacing: 0.2px;
              font-weight: 500;
            }

            .svg-icon {
              float: right;
              margin-top: 4px;
              margin-left: 10px;

              .icon {
                fill: white;
              }
            }
          }

          .close_icon {
            position: absolute;
            right: 15px;
            top: 15px;

            .icon {
              fill: white;
            }

            &:hover {
              .icon {
                fill: white;
              }
            }
          }
        }

        .gotoModel {
          position: absolute;
          bottom: -50px;
          right: 100px;
          z-index: 1;
          color: $color-steel;

          &:hover {
            color: $color-dark-grey-blue;
          }
        }
      }
    }
  }
}
