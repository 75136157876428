/* stylelint-disable max-nesting-depth, no-duplicate-selectors, declaration-no-important */
.modal.dashboard-modal-dialog,
.modal.model-store-dialog {
  .modal-dialog {
    width: 1100px;

    .modal-body {
      max-height: 600px;
      height: 600px;

      .restore-widgets {
        position: absolute;
        right: 40px;
        top: -63px;
      }

      .selection-content {
        .chart-section {
          @include large-text-dark-mint;

          margin-bottom: 15px;
        }

        .chart-content {
          margin-bottom: 30px;
          @include flexbox;

          .chart-display {
            position: relative;
            @include flex(0 0 400px);

            height: 250px;
            @include box-shadow(0 3px 6px rgba(0, 0, 0, 0.25));

            margin-top: 5px;

            .single-kpi-chart {
              height: 250px;
              position: relative;

              .kpi-data-content {
                text-align: center;

                .kpi-value {
                  @include large-text-dark-mint;
                }
              }
            }

            .kpi-chart {
              height: 250px;
              @include flexbox;
              @include flex-wrap(wrap);

              .kpi-data {
                text-align: center;
                position: relative;
                @include flex(0 0 49%);

                &:nth-child(n + 3) {
                  border-top: 1px solid $color-silver;
                }

                &:nth-child(2n) {
                  border-left: 1px solid $color-silver;
                }

                .kpi-data-content {
                  width: 100%;

                  .kpi-value {
                    @include large-text-dark-mint;

                    padding-right: 5px;
                  }
                }
              }
            }
          }

          .chart-details {
            margin-left: 15px;
            position: relative;
            @include flex(1 1);

            .chart-title {
              @include big-mid-text-grey-blue;

              margin-bottom: 10px;
            }

            .chart-actions {
              position: absolute;
              bottom: 0;
              right: 0;
              height: 24px;

              > * {
                display: inline-block;
              }

              .chart-type-number {
                padding-right: 15px;
                font-weight: 600;
              }

              button.disable {
                cursor: not-allowed;
                background-color: $color-silver;

                &:hover {
                  background-color: $color-silver;
                }
              }

              .chart-add-btn {
                float: right;

                .svg-icon .icon {
                  float: left;
                }

                &:hover {
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
    @media screen and (max-height: 700px) {
      .modal-body {
        max-height: 400px;
      }
    }
    @media screen and (max-height: 800px) {
      .modal-body {
        max-height: 500px;
      }
    }
  }
}

.modal-container.advanced-chart {
  .modal {
    .modal-body {
      padding-bottom: 10px;

      .advanced-option {
        margin-bottom: 20px;
      }

      .selected-kpis {
        .selected-kpis-list {
          @include flexbox;
          @include flex-direction(row);

          > .selected-kpis-list-all,
          > .selected-kpis-list-chosen {
            @include flex(1);

            .select-list {
              border: 1px solid $color-silver;
              overflow-y: auto;
              max-height: 350px;
              height: 350px;
            }
          }

          > .selected-kpis-list-all {
            margin-right: 10px;

            .select-list {
              overflow-y: hidden;

              > .ember-list-view {
                > div > div > div {
                  padding: 5px;
                  overflow: hidden !important;
                }

                label {
                  width: 180px;
                }

                .sub-title {
                  width: 195px;
                }

                .model-title {
                  width: 210px;
                  font-weight: 500;
                }
              }
            }
          }

          > .selected-kpis-list-chosen {
            margin-left: 10px;

            .select-list {
              padding: 5px;

              label {
                width: 220px;
              }
            }
          }
        }

        .selected-kpis-title {
          span {
            font-size: 22px;

            &.above-max {
              color: $color-red;
            }
          }
        }

        .kpi-selection {
          > * {
            display: inline-block;
          }
        }

        .kpi-selection {
          .sub-title {
            margin-left: 15px;
          }

          .sub-title,
          .model-title,
          label {
            text-overflow: ellipsis;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
          }

          label {
            text-overflow: ellipsis;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
          }

          &.inner-list-item {
            margin-left: 30px;
          }
        }
      }
    }
  }
}

.modal-container.widget-rename {
  .modal {
    .modal-body {
      @include flexbox;

      min-height: 100px;
      padding-top: 20px;

      label {
        line-height: 38px;
        margin: 0;
        @include flex(0 0 100px);
      }

      input {
        @include flex(1 1);
      }
    }
  }
}
