/* stylelint-disable max-nesting-depth */
.import-actuals {
  &.mapping-drop-down,
  &.category-drop-down,
  &.type-drop-down {
    min-width: fit-content;
  }
}

.import-actuals-power-select.power-select-lazy {
  .ember-power-select-placeholder {
    color: $font-color;
    margin: 0;

    &::after {
      color: $font-color;
    }
  }

  .ember-power-select-trigger {
    padding-right: 20px;
    border: 0;

    .selected-text {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &::after {
      color: $font-color;
      top: 3px;
    }
  }
}

.main-content {
  .main-content-overflow {
    .inner-content {
      .import {
        .actuals-import {
          .import-step {
            .numbered-steps {
              .import-info {
                .flex {
                  display: flex;
                  gap: 5px;
                  margin-left: -3px;

                  .import-lines-intro {
                    color: $color-steel;
                  }

                  .info-icon.ignore-hover {
                    @include ignore-hover;

                    svg {
                      width: 24px;
                      height: 24px;
                      bottom: 2px;
                      position: relative;
                      fill: $color-steel;
                      cursor: default;
                    }
                  }
                }

                .more-details-link {
                  color: $color-light-blue;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
