.company-budget-list-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  li {
    @include flexbox;

    margin-bottom: 10px;

    > * {
      margin-left: 5px;
    }
  }

  .modal-container {
    .header-container {
      height: 70px;

      .header-text {
        padding-right: 195px;
      }

      .seperator {
        display: none;
      }
    }

    .body-container {
      display: flex;
      align-items: center;
    }

    .input-container {
      align-items: start;
      padding: 0 0 0 20px;
    }
  }

  .budget-list-header {
    @include flexbox;

    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    h2 {
      padding-bottom: 0;
    }

    .right-side {
      @include flexbox;
    }

    .input-container {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }

  .table-wrapper {
    flex: 1;
  }

  .actions-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .left-btn {
    padding-left: 0;
  }
}
