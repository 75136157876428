/* stylelint-disable max-nesting-depth, selector-class-pattern */
.elegant-modal {
  .modal {
    &:not(.edit-line-modal-dialog) {
      .modal-dialog {
        .modal-content {
          .tab-content .tab-pane {
            &.add-root {
              .new-budget-options {
                .or-option {
                  height: 50px;
                  position: relative;
                  text-align: center;

                  > div {
                    padding: 0 8px;
                    background: white;
                    margin-top: -3px;
                  }

                  &::before {
                    left: 0;
                    height: 1px;
                    content: "";
                    width: 100%;
                    background: rgba(#000, 0.07);
                    position: absolute;
                    top: 50%;
                    @include transform(translate(0%, -50%));
                  }
                }

                ul:not(.dropdown-menu) {
                  @include flexbox;
                  @include flex-wrap(wrap);

                  li {
                    width: 190px;
                    margin: 13px;

                    div.content {
                      @include flexbox;

                      justify-content: center;
                      align-items: center;
                      @include box-shadow(0 4px 11px rgba(0, 0, 0, 0.3));

                      border-radius: 3px;
                      background-color: $color-ivory;
                      height: 100px;

                      .content-title {
                        font-size: 25px;
                      }
                    }

                    div.content-about {
                      padding-top: 10px;
                      text-align: justify;
                      color: $color-steel;
                      line-height: 1;
                    }

                    &.active {
                      div.content {
                        border: 5px solid $color-weird-green;
                      }
                    }
                  }

                  &.add-root-select-option-erp {
                    li {
                      height: 100px;
                    }
                  }

                  &.add-root-select-option-template {
                    margin-top: 50px;
                    justify-content: center;

                    li {
                      div.content {
                        position: relative;

                        img {
                          height: 30px;
                        }

                        div {
                          text-align: center;
                        }

                        div.price {
                          position: absolute;
                          bottom: 0;
                          right: 5px;
                          font-weight: 500;
                          color: $color-dark-sky-blue;
                        }
                      }
                    }
                  }
                }

                .change-settings-text {
                  position: absolute;
                  bottom: 35px;

                  &.small-text-steel-light {
                    // TODO name needs to be changed doesn't match font_style.scss
                    font-weight: 300;
                    color: #878d94;
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
