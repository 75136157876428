/* stylelint-disable max-nesting-depth, selector-class-pattern, declaration-no-important */
.drop-down {
  display: none;

  ul {
    padding: 0;
    margin-bottom: 0;
  }

  > ul,
  > div > ul {
    text-align: left;

    button {
      margin: 5px;
    }

    li a,
    li label,
    li > .svg-icon {
      width: 100%;
      display: inline-block;
      padding: 2px 5px;
      font-size: 0.8em;

      &:hover {
        background: $color-highlight;
      }

      cursor: pointer;
    }

    &.addChildren {
      width: 209px !important;

      .subAddChildren {
        border-left: 1px solid #e8e8e7;
        display: none;
        position: absolute;
        top: 0;
        margin-left: 210px;
        width: 420px;
        height: 100%;

        ul.addChildren {
          width: 209px;
          height: 100%;
          overflow-y: auto;
        }
      }

      li {
        padding: 0;

        > div:not(.ember-view) {
          padding: 2px 5px;

          > span {
            font-size: 0.8em;
          }

          &:hover {
            background: $tree-background-hover;
          }
        }

        &.disabled.has-children:not(.expand)::before {
          content: "\3009";
          position: absolute;
          left: 196px;
        }

        &.selected {
          > div:not(.ember-view) {
            background: $tree-background-selected;
          }

          > .subAddChildren {
            display: block;
          }
        }

        label:hover {
          background: $tree-background-selected;
        }

        .expand-trigger {
          @include transition(all 0.2s ease);
          @include transform(rotate(-90deg));

          padding: 0 2px;
        }

        &.expand {
          .expand-trigger {
            @include transform(rotate(0deg));
          }

          > ul.children {
            display: block;
          }
        }
      }

      li ul:not(.addChildren) {
        display: none;

        li label {
          padding-left: 20px;
          margin-bottom: 0;
        }
      }
    }
  }
  @for $i from 0 through 9 {
    ul[data-level="#{$i}"] {
      li {
        padding-left: 6px * ($i + 1);
      }
    }
  }
}
