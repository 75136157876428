/* stylelint-disable selector-class-pattern */
.elegant-modal.group-company-intacct .step-budgetSettings {
  .tab-pane {
    .new-budget-options {
      .change-settings-text {
        position: absolute;
        bottom: 35px;

        &.small-text-steel-light {
          // TODO name needs to be changed doesn't match font_style.scss
          font-weight: 300;
          color: #878d94;
          font-size: 16px;
        }
      }
    }
  }
}
