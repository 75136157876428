.page-container {
  @include flexbox;

  flex-direction: column;
  height: 100%;
}

.page-header {
  @include flexbox;

  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid $color-gray-4;
  padding: 0 30px 30px;
}

.page-body {
  flex: 1;
  padding: 0 30px;

  h2 {
    padding-top: 20px;
  }
}

.page-footer {
  border-top: 2px solid $color-gray-4;
  padding: 10px 20px;
}
