/* stylelint-disable max-nesting-depth, scss/double-slash-comment-whitespace-inside, scss/at-import-partial-extension, font-family-no-missing-generic-family-keyword, no-duplicate-at-import-rules, no-duplicate-selectors, no-invalid-position-at-import-rule, selector-class-pattern, selector-id-pattern, declaration-no-important */
@import "font-face";

$fa-font-path: "/assets/fonts" !default;
@import "font-awesome/scss/font-awesome";

.fa {
  font-family: FontAwesome !important;
}

/* import css-modules (*.css files) */
@import "modules";

// common for sibp and ui-components
@import "sip-components/styles/colors";

@import "mixins";
@import "colors";
@import "fonts_style";

body,
html,
* {
  @include font-content;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title {
  font-family: Metric, sans-serif;
}

// Style rules needed to override nested styles contained
// in `html:not(.admin-application) etc.
// We should start moving styles applied to elements
// such as html, body, label, span, div etc. out of the
// nesting so they can be overriden when required.
@import "nested-styles-overrides";

html:not(.admin-application):not(.company-admin) {
  @import "bootstrap-sass-official/assets/stylesheets/bootstrap";
  @import "mixins";
  @import "shapes";
  @import "file-drop";
  @import "wizard-step";
  @import "import";
  @import "import-actuals";
  @import "import-generic-file";
  @import "models";
  @import "table-edit";
  @import "add-model-to-budget-modal";
  @import "add-model-dashboard-modal";
  @import "actuals-accounts";
  @import "dependencies";
  @import "login-signup";
  @import "setup_guide";
  @import "advanced-benefits";
  @import "performance-logger-widget";
  @import "utils";
  @import "user-profile";
  @import "integration-wizard-dimension-values-mapping";
  @import "common-react-components/profile/profile";
  @import "budget-menu";
  @import "budget-copy";
  @import "change-log";

  #snow {
    position: absolute;
    top: 0;
    left: 0;
    background: #132437;
  }

  .sip-link {
    color: $color-dark-sky-blue;
  }

  .sip-hyperlink {
    text-decoration: underline;
    color: #008146;
    background: 0 0;
    padding: 0;
  }

  .subnav,
  .highlight {
    @include font-sidebar;

    width: 100%;
    height: 35px;
    background: $color-mercury;
    line-height: 35px;
    padding: 0 10px;
    position: relative;
    @include flexbox-important;

    align-items: center;

    &.highlight {
      background: $color-light;
      border-bottom: 1px solid $color-light-border;
    }

    > a:not(.add-root-budget),
    label,
    select,
    > .svg-icon {
      @include font-sidebar;

      margin-right: 10px;
    }

    label {
      width: auto;
      margin: 0;
    }

    .scenario-highlight {
      [data-toggle="dropdown"] {
        @media screen and (max-width: 1370px) {
          max-width: 130px;
        }
      }
    }

    .left {
      @include flexbox;

      &,
      * {
        line-height: 35px;
      }

      > .flag {
        margin-right: 13px;
      }

      .name {
        * {
          font-size: 32px;
          height: auto;
          line-height: 32px !important;
          font-weight: normal;
        }

        height: 32px;
        @include long-text(360px);

        .inline-edit {
          margin-top: 4px;
        }
      }

      .type {
        line-height: 1;

        * {
          vertical-align: middle;
        }
      }

      .inline-edit-select {
        min-width: 200px;
        display: inline-block;
        position: relative;
        vertical-align: middle;

        &,
        * {
          line-height: 24px;
        }

        height: 25px;
        padding: 0 3px;

        &:not(.editing) {
          padding: 0 20px 0 3px;
        }

        border: 1px solid transparent;

        &:hover:not(.editing):not(.readonly) {
          cursor: pointer;

          &::after {
            content: "\f107";
            color: $font-color;
            font-family: FontAwesome;
            display: block;
            width: 16px;
            line-height: 25px;
            position: absolute;
            right: 5px;
            top: -1px;
            text-align: center;
          }
        }

        .select2-container {
          padding: 0;
          height: 25px;
          border: none;

          a.select2-choice {
            padding-left: 0;

            span.select2-arrow {
              b::after {
                color: $font-color;
              }
            }
          }
        }
      }

      .normal {
        font-weight: normal;
        vertical-align: middle;
      }
    }

    .right {
      position: absolute;
      line-height: 35px;
      right: 15px;
      top: 0;

      .svg-icon {
        margin-left: 5px;
      }

      form {
        display: inline;

        button {
          border: 0;
          padding: 0;
          background: transparent;
          display: inline;
        }
      }
    }

    .help-iq {
      cursor: pointer;
      line-height: 1;
    }

    .add {
      color: $color-grey-light-black !important;
      margin-right: 5px;
      vertical-align: middle;
    }

    .styled-select,
    .dropdown {
      background: none;
      border: 0;
    }

    .dropdown {
      .add-border {
        border: 1px solid $color-gray-1;
      }
    }

    .right {
      .dropdown {
        margin-right: 10px;

        ul {
          min-width: 280px;

          * {
            font-size: 0.9em;
          }

          li {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: auto;
            max-height: 40px;
            padding: 0 5px;

            label {
              white-space: normal;
              display: inline-block;
              width: 60px;
              font-weight: normal;
              line-height: 1.2em;
            }

            border-top: 1px solid $color-light-gray;

            &:first-of-type {
              border-top: 0;
            }
          }
        }
      }
    }

    .dropdown.change-log-container {
      ul {
        li {
          max-height: 45px;

          label {
            display: inline !important;
          }
        }
      }
    }
  }

  .inner-content .subnav,
  .inner-content .highlight,
  aside.root .subnav {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .section-scenario {
    display: none;
  }

  .section-main,
  .section-Main {
    .panel-heading {
      display: none;
    }
  }

  .section-dimensions {
    .budgeta-type-value.full > label {
      @include overflow-ellipsis;

      width: 120px !important;
    }
  }

  .section-fixed-assumptions {
    margin-top: 0 !important;
    margin-bottom: 0;

    > .panel-collapse > .panel-body {
      padding-bottom: 0;
    }
  }

  :not(.subnav).scenario-highlight {
    display: inline-block;
    line-height: 24px;
    padding: 0 5px;

    label {
      line-height: 33px;
      vertical-align: bottom;
    }
  }

  .scenario-mode {
    :not(.subnav).scenario-highlight,
    .subnav.scenario-subnav {
      background: $color-blue-changes;

      .dropdown > a {
        color: #fff !important;

        &::after {
          color: #fff;
        }
      }

      .svg-icon svg.icon {
        fill: #fff !important;
      }
    }

    .section-scenario {
      display: block;
    }
  }

  .version-mode {
    .subnav.version-subnav {
      background: $color-strong;
    }

    ol.tree {
      .actions-toggle {
        .actions-wrapper {
          display: none;
        }
      }
    }
  }

  aside.root .subnav {
    .add-root-budget {
      svg {
        fill: $color-green;
      }
    }
  }

  .table-wrapper {
    margin: 20px -20px;
    background: #f1f1f1;
    border-top: 1px solid $color-mercury;

    > label {
      font-weight: 400;
      margin: 15px 20px;
    }

    &:not(.no-scroll) {
      overflow-y: auto;
      max-height: 300px;
    }

    ul.table {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;

      li {
        padding: 13px 20px;
        border-bottom: 1px solid $color-mercury;

        &:not(:first-child) {
          border-top: 0;
        }
        @include flexbox-important;

        .column {
          @include flex(1);

          .svg-icon {
            line-height: 28px !important;
          }

          input[type="text"],
          .styled-select select {
            width: 120px;
            height: 28px;
            line-height: 26px !important;
          }

          .styled-select::after {
            line-height: 28px !important;
          }

          .revoke-access {
            @include transition(all 0.2s);

            opacity: 0;
            pointer-events: none;
            float: right;
            margin-right: 20px;
          }

          .user-name {
            line-height: 28px;
            font-weight: 400;
            color: #000;
          }

          &:last-of-type {
            text-align: left;
            max-width: 220px;
          }
        }

        &:hover {
          .column {
            .revoke-access {
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
    }
  }

  .help-iq {
    .tip-content {
      display: none !important;
    }

    &.inline-icon {
      .icon {
        vertical-align: text-bottom;
      }
    }
  }

  i.fa {
    cursor: pointer;
    text-align: center;
  }

  a.fa {
    vertical-align: middle;
  }

  span.strike {
    display: inline-block;
    position: relative;
    padding: 0 5px;
    margin-left: -5px;

    &::before {
      content: "";
      display: block;
      border-bottom: 2px solid rgba($color-strong, 0.8);
      width: 100%;
      top: 45%;
      left: 0;
      position: absolute;
    }

    &.scenarioRemoved {
      overflow: visible;

      &::before {
        border-bottom: 2px solid rgba($color-blue, 0.8);
      }
    }
  }

  @import "drop-down";

  .budget-select {
    &.multi {
      .select2-container {
        border: 0;

        .select2-choices {
          border: 1px solid #e8e8e7;
        }
      }
    }

    .select2-container {
      min-width: 200px !important;
    }
  }

  i.expand-trigger {
    @include transition(all 0.2s);

    &.collapsed {
      @include transform(rotate(-90deg));
    }
  }

  .svg-icon.add {
    &:not(.white-icon) {
      color: $color-steel !important;

      svg {
        fill: $color-steel !important;
        width: 24px;
        height: 24px;
        vertical-align: middle;
      }

      &.add-small {
        svg {
          width: 18px;
          height: 18px;
        }
      }

      &:hover {
        color: $color-grey-dark-black !important;

        svg {
          fill: $color-grey-dark-black !important;
        }
      }
    }
  }

  .main-content .inner-content .children-grid {
    margin-top: 25px;
    width: 100%;

    @for $i from 0 through 99 {
      [data-order="#{$i}"] {
        @include flex-order($i);
      }
    }

    :not(.month-picker) > input,
    :not(.month-picker) > input[type="number"] {
      width: 100% !important;
    }

    .panel {
      margin-bottom: 0;
    }

    .grid-row {
      position: relative;
      width: 100% !important;
      max-width: 960px !important;
      margin-top: 0 !important;
      height: 40px;
      padding-right: 15px;

      .delete-child {
        position: absolute;
        left: -25px;
        line-height: 26px;
        top: 8px;
        width: 20px !important;
        text-align: right;
      }

      .budget-attributes-wrapper {
        padding: 0;

        .attribute-hidden {
          @include flexbox-important;

          * {
            visibility: hidden;
          }
        }
      }
    }

    .pct-fixed-select {
      min-width: 40px;

      a[data-toggle="dropdown"]::after {
        right: 5px;
      }
    }

    .buttons {
      margin-top: 15px;
      border-top: 2px solid $color-dark-mint;

      a {
        display: inline-block;
        color: $color-dark-mint;
        margin-right: 15px;
      }
    }

    .upload {
      display: inline-block;
      width: 100px;
      margin: 0;
      padding-top: 3px;
      vertical-align: middle;
      background: transparent;
      border: 0;
      margin-right: 15px;

      a {
        @include mid-text-dark-green;
      }
      @include mid-text-dark-green;

      &::before {
        content: "Restore";
        position: absolute;
        cursor: pointer;
      }

      &.import-csv {
        padding-top: 0;

        &::before {
          content: "Import CSV";
          color: $color-dark-mint;
        }
      }
    }

    .first-col {
      width: 120px;
      display: inline-block;
    }

    .grid-head {
      * {
        font-weight: 400;
      }

      background: $color-light-gray;
      border-bottom: 1px solid #dfdfdf;
      margin-top: 10px !important;

      .attr-cols {
        .grid-col {
          @include flexbox-important;

          label {
            display: block;
            @include flex(1);

            margin-bottom: 0;
            padding: 12px 0 12px 10px;
            line-height: 1;
          }

          &.double-width {
            min-width: 180px;
          }

          &.triple-width {
            min-width: 240px;
          }

          &.fix-size-60 {
            @include flex(0 0 60px);
          }
        }
      }
    }

    .attr-cols-wrapper,
    .grid-row > .ember-view {
      width: calc(100% - 15px);
      display: inline-block;
    }

    .attr-cols,
    [data-budget-module] {
      @include flexbox-important;

      .grid-col,
      div[id^="attribute-"] {
        @include flex(10 10);

        label {
          display: none;
        }
      }
    }

    .grid-row [data-budget-module] {
      > div {
        input[type="text"] {
          min-width: 80px;
        }

        min-width: 0 !important;

        &#attribute-startDate {
          min-width: 180px !important;
        }

        margin-top: 4px !important;
        margin-right: 4px;

        .has-formula-preview {
          label.formula-preview {
            display: inline-flex;
            width: 56px;
          }
        }
      }

      div[data-key="benefits"] {
        @include full-flex-values(0 0, 60px);

        .label-after {
          display: block;
          background: transparent;
          border: 0 solid;
          margin-left: -15px;
          width: 10px;
          min-width: 10px;
          padding-left: 0;
          font-size: 14px;
          font-weight: normal;
          color: $font-color;
        }

        input {
          min-width: 50px;
        }
      }

      div[data-key="bonus"] {
        .attribute-modifier-content {
          @include flexbox-important;

          > input {
            @include flex(1);

            min-width: none;
          }

          > .dropdown {
            @include full-flex-values(0, 45px);
          }
        }
      }
    }
  }

  @import "popup";

  .panel {
    @include box-shadow(none);

    &.subsection {
      background: #f3f3f3;
      margin-right: 10px;

      .section-row {
        width: 100%;
      }

      .select2-container {
        background: #fff;
      }

      .panel-heading {
        padding: 10px 0 0 10px;

        a {
          text-transform: none;
          width: auto;
        }
      }

      .custom-amount {
        label:not(.error) {
          width: 90px;
          min-width: 90px !important;
        }
      }
    }

    .panel-heading {
      border: none;
      background-color: transparent;
      padding: 0;

      .accordion-toggle {
        @include section-header;

        text-transform: uppercase;
      }

      .tooltip-help {
        position: relative;
        bottom: 2px;
      }
    }

    .panel-body {
      padding: 15px 0;
    }

    &.panel-default {
      border: none;
    }
  }

  .nav-pills > li.active > a,
  .nav-pills > li.active > a:hover,
  .nav-pills > li.active > a:focus {
    background-color: $color-green;
    font-weight: 400;
  }

  .nav > li > a {
    padding: 0 10px;
  }

  .styled-select {
    overflow: hidden;
    border: 1px solid #e8e8e7;
    background: #fff;
    position: relative;
    width: auto;
    display: inline-block;
    vertical-align: top;

    &::after {
      content: "\f107";
      color: #adadad;
      font-family: FontAwesome;
      font-weight: bold;
      font-size: 16px;
      display: block;
      position: absolute;
      right: 5px;
      top: 0;
      transition: all 0.4s ease;
      pointer-events: none;
    }

    select {
      padding-right: 18px;
      width: 100%;
      background: transparent;
      border: 0;
      border-radius: 0;
      appearance: none;

      option {
        background-color: #fff !important;
        @include font-content;

        &:hover {
          background-color: $color-highlight !important;
        }
      }
    }
  }

  .datepicker {
    width: 100px;
  }

  .ui-datepicker {
    background: $color-light-gray;
    @include box-shadow(0 2px 2px #888);

    select {
      border: 0;
    }
  }

  .ui-datepicker {
    width: 17em;
    padding: 0.2em 0.2em 0;
    display: none;
  }

  .ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: 0.2em 0;
  }

  .ui-datepicker .ui-datepicker-prev,
  .ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 2px;
    font-weight: 0.8em;
  }

  .ui-datepicker .ui-datepicker-prev-hover,
  .ui-datepicker .ui-datepicker-next-hover {
    top: 1px;
  }

  .ui-datepicker .ui-datepicker-prev {
    left: 2px;
  }

  .ui-datepicker .ui-datepicker-next {
    right: 2px;
  }

  .ui-datepicker .ui-datepicker-prev-hover {
    left: 1px;
  }

  .ui-datepicker .ui-datepicker-next-hover {
    right: 1px;
  }

  .ui-datepicker .ui-datepicker-prev span,
  .ui-datepicker .ui-datepicker-next span {
    display: block;
  }

  .ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
  }

  .ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
  }

  .ui-datepicker select.ui-datepicker-month,
  .ui-datepicker select.ui-datepicker-year {
    width: 45%;
  }

  .ui-datepicker table {
    width: 100%;
    font-size: 0.9em;
    border-collapse: collapse;
    margin: 0 0 0.4em;
  }

  .ui-datepicker th {
    padding: 0.7em 0.3em;
    text-align: center;
    font-weight: bold;
    border: 0;
  }

  .ui-datepicker td {
    border: 0;
    padding: 1px;
  }

  .ui-datepicker td span,
  .ui-datepicker td a {
    display: block;
    padding: 0.2em;
    text-align: right;
    text-decoration: none;
  }

  .ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    margin: 0.7em 0 0;
    padding: 0 0.2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }

  .ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
    margin: 0.5em 0.2em 0.4em;
    cursor: pointer;
    padding: 0.2em 0.6em 0.3em;
    width: auto;
    overflow: visible;
  }

  .ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
  }

  .upload {
    background: $color-light-gray;
    border: 1px solid #e8e8e7;
    @include border-radius(3px);

    overflow: hidden;
    position: relative;
    text-align: center;
    height: 37px;
    padding-top: 3px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;

    &.transparent {
      position: absolute;
      opacity: 0;
      z-index: 1;
      margin: 0;
    }

    &::before {
      content: "Restore";
      @include mid-text-grey-blue;

      vertical-align: middle;
      margin: 0 auto;
      width: 100%;
      cursor: pointer;
    }

    &.import-select-file {
      background-color: transparent;
      border: none;
      height: 37px;
      border-radius: 3px;
      width: auto;
      display: inline-block;

      &::before {
        content: attr(data-before);
        color: $color-weird-green;
        font-size: 32px;
        font-weight: 300;
      }
    }

    input {
      position: absolute;
      top: 0;
      margin: 0;
      opacity: 0 !important;
      overflow: hidden !important;
      height: 30px;
      width: 100%;
      border: 0;
      cursor: pointer;
    }
  }

  .fa.fa-upload,
  a.restore {
    position: relative;
    cursor: pointer;

    .upload {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 0;
      background: none;
      border: 0;
      height: 100%;

      &::before {
        display: none;
      }
    }
  }

  .alert {
    border-radius: 0;
    padding: 5px 15px;
  }

  .dropdown,
  .modal-dialog .dropdown {
    display: inline-block;
    border: 1px solid #e8e8e7;
    line-height: 33px !important;

    &:not(.fa) {
      [data-toggle="dropdown"] {
        width: 100%;
        min-width: 100px;
      }
    }

    [data-toggle="dropdown"] {
      @include font-content;

      line-height: 33px;

      &:focus {
        outline: #3f84b5 solid 1px;
      }

      padding: 0 25px 0 10px;
      max-width: 240px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;

      label {
        font-size: 0.8em;
        width: 50px;
      }

      &::after {
        content: "\f107";
        color: #adadad;
        font-family: FontAwesome;
        display: block;
        position: absolute;
        right: 10px;
        top: 0;
        line-height: 33px;
        transition: all 0.4s ease;
        font-weight: bold;
        font-size: 16px;
      }
    }

    > span {
      padding: 0 10px;
    }

    &.without-helper {
      [data-toggle="dropdown"]::after {
        display: none;
      }
    }

    &.no-style {
      border: 0;

      [data-toggle="dropdown"] {
        padding: 0;

        &::after {
          display: none;
        }
      }
    }

    &.open {
      [data-toggle="dropdown"] {
        &::after {
          @include transform(rotate(180deg));
        }
      }
    }

    a.inherited {
      color: $color-green;
    }

    span.date {
      font-size: 0.8em;
      line-height: 32px;
    }
  }

  header {
    .dropdown {
      [data-toggle="dropdown"],
      span.user-name {
        color: $color-white;

        &:hover {
          color: $color-green-sage-logo;
        }
      }

      a[data-toggle="dropdown"] {
        min-width: auto !important;
        padding-right: 32px;
      }

      > ul.dropdown-menu {
        left: -20px;
      }

      &.open {
        span.user-name {
          color: $color-green-sage-logo;
        }

        [data-toggle="dropdown"] {
          &::after {
            color: $color-green-sage-logo;
          }
        }
      }
    }

    .company-dropdown {
      .ember-power-select-trigger {
        line-height: 45px;
        border: 0;
        padding-left: 10px;
        padding-right: 0;
        margin-right: 20px;

        .ember-power-select-selected-item {
          padding-right: 17px;
        }

        &.ember-basic-dropdown-trigger--below {
          background-color: $color-black-darker;
        }

        span {
          color: $color-white;

          &:hover {
            color: $color-green-sage-logo;
          }
        }

        &::after {
          top: 3px;
          right: 0;
          color: $color-white;
        }

        &:hover::after {
          color: $color-green-sage-logo;
        }

        &[aria-expanded="true"] {
          span {
            color: $color-green-sage-logo;
          }

          &::after {
            color: $color-green-sage-logo;
          }
        }
      }
    }

    .company-label {
      line-height: 45px;
      border: 0;
      padding-left: 10px;
      padding-right: 0;
      margin-right: 20px;
      color: $color-white;

      &:hover {
        color: $color-green-sage-logo;
      }
    }
  }

  .company-dropdown.ember-power-select-dropdown {
    width: auto !important; //required to override inline style
    .ember-power-select-option {
      color: $color-steel;
    }

    .ember-power-select-option[aria-current="true"] {
      background-color: $color-dropdown-hover;
    }
  }

  .subnav {
    .dropdown {
      a[data-toggle="dropdown"]::after {
        color: #888;
      }
    }
  }

  .tt-dropdown-menu,
  .dropdown-menu {
    @include box-shadow(none);

    border-radius: 0;
    margin-top: -1px;
    width: auto;
    border: 1px solid #e8e8e7;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
    @include transform(translateZ(0));

    div {
      width: 100%;
      padding: 0;
      @include font-content;
    }

    .tt-suggestion,
    li a {
      padding: 0 10px;
      line-height: 32px;
      font-size: 13px;
      font-weight: 400;

      &:hover,
      &.tt-cursor {
        background: $color-highlight;
      }
    }

    li.header {
      background: $color-light-gray;
      line-height: 30px;
    }
  }

  .modal-backdrop {
    display: none;
  }

  ul.scroll-list {
    &.main,
    &.left {
      max-height: 250px;
      border: 1px solid #e8e8e7;
      overflow-y: auto;
      float: left;
    }

    &.main {
      width: 570px;
      overflow-x: auto;
      border-left: 0;

      .column {
        text-align: right;
      }
    }

    &.top {
      margin-bottom: 0;
      overflow-y: hidden;
      background: #e8e8e7;

      .styled-select {
        max-width: 140px;
      }

      li:nth-child(2) {
        height: 25px;

        .column {
          height: 25px;

          .styled-select {
            height: 25px;
          }
        }
      }
    }

    &.top,
    &.left {
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
    }

    &.left {
      .column {
        width: 140px;

        .right {
          float: right;
          line-height: 35px;
        }
      }
    }

    &.fixed.top.main {
      .column {
        @include flexbox-important;

        justify-content: flex-end;
      }
    }

    list-style: none;
    padding: 0;

    li {
      position: relative;
      height: 35px;

      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }

    &:not(.top) {
      li {
        &.draggable {
          cursor: pointer;
          cursor: grab;

          &:hover {
            * {
              font-weight: 400;
            }

            > .column {
              background-color: $tree-background-hover;
            }
          }
        }

        &.ui-selected:not(.override),
        &.ui-selecting {
          background-color: rgba($color-vanilla, 0.4) !important;

          *:not(.ui-selected-column) {
            background: none !important;
          }
        }
      }

      &.main {
        li,
        > span {
          &:nth-child(odd),
          &:nth-child(odd) > li {
            background: #f3f3f3;
          }

          &:nth-child(even),
          &:nth-child(even) > li {
            background: #fdfdfd;
          }

          &.ui-selected {
            &:nth-child(odd) {
              background-color: rgba($color-vanilla, 0.6) !important;
            }
          }
        }
      }
    }

    .column {
      padding: 0 10px;
      height: 35px;
      line-height: 35px;
      float: left;
      width: 100%;

      &.wide {
        width: 220px;
      }

      text-overflow: ellipsis;
      white-space: nowrap;

      &:not(.allow-overflow) {
        overflow: hidden;
      }

      .styled-select {
        max-width: 140px;

        &::after {
          line-height: 26px;
        }

        select {
          line-height: 24px;
        }
      }

      i.fa {
        width: 15px;
      }

      input[type="text"] {
        width: 110px;
        line-height: 24px !important;
      }

      .month-picker {
        input[type="text"] {
          width: 90px;
        }
      }
    }
  }

  .test-calculations {
    .modal {
      .modal-dialog {
        width: 1000px;

        .modal-body {
          min-height: 500px;

          .tab-content {
            min-height: 170px !important;
          }

          ul.scrollable {
            height: 400px;
            overflow-y: scroll;
          }

          > ul > li {
            .more-data {
              display: none;

              * {
                font-size: 10px !important;
              }
            }

            &:hover {
              outline: 2px solid $heading-color;

              .more-data {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .uploading {
    @include large-header;

    height: 80%;
    @include flexbox;

    flex-flow: column;
    align-items: center;
    justify-content: center;
    line-height: 27px;

    .progress {
      margin-top: 13px;
      width: 300px;
      height: 8px;
      @include border-radius(0);
    }

    .disable-page {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      position: fixed;

      &.remove-disable {
        display: none;
      }
    }
  }

  .ui-selectable-helper {
    position: absolute;
    border: 1px dotted black;
  }

  li.ui-draggable-dragging {
    list-style: none;
    background: $tree-background-selected;

    * {
      font-weight: 400;
    }

    padding: 0 20px;
    line-height: 35px;
    @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.1));

    div.column:not(:first-child) {
      display: none;
    }
  }

  .select-imported-type {
    width: 200px;
    display: none;
    position: fixed;
    z-index: 1000;
    background: white;
    @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
  }

  .chart-modal {
    .modal-dialog {
      width: 80%;

      .single-kpi-chart {
        position: relative;
        height: 250px;

        .kpi-data-content {
          text-align: center;

          .kpi-value {
            @include large-text-dark-mint;
          }
        }

        &.interactive {
          .kpi-value-2 {
            color: #939393;
          }
        }
      }

      .kpi-chart {
        height: 250px;
        @include flexbox;
        @include flex-wrap(wrap);

        .kpi-data {
          text-align: center;
          position: relative;
          @include flex(0 0 49%);

          &:nth-child(n + 3) {
            border-top: 1px solid $color-silver;
          }

          &:nth-child(2n) {
            border-left: 1px solid $color-silver;
          }

          .kpi-data-content {
            width: 100%;

            .kpi-value {
              @include large-text-dark-mint;

              padding-right: 5px;
            }
          }
        }

        &.interactive {
          .kpi-data {
            .kpi-data-content {
              .kpi-value-2 {
                color: #939393;
              }
            }
          }
        }
      }
    }
  }

  .modal-container.add-root-modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .back-to-video-btn {
            position: absolute;
            bottom: -43px;
            left: 5px;
            z-index: 1;
          }
        }
      }
    }
  }

  .clearfix {
    clear: both;
  }

  .dropdown-arrow {
    svg {
      transition: all 0.2;
    }

    &.closed {
      svg {
        @include transform(rotate(180deg));
      }
    }
  }

  .spinner {
    position: absolute;
    top: 30%;
    left: calc(50% - 25px);
    width: 50px;
    height: 50px !important;
    background-color: #333;
    animation: rotateplane 1.2s infinite ease-in-out;
  }

  header .busy {
    display: none;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 6px;
    z-index: 2001;

    > span {
      display: block;
      position: relative;
      height: 100%;
      overflow: hidden;
      background-color: #ffd100;

      &::after,
      > span {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: linear-gradient(
          -45deg,
          #f7b800 25%,
          transparent 25%,
          transparent 50%,
          #f7b800 50%,
          #f7b800 75%,
          transparent 75%,
          transparent
        );
        z-index: 1;
        background-size: 10px 10px;
        overflow: hidden;
        animation: move 4s linear infinite;
      }
    }
  }

  .progress {
    > span {
      display: block;
      position: relative;
      height: 100%;
      overflow: hidden;
      background-color: $color-grey-dark-black;

      &::after,
      > span {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: linear-gradient(
          -45deg,
          rgba(#fff, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(#fff, 0.2) 50%,
          rgba(#fff, 0.2) 75%,
          transparent 75%,
          transparent
        );
        z-index: 1;
        background-size: 30px 30px;
        overflow: hidden;
        animation: move 4s linear infinite;
      }
    }
  }

  @keyframes move {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 50px 50px;
    }
  }
  @keyframes move {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: 50px 50px;
    }
  }

  @keyframes rotateplane {
    0% {
      transform: perspective(120px);
    }

    50% {
      transform: perspective(120px) rotateY(180deg);
    }

    100% {
      transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
  }

  @keyframes rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }

  #noty_top_layout_container {
    .bootstrap {
      .noty_bar {
        text-align: center;

        .noty_message {
          display: inline-block;
          height: 48px;
          min-width: 526px;
          padding-left: 32px;

          * {
            @include small-text-white;

            line-height: 48px;
          }

          a {
            text-decoration: underline;
          }
          @include border-radius-bottom(3px);

          .noty_close {
            float: right;
            margin: 0 15px;

            &::before {
              content: "\2715";
              display: block;
              font-size: 22px;
            }
          }
        }

        &.noty_type_error .noty_message {
          background: $color-error;
        }

        &.noty_type_warning .noty_message {
          background: $color-warning-orange;
        }

        &.noty_type_information .noty_message {
          background: #539ade;
        }
      }
    }

    .noty-renew-subscription {
      .noty_bar {
        text-align: center;

        .noty_message {
          display: inline-block;
          height: 48px;
          min-width: 526px;
          padding-left: 32px;

          * {
            @include small-text-white;

            line-height: 48px;
          }

          a {
            text-decoration: underline;
          }
          @include border-radius-bottom(3px);

          .noty_close {
            float: right;
            margin: 0 15px;

            &::before {
              content: "\2715";
              display: block;
              font-size: 22px;
            }
          }
        }

        &.noty_type_error {
          .noty_message {
            background: $color-error;
          }

          .noty_buttons button {
            background-color: $color-error;

            &:hover {
              background-color: $color-error;
            }
          }
        }

        &.noty_type_warning {
          .noty_message {
            background: $color-warning-orange;
          }

          .noty_buttons button {
            background-color: $color-warning-orange;

            &:hover {
              background-color: $color-warning-orange;
            }
          }
        }

        &.noty_type_information {
          .noty_message {
            background: #539ade;
          }

          .noty_buttons button {
            background-color: #539ade;

            &:hover {
              background-color: #539ade;
            }
          }
        }

        .noty_buttons {
          display: inline-block;
          margin-left: -110px;
        }
      }
    }
  }

  #noty_topRight_layout_container,
  #noty_topLeft_layout_container,
  #noty_topCenter_layout_container,
  #noty_centerRight_layout_container {
    transform: translateY(-20px);

    .budgeta {
      .noty_bar {
        padding: 24px 24px 12px 18px;
        margin-top: 10px;

        &::before {
          content: "";
          width: 24px;
          height: 24px;
          position: absolute;
          top: -6px;
          left: 30px;
          transform: rotate(45deg);
        }

        * {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
        }

        h1 {
          font-size: 23px;
          margin-bottom: 12px;
          margin-top: 0;

          b {
            font-size: inherit;
            font-weight: 500;
          }

          a {
            font-size: 23px;
          }
        }

        a {
          text-decoration: underline;
        }
        @include border-radius(3px);

        .noty_buttons {
          margin-top: 12px;
          text-align: right;
          height: 40px;

          button {
            background-color: rgba(#000, 0.25);

            &.btn-cancel {
              float: left;
              background: none;
              padding: 0;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        &.noty_type_error {
          &,
          &::before {
            background: $color-red;
          }
        }

        &.noty_type_warning {
          &,
          &::before {
            background: $color-warning-orange;
          }
        }

        &.noty_type_information {
          &,
          &::before {
            background: $color-dark-sky-blue;
          }
        }
      }
    }
  }

  #noty_centerRight_layout_container {
    top: 30px !important;

    .budgeta {
      .noty_bar {
        &::before {
          top: 55px;
          left: -12px;
        }
      }
    }
  }

  #noty_topCenter_layout_container {
    top: -125px !important;

    .budgeta {
      .noty_bar {
        &::before {
          top: 200px;
          left: 15px;
        }
      }
    }
  }

  #noty_topRight_layout_container {
    .budgeta {
      .noty_bar {
        &::before {
          top: auto;
          left: -6px;
          right: auto;
        }
      }
    }
  }

  #overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .form-control {
    padding: 5px;

    &.select2-container {
      padding: 4px 5px;
    }
  }

  .select2-container {
    * {
      font-weight: 400;
    }

    background: none;
    outline: 0 !important;
    background-color: transparent;
    @include border-radius(0);
    @include box-shadow(none);

    border: 1px solid #e8e8e7;

    &.without-boarder {
      border: none;
    }

    &.without-left-padding {
      padding-left: 0;

      > a {
        padding-left: 0;
      }
    }

    &.select2-container-multi {
      padding: 0;

      .select2-choices {
        min-height: 35px;
        border: 0;
        background: none;

        .select2-search-field {
          height: 39px;
        }

        .select2-search-choice {
          padding-top: 0;
          padding-bottom: 0;

          > div {
            white-space: nowrap;
            max-width: 250px;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 35px;
          }
        }
      }
    }

    &.select2-container-active {
      outline: #3f84b5 solid 1px;
    }

    &.select2-container-disabled {
      .select2-arrow {
        display: none;
      }

      .select2-choice > .select2-chosen {
        margin-right: 0;
      }

      * {
        cursor: unset;
      }
    }

    .select2-choice {
      border: 0 !important;
      background: none !important;
      background-color: transparent !important;
      @include border-radius(0);
      @include box-shadow(none);

      abbr {
        right: 20px;
        top: 5px;
      }

      .select2-arrow {
        background: none;
        background-color: transparent;
        border: 0;

        b {
          background: none !important;

          &::after {
            content: "\f107";
            color: #adadad;
            font-family: FontAwesome;
            font-weight: bold;
            font-size: 16px;
            display: block;
            position: absolute;
            right: 5px;
            top: 0;
            transition: all 0.4s ease;
            pointer-events: none;
          }
        }
      }
    }

    &.mid-sign {
      .select2-choice {
        .select2-arrow {
          b {
            &::after {
              top: 6px;
            }
          }
        }
      }
    }

    &.font-color-sign {
      .select2-choice {
        .select2-arrow {
          b {
            &::after {
              color: $font-color;
            }
          }
        }
      }
    }

    &:not(.without-hightlight) {
      .select2-choice {
        &.select2-default {
          .select2-chosen {
            color: $color-green !important;
          }
        }
      }
    }

    &.select2-dropdown-open {
      .select2-choice .select2-arrow {
        b::after {
          @include transform(rotate(180deg));
        }
      }
    }

    .select2-display-none {
      display: none !important;
    }

    &.without-hightlight {
      .select2-chosen {
        color: $color-grey-dark-black;
      }

      &.select2-container-active {
        outline: 0;
      }
    }
  }

  .select-2-typeahead {
    .select2-search {
      input {
        background: none;
        padding: 0 5px;
        margin-top: 4px;
      }
    }
  }
  @media print {
    body {
      margin: 1px;
    }
  }

  #gw-footer * {
    font-size: 9px !important;
  }

  .add-icon-list {
    @include add-icon-list;
  }

  .account-mapping-menu {
    height: 100%;
    position: relative;

    > .mapping-display {
      cursor: pointer;
      @include centerVElement;

      width: 100%;
      padding-left: 10px;
      line-height: 40px;

      &::after {
        content: "\f107";
        color: #adadad;
        font-family: FontAwesome;
        font-weight: bold;
        font-size: 16px;
        display: block;
        position: absolute;
        @include centerVElement;

        right: 5px;
      }

      &.active {
        background-color: rgba(56, 218, 149, 0.25);
        border: solid 1.5px $color-weird-green;
      }
    }
  }

  .dropdown-sign {
    @include dropdown-sign;
  }

  .left-list-selection {
    @include flexbox;

    height: 100%;

    &.with-padding {
      .selection-list {
        padding-left: 48px;
      }

      .selection-content {
        padding-right: 48px;
        padding-top: 10px;
      }
    }

    .selection-list {
      @include flex(0 0 200px);

      max-width: 200px;
      border-right: 3px solid $color-dark-mint;

      ul,
      ol {
        margin-left: -5px;

        li {
          padding: 10px 5px;
          @include large-text;

          &.active {
            color: #fff;
            background-color: $color-dark-mint;
          }

          &:hover {
            color: #fff;
            background-color: $color-weird-green;
          }
        }
      }
    }

    .selection-content {
      overflow-y: auto;
      padding-right: 16px;
      @include flex(1 1);

      margin-left: 16px;
    }
  }

  .select-filter-dropdown {
    display: inline-block;

    > .select2-container {
      height: 25px;
    }

    &.has-filter-option {
      @include border-radius(20px);

      border: 1px solid $color-dark-grey-blue;
      @include box-shadow(1px 2px 1px 0 rgba(0, 0, 0, 0.2));

      .select2-container {
        margin-top: -6px;
      }

      .select2-arrow {
        b {
          &::after {
            color: $color-dark-grey-blue;
          }
        }
      }
    }

    &:not(.has-filter-option) {
      @include border-radius(20px);

      border: 1px solid transparent;
    }
  }

  div.filter-divider {
    width: 1px;
    display: inline-block;
    position: relative;
    margin-right: 10px;

    &::after {
      width: 1px;
      background-color: rgba(0, 0, 0, 0.07);
      height: 24px;
      content: "";
      display: block;
    }

    &:not(.table-divider) {
      &::after {
        @include centerVElement;

        top: -5px !important;
        position: absolute;
      }
    }

    &.table-divider {
      margin-left: 5px;
    }
  }

  div.filter-divider + div.filter-divider {
    display: none;
  }

  // for space between video-icon and drop-down
  .video-tooltip.tooltip {
    margin-top: 5px;
  }

  // video drop-down
  .video-tooltip.tooltip .tooltip-content {
    max-height: 320px;
    overflow-y: auto;
    padding: 10px 0;

    .menu .menu-item {
      width: 100%;
      margin-left: 0;
      padding: 5px 10px;
      line-height: 30px;

      :hover {
        background-color: rgba(57, 218, 149, 0.25);
        color: $color-dark-blue-grey;
        cursor: pointer;
      }

      span {
        padding: 4px 0;
        color: $color-steel;
        border-bottom: 1px solid #878d94;
      }
    }
  }

  @import "qtip";
  @import "modal";
  @import "non-mac";
  @import "currency";
  @import "budget_tree";
  @import "quick_links";
  @import "budgeta-global";
  @import "formula-editor";
  @import "inline_edit";
  @import "forecast";
  @import "main_content";
  @import "user_page";
  @import "budget_attributes_wrapper";
  @import "budget_navigator";
  @import "top_bar";
  @import "budgeta_switch";
  @import "main_tree";
  @import "drag_and_drop";
  @import "collapse_expand_component";
  @import "budgeta_type_label";
  @import "month_picker";
  @import "select2_drop";
  @import "share";
  @import "dashboard";
  @import "wizard";
  @import "dashboard_modal";
  @import "model-store-modal";
  @import "select_assumptions_modal";
  @import "reports-modal";
  @import "admin";
  @import "notes-modal";
  @import "add-employee-modal";
  @import "video_modal";
  @import "budgeta-video";
  @import "breadcrumbs-bar";
  @import "progress-bar";
  @import "trx-filter";
  @import "trx";
  @import "advanced-benefits";
  @import "accounts";
  @import "multi-select-dropdown";
  @import "add-actual-accounts-modal";
  @import "dimension-tree-values";
  @import "intacct-wizard";
  @import "radio-buttons-group";
  @import "ember-power-select";
  @import "budgeta-spinner";
  @import "elegant-modal";
  @import "modal-add-budget";
  @import "modal-intacct-budget-settings";
  @import "modal-intacct-budget-structure";
  @import "modal-intacct-step-year-over-year";
  @import "modal-intacct-account-list";
  @import "modal-intacct-budget-structure-edit";
  @import "modal-intacct-data-populate";
  @import "modal-intacct-import-dimensions";
  @import "modal-intacct-match-accounts-to-dimensions";
  @import "modal-intacct-select-stat-accounts";
  @import "budget-info";
  @import "modal-export-missing-mappings";
  @import "modal-import-missing-mappings";
  @import "path-list";
  @import "display-account-ids";
  @import "power-select";
  @import "power-select-lazy";
  @import "dimension-mapping";
  @import "import-column-menu";
  @import "import-column-resizer";
  @import "steps-progress-bar";
  @import "generic-file/components/generic-manager/general-manager";
  @import "generic-file/components/budget-settings/budget-settings-step";
  @import "generic-file/components/file-upload/file-upload-step";
  @import "generic-file/components/file-upload-intro/file-upload-intro";
  @import "generic-file/components/data-format/data-format";
  @import "generic-file/components/generic-progress-bar/generic-progress-bar";
  @import "generic-file/components/input-box/input-box";
  @import "generic-file/components/preview-step/preview-step";
  @import "generic-file/components/table-intro/table-intro";
  @import "generic-file/components/budget-lines-intro/budget-lines-intro";
  @import "generic-file/components/data-mapping-intro/data-mapping-intro";
  @import "generic-file/components/account-category/account-category-step";
  @import "generic-file/components/table-row-column-step/table-row-column-step";
  @import "generic-file/components/table-column-mapping-step/table-column-mapping-step";
  @import "generic-file/components/account-category-error/account-category-error";
  @import "generic-file/components/finalize-preview/finalize-preview";
  @import "generic-file/components/dimensions-mapping/dimensions-mapping";
  @import "generic-file/components/dimensions-hierarchies-intro/dimensions-hierarchies-intro";
  @import "generic-file/components/budget-structure-intro/budget-structure-intro";
  @import "generic-file/components/preview-budget-intro/preview-budget-intro";
  @import "generic-file/components/finalize-mapping-intro/finalize-mapping-intro";
  @import "generic-file/components/dimension-tree/dimension-tree-step";
  @import "generic-file/components/budget-structure/budget-structure";
  @import "generic-file/components/currencies-mapping/currencies-mapping";
  @import "generic-file/components/table-row-selection-step/table-row-selection-step";
  @import "generic-file/components/loader-screen/loader-screen";
  @import "common-react-components/login/login-form";
  @import "components/react/grid-components/grid";
  @import "components/react/grid-components/filters/filters";
  @import "components/react/download-options-modal/download-options-modal";
  @import "components/react/synced-users-menu/synced-users-menu";
  @import "profile-user-menu";
  @import "components/react/locale/locale";
}

html.admin-application {
  @import "admin/admin-app/main/main";
}

html.company-admin {
  @import "admin/company-admin-app/main/main";
}
