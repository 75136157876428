/* stylelint-disable declaration-no-important */

@import "sip-components/styles/fonts";

.audit-logging-container {
  .tr {
    height: auto !important; // it overrides row style that react-table adds
    min-height: 45px;
  }

  svg {
    padding: 5px 3px;
  }

  .page-header {
    .right-side {
      @include flexbox;
    }

    .input-container {
      margin-right: 20px;
    }
  }
}
