/* stylelint-disable function-url-quotes */
.generic-manager {
  background: url(/assets/images/bg_squares_and_circles.png) rgba(102, 133, 146, 0.8);
  background-size: cover;
  background-blend-mode: multiply;

  .noty-container {
    position: fixed;
    top: 10px;
    left: 55%;
    border-radius: 5px;
    min-width: 580px;
    min-height: 50px;
    padding-left: 20px;

    .noty-text span,
    .noty-text,
    .noty-close {
      color: white;
    }
  }

  .header-container {
    .header-text {
      font-size: 26px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.2px;
      -webkit-text-stroke: 0.2px $font-color;
    }
  }

  .body-container {
    :not(.padding-intro-body) .lottie-animation {
      margin-top: 20px;
    }

    .padding-intro-body .lottie-animation {
      margin-top: 0;
    }
  }

  .recomended-bar {
    display: grid;
    grid-template-rows: 110px 80px;
    height: 100%;
  }

  .recommended-label {
    height: 50px;
    line-height: 45px;
    border: 3px solid $color-mint-tulip;
    margin: 20px 0;
    display: grid;
    grid-template-columns: 27px auto;
    justify-content: center;

    svg {
      position: relative;
      top: 12px;
      margin-right: 6px;
    }

    span {
      color: $heading-border-color;
      font-size: 16px;
      letter-spacing: -0.1px;
      line-height: 45px;
      text-align: left;
    }
  }

  .intro-image-middle {
    display: flex;
    justify-content: center;
  }

  .padding-intro-body {
    padding: 30px 90px 0;
  }

  .description-left {
    text-align: left;
    line-height: 20px;
  }

  .description-center {
    text-align: center;
    line-height: 20px;
  }

  .header-meduim {
    font-size: 23px;
    text-align: center;
  }

  .title-next-to-prefix {
    display: grid;
    grid-template-columns: 1fr 300px;
    width: 400px;
    height: 80px;
    margin-top: 30px;

    .title {
      text-align: left;
    }
  }

  .tree-structure-container {
    svg.close {
      text-shadow: unset;
      opacity: unset;
    }
  }

  .footer-container {
    .footer-buttons {
      line-height: 40px;
    }
  }

  .modal-container {
    box-shadow: none;
    z-index: 1;
  }

  div:not(.preview-step-container) {
    .modal-container {
      border-radius: 3px;
    }
  }
}
