/* stylelint-disable max-nesting-depth, declaration-block-no-duplicate-properties, no-duplicate-selectors, selector-class-pattern, selector-pseudo-class-no-unknown, selector-pseudo-element-no-unknown, declaration-no-important */
$tree-view-type-height: 50px;
$tree-search-height: 62px;
$tree-create-model-height: 60px;

.icon-comment {
  position: absolute;
  opacity: 0;
  right: 0;
  z-index: 1;
  width: 15px;
  height: 20px;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.secondary .tooltip {
  font-family: Metric, sans-serif;
  font-size: 16px;
  white-space: pre-wrap;
  width: 100%;

  // scrolling support
  .tooltip-content {
    padding: 5px;
    max-height: 150px;
    overflow-y: auto;
  }
}

// when hover on icon comment
.reference:hover path {
  fill: rgb(51, 67, 86);
}

.tooltip-toggle {
  .icon-comment {
    opacity: 1;
  }
}

.main-content {
  &.grid-budget-option-rollf {
    aside.secondary {
      .tree-edit-bar {
        margin-bottom: 20px;
      }

      ol.tree {
        &:not(.metrics) {
          height: calc(100% - #{$tree-view-type-height} - #{90px});
        }
      }
    }
  }

  --left-aside-width: 325px;

  aside {
    background: $font-color-white;
    position: relative;
    width: 320px;
    min-width: 100px;
    padding: 0;
    height: 100%;

    &.small-aside {
      .subnav {
        .scenarios span,
        .versions span {
          display: none;
        }
      }
    }

    .ui-resizable-e {
      cursor: ew-resize;
      position: absolute;
      right: -15px;
      top: 0;
      height: 100%;
      width: 15px;
    }

    ol.tree.budget-list {
      li.budget-list-item {
        .actions-toggle {
          padding-left: 15px;

          > a:not(.fa) {
            width: calc(100% - 40px);
          }

          .actions-wrapper {
            width: 25px;
          }
        }

        &.selected-root {
          background-color: $color-dark-green;
        }
      }
    }

    .empty-budget-wrapper {
      width: 100%;
      text-align: center;
      margin-top: 100px;

      * {
        font-weight: 600;
      }

      .empty-budget-text {
        font-size: 18px;
        color: $color-bay-of-many;
        margin: 15px 0;
      }

      .create-budget {
        background-color: $color-dark-blue;
        border-radius: 17px;
        display: inline-block;
        padding: 4px 12px;

        .svg-icon {
          &:hover * {
            font-weight: 600 !important;
          }

          .icon {
            fill: #fff;
          }
        }

        span {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }

  aside.secondary {
    .scenario-highlight .dropdown {
      padding-right: 15px;

      [data-toggle="dropdown"] {
        padding-left: 0;

        &::after {
          right: 20px;
        }
      }
    }

    &.small-aside {
      .subnav:not(.sub-subnav) {
        .scenarios,
        .versions {
          display: inline-block;
        }

        > div:not(:first-child) {
          padding-left: 0;
        }

        .subnav-main-icons,
        .right {
          border: none;

          span {
            display: none;
          }
        }
      }
    }

    .tree-edit-bar-view-type {
      background-color: $color-white;
      padding-left: 12px;
      height: $tree-view-type-height;

      &.open {
        background-color: $color-dropdown-hover;
      }

      .ember-power-select-trigger {
        display: flex;
        align-items: center;
        padding: 0 15px 0 3px;

        &.ember-basic-dropdown-trigger--below {
          background-color: initial;
        }

        &::after {
          right: 16px;
          top: 12px;
        }
      }
    }

    .tree-edit-bar {
      position: relative;
      height: $tree-search-height;
      width: 100%;
      border-top: 1px solid $color-light-grey;
      border-bottom: 1px solid $color-light-steel-gray;

      .close-tree-edit {
        margin-right: 20px;
      }

      .tree-edit-bar-inner {
        position: relative;
        height: 100%;
        margin: 0 12px;
        border-bottom: 1px solid $color-silver;

        * {
          color: $color-steel;
          font-size: 18px;
        }

        svg {
          fill: $color-steel;
        }

        .svg-icon.disable {
          cursor: not-allowed;
        }
      }

      .search {
        left: 55px;
        width: calc(100% - 70px);
        padding-left: 5px;

        input {
          width: 50%;
          height: 100%;
          background-color: transparent;
          border: none;
          color: $color-steel;
          padding: 0;

          &:focus {
            outline: none !important;
          }
        }

        input::input-placeholder {
          /* Chrome/Opera/Safari */
          color: $color-steel;
        }

        input::placeholder {
          /* Firefox 19+ */
          color: $color-steel;
        }

        input:input-placeholder {
          /* IE 10+ */
          color: $color-steel;
        }

        input:placeholder {
          /* Firefox 18- */
          color: $color-steel;
        }

        .svg-icon {
          .icon {
            fill: $color-steel;
          }

          &:hover .icon {
            fill: $color-dark-grey-blue;
          }
        }
      }

      &.tree-search-mode {
        .search {
          width: calc(100% - 15px);
          left: 15px;

          input {
            width: calc(100% - 70px);
          }
        }

        &.with-close {
          .search {
            input {
              width: calc(100% - 150px);
            }
          }
        }
      }

      &.tree-read-only {
        background-color: #fff;
        border-bottom: 2px solid #ededed;

        .collapse_expand_wrapper {
          > a {
            display: none;
          }
        }
      }

      &::after {
        content: "";
        display: block;
        clear: both;
      }

      label {
        float: left;
        font-weight: 400;
        color: #222;
        line-height: 40px;
        margin: 0;

        &:first-child {
          width: calc(100% - 210px);
          margin-left: 20px;
        }
      }

      .collapse_expand_wrapper {
        > div:first-child {
          margin-left: 12px;
        }
      }

      .right {
        right: -5px;

        button {
          height: 20px;
          line-height: 20px !important;
          text-transform: none !important;
          padding: 0 8px;

          .svg-icon {
            line-height: 20px;

            .icon {
              fill: #fff;
              width: 8px;
              height: 8px;
            }
          }
        }
      }
    }

    ul {
      padding: 0;
    }

    ol.tree {
      .tree-resizer {
        height: 8px;
        position: absolute;
        width: 100%;
        bottom: 0;

        &:hover {
          cursor: row-resize;
        }
      }

      position: relative;

      .term-highlight {
        background-color: rgba(56, 218, 149, 0.25);
      }

      .budget-tree-no-results {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: absolute;
        align-items: center;
        margin-top: 30px;

        #no-results-text {
          color: $color-steel;
          text-transform: uppercase;
        }
      }

      &:not(.metrics) {
        height: calc(100% - #{$tree-view-type-height} - #{$tree-search-height});
      }

      &.search-tree-list {
        overflow-y: hidden;

        > .ember-list-view {
          padding: 12px 0;
        }
      }

      &.in-search-mode:not(.search-tree-list) {
        display: none;
      }

      &.search-tree-list:not(.in-search-mode) {
        display: none;
      }

      &.budget-list {
        @include transition(height 0.2s);

        height: 210px !important;

        &.has-search {
          margin-top: 5px;
          height: 182px !important;
        }

        * {
          color: #fff;
          font-weight: 400;
        }

        svg {
          fill: #fff !important;
        }
      }

      overflow-y: hidden;
      overflow-x: hidden;
      @include font-sidebar;

      &.tree-read-only {
        padding-top: 0;

        li[data-type^="group-model"] {
          display: none;
        }

        li.active > .tree-element > .actions-toggle {
          &:not(:hover) {
            background-color: transparent !important;
          }

          span.budget-name-text,
          span.budget-name-text-display,
          span.type {
            color: $color-dark-grey-blue;
          }

          .svg-icon svg {
            fill: $color-dark-grey-blue;
          }

          * {
            font-weight: 600;
          }
        }

        .tree-element {
          border-bottom: 1px solid #ededed;

          .actions-toggle {
            > a:not(.fa) {
              width: 100% !important;
              margin-top: 10px;

              > span.budget-name {
                span.type {
                  display: none;
                }
              }
            }

            .empty-budget-label {
              display: none !important;
            }
          }
        }
      }

      span {
        @include font-sidebar;
      }

      &:not(.scenario) {
        li.any-scenario-added {
          display: none;
        }
      }

      &.scenario {
        li.any-scenario-added:not(.scenario-added) {
          display: none;
        }
      }

      a.fa {
        color: $font-color;
        text-decoration: none;
      }

      &.metrics {
        li.budget-list-item:not([data-type^="group-model"]) {
          display: none;
        }

        li.budget-list-item[data-type^="group-model"] {
          .add-child-budget {
            display: none;
          }
        }
      }

      &:not(.metrics) {
        li.budget-list-item[data-type^="group-model"],
        li.budget-list-item[data-type="assumption"] {
          display: none;
        }
      }

      li {
        &.budget-list-item {
          list-style: none;
          position: static;

          &.in-search-mode {
            &.ui-draggable-dragging {
              display: none;
            }
          }

          &:not(.drag-and-drop-mode) {
            .tree-element {
              .actions-toggle {
                &:hover {
                  background-color: rgba($color-weird-green, 0.25);

                  .icon-comment {
                    opacity: 1;
                  }

                  span.budget-name:not(.strike) {
                    span.type {
                      opacity: 0.6;
                    }
                  }

                  .actions-wrapper .actions-inner-wrapper {
                    .svg-icon {
                      .icon {
                        display: inline !important;
                      }
                    }
                  }
                }
              }
            }
          }

          .tree-element {
            &.model-group-budget:not(.model-group-parent) {
              .svg-icon.collapse-tree {
                opacity: 0;
              }
            }

            .actions-toggle {
              width: calc(100% - 24px);
              @include border-radius(3px);

              margin: 0 12px;
              display: inline-block;
              position: relative;
              min-height: 46px;

              input[name="name"] {
                margin: 4px 10px;
                width: calc(100% - 20px);
              }

              &.tree-edit-mode {
                input[name="name"] {
                  border: none;
                  border-bottom: 1px dashed $color-white;
                  background-color: transparent;
                  margin-top: 12px;
                  outline: 0;
                }
              }

              span.type {
                font-size: 12px;
                font-weight: 400 !important;
                opacity: 0;
                line-height: 1;
                position: absolute;
                margin-top: -1px;
                @include transition(opacity 0.2s);
              }

              > a:not(.fa) {
                @include font-sidebar;

                padding: 0 0 0 20px;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                overflow: hidden;
                vertical-align: middle;

                .expand-trigger,
                .leaf {
                  display: none;
                }
              }

              .actions-wrapper {
                position: relative;
                display: inline-block;
                height: 44px;
                vertical-align: middle;

                .actions-inner-wrapper {
                  @include centerVElement;

                  right: 0;
                  text-align: right;
                  padding-right: 15px;
                  display: flex;
                  align-items: center;

                  .star.star.star {
                    display: flex;
                    width: 24px;
                    justify-content: center;

                    svg {
                      fill: $heading-color;
                    }
                  }

                  .svg-icon {
                    &:not(.share-icon):not(.help-icon) {
                      .icon {
                        display: none;
                      }
                    }

                    &:not(.flagged) {
                      .icon {
                        fill: $color-dark-grey-blue;
                      }
                    }

                    &.help-icon {
                      .icon {
                        fill: $color-red;
                      }

                      &:hover {
                        fill: $color-dark-grey-blue !important;
                      }
                    }
                  }

                  > div:not(.drop-down) {
                    display: inline;
                  }

                  > * {
                    line-height: 34px;
                  }

                  .drop-down {
                    li a:hover {
                      background: $tree-background-hover;
                    }
                  }
                }
              }

              &:not(.budget-flagged) {
                &.shared-budget:not(.read-only-budget),
                &.no-grouping-child:not(.shared-budget),
                &.shared-budget:not(.no-grouping-child) {
                  > a:not(.fa) {
                    width: calc(100% - 110px);
                  }

                  .actions-wrapper {
                    width: 105px;
                  }
                }

                &.shared-budget:not(.read-only-budget),
                &.no-grouping-child {
                  > a:not(.fa) {
                    width: calc(100% - 130px);
                  }

                  .actions-wrapper {
                    width: 125px;
                  }
                }

                &:not(.shared-budget):not(.no-grouping-child),
                &.read-only-budget {
                  &.allow-children:not(.model-group),
                  &.has-children:not(.model-group) {
                    &:not([data-type^="group-model"]) {
                      > a:not(.fa) {
                        width: calc(100% - 84px);
                      }

                      .actions-wrapper {
                        width: 80px;
                      }
                    }
                  }

                  &:not(.allow-children):not(.has-children),
                  &.any-scenario-added:hover,
                  &.model-group {
                    > a:not(.fa) {
                      width: calc(100% - 44px);
                    }

                    .actions-wrapper {
                      width: 40px;
                    }
                  }
                }
              }

              &.budget-flagged {
                &.shared-budget:not(.read-only-budget),
                &.no-grouping-child:not(.share) {
                  > a:not(.fa) {
                    width: calc(100% - 130px);
                  }

                  .actions-wrapper {
                    width: 125px;

                    .actions-inner-wrapper {
                      .svg-icon.flag,
                      .svg-icon.share-icon,
                      .svg-icon.help-icon {
                        .icon {
                          display: inline;
                        }
                      }
                    }
                  }
                }

                &.shared-budget:not(.read-only-budget),
                &.no-grouping-child {
                  > a:not(.fa) {
                    width: calc(100% - 155px);
                  }

                  .actions-wrapper {
                    width: 150px;

                    .actions-inner-wrapper {
                      .svg-icon.flag,
                      .svg-icon.share-icon,
                      .svg-icon.help-icon {
                        .icon {
                          display: inline;
                        }
                      }
                    }
                  }
                }

                &:not(.shared-budget),
                &:not(.no-grouping-child) &.read-only-budget {
                  > a:not(.fa) {
                    width: calc(100% - 104px);
                  }

                  .actions-wrapper {
                    width: 100px;

                    .actions-inner-wrapper {
                      .svg-icon.flag,
                      .svg-icon.share-icon {
                        .icon {
                          display: inline;
                        }
                      }
                    }
                  }
                }
              }

              .svg-icon.collapse-tree {
                width: 24px;
                text-align: center;
                position: absolute;
                margin-left: -24px;

                .icon {
                  height: 15px;
                  width: 15px;
                }
              }

              &.has-action-links {
                .actions-wrapper {
                  width: 130px;

                  a {
                    font-size: 11px;
                    color: $color-dark-green;
                    font-weight: 400;
                  }
                }

                > a:not(.fa) {
                  width: calc(100% - 134px);
                }
              }

              > a {
                .collapse_expand_wrapper {
                  margin-left: -15px;
                }

                > span.budget-name {
                  display: inline-block;
                  vertical-align: bottom;
                  overflow: hidden;
                  width: 100%;
                  text-overflow: ellipsis;
                  white-space: nowrap;

                  span.budget-name-text {
                    @include flexbox;

                    width: 100%;

                    &.account-before-title {
                      flex-direction: row-reverse;
                      justify-content: flex-end;

                      .budget-name-text-display {
                        margin: 0;
                        line-height: 1.4;
                      }

                      .display-account-ids {
                        margin-right: 5px;

                        &::after {
                          content: "|";
                          padding-left: 5px;
                        }
                      }
                    }

                    span.account-id {
                      font-size: 14px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      @include flex(0 auto);
                    }

                    span.budget-name-text-display {
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      margin-right: 5px;

                      &.has-min-width {
                        min-width: 65px;
                        flex-shrink: 100000;
                      }

                      height: 21px;
                    }

                    span.budget-name-suffix {
                      font-size: 0.8em;
                      color: $color-steel;
                    }
                  }

                  span.model-label {
                    @include smallest-text-white-bold;

                    border-radius: 3px;
                    background-color: $color-dark-grey-blue;
                    padding: 2px 5px;
                    display: inline-block;
                    line-height: 1.5;
                  }

                  &.scenarioRemovedWrapper {
                    margin-left: -5px;

                    .budget-name-text {
                      left: 5px;
                    }

                    span.type {
                      margin-left: 5px;
                    }
                  }
                }
              }
            }

            &.scenario-added-line {
              .actions-toggle {
                > a {
                  > span.budget-name {
                    span.budget-name-text {
                      @include flexbox;

                      span.budget-name-text-display {
                        width: calc(100% - 46px);
                      }
                    }
                  }
                }
              }
            }
          }

          &[data-level="0"] {
            .actions-toggle {
              & > a {
                padding-left: 5px;
              }
            }

            &.importing {
              > .tree-element > .actions-toggle {
                padding-left: 13px;
              }
            }
          }

          @for $i from 0 through 9 {
            &[data-level="#{$i}"] {
              .tree-element {
                .actions-toggle {
                  & > a:not(.leaf-mode) {
                    padding-left: ($i + 1) * 20px;
                  }

                  $s: $i * 20px + 69px;
                  $m: $i * 20px + 14px;
                  $im: $i * 20px + 115px;
                  $im2: $i * 20px + 195px;
                  $cm: $i * 20px - 2px;

                  input:first-of-type,
                  .inline-edit {
                    margin-left: $m;
                  }

                  input[name="name"],
                  .inline-edit {
                    top: 6px;
                    width: calc(100% - #{$s});
                  }

                  $s2: $i * 20px + 260px;

                  &.has-dropdown {
                    input[name="name"],
                    .inline-edit {
                      width: calc(100% - #{$s2});
                    }
                  }

                  > input[name="name"] {
                    width: calc(100% - #{$im}) !important;
                  }

                  &.tree-edit-mode {
                    > input[name="name"] {
                      width: calc(100% - #{$im2}) !important;
                    }
                  }
                }
              }
            }
          }

          &,
          * {
            font-weight: 400;
          }

          &.is-top-level .budget-name-text-display {
            &,
            div {
              color: var(--list-item-text-color);
              font-weight: 500;
              letter-spacing: var(--bold-font-letter-spacing);
            }
          }

          span.budget-type {
            font-size: 12px;
            color: #909090;
            line-height: 35px;
            margin-left: 10px;
          }

          &:hover,
          &.chosen {
            > .tree-element > .actions-toggle {
              span.budget-type {
                display: inline;
              }
            }
          }

          > .svg-icon {
            position: absolute;
            z-index: 1;
            margin-top: 7px;
            margin-left: 10px;
          }

          &:not(.active) {
            > .svg-icon {
              &.import-arrow {
                .icon {
                  fill: $color-dark-mint;
                }
              }
            }
          }

          &.active {
            > .svg-icon {
              &.import-arrow {
                .icon {
                  fill: $color-white;
                }
              }
            }
          }

          &.empty-amounts,
          &.new-budget {
            > .tree-element {
              > .actions-toggle {
                .empty-budget-label {
                  display: inline-block;
                  position: absolute;
                  right: 0;
                  @include small-14-text;

                  color: $color-dark-grey-blue !important;
                  background-color: $color-light-dark-grey;
                  padding: 4px 10px;
                  @include border-radius(4px);
                }

                &:hover {
                  .empty-budget-label {
                    display: none;
                  }
                }
              }
            }

            &.active {
              > .tree-element {
                > .actions-toggle {
                  .empty-budget-label {
                    display: none;
                  }
                }
              }
            }
          }

          .missing-name {
            opacity: 0.6;
          }

          &.scenario-added {
            * {
              color: $color-blue-changes;
            }

            span.budget-name,
            span.budget-name-text,
            span.budget-name-text-display,
            span.scenario-new-budget {
              font-weight: 600;
            }

            span.scenario-new-budget {
              @include smallest-text-white-bold;

              border-radius: 3px;
              background-color: $color-blue-changes;
              padding: 2px 5px;
              display: inline-block;
              line-height: 1.5;
            }

            input[type="text"],
            .dropdown {
              background: transparent;
            }
          }

          &.new-line {
            &.hide-from-tree {
              display: none;
            }

            > .tree-element {
              .inline-edit {
                padding-right: 4px;
              }

              > .actions-toggle {
                div.add {
                  position: absolute;
                  top: 0;
                  right: 10px;
                  width: auto;
                  line-height: 40px;
                  font-size: 12px;
                  font-weight: 600;
                  padding: 0;
                }

                div.add.add-line {
                  position: absolute;
                  top: 50%;
                  line-height: 20px;
                  right: 0;
                  width: 72px;
                  height: 32px;

                  > div {
                    display: inline-block;

                    &:first-child {
                      margin-right: 8px;
                    }
                  }

                  &:not(.enable) {
                    opacity: 1;
                    cursor: default;

                    .svg-icon {
                      line-height: normal;

                      .icon {
                        fill: $color-steel;
                      }

                      &.add-budget-line:hover {
                        cursor: not-allowed;

                        .icon {
                          fill: $color-steel;
                        }
                      }

                      &:not(.add-budget-line):hover {
                        .icon {
                          fill: $color-dark-grey-blue;
                        }
                      }
                    }
                  }

                  &.enable {
                    .svg-icon:hover {
                      cursor: pointer;

                      .icon {
                        fill: $color-dark-grey-blue;
                      }
                    }
                  }
                }
              }
            }

            &:not(.has-budget-type) {
              > .tree-element > .actions-toggle {
                a.add {
                  opacity: 0.2;
                }

                a.add.add-line {
                  opacity: 1;
                  background-color: $color-mercury;
                }

                a.add.add-line:hover {
                  cursor: default;
                }
              }
            }
          }

          &.scenario-changed:not(.scenario-removed):not(.scenario-added):not(.selected-root) {
            > .tree-element > .actions-toggle {
              span {
                &.budget-name,
                &.budget-name-text,
                &.budget-name-text-display,
                &.display-account-ids,
                &.wrapper,
                &.value-display-first,
                &.spliter,
                &.value-display {
                  color: $color-blue-changes;
                }

                &.budget-name {
                  padding: 0 10px;
                  margin-left: -10px;
                }

                &.budget-name {
                  display: inline-block;
                }

                &.budget-name-text {
                  @include flexbox;
                }

                &.budget-name {
                  .budget-name-text {
                    line-height: 36px;
                    font-weight: 600;
                  }

                  width: calc(100% - 20px);

                  span.type {
                    margin-left: 0;
                  }
                }
              }

              .collapse_expand_wrapper + span {
                &.budget-name,
                &.budget-name-text {
                  margin-left: 0;
                  padding-left: 20px;
                }
              }
            }

            &.active {
              > .tree-element > .actions-toggle {
                span {
                  &.budget-name,
                  &.budget-name-text,
                  &.budget-name-text-display {
                    color: $color-white;
                  }
                }

                .actions-wrapper {
                  .svg-icon {
                    .icon {
                      fill: $color-white;
                    }
                  }
                }
              }
            }
          }

          &.scenario-removed {
            .tree-element .actions-toggle {
              .strike::before {
                top: 55%;
              }

              .actions-wrapper {
                display: none;
              }
            }
          }

          &.collapsed {
            > div.tree-element > div.actions-toggle .collapse-tree {
              @include transform(rotate(-90deg));
            }

            > ol {
              display: none;
            }
          }

          &.empty-group:not(.root-only) {
            .tree-element:not(.model-group-budget) {
              .actions-toggle {
                .actions-wrapper .actions-inner-wrapper {
                  svg {
                    display: inline;
                  }
                }
              }
            }
          }

          &.no-grouping {
            .tree-element {
              .budget-name {
                .budget-name-text {
                  .budget-name-text-display {
                    color: $color-red;
                  }
                }
              }
            }
          }

          input[type="checkbox"] {
            margin-top: -2px;
          }

          &.edit-mode {
            > .tree-element > .actions-toggle {
              .budget-name {
                font-weight: 600;
              }
            }

            a.btn {
              color: #fff;
              text-transform: none;
              font-size: 14px;
              line-height: 23px;
              font-weight: normal;
              margin-top: 8px;
              padding: 0 5px;
            }
          }
        }

        &.active:not(.drag-and-drop-mode),
        &.in-drag-mode {
          > .tree-element > .actions-toggle {
            .icon-comment {
              opacity: 1;
            }

            background-color: $color-dark-mint !important;

            * {
              color: $color-white !important;
              -webkit-text-stroke-color: $color-white;
            }

            .budget-type-dropdown,
            .inline-edit-tree {
              border: 1px solid $color-white;

              input::placeholder {
                color: $color-white;
              }
            }

            span.type {
              opacity: 1;
              color: $color-white;
            }

            span.budget-name-text,
            span.budget-name-text-display {
              color: $color-white;
            }

            .actions-wrapper {
              display: inline-block;
            }

            .actions-wrapper .actions-inner-wrapper,
            .svg-icon {
              .icon {
                fill: $color-white !important;
                display: inline !important;
              }
            }

            .star.star.star.star.star svg {
              fill: $color-white;
            }
          }
        }

        &:not(.active):not(.empty-amounts):not(.empty-group):not(.new-budget),
        &:not(.active).any-scenario-added,
        &:not(.active)[data-type^="group-model"] {
          &:not(:hover) {
            > .tree-element > .actions-toggle {
              &:not(.budget-flagged):not(.shared-budget):not(.no-grouping-child) {
                a {
                  width: calc(100% - 5px) !important;
                }

                .actions-wrapper {
                  width: 0;
                }
              }
            }
          }
        }

        &.guidance-container {
          text-align: center;
          position: absolute;
          top: 120px;
          width: 100%;

          .curved-arrow {
            position: absolute;
            right: 74px;
            left: auto;
            top: -45px;
          }

          .guidance-heading {
            font-size: 16px;
            font-weight: bold;
          }

          .guidance-content {
            width: 180px;
            margin: 0 auto;
          }

          &.smaller {
            position: absolute;
            bottom: 0;
            left: 60px;
            @include flexbox;

            .large-text-steel {
              // TODO needs to be renamed, does not correspond to large-text-steel in font_styles.scss
              font-size: 20px;
              line-height: 35px;
              margin-left: 10px;
            }

            .svg-icon.big-icon .icon {
              height: 35px;
              width: 35px;
            }
          }

          * {
            color: $color-bay-of-many;
          }

          &.import {
            border-top: 1px solid $color-mercury;
            padding-top: 12px;
            margin-top: 20px;

            .guidance-content {
              width: 260px;
            }

            * {
              color: $color-dark-green;
              font-weight: normal;
              line-height: 19px;
            }
          }
        }
      }

      .ui-sortable-placeholder {
        background: #f2f2f2;
      }

      .new-line {
        * {
          color: #a0a0a0;
        }

        &.chosen {
          > .tree-element > .actions-toggle * {
            color: $font-color;
          }
        }

        .actions-toggle {
          input[type="text"] {
            width: calc(100% - 104px);
            margin: 6px 5px;
            font-size: 18px;
            height: 25px;
            line-height: 23px;
            padding: 0 5px;

            &[name="type"] {
              max-width: 150px;
              width: 150px;
              margin-left: 0;
            }
          }

          &.has-dropdown {
            input[name="name"] {
              width: calc(100% - 245px);

              &[name="type"] {
                max-width: 150px;
                width: 150px;
                margin-left: 0;
              }
            }
          }

          .select2-container,
          .budget-type-dropdown {
            display: inline-block;
            margin-right: 5px;
            margin-left: 10px;
            text-align: left;
            height: 32px;
            width: 150px;
            max-width: 150px;
            vertical-align: top;
            margin-top: 6px;
            padding-left: 5px;
            border: 1px solid $color-steel;
            border-radius: 3px;

            .select2-chosen {
              height: 32px;
              line-height: 32px;
              font-size: 16px;
            }
          }

          .budget-type-dropdown {
            > div {
              .dropdown-value-text {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                line-height: 30px;
                height: 100%;
                display: inline-block;
                max-width: calc(100% - 20px);
              }

              .svg-icon {
                display: inline-block;
                float: right;

                .icon {
                  height: 20px;
                  width: 20px;
                }
              }

              height: 100%;
            }
          }
        }
      }
    }

    > .guidance-container {
      @include flexbox;
      @include flex-justify(center);

      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 6px;
        top: -6px;
        left: 0;
        background: rgba(216, 216, 216, 0.25);
        z-index: 1;
      }

      .svg-icon {
        line-height: 40px;
      }

      .large-text-steel {
        // TODO needs to be renamed, does not correspond to large-text-steel in font_styles.scss
        margin-left: 10px;
        font-size: 18px;
        line-height: 40px;
      }
    }
  }

  &.metrics {
    aside.secondary {
      &:hover {
        ol div:first-of-type + .tree-resizer {
          border-bottom: 1px solid $color_weird_green;
        }
      }

      ol.tree {
        &:not(.show-tree) {
          display: none;
        }
      }

      .tree-edit-bar {
        height: 50px;
        background-color: $color_white;
        border: none;

        .tree-edit-bar-inner {
          border-bottom: none;

          > .centerVElement {
            display: flex;
            width: 100%;
            gap: 5px;
          }

          .add-button {
            margin-left: auto;
          }

          * {
            color: $font_color;
            -webkit-text-stroke: 0.5px $font_color;
          }

          .collapsed {
            .expand-collapse {
              transform: rotate(270deg);
            }
          }

          .svg-icon {
            &.expand-collapse {
              svg {
                width: 16px;
                height: 16px;
              }
            }

            &.add-button {
              svg {
                fill: $color_dark_blue_grey;
              }

              &.disable {
                svg {
                  fill: $color-regular-white;
                }
              }
            }
          }
        }
      }

      .empty-tree-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        .empty-tree-image {
          color: $color_steel;
          font-size: 18px;
          line-height: 21px;
          text-transform: uppercase;
          text-align: center;
        }
      }
    }
  }

  &.wizard-open {
    aside.secondary {
      width: 320px !important;

      ol.tree {
        background-color: rgba(234, 234, 234, 0.5);

        * {
          color: $color-dark-grey-blue !important;
          pointer-events: none;
        }

        li.active {
          background-color: rgba(#ebebeb, 0.5);

          > .tree-element .actions-toggle {
            * {
              color: $color-dark-grey-blue !important;
            }

            background-color: transparent !important;
            min-height: 40px;
            margin: 3px;
            width: calc(100% - 6px) !important;

            .svg-icon .icon {
              fill: $color-dark-grey-blue !important;
            }
          }
        }

        z-index: 2000;

        li.guidance-container {
          display: none;
        }

        .actions-wrapper,
        span.type,
        div.add.add-line,
        span.budget-type,
        .budget-type-dropdown {
          display: none !important;
        }

        .inline-edit {
          width: auto !important;
          border: 0 !important;
        }

        li.budget-list-item.edit-mode {
          border-bottom: 0;

          &:not([data-level="0"]) {
            border-top: 0;
          }
        }

        .actions-toggle {
          width: 100% !important;

          > a {
            width: 100% !important;
          }

          .empty-budget-label {
            right: 24px !important;
          }
        }
      }
    }
  }

  &[data-path="main.budget.trx"] {
    aside.secondary {
      .tree-edit-bar {
        .search {
          display: none;
        }
      }
    }
  }
}
