/* stylelint-disable max-nesting-depth, declaration-no-important */
.modal.video-modal-dialog {
  .modal-dialog {
    width: 90%;
    max-height: 90%;
    height: 90%;
    margin-top: 35px !important;

    .modal-content {
      height: 100%;
      max-height: 100%;

      .modal-header {
        height: 35px;
        border-bottom-color: transparent;

        .modal-actions {
          right: 10px;
          top: 10px;
        }
      }

      .modal-body {
        max-height: calc(100% - 35px);
        height: calc(100% - 35px);
        padding: 0;

        .video-modal-content {
          height: 100%;
          margin-bottom: 0;

          .budgeta-video {
            padding-top: 0;
          }
        }
      }
    }
  }
}
