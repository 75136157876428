.nav-bar-container {
  display: flex;
  flex: 0 0 200px;
  background-color: $color-white;

  .folder-label {
    span {
      color: $color-steel;
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
    }
  }
}
