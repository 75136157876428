.user-list-container {
  .page-header {
    .right-side {
      @include flexbox;
    }

    .input-container {
      margin-right: 20px;
    }
  }

  // .body {
  //   padding: 0 30px;
  //   flex-grow: 1;
  // }
}
