/* stylelint-disable max-nesting-depth, selector-class-pattern, declaration-no-important */
aside.secondary {
  ol.tree {
    li {
      &.budget-list-item {
        &.drag {
          @include transform(rotate(-7deg));
        }

        &.ui-draggable-handle {
          z-index: 10;
          width: 100%;
        }

        &.dropIn {
          > .tree-element > .actions-toggle {
            background-color: $color-move;
          }
        }

        &.moveDown {
          > .tree-element > .actions-toggle::after {
            position: absolute;
            top: 40px;
            content: "";
            background-color: $color-move;
            height: 5px;
            width: 100%;
            display: block;
          }
        }

        &.moveUp {
          > .tree-element > .actions-toggle::before {
            position: absolute;
            content: "";
            background-color: $color-move;
            height: 5px;
            width: 100%;
            display: block;
          }
        }

        .movePlace {
          border: 2px solid red;
          height: 1px;
        }

        &.markAsDrop {
          border: 5px solid $heading-border-color;
        }

        > .dropPlace {
          height: 40px;
          width: 100%;
          background: $color-light-gray;
        }

        .tmp-droppable {
          height: 40px;

          &.droppable-active {
            border: 2px dashed $color-mercury;
          }

          &.droppable-hover {
            border: 2px dashed $heading-color;
          }
        }

        &.no-drop {
          cursor: no-drop !important;
        }
      }
    }
  }
}
