/* stylelint-disable max-nesting-depth, selector-class-pattern */
.login-container {
  background: $color-black-darker;

  .alert-container {
    // fix login alert form text
    height: auto;
    max-height: 120px;
  }

  .headerLogo {
    margin-top: 10px;

    .app-logo-image {
      > svg {
        transform: scale(2);
        width: 60px;
        height: 32px;
      }
    }
  }

  .sibp-modal-base-container {
    margin-top: 10px;

    .modal-container {
      box-shadow: 10px 10px 50px rgba(0, 41, 64, 0.12);
      border-radius: 8px;
      padding: 0 40px;
      position: relative;
      transform: translateX(-50%);
      top: unset;

      .header-container {
        > span {
          font-size: 34px;
          color: $color-black-darker;
          opacity: 0.9;
          height: 90px;
        }
      }

      .body-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .alert-container {
          margin-bottom: 10px;

          .alert-text {
            .second {
              color: $color-dark-green;
            }
          }
        }

        .intro {
          padding-bottom: 20px;
          color: $color-black-darker;
          text-align: center;

          .second {
            color: $color-dark-green;
          }
        }

        .sub-header {
          color: $color-black-darker;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 20px;
        }

        .update-password-notice-message {
          margin-bottom: 15px;

          .alert-container {
            height: auto;
            max-height: 140px;

            .alert-text {
              padding: 10px 20px 12px;
            }
          }
        }

        .input-container {
          margin-bottom: 20px;

          &.forgot-email-address {
            padding-bottom: 20px;
          }

          label {
            padding: 0;
            margin-bottom: 8px;
            color: $color-black-darker;
            font-size: 16px;
            font-weight: 500;

            span {
              display: none;
            }
          }

          .base-input-container {
            width: 420px;
            height: 50px;

            input {
              width: inherit;
              height: inherit;
              padding: 0 15px;
              font-size: 16px;
              border: 1px solid $color-grey-blue-input-border;
              border-radius: 4px;

              &.hover-green:hover {
                border: 1px solid $color-weird-green;
                background-color: $color-green-background;
              }
            }
          }

          &.password-input {
            .base-input-container {
              input {
                padding: 0 60px 0 15px;
              }
            }
          }

          .error-message {
            width: inherit;
          }
        }

        .sibp-checkbox {
          align-self: flex-start;
          margin-bottom: 30px;

          > label {
            display: flex;

            &::before {
              margin-right: 0;
            }
          }

          span {
            font-size: 16px;
            font-weight: 400;
            color: $color-black-darker;
          }
        }
      }

      .footer-container {
        height: auto;
        padding-bottom: 40px;

        .footer-buttons {
          display: flex;
          flex-direction: column;
          align-items: center;

          .sibp-button {
            &.round {
              width: 420px;
              height: 50px;
              background-color: $color-green-sage-button-darker;
              color: $color-regular-white;
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 25px;

              &.white-button {
                background-color: $color-regular-white;
                border: 2px solid $color-green-sage-button-darker;
                color: $color-green-sage-button-darker;
              }
            }

            &.no-style {
              text-decoration: underline;
              color: $color-green-login-password-link;
              background: none;
              padding: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          .change-pass-error {
            color: $color-red-dark;
          }
        }
      }
    }

    &.modal-sso-container {
      .modal-container {
        background-color: $color-grey-background;

        .header-container {
          margin-left: -40px;
          width: 500px;
          background: $color-regular-white;
          border-radius: 8px 8px 0 0;
          height: 74px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          border-bottom: 0.1px solid $color-grey-divider;

          > span {
            font-size: 28px;
            height: 30px;
            padding-left: 40px;
          }
        }

        .body-container {
          padding-top: 20px;

          .round {
            width: 420px;
            height: 50px;
            background-color: $color-green-sage-button-darker;
            color: $color-regular-white;
            font-size: 16px;
            font-weight: 500;
          }

          .divider {
            border: none;
            border-top: 1px solid $color-grey-border-light;
            margin: 20px 0;
            width: 100%;
          }

          .label-text {
            font-size: 16px;
            font-weight: 500;
            color: $color-black-darker;
            margin-bottom: 14px;
            align-self: flex-start;
          }

          .alert-container {
            margin-bottom: 20px;
          }

          .align-start {
            align-self: flex-start;
          }

          .sub-header-container {
            background-color: $color-regular-white;
            display: flex;
            width: 100%;

            .sub-header {
              color: $color-black-darker;
              font-size: 24px;
              font-weight: 700;
              margin-bottom: 20px;
              align-self: flex-start;
            }
          }

          .update-password-notice-message {
            margin-bottom: 0;
          }

          .sibp-checkbox {
            margin-bottom: 20px;

            > label {
              &::before {
                background: $color-regular-white;
                border: 1px solid $color-grey-blue-input-border;
              }
            }
          }
        }

        .footer-container {
          padding-bottom: 30px;

          .footer-buttons {
            .sibp-button {
              &.white-button {
                background-color: $color-grey-background;
              }

              &.no-style {
                font-weight: 500;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}
