/* stylelint-disable no-duplicate-selectors */
.steps-progress-bar {
  &.bar-connector {
    border-top: 1px solid $color-mint-tulip;
  }

  ul {
    @include flexbox;
    @include flex-justify(space-between);

    text-align: center;
    margin-bottom: 0;

    .step-item {
      font-size: 13px;
      line-height: 18px;
      width: 21px;
      height: 21px;
      border: 1px solid $color-mint-tulip;
      margin-top: -11px;
      background-color: white;
      @include border-radius(50%);

      &.current-step {
        border: 2px solid $color-dark-mint;
        line-height: 15px;
      }

      &.selected {
        border: 2px solid $color-dark-mint;
        background-color: $color-dark-mint;
      }

      &.selected {
        &::after {
          background-image: url("/assets/icons/check_white.svg");
          background-size: 16px 18px;
          display: inline-block;
          width: 16px;
          height: 18px;
          content: "";
        }
      }
    }
  }
}
