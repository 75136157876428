/* stylelint-disable max-nesting-depth */
.elegant-modal.wizard-modal.group-company-intacct {
  .intacct-data-populate {
    .slider-wrapper {
      height: 145px;
      margin-top: -30px;
      position: relative;

      .ui-slider {
        .slider-track {
          height: 113px;
          background-image: linear-gradient(transparent, transparent);
          box-shadow: none;

          .slider-selection {
            background-image: linear-gradient(
              transparent,
              transparent 11%,
              rgba(80, 184, 131, 0.25) 11%,
              rgba(80, 184, 131, 0.25) 78%,
              transparent 78%
            );
            @include border-radius(0);

            box-shadow: none;
            border-right: 1px solid #50b783;
          }

          .slider-track-high {
            @include border-radius(0);

            background-image: linear-gradient(transparent, transparent 11%, #f7f7f7 11%, #f7f7f7 78%, transparent 78%);
          }

          .slider-handle {
            width: 11px;
            height: 11px;
            margin-left: -6px;
            background-color: $color-dark-mint;
            background-image: linear-gradient($color-dark-mint, $color-dark-mint);
            box-shadow: none;
          }
        }

        .tooltip {
          display: none;
        }
      }

      .slider-data {
        position: absolute;
        z-index: 5;
        top: 17px;
        display: grid;
        height: 100px;
        width: 100%;
        grid-template-rows: 40px auto 24px;

        .slider-data-header {
          border-top: 1px solid $color-silver;
          border-bottom: 1px solid $color-silver;
          @include flexbox;

          position: relative;

          &::before,
          &::after {
            position: absolute;
            content: "";
            height: 40px;
            top: -1px;
            width: 32px;
            border-top: 1px solid $color-silver;
            border-bottom: 1px solid $color-silver;
          }

          &::before {
            left: -32px;
            background-color: rgba(80, 184, 131, 0.25);
          }

          &::after {
            right: -32px;
            background-color: #f7f7f7;
          }

          div {
            display: inline-block;
            font-size: 16px;
            letter-spacing: 0.2px;
            height: 100%;
            font-weight: 600;
            line-height: 40px;
            text-align: center;
            @include text-ellipsis;
          }
        }

        .slider-data-months {
          @include flexbox;

          position: relative;

          &::before,
          &::after {
            position: absolute;
            content: "";
            height: 36px;
            width: 32px;
            border-bottom: 1px solid $color-steel;
          }

          &::before {
            left: -32px;
            background-color: rgba(80, 184, 131, 0.25);
          }

          &::after {
            right: -32px;
            background-color: #f7f7f7;
          }

          div {
            @include flex(1);

            font-size: 16px;
            letter-spacing: 0.2px;
            text-align: center;
            position: relative;
            line-height: 2;

            &::before {
              position: absolute;
              bottom: 0;
              left: 0;
              height: 4px;
              width: 1px;
              content: "";
              background-color: $color-steel;
            }
          }

          border-bottom: 1px solid $color-steel;
        }

        .slider-data-years {
          border-bottom: 1px solid $color-silver;
          position: relative;
          @include flexbox;

          > div {
            display: inline-block;

            .slider-data-years-year {
              font-size: 12px;
              font-weight: 600;
              color: $color-light-steel;
              line-height: 24px;
            }

            &:last-child:not(:first-child) {
              border-left: 1px dashed $color-steel;
              padding-left: 4px;
            }

            &:first-child:not(:last-child) {
              margin-left: -32px;
              text-align: center;
            }
          }

          &::before,
          &::after {
            position: absolute;
            content: "";
            height: 24px;
            width: 32px;
            border-bottom: 1px solid $color-silver;
          }

          &::before {
            left: -32px;
          }

          &::after {
            right: -32px;
          }
        }

        &.has-two-years {
          .slider-data-months {
            .is-jan {
              border-left: 1px dashed $color-steel;
            }
          }
        }

        &.hide-actuals {
          .slider-data-header {
            &::before {
              background-color: #f7f7f7;
            }

            > div:last-child {
              border-left: 1px solid $color-dark-mint;
            }
          }

          .slider-data-months {
            &::before {
              background-color: #f7f7f7;
            }

            > div:first-child {
              border-left: 1px solid $color-dark-mint;
            }
          }
        }

        &.hide-budget {
          .slider-data-header {
            &::after {
              background-color: rgba(80, 184, 131, 0.25);
            }
          }

          .slider-data-months {
            &::after {
              background-color: rgba(80, 184, 131, 0.25);
            }
          }
        }
      }
    }

    .modal-padding {
      padding-top: 10px;
    }
  }
}
