/* stylelint-disable font-family-no-missing-generic-family-keyword, selector-class-pattern, declaration-no-important */
.inline-edit {
  &,
  * {
    line-height: 23px !important;
  }

  .svg-icon {
    padding: 0 2px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;

    .icon {
      fill: $color-silver;
    }

    &:hover {
      .icon {
        fill: $color-silver;
      }
    }
  }
  @include long-text(auto);

  position: relative;
  display: inline-block;
  height: 25px;

  &.no-height {
    height: auto;
  }

  padding-right: 30px;
  padding-left: 4px;

  &:not(.simpleDesign) {
    border-bottom: 1px dashed transparent;
  }

  &.with-show-value {
    &.editing {
      > .show-value {
        opacity: 0;
      }
    }

    .show-value {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &:not(.editing) {
      input {
        display: none;
      }
    }
  }

  &.editing:not(.simpleDesign) {
    border: 1px solid #bcbcbc;
    padding: 0;

    &.white-border {
      border-bottom: 2px dashed white;

      input {
        outline: none !important;

        &:-webkit-autofill {
          background: transparent;
          border-bottom: none !important;
          box-shadow: 0 0 0 1000px #fff inset;
          -webkit-text-fill-color: white !important;
        }
      }
    }
  }

  &.transparent input {
    background: transparent;
    border: none;
    outline: 0;
  }

  &:hover,
  &.empty-value {
    &:not(.editing):not(.readonly) {
      cursor: pointer;

      &:not(.simpleDesign):not(.white-border) {
        border-bottom: 1px dashed $color-silver;
      }

      .svg-icon {
        opacity: 1;
      }

      &.white-border {
        border-bottom: none;
      }
    }
  }

  &.simpleDesign {
    &.editing {
      padding: 0;
    }
  }

  &.underline {
    position: relative;
    border-bottom: 1px solid $color-silver;
    border-top: none;
    border-right: none;
    border-left: none;
  }

  &.no-padding {
    padding: 0;

    input {
      padding: 0;
    }
  }

  &.full-width {
    width: 100%;
  }

  input {
    border: 0;
    margin: 0 !important;
    width: 100% !important;
    outline: none !important;
  }

  .check-sign {
    position: absolute;
    top: 50%;
    @include transform(translate(0%, -50%));

    right: 10px;

    &::after {
      content: url("/assets/icons/check_green.svg");
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translate(-60%, -35%));

      color: $color-weird-green;
      font-family: FontAwesome;
      font-size: 14px;
      display: block;
      transition: all 0.4s ease;
    }
  }

  &.empty-value {
    .check-sign {
      opacity: 0.25;
    }
  }

  &.inline-edit-tree {
    border: 0;
    border-bottom: 1px solid $color-silver;
    color: $color-dark-grey-blue !important;
    font-size: 18px;
    height: 32px;
    line-height: 32px !important;

    input {
      color: $color-dark-grey-blue !important;
      background-color: transparent;
    }
    @include placeholder-color($color-steel);

    &:hover,
    &.empty-value {
      &:not(.editing):not(.readonly) {
        border: 0;
        border-bottom: 1px solid $color-silver;
      }
    }

    &:not(.empty-value) + .select2-container {
      border: 0 !important;

      a.select2-choice {
        .select2-chosen {
          color: $color-dark-grey-blue !important;
        }
      }
    }
  }

  &.display-accounts {
    width: calc(100% - 115px);
    height: 100%;
    @include flexbox;

    &:hover,
    &.empty-value {
      &:not(.editing):not(.readonly) {
        cursor: pointer;

        &:not(.simpleDesign):not(.white-border) {
          border-bottom: none;
        }
      }
    }

    .budget-line-name {
      width: auto;
      margin-right: 5px;
      margin-top: 8px;
      @include text-ellipsis;

      min-width: 30px;
    }

    .sidebar-accounts {
      padding-top: 10px;
      min-width: 50px;
    }

    &.editing {
      border-color: $color-weird-green;
    }
  }
}
