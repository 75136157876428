/* stylelint-disable declaration-no-important */
.integration-wizard-dimension-values-mapping {
  .accounts-table-body .column {
    @include flex-important(0 auto);

    &.dimension-name {
      width: 40%;
    }

    .multi-select-dropdown {
      padding-left: 0 !important;
    }
  }
}
