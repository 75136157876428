/* stylelint-disable scss/double-slash-comment-whitespace-inside, scss/at-import-partial-extension, font-family-no-missing-generic-family-keyword, no-duplicate-at-import-rules, no-duplicate-selectors, no-invalid-position-at-import-rule, selector-class-pattern, selector-id-pattern, declaration-no-important */
.popup-menu_pointer {
  display: none;
}

.popup-menu {
  @include transform(translateZ(0));

  z-index: 10000;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  box-shadow: 0 5px 5px rgba(0, 20, 30, 0.2), 0 10px 10px rgba(0, 20, 30, 0.1);

  /* 
        TODO rebrand 2023 - delete this section when finish rebranding all dropdowns
        from here until #end
       */
  text-align: left;

  ul {
    &.narrow {
      width: 100px;
    }
  }

  &.no-padding {
    ul {
      padding: 0;

      li a {
        width: 100%;
        display: block;
      }
    }
  }

  label {
    display: inline-block !important;
  }

  li {
    list-style: none;
    line-height: 30px;

    .svg-icon {
      svg {
        margin: 0 3px;
      }
    }

    &.heading {
      border-bottom: 1px solid #dfdfdf;
      text-transform: capitalize;
      padding: 5px 10px;
      font-weight: 400;
    }
  }

  .popup-bottom-bar {
    position: relative;

    a {
      display: block;
      text-align: center;
      background: $color-highlight !important;
      margin: 20px;
      padding: 5px 0;
    }
  }

  .popup-top-bar {
    line-height: 40px;
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 10px;

    .svg-icon {
      float: right;
      margin-top: 6px;
      margin-right: 6px;
    }

    * {
      font-size: 0.8em;
    }

    label {
      margin: 0 15px;
    }

    a {
      display: inline-block;
      padding: 0 10px;
      color: $heading-color;
    }
  }

  > div.ember-view > ul {
    width: 150px;
    padding: 0 15px;
    margin: 0;
    max-height: 260px;
    overflow-y: auto;
  }

  /* #end */
}
