.radio-buttons-group,
.checkbox-buttons-group {
  .selection {
    height: 40px;
    @include flexbox;

    align-items: center;

    .check-sign {
      @include flex(0 0 20px);

      height: 20px;

      &.checked {
        background-color: $color-dark-mint;
        position: relative;

        &::after {
          position: absolute;
          top: 42%;
          left: 35%;
          font-size: 14px;
          display: block;
          transition: all 0.4s ease;
        }
      }

      &:not(.checked) {
        border: 1px solid $color-silver;
      }
    }

    label {
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
}

.checkbox-buttons-group {
  .check-sign {
    @include border-radius(3px);

    &.checked {
      &::after {
        content: url("/assets/icons/check_white.svg");
        @include transform(scale(0.7) translate(-50%, -50%));
      }
    }
  }
}

.radio-buttons-group {
  .check-sign {
    @include border-radius(50%);

    &.checked {
      &::after {
        content: url("/assets/icons/check_white.svg");
        @include transform(scale(0.7) translate(-50%, -50%));
      }
    }
  }
}
