@font-face {
  font-family: Metric;
  src: url("/assets/fonts/MetricWeb-Light.eot");
  src: url("/assets/fonts/MetricWeb-Light.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/MetricWeb-Light.woff") format("woff"),
    url("/assets/fonts/MetricWeb-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Metric;
  src: url("/assets/fonts/MetricWeb-Semibold.eot");
  src: url("/assets/fonts/MetricWeb-Semibold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/MetricWeb-Semibold.woff") format("woff"),
    url("/assets/fonts/MetricWeb-Semibold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Metric;
  src: url("/assets/fonts/MetricWeb-Bold.eot");
  src: url("/assets/fonts/MetricWeb-Bold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/MetricWeb-Bold.woff") format("woff"), url("/assets/fonts/MetricWeb-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Metric;
  src: url("/assets/fonts/MetricWeb-Bold.eot");
  src: url("/assets/fonts/MetricWeb-Bold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/MetricWeb-Bold.woff") format("woff"), url("/assets/fonts/MetricWeb-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Metric;
  src: url("/assets/fonts/MetricWeb-Regular.eot");
  src: url("/assets/fonts/MetricWeb-Regular.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/MetricWeb-Regular.woff") format("woff"),
    url("/assets/fonts/MetricWeb-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
