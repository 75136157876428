/* stylelint-disable max-nesting-depth, selector-class-pattern */
.react-section {
  &.table-view {
    .selection-box {
      display: flex;
      flex-direction: column;
      height: 159px;
      width: 460px;
      position: absolute;
      bottom: 88px;
      right: 15px;
      background: #fff;
      border: 1px solid $color-weird-green;
      border-radius: 3px;
      margin-right: 10px;

      .headers-selectors {
        flex-direction: row;
        display: flex;
        margin-top: 20px;
      }

      .dropdown-container {
        margin-bottom: 20px;
        width: 200px;
      }

      .main-row {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
      }

      .main-column {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
      }

      .select__option--is-selected {
        background-color: white;
      }

      .select__option--is-focused {
        background-color: $color-dropdown-hover;
      }

      .main-row-dropdown .select__control {
        background-color: $color-weird-green;
        height: 40px;
      }

      .main-column-dropdown .select__control {
        background-color: $color-blue-changes;
        height: 40px;
      }

      .select__single-value {
        color: white;
        margin-top: -5px;
      }

      .select__indicators {
        margin-bottom: 7.5px;
      }

      .select__indicator-separator {
        display: none;
      }

      .default-selection-checkbox {
        margin-left: 22px;
        margin-bottom: 33px;
      }

      .checkbox-label span {
        color: $font-color;
        font-weight: inherit;
        font-size: 18px;
        line-height: 21px;
        padding: 0;
      }

      .sibp-checkbox > label.checked::before {
        border: 0;
      }

      .sibp-checkbox > label {
        display: inherit;
      }

      .select__control {
        .select__indicator {
          > svg {
            fill: white;
          }
        }
      }
    }

    .footer-container {
      position: fixed;
      width: calc(100% - 300px);
    }
  }
}
