.company-edit-container {
  .page-header {
    border: 0;
  }

  .page-body {
    .company-body {
      flex: 1;
    }
  }

  .tabs {
    padding-left: 30px;
  }
}
