/* stylelint-disable max-nesting-depth, keyframes-name-pattern, selector-class-pattern, declaration-no-important */
.modal-container {
  .modal.setup-guide-modal-dialog {
    #back-btn .right {
      width: max-content;
      left: 47px;
    }

    .modal-dialog {
      width: 650px;

      .modal-content {
        .modal-body {
          padding: 0;

          .summary {
            text-transform: uppercase;
            bottom: 0;

            &.info .summary-line {
              @include structure-summary($color-dark-mint, 16px, 500);
            }

            &.warning .summary-line {
              @include structure-summary($color-red, 16px, 500);
            }
          }
        }

        .modal-footer {
          @include transition(all 0.25s ease);
          @include box-shadow(0 -14px 19px 0 rgba(0, 0, 0, 0));

          border-top: 1px solid $color-mid-grey;

          #back-btn {
            width: 130px !important;
          }
        }

        .scrollable {
          padding: 0 20px 20px;
          margin-bottom: 0;
          height: 435px;
          overflow-y: auto;

          .guide-progress {
            .progress-content {
              margin-top: 3px;
              position: relative;

              .steps-link {
                display: none;
                position: absolute;
                right: 0;
                bottom: 0;
                font-style: italic;

                &:hover {
                  cursor: pointer;
                }

                &.options {
                  .svg-icon {
                    .icon {
                      fill: $color-dark-blue-grey;
                    }
                  }
                }
              }
            }

            &:hover {
              background-color: $color-light-mid-grey;

              .progress-content {
                .steps-link {
                  display: block;
                }
              }

              .progress-wrapper-title {
                color: $color-steel;
              }
            }
          }

          &:not(.selected-group) {
            .guide-progress {
              cursor: pointer;

              * {
                cursor: pointer;
              }
            }

            .guide-progress-icon {
              margin-top: -3px;

              .svg-icon {
                .icon {
                  fill: $color-dark-grey-blue;
                }

                &:hover {
                  .icon {
                    fill: $color-dark-grey-blue;
                  }
                }
              }
            }
          }
        }

        .current {
          margin: 0;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          position: relative;
          padding: 20px 20px 0;

          .close_icon {
            position: absolute;
            right: 5px;
            top: 5px;
          }

          .guide-progress {
            .guide-text {
              white-space: nowrap;
            }

            .progress-content {
              margin-top: 6px;
            }
          }
        }

        .guide-progress {
          @include flexbox;

          height: 80px;
          padding: 10px;

          .guide-text,
          .progress-content {
            @include flex(1 1);

            position: relative;
          }

          .guide-text {
            .guide-text-title {
              font-size: 23px;
              line-height: 22px;
            }

            .guide-text-subtitle {
              color: $color-steel;
            }
          }

          .guide-progress-icon {
            margin-right: 15px;
          }

          &.active {
            &.incomplete {
              .guide-text-subtitle.complete {
                display: none;
              }

              .svg-icon:not(.steps-link) {
                cursor: auto;

                .icon {
                  fill: $color-dark-sky-blue;
                }
              }
            }

            &.complete {
              .guide-text-subtitle.incomplete {
                display: none;
              }

              .svg-icon:not(.steps-link) {
                cursor: auto;

                .icon {
                  fill: $color-weird-green;
                }
              }
            }
          }

          &:not(.active) {
            .guide-text {
              * {
                text-decoration: line-through;
                text-decoration-color: $color-steel;
                color: $color-steel;
              }
            }

            .svg-icon:not(.steps-link) {
              cursor: auto;

              .icon {
                fill: $color-steel;
              }
            }

            .guide-text-subtitle.incomplete {
              display: none;
            }
          }
        }
      }
    }
  }

  body:not(.hiddenscroll) {
    .modal.setup-guide-modal-dialog {
      .modal-dialog {
        .modal-content {
          .current {
            margin-right: 15px;

            .close_icon {
              position: absolute;
              right: -5px;
              top: 5px;
            }
          }
        }
      }
    }
  }

  @keyframes moveBox {
    0% {
      width: 400px;
      height: 300px;
      border-radius: 15px;
    }

    25% {
      border-radius: 3px;
    }

    50% {
      width: 40px;
      height: 40px;
    }

    75% {
      border-radius: 50%;
    }

    100% {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      top: 80px;
      right: 345px;
    }
  }

  .setup-guide-box-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
  }

  .setup-guide-box {
    position: absolute;
    top: 30%;
    right: 50%;
    @include transform(translate(50%, -50%));

    width: 600px;
    height: 560px;
    border-radius: 3px;
    animation: moveBox 0.4s linear forwards;
    background-color: white;
    @include box-shadow(0 4px 11px rgba(0, 0, 0, 0.3));

    svg {
      fill: $color-dark-blue-grey;
      position: absolute;
      top: 50%;
      right: 50%;
      @include transform(translate(50%, -50%));
    }
  }
}
