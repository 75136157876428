/* stylelint-disable max-nesting-depth, no-duplicate-selectors, declaration-no-important */
.modal-share {
  .modal {
    overflow-y: hidden;
  }

  .modal-dialog {
    width: 1000px;

    .modal-content {
      .modal-body {
        max-height: 500px;
        overflow-y: auto;

        div.exclude > label {
          width: 150px;
        }

        .panel-heading {
          padding-left: 0;

          &,
          * {
            font-weight: 500;
            color: $color-dark-mint;
          }

          .svg-icon {
            svg {
              fill: $color-dark-mint;
            }
          }

          &.collapsed {
            > a .svg-icon {
              @include transform(rotate(-90deg));
            }
          }
        }

        .table-wrapper {
          margin: 20px 0;

          > label {
            margin: 0;
            padding: 15px 20px;
          }

          ul.table {
            input {
              background: transparent;
              border: none !important;
            }

            li .column {
              position: relative;

              &.collapsed,
              &.expanded {
                .expand-trigger {
                  opacity: 0.6;
                  position: absolute;
                  left: -20px;

                  .icon {
                    width: 18px;
                    height: 18px;
                  }
                }
              }

              &.collapsed {
                .expand-trigger {
                  @include transform(rotate(-90deg));
                }

                > .advanced {
                  display: none;
                }
              }
            }
          }

          .inner-permission-select {
            border: none !important;
            padding: 0;

            a.select2-choice {
              padding-left: 0;

              span.select2-chosen {
                line-height: 28px !important;
              }
            }
          }
        }

        .invite-more {
          width: 100%;
          position: relative;
          float: left;

          label.full {
            margin: 10px 0 20px;
          }

          input[type="email"] {
            width: 360px;
            float: left;
          }

          button.send {
            position: absolute;
            right: 0;
          }

          .permission-select {
            display: inline-block;
            width: 240px;
          }

          > .select2-container {
            box-shadow: none;
          }

          p.input-error {
            position: relative;

            &[role="alert"] {
              top: 94px;
              position: absolute;
            }
          }

          input[type="email"] {
            width: 250px;
          }

          // for translation - 'Include sheets'
          .exclude.budgeta-checkbox.locale {
            margin-right: 0;
          }

          .exclude,
          .employee-data {
            @include flexbox;

            .share-dashboard-list {
              padding-top: 5px;

              > div {
                width: 150px;
                display: inline-block;
                line-height: 20px;

                label {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }

            > label {
              line-height: 38px;
              margin-bottom: 0;
              margin-right: 25px;
              padding-top: 5px;
              min-width: 140px;
              width: 140px;
              color: $color-dark-grey-blue !important;
            }

            &.budgeta-checkbox,
            .budgeta-checkbox {
              line-height: 38px;
              padding-top: 5px;
              margin-right: 10px;

              > label {
                padding-top: 0;
              }
            }

            &:not(.scenario) {
              .select2-container:not(.select2-container-multi) {
                padding: 8px 5px 0;
              }

              .select2-container.select2-container-multi {
                padding-top: 2px;
              }
            }

            &.version {
              .budgeta-checkbox {
                min-width: 150px;
              }

              .error {
                line-height: 38px;
                padding-top: 5px;
              }
            }

            &.scenario {
              > label {
                line-height: 40px;
                padding-top: 0;
                width: 242px;
              }

              > .budgeta-checkbox {
                padding-top: 0;
              }

              .dropdown-select {
                width: 240px;
                text-overflow: ellipsis;

                .dropdown-select-label {
                  span {
                    line-height: 38px;
                  }

                  &.multi-select {
                    span {
                      color: $color-green;
                    }
                  }
                }

                .dropdown-search {
                  .search-box {
                    .search-box-input {
                      padding: 5px 10px;

                      &:focus {
                        outline: 1px solid $color-weird-green;
                      }
                    }
                  }
                }

                .dropdown-select-content-option {
                  .dropdown-option-checkbox {
                    .sibp-checkbox label {
                      display: flex;
                      margin-bottom: 0;
                    }
                  }
                }

                .dropdown-select-add-new-item {
                  span {
                    color: $color-green-sage-darker;
                  }
                }

                &.expanded {
                  .dropdown-select-content {
                    position: fixed;
                  }
                }
              }
            }

            &.budgeta-checkbox {
              input[type="checkbox"] + label,
              input[type="radio"] + label {
                width: auto;
                min-width: auto;
              }
            }

            .select2-container {
              .select2-choices {
                margin-top: 4px;
                min-height: 40px;
              }

              .select2-search-field {
                > input {
                  min-width: 250px;
                }

                * {
                  font-family: Metric, sans-serif;
                }
              }
            }

            .full-size {
              @include flex(1 1 auto);
            }
          }
        }
      }
    }
  }
}
