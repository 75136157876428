.user-budget-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 30px;

  li.budget {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & > * {
      margin-right: 10px;
    }

    .light {
      color: $color-gray-7;
    }
  }

  .unlock-btn {
    border: 1px solid $color-gray-3;
  }
}
