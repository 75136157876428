/* stylelint-disable declaration-no-important */

.wizard-steps-container {
  .wizard-step {
    @include flexbox;
    @include transition(width 0.2s);

    float: left;

    &:not(:last-child) {
      margin-right: 10px;
    }

    .step-number {
      width: 36px;
      min-width: 36px;
      height: 36px;
      font-size: 16px !important;
      font-weight: bold;
      color: #fff;
      @include border-radius(18px);

      text-align: center;
      line-height: 36px !important;
    }

    .step-text {
      color: #000;
      font-weight: normal;
      margin-left: 10px;
      line-height: 18px;
      align-self: center;
    }

    &.current {
      width: calc(100% - 240px);

      .step-number {
        background: $color-bay-of-many;
      }
    }

    &.past {
      cursor: pointer;

      .step-number {
        background: #b8c1e6;
      }
    }

    &.future {
      .step-number {
        background: #bcbcbc;
      }
    }

    &.next {
      .step-number {
        background: $heading-color;
        width: 110px;
        font-weight: normal;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  button {
    float: right;
    @include border-radius(18px !important);

    &.disabled {
      background: $color-mercury !important;
    }
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.step-4 .wizard-step.current {
  width: calc(100% - 280px);
}
