/* stylelint-disable max-nesting-depth, scss/double-slash-comment-whitespace-inside, scss/no-global-function-names, no-duplicate-selectors, selector-class-pattern, declaration-no-important */
.main-content[data-path="main.budget.accounts"] .table-editor,
.wizard-modal.modal-container .modal-dialog .modal-content .new-accounts-table-wizard.table-editor,
.elegant-modal.intacct-wizard.wizard-modal .export-missing-mapping .new-accounts-table-wizard.table-editor {
  .table-body {
    .table-edit-line {
      height: auto;
      overflow: hidden;

      &:not(.selected) {
        .trx-row-content {
          &.account-error {
            .column {
              background-color: $color-error-light;

              input {
                background: transparent;
              }

              .svg-icon {
                .icon {
                  fill: $color-error-dark;
                }
              }
            }
          }
        }
      }

      .trx-row-content {
        @include flexbox;

        position: relative;

        > .column {
          &.checkbox-column {
            position: absolute;
            height: 100%;

            .budgeta-checkbox {
              height: 100%;
            }
          }

          &.account {
            height: auto;

            input {
              background-color: transparent;
            }
          }

          &.actions-column {
            @include flexbox;

            align-items: center;
            justify-content: center;
            height: auto;

            &::before {
              height: 100%;
            }

            .actions-column-wrapper {
              width: 100%;
            }
          }

          > input {
            height: 100% !important;
            width: 100%;
          }
        }

        .accounts-import-wizard {
          .path-list-element-lazy {
            margin-top: 6px;
          }
        }
      }

      .scrollable-wrapper {
        &:not(.accounts-import-wizard) {
          height: auto;
        }

        .scrollable {
          .column {
            height: auto;
            @include flexbox;

            align-items: center;
            justify-content: center;

            &.multi-dropdown {
              @include flexbox;

              > .select2-container {
                @include flex(1);
              }
            }

            > .account-mapping-menu {
              height: 100%;
              width: 100%;

              .mapping-display {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                padding-right: 20px;
              }
            }

            .select-2-lazy-inactive-label {
              line-height: 40px;
              height: 40px;
              min-width: 100%;
              max-width: 100%;
              padding-right: 20px;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.placeholder {
                color: $color-green;
              }
            }

            .select-2-lazy,
            .budget-select-lazy {
              height: 40px;
              width: 100%;

              .select-2-lazy-inactive-label {
                @include dropdown-sign;

                width: 100%;

                &::after {
                  top: 0;
                }
              }
            }

            &.budget-line-mapping {
              > * {
                width: 100%;
              }

              .multi-budget-lines-mapping {
                width: 100%;

                > div {
                  height: 100%;
                }

                &.single-mapping {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  * {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .budget-select-lazy {
                    @include flexbox;

                    height: 100%;

                    .select-2-lazy-inactive-label {
                      padding-right: 20px;
                    }

                    .select2-container {
                      width: 100% !important;
                      max-width: none;
                    }

                    &:not(.active):not(.read-only) {
                      @include dropdown-sign;
                    }
                  }
                }

                .multi-select-dropdown {
                  &:not(.active) {
                    border: solid 1.5px transparent;
                  }

                  &.active {
                    background-color: rgba(56, 218, 149, 0.25);
                    border: solid 1.5px $color-weird-green;
                  }

                  .selection {
                    padding: 0 20px 0 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.main-content {
  &[data-path="main.budget.accounts"] {
    .table-editor {
      .table-header {
        .actions-column.global-actions {
          padding-right: 10px;
        }
      }

      &:not(.dimensions-table-editor) {
        .column {
          &.wide {
            max-width: 160px;
          }

          &.large-column {
            max-width: 250px;
          }
        }
      }

      .table-body {
        max-height: calc(100% - 41px);

        &.scrollable {
          .column {
            &.budget-line-mapping {
              .multi-budget-lines-mapping {
                &.single-mapping {
                  height: 40px;
                }
              }
            }
          }

          .table-body-wrapper {
            > div > div {
              overflow-x: hidden !important;
            }
          }
        }

        .table-edit-line {
          .trx-row-content {
            > .column {
              &.account {
                height: auto;
                margin-left: 40px;
              }
            }
          }
        }
      }
    }

    .add-accounts {
      .add-account-text {
        color: $color-steel;
        font-size: 23px;
      }

      .svg-icon {
        display: block;
        text-align: center;
        color: $color-steel;

        .icon {
          fill: $color-steel;
        }
      }
    }

    .account-types {
      .selection-list {
        @include flex(0 0 200px);

        max-width: 200px;
        overflow: auto;
        background-color: $color-light-soft-grey;

        .selection-list-title {
          margin: 30px 0;
          padding-left: 25px;
          text-transform: uppercase;
          color: $color-dark-mint;
          @include mid-text;
        }

        .si-account-types li {
          padding: 16px 25px;

          &.selected,
          &:hover {
            background-color: $color-dropdown-hover;
          }
        }
      }

      .selection-content {
        width: 100%;

        .table-header,
        .table-body-wrapper {
          border-left: 1px solid $color-light-dark-grey;
        }
      }
    }

    .accounts-dimensions {
      .selection-list {
        @include flex(0 0 200px);

        max-width: 200px;
        overflow: auto;
        background-color: $color-light-soft-grey;

        .selection-list-title {
          margin: 30px 0;
          padding-left: 25px;
          text-transform: uppercase;
          color: $color-dark-mint;
          @include mid-text;
        }

        .si-dimensions li {
          padding: 16px 25px;

          &.selected {
            color: white;
            background-color: $color-dark-mint;
          }

          &:hover:not(.selected) {
            background-color: $color-dropdown-hover;
          }
        }

        .budget-dimensions li {
          padding: 5px 25px;
        }
      }

      .selection-content {
        @include flex(1 1);

        .section-row {
          color: $color-steel;

          .budgeta-type-value {
            border-bottom: 0;

            &.budgeta-type-dropdown.dimension-mapping {
              .budget-select-lazy {
                border-bottom: 1px solid $color-silver;

                .select-2-lazy-inactive-label.enabled {
                  padding-right: 30px;

                  &::after {
                    color: $font-color !important;
                  }
                }
              }
            }
          }
        }

        .dimensions-table-editor {
          border-top: 1px solid #d1d1d1;
          width: 750px;

          .column {
            &.dimension-value-name {
              width: 270px;
              max-width: 270px;
              margin-left: 0 !important;
              background-color: rgba(234, 234, 234, 0.35);
              height: unset;

              > input:focus {
                background-color: transparent !important;
                outline: none;
              }
            }

            &.dimension-name {
              width: 200px;
              max-width: 200px;
              padding-left: 10px;
              @include text-ellipsis;

              .overflow {
                @include text-ellipsis;
              }
            }

            &.dimension-value-mapping {
              width: 280px;
              max-width: 280px;
            }

            .multi-select-dropdown {
              width: 100%;
              padding-left: 10px;
              position: relative;
            }
          }
        }
      }

      .splitter {
        @include flexbox;

        align-items: center;

        &::before {
          content: "";
          border: 1px solid darken($color-white-soft-grey, 10%);
          @include flex(1);
        }
      }
    }
  }
}

body.hiddenscroll {
  .main-content[data-path="main.budget.accounts"] {
    .view-table-edit {
      .table-editor:not(.dimensions-table-editor) {
        .scrollable-wrapper {
          width: calc(100% - 40px - 180px - 75px);
        }
      }

      .table-editor.dimensions-table-editor {
        .scrollable-wrapper {
          width: calc(100% - 270px);
        }
      }
    }
  }
}

body:not(.hiddenscroll) {
  .main-content[data-path="main.budget.accounts"] {
    .view-table-edit {
      .table-editor:not(.dimensions-table-editor) {
        .scrollable .scrollable-wrapper {
          width: calc(100% - 40px - 180px - 75px);
        }
      }

      &.hasRightScroll .table-editor:not(.dimensions-table-editor) {
        .table-header {
          .scrollable-wrapper {
            width: calc(100% - 40px - 180px - 75px - 16px);
          }
        }
      }

      &.hasRightScroll .table-editor.dimensions-table-editor {
        .table-header {
          .scrollable-wrapper {
            width: calc(100% - 270px);
          }
        }
      }

      .table-editor.dimensions-table-editor {
        .table-header {
          .scrollable-wrapper {
            width: calc(100% - 270px);
          }
        }
      }

      &:not(.hasRightScroll) .table-editor:not(.dimensions-table-editor) {
        .table-header {
          .scrollable-wrapper {
            width: calc(100% - 40px - 180px - 80px);
          }
        }
      }

      .table-editor.dimensions-table-editor {
        .scrollable-wrapper {
          width: calc(100% - 300px);
        }
      }
    }
  }
}

//must be global style because of qtip component
.trx-filter-qtip.dimensions .drop-down.trx-filter-dropdown {
  width: auto;
}
