/* stylelint-disable function-url-quotes */
.loader-screen-container {
  background: url(/assets/images/bg_squares_and_circles.png) rgba(102, 133, 146, 0.8);
  background-size: cover;
  background-blend-mode: multiply;

  &.hide-loader {
    display: none;
  }

  &.show-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);

    label {
      margin-top: -35px;
      z-index: 9999;
      position: absolute;
      color: white;
    }

    .loader-container {
      position: relative;
      width: 300px;
      margin-top: 22px;
      margin-left: auto;
      margin-right: auto;
      z-index: 9999;
      height: 8px;

      > span {
        background-color: $heading-border-color;

        &::after,
        > span {
          background-size: 20px 20px;
          background-image: linear-gradient(
            -45deg,
            $color-weird-green 25%,
            transparent 25%,
            transparent 50%,
            $color-weird-green 50%,
            $color-weird-green 75%,
            transparent 75%,
            transparent
          );
        }
      }
    }
  }
}
