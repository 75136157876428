@import "sip-components/styles/fonts";

.company-list-container {
  .page-header {
    .right-side {
      display: flex;
    }

    .input-container {
      margin-right: 20px;
    }
  }

  .page-body {
    .table-container {
      .status-cell {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
