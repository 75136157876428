/* stylelint-disable max-nesting-depth, no-duplicate-selectors, declaration-no-important */
.elegant-modal {
  .modal {
    &.full-screen-width,
    &.regular-width {
      display: flex;
      justify-content: center;

      .modal-dialog {
        margin: 0;
        @include transition(width 1s ease-in-out);

        .modal-content {
          width: 100%;
        }
      }
    }

    &.full-screen-width .modal-dialog {
      width: 90%;
    }

    .modal-dialog {
      margin-top: 75px !important;
      z-index: 10000;
      width: 530px;

      .modal-content {
        display: grid;
        grid-template-rows: 84px auto 72px;
        height: 590px;
        width: 530px;

        &.expand-animation {
          @include transition(width 1s ease-in-out);

          width: 660px;
        }

        &.shrink-animation {
          @include transition(width 1s ease-in-out);

          width: 530px;
        }

        .modal-header {
          padding: 0 32px;
          border-bottom: 1px solid $color-soft-grey;

          .modal-header-inner {
            display: grid;
            height: 84px;

            .modal-title {
              align-self: center;

              .modal-title-text {
                font-size: 32px;
                font-weight: 300;
              }
            }
          }

          .modal-actions {
            top: 11px;
            right: 2px;

            .svg-icon {
              span {
                font-size: 14px;
                color: #1b1b1b;
                font-weight: 500;
              }
            }
          }
        }

        .modal-footer {
          border-top: 1px solid $color-soft-grey;
          padding: 18px 32px 0;
          clear: both;
          position: relative;

          #more-btn {
            display: none;
            position: absolute;
            background: white;
            border: 1px solid $color-dark-mint;
            color: $color-dark-mint;
            right: 7px;
            margin-top: 1px;
          }

          #back-btn {
            width: 70px;

            .svg-icon {
              right: 176px;

              .icon {
                fill: $color-steel;
              }
            }

            div {
              color: $color-steel;
            }

            &.wizard {
              height: 40px;
              width: 70px;
              position: absolute;
              left: 0;

              .svg-icon {
                left: 10px;
              }

              .right {
                right: 0;
              }
            }
          }

          #continue-btn,
          #confirm-btn {
            width: 87px;
            height: 37px;
          }

          .steps {
            position: absolute;
            top: 34px;
            left: 30px;
          }

          .component-next-to-confirm {
            display: inline;

            .add-another {
              display: inline;
              margin-right: 10px;
            }
          }

          #cancel-btn.btn-left {
            padding: 0;
          }

          label.modal-error {
            bottom: unset;
            top: 0;
          }
        }

        .modal-body-top-padding,
        .subtitle,
        .modal-body form > div:first-child.wizard-question {
          padding-top: 16px;
        }

        .modal-body {
          padding: 0;
          overflow-x: auto;
          overflow-y: auto;

          .tab-content,
          .tab-content > .tab-pane,
          .tab-content > .tab-pane > form:not(.excel-data),
          .tab-content > .tab-pane > form:not(.excel-data) > div:first-child:last-child {
            height: 100%;
          }

          .subtitle {
            color: $color-steel;
            height: 80px;
            width: 100%;
          }

          .short-subtitle {
            color: $color_steel;
            height: 45px;
            width: 100%;
            padding-top: 16px;

            &.height-auto {
              height: auto;
            }
          }

          .wizard-question-selection {
            &.top-padding {
              padding-top: 15px;
            }

            > .budgeta-checkbox {
              height: 39px;
              display: flex;
              align-items: center;

              &label {
                color: $font-color;
              }
            }

            > .checkbox-with-dropdown {
              @include flexbox;

              .wizard-question-radio {
                @include flex(0 0 150px);
              }

              .select2-container.select2-container-disabled {
                .select2-chosen {
                  color: $color-steel !important;
                }

                .select2-arrow {
                  display: inline-block;
                  background-color: transparent;
                }
              }
            }
          }

          div:first-child:last-child.wizard-question {
            // as subtitle
            color: $color-steel;
            margin-top: 0;

            .wizard-question-selection {
              margin-top: 45px;
            }
          }

          .budgeta-spinner,
          .budgeta-progress-bar {
            @include centerElement;
          }

          .budgeta-progress-bar {
            width: calc(100% - 128px);
          }

          .scrollable {
            margin-top: 0;
          }
        }

        .modal-padding {
          padding-left: 32px;
          padding-right: 32px;
        }

        .modal-right-padding {
          padding-right: 32px;
        }

        .budgeta-checkbox:not(.add-another) {
          // &label {
          border-bottom: 0;
          display: block;
          margin-left: 4px;
          margin-top: 8px;

          // }
        }

        input {
          &[type="text"],
          &[type="email"],
          &[type="number"],
          &[type="password"] {
            line-height: 40px !important;
            padding-left: 0;
            height: 40px;
            border: none;
            outline: 0;
            border-bottom: 1px solid #eee;
          }
        }

        .budgeta-checkbox:not(.add-another) {
          // &label {
          border-bottom: 0;
          display: block;

          &:not(.without-margin) {
            margin-left: 4px;
            margin-top: 8px;
          }

          // }
        }

        .dropdown {
          border: none;
          outline: 0;
          border-bottom: 1px solid #eee;

          a {
            border: none;
            outline: 0;
            padding-left: 0;
          }
        }

        div.select2-container {
          border: none;
          outline: 0;
          border-bottom: 1px solid #eee;
          margin-top: 0;
          height: 40px;

          a.select2-choice {
            height: 32px;
            line-height: 32px;

            span.select2-chosen {
              height: 32px;
              line-height: 32px;
            }
          }

          &.select2-container-multi {
            height: auto !important;
            min-height: 40px;
            box-shadow: none;

            ul.select2-choices {
              border: none;
              margin-top: -2px;
              @include box-shadow(none);

              li.select2-search-choice {
                border: none;
                background-image: none;
                background-color: $color-white;
                height: 35px;
                line-height: 35px;

                div {
                  line-height: 35px;
                  @include big-mid-text;
                }

                a {
                  @include centerVElement;
                }
              }

              li.select2-search-field {
                input {
                  outline: 0;
                  border: none;
                  height: 36px;
                  min-width: 40px;
                  @include box-shadow(none);

                  &:focus {
                    @include box-shadow(none);
                  }
                }
              }

              &.select2-container-active {
                outline: 0;
                @include box-shadow(none);
                @include transition(none);
              }
            }
          }

          &.short-value-size {
            width: 250px;
          }
        }

        .tab-content .tab-pane,
        .modal-design {
          @include placeholder-color($color-green);

          div.dobule-flex {
            @include flexbox;

            div.flex {
              @include flex(1);

              &:first-child:not(:last-child) {
                margin-right: 20px;
              }
            }
          }

          div.flex {
            &:not(.no-margin) {
              margin-bottom: 15px;
            }

            &.section-head {
              margin-bottom: 0;
              margin-top: 20px;
            }
            @include flexbox;

            position: relative;

            > *:not(.help-iq):not([type="submit"]) {
              @include flex(1 1);

              min-width: 0;

              input[type="text"] {
                width: 100%;
              }

              &.double-width {
                @include flex(2 1);
              }

              &.smaller {
                @include flex(0.7 1);

                &.svg-icon {
                  line-height: 40px;
                  @include flex(0 1 30px);
                }
              }
            }

            input[type="submit"] {
              margin-left: 30px;

              &:hover {
                color: #fff;
              }
            }

            .svg-icon.checkmark {
              position: absolute;
              right: 50px;

              .icon {
                width: 32px;
                height: 32px;
              }
            }

            div.remove-line {
              font-weight: bold;
              font-size: 18px;
              line-height: 35px;
              margin-left: 10px;
              max-width: 30px;
            }

            .help-iq {
              position: absolute;
              margin-left: 5px;

              &:not(.without-position) {
                right: 5px;
              }

              &:not(.without-margin-top) {
                margin-top: 5px;
              }
            }

            .employee-title,
            .row-title {
              flex-basis: 40%;
              line-height: 40px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &.flex-part .overflow-ellipsis {
              line-height: 39px;
            }
          }

          div.short-term {
            input,
            label:not(.content-size) {
              float: left;
            }
          }

          :not(.budgeta-checkbox) > label:not(.budgeta-checkbox) {
            margin-bottom: 0;
            display: inline-block;

            &:not(.error) {
              &:not(.modal-small-title):not(.without-border):not(.ignore-modal-label) {
                border-bottom: 1px solid #eee;
                @include mid-text-steel;
              }

              padding-right: 10px;
              line-height: 39px;

              &.full {
                width: 100%;
              }

              &:not(.content-size):not(.fix-size-170):not(.fix-size-180):not(.fix-size-220):not(.fix-size-215):not(
                  .modal-small-title
                ):not(.ignore-modal-label):not(.flex-1) {
                @include flex(0 0 165px !important);

                &.flex-auto {
                  @include flex(0 0 auto !important);

                  &.connect-to-intacct {
                    @include flex(0 0 165px !important);

                    padding-right: 0;
                  }
                }
              }
            }

            &.flex-1 {
              @include flex(1 1);
            }

            &.content-size {
              @include flex(0 0 auto !important);

              width: auto;
            }

            &.fix-size-170 {
              @include flex(0 0 170px !important);

              width: auto;
            }

            &.fix-size-180 {
              @include flex(0 0 180px !important);

              width: auto;
            }

            &.fix-size-215 {
              @include flex(0 0 215px !important);

              width: auto;
            }

            &.fix-size-220 {
              @include flex(0 0 220px !important);

              width: auto;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 0.9em !important;
            }

            &.error {
              display: block;
              margin-top: -20px;
            }

            &.narrow {
              max-width: 80px;
            }
          }

          .mini-value-size {
            @include flex(0 0 50px !important);

            width: 50px;
          }

          .short-value-size {
            @include flex(0 0 235px !important);

            &.form-control.select2-container {
              padding-left: 0;

              .select2-choice {
                padding: 0;
              }
            }
          }

          .mid-value-size {
            @include flex(0 0 290px !important);
          }

          .month-picker-wrapper {
            @include flexbox;

            .year-picker-from,
            .year-picker-to {
              @include flex(1 1);

              .month-picker {
                width: 100%;
              }
            }
          }

          a.link {
            color: $color-dark-sky-blue;
            text-decoration: underline;

            &.no-underline {
              text-decoration: none;
            }
          }
        }

        label.modal-error {
          width: 100%;
          background-color: $color-red-dark;
          padding: 0 32px;
          color: white !important;
          bottom: 0;
          margin-top: 0;
          margin-bottom: 0;
          line-height: 32px;
          left: 0;
          text-align: left;
        }
      }

      .modal-content.large-footer {
        grid-template-rows: 84px auto 94px;

        &.create-si-wizard .modal-footer {
          &::before,
          &::after {
            display: none;
          }

          border-color: transparent;
          padding: 0;
          display: grid;
          grid-template-areas:
            "steps steps steps steps steps"
            ". . . back confirm";
          grid-auto-columns: 1fr;
          grid-auto-rows: 38px 1fr;

          .steps {
            grid-area: steps;
            margin-top: 18px;
            display: flex;
            justify-content: center;
            border-top: 1px solid $color-soft-grey;
            position: static;
          }

          #back-btn {
            grid-area: back;
            display: flex;
            margin-top: 6px;
          }

          #confirm-btn {
            grid-area: confirm;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  &.extra-wide {
    .modal-dialog {
      .modal-content {
        &.expand-animation {
          @include transition(width 1s ease-in-out);

          width: 860px !important;
        }
      }
    }
  }
}
