/* stylelint-disable max-nesting-depth, no-duplicate-selectors, selector-class-pattern, declaration-no-important */

/* TODO rebrand 2023 - uncomment this section from here to #end when finish rebranding all dropdowns */
// /* required to override vendor styles */
// .qtip {
//   max-width: 620px;
// }
// .qtip .qtip-content {
//   padding: 0;
// }
// /* #end */

.sibp-qtip {
  z-index: 10000;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  box-shadow: 0 5px 5px rgba(0, 20, 30, 0.2), 0 10px 10px rgba(0, 20, 30, 0.1);
}

/* TODO rebrand 2023 - delete when finish rebranding all dropdowns from here to the end of the file */

.qtip {
  max-width: 620px;

  // display: block !important;
  // left: 200px !important;
  // top: 200px !important;

  &.full-size {
    max-width: 100%;
  }

  &.qtip-pos-rt {
    .qtip-tip {
      @include transform(rotate(180deg));
    }
  }

  &.no-limit {
    width: auto !important;
    max-width: 800px;
  }

  .qtip-content {
    padding: 0;

    > div {
      height: 100%;
    }

    > div:not(.drop-down) {
      padding: 10px;
    }

    > div.account-mapping-menu {
      * {
        line-height: 49px !important;
      }

      ul.mapping-type {
        width: 250px;
      }

      ul.mapping-type-import {
        width: auto;
      }
    }

    > div {
      &.drop-down-card {
        height: 420px;
        width: 310px;

        .popup-card {
          @include flexbox;
          @include flex-flow(column);

          height: 100%;

          .popup-body {
            @include flex(1 1);
            @include flexbox;
            @include flex-flow(column);

            overflow-y: auto;

            .popup-header {
              @include flex(0 0 110px);

              padding: 24px 24px 16px;

              input {
                width: 100%;
              }
            }

            .popup-content {
              @include flex(1 1);

              padding: 24px;
              background: $color-light-white;
              @include flexbox;
              @include flex-flow(column);

              overflow-y: auto;

              .select-options {
                overflow-y: auto;

                > .budgeta-checkbox {
                  display: block;
                }
              }
            }
          }

          .popup-footer {
            @include centerElementsRight;

            background: $color-light-white;
            border-top: 1px solid rgba(0, 0, 0, 0.07);
            @include flex(0 0 65px);

            > .btn {
              margin-right: 12px;
            }
          }
        }
      }

      .input-popup {
        height: 381px;
        padding: 32px;
        position: relative;

        .popup-actions {
          top: 12px;
        }

        .popup-header {
          font-size: 32px;
          font-weight: 300;

          .help-iq {
            position: relative;
            bottom: 5px;

            .svg-icon .icon {
              width: 24px;
              height: 24px;
              fill: $color-steel;
            }
          }
        }

        .popup-content {
          margin-top: 50px;
          margin-right: 160px;
          width: 100%;

          .flex {
            @include flexbox;

            margin-top: 24px;

            label {
              @include flex-important(0 0 180px);

              border-bottom: 1px solid $color-silver;
              padding-right: 10px;
              line-height: 38px;
              margin-bottom: 0;
              font-size: 20px;
              color: $color-steel;
            }

            input {
              @include flex(1 1);

              line-height: 40px !important;
              padding-left: 0;
              height: 40px;
              border: none;
              outline: 0;
              border-bottom: 1px solid $color-silver;
              font-size: 18px;
              font-weight: 400;
            }

            .percentage {
              line-height: 39px;
              margin: 0 10px 0 -20px;
              color: $color-dark-grey-blue;
            }

            .select2-container {
              @include flex(1);

              border: none;
              border-bottom: 1px solid $color-silver;
              margin-top: 0;
              height: 40px;

              b::after {
                color: $color-dark-grey-blue;
              }

              .select2-chosen {
                line-height: 32px;
              }
            }
          }
        }

        .popup-footer {
          margin-top: 47px;
          @include flexbox;

          justify-content: flex-end;
        }

        &.popup-message {
          height: 100%;
          padding: 25px;

          .popup-content {
            margin: 0;
          }
        }
      }
    }

    > div.budgeta-dropdown {
      min-width: 192px;

      .add-option {
        .upload.transparent {
          height: 100%;

          input.files {
            height: 100%;
            width: 100%;
          }
        }
      }

      .addModelFromFileWrapper {
        border: none;
        width: 100%;
        height: 40px;

        .addModelFromFileText {
          padding: 6px 0 0 13px;
        }
      }
    }

    iframe {
      width: 400px;
      height: 100px;
      border: 0;
    }

    .tip-bottom {
      label {
        font-size: 0.8em;
      }
    }

    ul.budgeta-dropdown-list {
      &.budget-input-menu {
        .svg-icon.flag {
          &:not(.flagged) {
            opacity: 1;

            .icon {
              fill: $color-steel;
            }
          }

          &.flagged {
            .icon {
              fill: $color-topaz;
            }
          }
        }
      }

      &:not(.without-width-limit) {
        width: 192px;

        &.version-list {
          width: 205px;
        }
      }

      &:not(.without-padding) {
        padding: 10px 0;
      }

      max-height: 320px;
      overflow-y: auto;
      overflow-x: hidden;

      &.narrow-width {
        width: 115px;
      }

      &.large-width {
        width: 228px;
      }

      &.extra-large-width {
        width: 300px;
      }

      &.large-list-height {
        max-height: 420px;
      }

      .svg-icon {
        padding: 0;
        @include mid-text-steel;

        svg {
          margin-right: 8px;
          margin-left: 0;
          fill: $color-steel;
        }
      }

      &:not(.without-padding) {
        li:not(.without-padding) {
          padding: 0 18px;
          line-height: 40px;

          a {
            line-height: 40px;
          }
        }
      }

      &:not(.narrow-width) {
        li {
          .svg-icon-text {
            padding-left: 40px;
          }
        }
      }

      li {
        cursor: pointer;
        height: 40px;
        @include mid-text-steel;

        position: relative;

        &.inner-dropdown {
          > .budget-type-dropdown {
            width: 100%;

            > div {
              line-height: 40px;
            }
          }
        }

        &.disable {
          cursor: not-allowed;

          &:hover {
            * {
              color: $color-silver;
            }
          }

          *,
          *:hover {
            color: $color-silver;
            cursor: not-allowed !important;
          }

          .svg-icon {
            .icon {
              fill: $color-silver !important;

              &:hover {
                color: $color-silver;
                fill: $color-silver !important;
              }
            }
          }
        }

        &.empty-list {
          display: none;
        }

        div,
        a {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
        }

        a {
          line-height: 20px;
          padding: 0;

          &.change-log-content {
            margin-top: -2px;
          }
        }

        * {
          @include mid-text-steel;
        }

        > *:not(.drop-down):not(.budgetPath):not(.path-item-name):not(.path-item-name .display-account-ids) {
          display: inline-block;
        }

        &.none-seleceted {
          * {
            color: $color-dark-grey-blue;
          }
        }

        > .svg-icon {
          line-height: 29px;
          @include centerVElement;

          &.centerIcon {
            .icon {
              @include centerVElement;
            }
          }
        }

        .collapse-expand-component {
          margin-left: 4px;
        }

        &:not(.no-match):not(.hidden-inner):not(.heading):not(.path-list-element):not(.disable-hover-background):hover {
          background-color: rgba($color-weird-green, 0.25);
          @include mid-text-dark-blue-grey;

          *:not(li) {
            background-color: transparent !important;
            @include mid-text-dark-blue-grey;
          }

          .svg-icon {
            @include mid-text-dark-blue-grey;

            svg {
              fill: $color-dark-blue-grey !important;
            }
          }
        }

        &.disable-hover-background:hover {
          * {
            background-color: transparent;
          }

          svg {
            color: $color-steel;
          }
        }

        &.hidden-inner {
          ul {
            display: none;
          }

          &:hover {
            height: auto;

            * {
              background-color: transparent;
            }

            ul {
              display: block;

              li {
                background-color: #fff;

                &:hover {
                  background-color: rgba($color-weird-green, 0.25);
                }
              }
            }
          }
        }

        &.no-match {
          font-style: italic;
          font-weight: 300;
        }

        &.with-inner-content {
          > div {
            > * {
              padding: 0 18px;
            }

            width: 100%;
          }
        }
      }

      &.large-list-height {
        li {
          height: 49px;
        }
      }

      &.extra-large-list-height {
        li {
          height: 70px;
        }
      }

      &.large-width {
        width: 228px;

        li {
          padding: 10px 24px;
        }
      }

      &.side-icon {
        li {
          padding-left: 0;

          .icon-wrapper {
            position: relative;
            height: 100%;
            width: 72px;

            .svg-icon {
              width: auto;

              svg {
                margin-right: 0;
              }
            }
          }

          .text-wrapper {
            position: relative;
            height: 100%;
            max-width: calc(100% - 80px);

            .text-inner {
              > div {
                width: 100%;
              }

              .text-tag {
                max-width: 175px;
                @include small-text-steel;
              }

              .text-date {
                @include mid-text-grey-blue;
              }
            }
          }
        }

        &.versions {
          &.snapshots-only {
            li.auto-version {
              display: none;
            }
          }
        }
      }

      &.with-help-iq {
        width: 230px;

        li {
          position: relative;
          padding: 10px 50px 10px 24px;

          .help-iq {
            right: 5px;
            height: 26px;
          }
        }
      }
    }

    .versions-selectors {
      padding: 16px 24px;
      border-bottom: 1px solid $color-silver;

      div {
        @include mid-text-steel;
      }
    }

    div.add-option {
      * {
        @include mid-text-steel;
      }

      border-top: 1px solid #ddd;
      height: 68px;
      position: relative;

      &.disabled#add-new-budget {
        &:hover {
          cursor: not-allowed;
          background-color: inherit;
          color: $color-light-steel-gray;

          .icon-wrapper.disable-icon {
            .svg-icon.add-root-budget {
              svg.icon {
                fill: $color-light-steel-gray;
              }
            }
          }
        }

        .icon-wrapper {
          .svg-icon {
            svg {
              fill: $color-light-steel-gray;
            }
          }
        }

        .add-option-text {
          color: $color-light-steel-gray;
        }
      }

      &.disabled * {
        cursor: not-allowed;
      }

      .icon-wrapper {
        position: relative;
        width: 72px;
        height: 100%;
        display: inline-block;
        float: left;

        .svg-icon {
          @include mid-text-grey-blue;
          @include centerElement;

          svg {
            fill: $color-dark-grey-blue;
          }
        }
      }

      .add-option-text {
        float: left;
        display: inline-block;
        @include centerVElement;
      }

      &.without-icon {
        > .centerVElement {
          padding: 0 24px;
        }
      }

      &:hover {
        background-color: rgba($color-weird-green, 0.25);
        @include mid-text-grey-blue;

        * {
          background-color: transparent !important;
          @include mid-text-grey-blue;
        }

        .svg-icon {
          @include mid-text-grey-blue;

          svg {
            fill: $color-dark-grey-blue;
          }
        }
      }
    }
  }

  &.help-iq-tip {
    border-color: rgba(0, 0, 0, 0.05);
    @include border-radius(3px);
    @include box-shadow(0 4px 10px 0 rgba(0, 0, 0, 0.25));
  }

  &.qtip-light {
    background-color: rgba(#fff, 0.95);
    border: 0;
    @include box-shadow(0 5px 15px 0 rgba(0, 0, 0, 0.35));

    .qtip-content {
      padding: 0 20px;
      color: $color-dark-mint;
      line-height: 40px;
      font-size: 16px;
    }
  }

  &.white-tip {
    color: white;

    .qtip-content {
      padding: 0;

      .tip-content {
        padding: 0 6px;

        > div {
          font-size: 12px;
        }
      }
    }
  }

  &.remove-padding {
    .qtip-content {
      padding: 0;

      .overflow-ellipsis {
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }
}
