$color-green-sage-logo: #00d639;
$color-green-sage-darker: #007e45;
$color-green-sage-button-darker: #008146;
$color-green-background: #cdf6e4;
$font-color-black: #000;
$font-color: #334356;
$color-highlight: #ffce00;
$color-highlight-light-real: #fffae6;
$color-highlight-light: rgba(#ffce00, 0.1);
$color-warning-orange: #f2b146;
$color-strong: #ffce00;
$color-light: rgba($color-strong, 0.2);
$color-light-border: rgba($color-strong, 0.4);
$color-light-blue: #498fe1;
$color-blue: #347cd0;
$color-dark-blue: #354489;
$heading-color: #1abc9c;
$heading-border-color: #00a58a;
$tree-background-selected: rgba($heading-color, 0.4);
$tree-background-hover: rgba(#8fcfc2, 0.4);
$color-green: $heading-border-color;
$color-light-steel-gray: #ccc;
$color-light-green: #e7efed;
$color-dark-green: #14967c;
$color-darker-green: #436163;
$color-light-gray: #f3f3f3;
$color-gray-1: #bcbcbc;
$color-dark-gray: #a0a0a0;
$color-error: #a94442;
$color-error-dark: #a94442;
$color-mercury: #e6e6e6;
$color-bay-of-many: #283d95;
$color-san-marino: #4d5ea6;
$color-vanilla: #8fcfc2;
$color-move: #57ccb4;
$color-topaz: #1abc9c;
$color-topaz-highlight: #2ee5a8;
$color-topaz-highlight-dark: #92eed6;
$color-topaz-highlight-lighter: #ebfbf5;
$color-topaz-highlight-light: #caf8ec;
$color-dark-blue-grey: #132437;
$color-dark-grey-blue: #334356;
$color-mint-tulip: #bff3dd;
$color-dark-mint: #50b883;
$color-dark-mint-two: #59bc8a;
$color-weird-green: #38da95;
$color-weird-green: #39da95;
$color-weird-green-light: #d5f5e5;
$color-silver: #bbbfc3;
$color-blue-changes: #44a3e5;
$color-red: #ff5e4c;
$color-red-dark: #f53434;
$color-error: #ee7e7c;
$color-error-light: #f29c9a;
$color-error-red-light: rgba(245, 52, 52, 0.1);
$color-duck-egg-blue: #e2faf0;
$color-dark-sky-blue: #44a3e5;
$color-french-blue: #4374bb;
$color-dashboard-yellow: #fdd700;
$color-budget-line-border: #39db95;
$color-cerulean: #0283d0;
$color-salmon: #ff7272;
$color-green-blue: #00bd9c;
$color-light-teal: rgb(164, 240, 207);
$color-dropdown-hover: rgba(56, 218, 149, 0.25);
$color-turquoise: #75e2e4;
$color-olive: #9cd25e;
$color-orange: #f08e44;
$color-purple: #9691f5;
$color-pink: #db68c9;
$color-turquoise-dark: #dbf0f1;
$color-olive-dark: #dbe6d0;
$color-orange-dark: #f2dfd2;
$color-purple-dark: #dad9ec;
$color-pink-dark: #eed8eb;
$color-turquoise-light: #e3f9fa;
$color-olive-light: #ebf6df;
$color-orange-light: #fce8da;
$color-purple-light: #eae9fd;
$color-pink-light: #f8e1f4;
$color-regular-white: #fff;
$font-color-white: #fff;
$color-steel-grey: #7c7f88;
$color-steel: #878d94;
$color-light-steel: #a1a5aa;
$color-cool-grey: #959ba1;
$color-black: #111;
$color-black-darker: #000;
$color-ivory: #f7f7fa;
$color-white: #ebebeb;
$color-white-light-grey: #eee;
$color-white-soft-grey: #eaeaea;
$color-soft-grey: #e6e6e6;
$color-mid-grey: #d8d8d8;
$color-grey-mouse: #888494;
$color-light-dark-grey: #cfcfcf;
$color-dark-grey: #c3c3c3;
$color-odd-actuals: #c6c8cb;
$color-even-actuals: #dbdcdd;
$color-actuals: #e1e2e3;
$color-softer-grey: #e1e1e1;
$color-light-steel-gray: #ccc;
$color-forecast-border: #979797;
$color-grey-dark-black: #4a4a4a;
$color-grey-light-black: #595959;
$color-charcoal-grey: #3c3d4c;
$color-light-white: #f3f2f3;
$color-light-grey: #fafafa;
$color-light-mid-opacity-grey: #f5f5f5;
$color-light-mid-grey: #f6f6f6;
$color-light-soft-grey: rgba(234, 234, 234, 0.35);
$color-grey-blue-input-border: #668494;
$color-green-login-password-link: #008146;
$color-grey-border: #fdfdfd;
$color-mid-grey-border: #ededed;
$color-shadow-box: #00000040;
$color-shadow-box-light: #0003;
$color-grey-background: #f2f5f6;
$color-grey-divider: #ccd6db;
$color-grey-border-light: #99adb7;

/* rebrand 2023 */
:root {
  /* colors */
  --color-gray-101: #e6ebed;
  --color-gray-102: #ccd6db;
  --color-gray-103: #0000004d;
  --color-blue-101: #335b70;
  --color-green-101: #007e45;

  /* fonts */
  --default-font-size: 18px;
  --bold-font-letter-spacing: 0.2px;

  /* lists */
  --list-item-hover-color: rgba(57, 218, 149, 0.25); /* var(--color-gray-101) */
  --list-item-active-color: rgba(57, 218, 149, 0.25); /* var(--color-gray-102) */
  --list-item-text-color: rgba(0, 0, 0, 0.9);
  --list-item-icon-color: rgba(0, 0, 0, 0.65);
  --list-item-font-size: var(--default-font-size);
  --list-item-line-height: normal;
}
