.finalize-mapping-intro-body-container {
  padding: 50px 0;

  > img {
    margin-top: 25px;
  }

  .description {
    margin-top: 25px;
    text-align: left;
  }

  .sub-header {
    margin-top: 5px;
    margin-bottom: 20px;
  }
}
