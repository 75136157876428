/* stylelint-disable max-nesting-depth, declaration-no-important */
.models {
  .quick-links {
    margin-left: 20px;
  }

  label {
    width: auto !important;
  }

  h1 {
    color: $color-bay-of-many;
    font-size: 21px;
    line-height: 28px;
    font-weight: bold;
    width: 100%;
    margin-top: 30px;
    margin-left: 20px;
    margin-bottom: 0;
  }

  h3 {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    width: 100%;
    margin-top: 6px;
    margin-left: 20px;
  }

  .scrollable {
    height: calc(100% - 143px) !important;

    ul.model-list {
      li {
        * {
          font-size: 12px;
        }

        &::after {
          content: "";
          display: block;
          clear: left;
        }

        div.column {
          float: left;
          width: 20%;

          &.auto {
            width: auto;
            margin-left: 5px;
          }

          &.column-name {
            font-weight: 400;
          }
        }

        button {
          line-height: 20px;
        }

        a {
          color: $color-bay-of-many;
        }

        button,
        a,
        .icon {
          opacity: 0;
        }

        &:hover {
          background-color: $tree-background-hover;

          button,
          a,
          .icon {
            opacity: 1;
          }
        }
      }
    }
  }
}
