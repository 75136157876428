.filters {
  background-color: $color-light-mid-opacity-grey;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 71px;

  .dropdown-option-checkbox .sibp-checkbox {
    label {
      display: flex;
      margin-bottom: 0;
      font-weight: 400;
      margin-left: 0;

      &::before {
        min-width: 19px;
        width: 19px;
        height: 19px;
      }
    }
  }

  .dropdown-search {
    .search-box {
      .search-box-input {
        border: none;

        &:focus {
          outline: 1px solid $color-weird-green;
        }
      }
    }
  }
}

.filter-dropdown {
  display: flex;
  align-items: stretch;
  line-height: 1.5;
  margin: auto 0;

  &.dropdown-with-separator::after {
    display: inline-block;
    position: relative;
    background-color: $color-light-steel-gray;
    width: 1px;
    content: "";
  }
}

.visibility-toggle-dropdown {
  margin-left: 15px;

  .visibility-content {
    /* move visibilty dropdown to the left of its content,
    to the right by default, could be cut by the page right end */
    transform: translateX(-80%);
  }
}
