.performance-logger-container {
  .charts-container {
    margin: 10px;
  }

  position: fixed;
  top: 0%;
  right: 0%;
  width: 500px;
  height: 100%;
  background-color: $color-light-gray;

  .info {
    max-height: 1400px;
    overflow: scroll;

    .open-log-button {
      line-height: 22px;
      border-radius: 1px;
      text-align: center;
    }

    .cotainer-with-button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 7px;
    }

    .numbers {
      margin-left: 7px;
    }

    .chart-box {
      min-height: min-content;
      display: flex;
      flex-direction: column;
    }

    .close-button {
      display: flex;
      justify-content: center;
    }
  }
}
