// These rules exist due to base rules in budgeta-global being
// nested under html:not(.admin-application). Creates a lot of
// specificity, and requires 3 levels to override

.body-container {
  .hierarchy-nav-container {
    label.large-text {
      @include large-text;
    }
  }
}

.tree-structure-container {
  .expand-collapse:not(#specificity-hack) {
    font-size: 18px;
  }
}

.noty-container.info {
  .noty-text,
  .noty-close {
    color: white;
  }
}
