/* stylelint-disable  */
.admin-menu {
  padding-top: 10px;

  form {
    display: inline-block;
    vertical-align: middle;

    > * {
      float: left;
    }

    .upload {
      margin: 0;
      width: 250px;

      &::before {
        content: "Import Customer's Budget";
      }
    }

    input[type="file"] {
      margin: 0;
    }

    button {
      line-height: 26px !important;
    }

    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }
}

.ember-basic-dropdown-content {
  color: $color-steel;

  .ember-power-select-option[aria-current="true"] {
    color: $font-color;
  }
}

.admin-top {
  margin-bottom: 20px;

  label {
    margin: 0 10px 0 0;
    line-height: 35px;
  }

  a {
    line-height: 35px;
  }

  &,
  > div {
    @include flexbox;
  }

  > div {
    @include flex(1 1 auto);
  }

  > div:not(:first-child) {
    margin-left: 20px;
  }

  .ember-power-select-trigger {
    border: 1px solid $color-white;
    width: 150px;
    margin-right: 10px;
    @include flexbox;

    .ember-power-select-placeholder,
    .ember-power-select-selected-item {
      line-height: inherit;
      color: $heading-border-color;
      margin-left: 8px;
      width: 150px;
      @include text-ellipsis;
    }

    .ember-power-select-clear-btn {
      top: 6px;
    }

    &::after {
      top: 5px !important;
    }
  }
}

.admin-date-type {
  div {
    display: inline-block;

    &:not(:first-child):not(:last-child) {
      width: 70px;
      text-align: center;

      &:not(:nth-last-child(2)) {
        border-right: 2px solid $color-dark-grey-blue;
      }
    }

    font-size: 18px;
    font-weight: 400;

    &:not(.active) {
      color: $color-dark-grey-blue;
    }

    &.active {
      color: $color-dark-mint;
    }
  }
}

.admin-page {
  overflow-x: auto !important;
}

table.admin-table {
  thead td {
    font-weight: 600 !important;
    border-bottom: 2px solid #444;
  }

  td {
    vertical-align: top;
    padding: 2px 10px;

    &:not(:first-child) {
      border-left: 1px solid #ebebeb;
    }

    border-bottom: 1px solid #ebebeb;

    .svg-icon.add svg {
      fill: $color-dark-mint !important;
    }
  }
}

div.admin-table {
  height: calc(100% - 50px);
  position: relative;

  .admin-scrollable {
    height: calc(100% - 60px);
    position: relative;

    .ember-view > div > div {
      display: flex;
    }
  }

  div.admin-table-header {
    display: flex;
    font-weight: 600 !important;
    border-bottom: 2px solid #444;
  }

  .column {
    width: 9%;

    &.wide {
      width: 12%;
    }

    &.narrow {
      width: 5%;
    }

    &.centered {
      text-align: center;
    }
  }
}

.admin-activity-content {
  @include flexbox;

  height: calc(100% - 79px);

  .admin-table-wrapper {
    padding: 10px;
    height: 700px;
    @include flex(1);

    .ember-list-view {
      border-bottom: 1px solid;
    }

    .user-row {
      @include flexbox;

      border-right: 1px solid;
      border-top: 1px solid;
      border-left: 1px solid;
      white-space: nowrap;

      div {
        &:not(:last-child) {
          border-right: 1px solid;
        }

        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 40px;
        padding-left: 5px;
        @include flex(1);
      }
    }
  }

  .user-actions-table {
    @include flex(0 0 350px);

    .user-row {
      .user-name {
        cursor: pointer;
      }

      .user-action-num {
        @include flex(0 0 85px);
      }
    }
  }

  .activity-charts {
    @include flex(1);

    margin: 20px;

    .chart {
      background: white;
      margin: 15px;
      position: relative;
      min-width: 550px;
      width: 550px;
      @include box-shadow(0 3px 6px rgba(0, 0, 0, 0.25));

      height: 400px;

      .chart-header {
        height: 72px;
        position: relative;
        padding: 10px 24px;
        border-bottom: 1px solid $color-silver;
        @include flexbox;

        align-items: center;

        .chart-title {
          @include large-text-grey-blue;

          margin-right: 5px;
        }

        .chart-range-type {
          padding-left: 20px;
          display: inline-block;

          > * {
            display: inline-block;
            padding: 0 10px;

            &:not(.active) {
              color: $color-dark-grey-blue;
            }

            &.active {
              color: $color-dark-mint;
            }
          }
        }
      }

      .chart-content {
        text-align: center;
        padding: 24px;
      }
    }
  }
}
