/* stylelint-disable max-nesting-depth, font-family-no-missing-generic-family-keyword */
.modal-container.add-actual-accounts,
.modal-container.add-dimension-value-mapping {
  .modal {
    .modal-dialog {
      min-width: 850px;

      .modal-content {
        .modal-body {
          padding-bottom: 0;

          .wizard-question-title {
            font-size: 22px;
          }

          .tab-content {
            position: relative;
          }

          .budgeta-checkbox {
            &:not(.add-another) {
              width: 100%;
              margin-top: 40px;

              label {
                font-size: 22px;
              }
            }

            &.add-another {
              position: absolute;
              bottom: -10px;
              right: -37px;
            }
          }

          .flex {
            label + div {
              max-width: calc(100% - 165px);
            }

            > .multi-select-dropdown {
              border-bottom: 1px solid #eee;
              max-height: 180px;
              overflow-y: auto;
            }

            > .select2-container {
              height: 100%;
            }
          }

          .account-mapping-menu {
            border-bottom: 1px solid #eee;

            .mapping-display {
              line-height: 40px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-right: 20px;
              padding-left: 0;
            }
          }

          .flex > .multi-budget-lines-mapping {
            &:not(.single-mapping) {
              max-width: calc(100% - 165px);
            }
          }

          .multi-budget-lines-mapping {
            border-bottom: 1px solid #eee;

            .ember-basic-dropdown-trigger {
              border: none;

              span {
                line-height: 40px;
              }
            }

            &.single-mapping {
              height: 40px;

              .select-2-lazy-inactive-label {
                display: inline-block;
                width: 100%;
                padding-right: 20px;
                height: 40px;
                line-height: 40px;
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.placeholder {
                  color: $color-dark-mint;
                }

                &::after {
                  content: "\f107";
                  color: #adadad;
                  font-family: FontAwesome;
                  font-weight: bold;
                  font-size: 16px;
                  display: block;
                  position: absolute;
                  right: 5px;
                  top: 7px;
                  transition: all 0.4s ease;
                  pointer-events: none;
                }
              }
            }

            .selection {
              padding-right: 20px;
            }
          }

          .dobule-flex {
            .flex {
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  &.add-dimension-value-mapping {
    .modal-body {
      .add-accounts-dimension-mapping {
        .margin-top-40 {
          margin-top: 40px;
        }

        .disabled-radio-button {
          pointer-events: none;
          opacity: 0.4;
        }

        padding-bottom: 20px;
      }
    }
  }
}

// elegant modal
.elegant-modal.add-actual-accounts {
  .add-account-content {
    padding-top: 45px;

    .account-mapping-menu {
      border-bottom: 1px solid $color-white-light-grey;
      height: auto;

      .mapping-display {
        line-height: 40px;
        @include text-ellipsis;

        padding-right: 20px;
        padding-left: 0;

        &::after {
          right: 10px;
        }
      }
    }

    .multi-budget-lines-mapping {
      border-bottom: 1px solid $color-white-light-grey;

      .ember-basic-dropdown-trigger {
        border: none;

        span {
          line-height: 40px;
        }

        .ember-power-select-placeholder {
          margin-left: 0;
          color: $heading-border-color;
        }
      }

      .ember-basic-dropdown-trigger,
      .ember-power-select-placeholder,
      .path-list-element-lazy {
        &::after {
          right: 10px;
        }
      }

      &::after {
        right: 10px;
      }

      &.single-mapping {
        height: 40px;

        .select-2-lazy-inactive-label {
          display: inline-block;
          width: 100%;
          padding-right: 20px;
          height: 40px;
          line-height: 40px;
          position: relative;
          @include text-ellipsis;

          &.placeholder {
            color: $color-dark-mint;
          }

          &::after {
            content: "\f107";
            color: $color-light-steel;
            font-family: FontAwesome;
            font-weight: bold;
            font-size: 16px;
            display: block;
            position: absolute;
            right: 5px;
            top: 7px;
            transition: all 0.4s ease;
            pointer-events: none;
          }
        }

        .path-list-element-lazy {
          &::after {
            top: 2px;
          }
        }
      }

      .selection {
        padding-right: 20px;
      }

      &:hover {
        background-color: rgba($color-weird-green, 0.05);
      }
    }
  }
}
