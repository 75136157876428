$top-nav-height: 48px;
$app-footer-height: 32px;

[data-path="main.budget.import-generic-file"] {
  .inner-content {
    > .import.simple {
      top: $top-nav-height;
      bottom: $app-footer-height;
      height: calc(100% - #{$top-nav-height+$app-footer-height});
    }
  }
}
