/* stylelint-disable max-nesting-depth, declaration-no-important */
.advanced-benefits {
  .benefits-list {
    li {
      @include flexbox;

      justify-content: space-between;
      border-bottom: 1px solid #eee;
      width: 400px;
      margin-bottom: 10px;

      .svg-icon {
        line-height: 40px;

        .icon {
          fill: $color-steel;
        }

        &:hover {
          .icon {
            fill: $color-dark-grey-blue;
          }
        }
      }

      .inline-edit-tag-wrapper {
        width: calc(100% - 140px);
        display: inline-block;
        float: left;

        label {
          line-height: 40px;
          flex: 0 0 220px !important;
          width: auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.inherited {
        .inline-edit-tag-wrapper {
          label {
            color: $color-dark-mint;
          }
        }

        &.deleted {
          label {
            text-decoration: line-through;
          }
        }
      }
    }
  }

  button {
    height: 40px;
  }
}

.budget-attributes-wrapper {
  .advanced-benefits {
    button {
      margin-left: 0;
    }

    ul.benefits-list > li {
      border-bottom: 1px solid $color-silver;

      label {
        font-size: 20px;
      }
    }
  }
}
