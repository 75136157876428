/* stylelint-disable selector-class-pattern */
.user-edit-container {
  .details-div {
    display: flex;

    & > div {
      margin-right: 100px;
      min-width: 0;
      width: calc((100% - 200px) * 0.33);
      @media screen and (min-width: 1600px) {
        width: calc((100% - 300px) * 0.25);
      }
    }

    .input-container {
      margin-bottom: 20px;

      .base-input-container {
        width: 100%;

        input,
        .error-message {
          width: 100%;
        }
      }
    }

    .dropdown-container {
      select {
        width: calc(100% + 20px);
      }
    }

    .day-picker-container {
      .DayPickerInput {
        display: block;
      }

      input {
        width: 100%;
      }
    }
  }

  .checkbox-container {
    margin-top: 20px;
    display: flex;

    .sibp-checkbox {
      padding-right: 5px;
    }
  }

  .page-footer {
    display: flex;
    justify-content: space-between;

    .left-side {
      display: flex;
      align-items: center;
    }

    .cancel-btn {
      margin-right: 10px;
    }
  }

  .close {
    cursor: pointer;
  }
}
