.user-profile-container {
  @include flexbox;

  flex-direction: column;
  height: 100%;
  padding: 0 30px;

  .header {
    @include flexbox;

    align-items: center;
    justify-content: space-between;
    height: 40px;
  }

  .header-container {
    width: 100%;
    @include flexbox;

    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .update-password-message {
    font-size: 18px;
    margin-bottom: 25px;
    color: $color-gray-3;
  }

  .update-password-notice-message {
    margin-bottom: 25px;

    .alert-container {
      .alert-text {
        padding: 10px 20px 12px;
        white-space: break-spaces;
      }
    }
  }

  .profile-details,
  .account-details,
  .change-password {
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: $color-blue-3;
  }

  .user-profile-full-name {
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-blue-3;
  }

  .body {
    flex: 1;
    @include flexbox;

    padding-top: 20px;
    overflow-y: auto;
    flex-direction: column;

    .tab {
      a {
        font-size: 16px;
      }
    }
  }

  .footer {
    @include flexbox;

    justify-content: flex-end;
    border-top: 2px solid $color-gray-4;
    padding: 20px 30px 0 0;

    button {
      margin-left: 20px;
    }
  }
}
