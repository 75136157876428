/* stylelint-disable declaration-no-important */
h1 {
  @include font(32, 100);
}

h2 {
  @include font(26, 400);

  letter-spacing: 0.3px;
  padding-bottom: 20px;
}
