/* stylelint-disable selector-class-pattern */
.company-user-edit-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  .body {
    flex: 1;

    .user-details {
      display: flex;

      & > div {
        margin-right: 100px;
        min-width: 0;
        width: calc((100% - 200px) * 0.33);
        @media screen and (min-width: 1600px) {
          width: calc((100% - 300px) * 0.25);
        }
      }
    }

    .checkbox-container {
      display: flex;
      margin-top: 20px;

      .sibp-checkbox {
        padding-right: 5px;
      }
    }

    .input-container {
      margin-bottom: 20px;

      .base-input-container {
        width: 100%;

        input,
        .error-message {
          width: 100%;
        }
      }
    }

    .dropdown-container {
      select {
        width: calc(100% + 20px);
      }
    }

    .day-picker-container {
      .DayPickerInput {
        display: block;
      }

      input {
        width: 100%;
      }
    }
  }

  .page-footer {
    display: flex;
    justify-content: space-between;

    .left-side {
      display: flex;
      align-items: center;
    }
  }
}
