/* stylelint-disable selector-id-pattern */
.import-column-menu {
  position: relative;
  top: -2px;
  right: -10px;

  &:not(.blue-dots) .icon {
    fill: $color-steel;
  }

  &.blue-dots {
    .icon {
      fill: $color-dark-grey-blue;
    }
  }

  #columnsMenu:hover {
    svg.icon {
      fill: $color-dark-blue-grey;
    }
  }
}

.import-column-dropdown.drop-down {
  width: 210px;

  &.wide {
    width: 230px;
  }

  .disabled {
    opacity: 0.4;
  }
}
