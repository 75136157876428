/* stylelint-disable max-nesting-depth */
.generic-wizard-container {
  display: flex;
  justify-content: center;

  .react-section {
    .generic-wizard-section {
      background: $color-light-mid-opacity-grey;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      height: 100%;
      width: 300px;
      z-index: 2;
      position: absolute;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .progress-footer {
        display: flex;
        flex-direction: column;

        .file-upload {
          padding: 20px;
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid $color-white;

          .title {
            color: $color-steel;
          }

          .file-name {
            color: $color-dark-grey-blue;
            font-weight: 400;
          }
        }

        #cancel-container {
          margin: 20px;
          display: flex;
          align-items: center;
          gap: 5px;

          &:hover {
            cursor: pointer;

            svg {
              fill: $color-dark-grey-blue;
            }

            .back {
              color: $color-dark-grey-blue;
            }
          }

          svg {
            fill: $color-steel;
          }

          .back {
            padding: 0;
          }
        }
      }

      .progress-div {
        border-bottom: 1px solid $color-white;

        .finalize-arrow-right {
          position: absolute;
          right: 8px;
          top: 315px;

          svg {
            fill: $heading-border-color;

            path {
              fill: $heading-border-color;
            }
          }
        }

        .tree-container {
          background-color: $color-regular-white;
          padding: 22px 20px;

          .folder {
            margin: 0;

            .folder-label {
              cursor: default;

              .text-icon-container {
                display: flex;
                align-items: center;
                gap: 18px;

                .text {
                  color: $heading-border-color;
                }

                path {
                  fill: $heading-border-color;
                }
              }
            }

            .collapsible {
              padding: 30px 0 0 55px;
              display: flex;
              flex-direction: column;
              gap: 28px;

              .file {
                .text-icon-container {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  svg {
                    width: 18px;
                    height: 18px;
                  }

                  &.active {
                    .text {
                      color: $heading-border-color;
                    }

                    path {
                      fill: $heading-border-color;
                    }
                  }

                  &.prev {
                    .text {
                      color: $color-dark-grey-blue;
                    }

                    path {
                      fill: $color-dark-grey-blue;
                    }
                  }

                  &.next {
                    .text {
                      color: $color-steel;
                    }

                    path {
                      fill: $color-steel;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &:not(.table-view) {
      .generic-manager {
        left: 300px;
        width: calc(100% - 300px);
        height: 100%;
        position: absolute;
      }
    }

    &.table-view {
      .generic-manager {
        bottom: 0;
        position: absolute;
        left: 300px;
        background-color: white;
        height: 60px;
        right: 0;

        .footer-container {
          min-height: 60px;
          height: 60px;
          padding-right: 75px;
          background-color: white;
        }
      }
    }
  }
}
