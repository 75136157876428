/* stylelint-disable max-nesting-depth, no-duplicate-selectors, selector-class-pattern, declaration-no-important */
#budget-attributes-wrapper {
  clear: both;
}

.budget-attributes-wrapper {
  padding: 10px 0;

  a {
    &.fa::before {
      margin-right: 3px;
    }

    &.right {
      float: right;
    }
  }

  textarea {
    resize: vertical;
  }

  button:not(:first-child) {
    margin-left: 10px;
    line-height: 31px;
  }

  .tag-list-wrapper {
    width: 100%;
    @include flexbox;

    .tag-list {
      @include flex(1 1 50% !important);

      &.empty {
        @include flex(1 1 0 !important);
      }

      span.tag {
        display: inline-block;
        font-size: 20px;
        height: 35px;
        line-height: 35px;
        background-color: $color-weird-green-light;
        padding: 0 5px;
        margin: 1px 0 5px 5px;
        white-space: nowrap;
      }
    }

    .select2-container {
      @include flex(1 1 100% !important);

      width: auto;
      min-width: 200px;
    }
  }

  .multi-select {
    .select2-container {
      display: block !important;
    }
  }

  .sticky-wrapper {
    width: 100% !important;
    max-width: 100% !important;
  }

  .force-unhide {
    display: flex !important;
  }

  .attribute-hidden,
  .attribute-group-hidden {
    &:not(.keep-space) {
      display: none !important;
    }

    &.keep-space {
      visibility: hidden;
    }
  }

  .attribute-allocated {
    .data-row {
      p.input-error {
        top: -10px;
        right: 0;
        left: auto;
      }
    }

    div.error {
      text-align: right;
    }
  }

  .attribute-assumption {
    label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .modifier {
    .select2-container {
      max-width: 100px;
      width: 100px;
    }

    .dropdown {
      max-width: 45px;
    }
  }

  .data-row {
    @include flexbox-important;

    position: relative;
    width: 100%;

    label.wide + select + div.selectize-control {
      width: calc(100% - 160px);
    }

    div.remove-line {
      display: none;
      position: absolute;
      z-index: 1;
      opacity: 0.8;
      line-height: 35px;
      right: -25px;
      font-weight: 400;
    }

    &.amount-change {
      margin-top: 5px;

      .currency {
        input {
          width: 100%;
        }
      }
    }

    &.by-geo.has-error {
      padding-bottom: 15px;

      p.input-error {
        left: 10px;
        bottom: -12px;
      }
    }

    .currency {
      width: 50%;
    }

    .right {
      label {
        margin-bottom: 0;
        line-height: 35px;
        display: inline-block !important;
      }

      .month-picker-wrapper {
        width: 90px !important;
        display: inline-block !important;
      }
    }

    .assumption-name {
      @include flex(1 1 auto);
    }

    .assumption-comment {
      @include flex(2 2 auto);
    }

    &:hover {
      div.remove-line {
        display: block;
      }
    }
  }

  .by-geo {
    .data-row {
      position: relative;

      div.remove-line {
        left: 155px;
        right: auto;
      }

      .second-input input {
        padding-right: 70px;
      }

      .formula-editor-button {
        position: absolute !important;
        right: 0;
        top: 0;
      }

      label.percent-sign {
        position: absolute;
        right: 40px;
      }
    }
  }

  .dropdown [data-toggle="dropdown"] {
    max-width: none;
  }

  [data-budget-module],
  [data-budget-module] .panel-body,
  [data-budget-module] .panel-body .section-row {
    @include flexbox-important;

    flex-wrap: wrap;
    padding-top: 0;

    &::before,
    &::after {
      display: none;
    }

    > div:not(.panel):not(.children-grid):not(.section-row):not(.advanced-benefits) {
      position: relative;
      @include flexbox;

      &.wrap {
        flex-wrap: wrap;

        label {
          white-space: normal !important;
        }
      }

      .select2-container {
        height: auto;
      }

      .help-iq {
        line-height: 40px;
        margin-left: 5px;
      }

      .svg-icon.add-margin-top {
        svg {
          margin-top: 8px;
        }
      }

      > label:not(.error) {
        white-space: nowrap;

        &.advancedExpense,
        &.advancedRevenue {
          display: flex;
          align-items: center;
          white-space: normal;
          line-height: normal;
        }
      }

      > label.error {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        position: absolute;
        bottom: -30px;
        left: 10px;
      }

      > div:not(.has-formula-button) > input {
        width: 100%;
      }

      > div.data-row {
        .percent {
          min-width: 60px;
        }
      }

      > div.add {
        line-height: 35px;
        padding-left: 10px;
      }

      &:not(.full) {
        > div:not(.data-row):not(.ignore-flex),
        > input {
          @include full-flex-values(1 1, auto);
        }

        margin-top: 15px;
      }

      &.wrap {
        flex-wrap: wrap;

        &:not([id^="attribute-customAmounts"]) {
          -ms-flex-pack: justify;
          justify-content: space-between;
        }

        label:not(.error) {
          height: auto;
        }
      }

      &.full {
        &:not(.no-flex-children) > div:not(.custom-amount):not(.select2-container-multi) {
          @include flexbox-important;

          label {
            min-width: 85px;
          }

          > div {
            @include flex(1 1 auto);
          }
        }
      }

      margin-right: 36px;

      &.full {
        margin-top: 15px;
        margin-right: 0;
        width: 100%;
        min-width: 60%;
        max-width: 960px;
        flex-basis: 100% !important;
      }
    }

    &:not(.disable-media) {
      @media screen and (max-width: 1500px) {
        > div:not(.panel):not(.children-grid):not(.section-row):not(.buttons):not(.ignore-budgeta-style) {
          margin-right: 24px;
        }
      }
    }

    &.section-row {
      width: 100%;

      > div:not(.panel):not(.children-grid):not(.section-row) {
        &:not(.full):not(.by-geo) {
          width: calc(33.33% - 10px);
          max-width: calc(33.33% - 10px);
        }
      }

      input.ember-text-field {
        @include flex(1 1 !important);
      }

      .budgeta-type-value.budgeta-type-value-add:not(.budgeta-type-value-add-block) .list-add-line .add-icon-list {
        bottom: 15px;
      }

      .budgeta-type-value.budgeta-type-value-add-block .add-icon-wrapper {
        bottom: 12px;
      }
    }

    > div.panel {
      width: 100%;

      &.section- {
        margin-bottom: 0;
      }
    }

    &:not([data-budget-module="assumption"]) {
      > div.panel:not(.budgeta-fix-assumptions):not(.section-) {
        margin-top: 30px;
      }
    }

    &[data-budget-module="assumption"] {
      .panel-body {
        padding-bottom: 0;
      }
    }
  }

  button.add-model-line-btn,
  button.add-assumption-btn {
    margin-bottom: 20px;
    width: auto;

    .svg-icon {
      width: 18px;

      svg {
        fill: $font-color-white;
        height: 18px;
      }
    }
  }

  button.add-assumption-btn {
    margin-left: 25px;
  }

  button.add-model-line-btn {
    margin-left: 0;
  }
}

.main-content .inner-content form,
.modal.add-employee-modal-dialog {
  .panel-default {
    border: 0;
    background-color: transparent;

    > .panel-heading {
      padding: 0;
      background: none;
      position: relative;
      border-bottom: 0;

      @include font-sidebar;

      a {
        display: inline-block;
        position: relative;
        text-transform: uppercase;
      }

      .accordion-toggle {
        @include section-header;
      }

      &.collapsed a::after {
        @include transform(rotate(180deg));
      }
    }
  }
}
