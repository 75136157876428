/* stylelint-disable selector-class-pattern, declaration-no-important */
.display-account-ids {
  @include text-ellipsis;

  width: auto;

  &.inputs-display {
    @include flexbox;

    margin-right: 10px;
    height: 35px;

    .wrapper,
    .spliter,
    .value-display,
    .value-display-first {
      font-size: 18px;
    }

    .wrapper {
      color: $color-steel;
    }

    .spliter {
      color: $color-steel;
      margin: 0 2px;
    }

    .value-display,
    .value-display-first {
      color: $font-color;
    }

    &.link:hover {
      border-bottom: 1px solid $font-color;
    }
  }

  &.sidebar-accounts {
    @include flexbox;

    margin-top: 1px;

    &.show-parenthesis {
      .wrapper,
      .spliter,
      .value-display,
      .value-display-first {
        font-size: 14px !important;
      }
    }

    .wrapper,
    .spliter,
    .value-display,
    .value-display-first {
      color: $font-color;
    }

    .spliter {
      margin: 0 2px;
    }

    .value-display-first {
      @include text-ellipsis;

      min-width: 15px;

      &.short {
        min-width: 5px;
      }
    }

    &.sheets {
      margin-top: 2px;
      margin-right: 5px;
      padding-left: 5px;
    }

    &.tableView {
      margin-top: 0;
    }
  }
}
