/* stylelint-disable max-nesting-depth, selector-class-pattern, declaration-no-important */
#actuals-accounts-table {
  height: 100%;
  @include flexbox;

  flex-direction: column;
  overflow-y: hidden;
  width: 800px;
  background-color: #fff;

  > .actuals-accounts-header {
    @include flex(0 0 46px);
  }

  > .actuals-accounts-lines {
    @include flex(1 1);

    overflow-y: scroll;
  }

  .actuals-accounts-row {
    min-height: 45px;

    &.header {
      .actuals-accounts-column {
        position: relative;
        font-weight: 600;
        line-height: 45px;

        .collapse_expand_wrapper {
          padding-left: 15px;
        }
      }
    }
    @include flexbox;

    .actuals-accounts-column {
      &:first-child {
        border-right: 2px solid #ededed;

        .svg-icon {
          .icon {
            width: 15px;
            height: 15px;
          }
        }

        .actuals-accounts-name {
          line-height: 45px;

          &.line-collapse {
            .svg-icon {
              @include transform(rotate(-90deg));
            }
          }
        }
      }

      &:last-child {
        padding-left: 5px;
      }

      border-bottom: 1px solid #ededed;
      @include flex(1);

      input {
        border: none;
        width: 100%;
        line-height: 45px;
        height: 45px;
        background-color: transparent;

        &:focus {
          outline: none !important;
        }

        &.unallowed-value {
          background-color: $color-error;
          color: #fff;
        }
      }

      .budgeta-type-value {
        label {
          display: none;
        }

        .add-icon-list {
          margin-top: 5px;
          margin-bottom: 5px;
        }

        .list-add-line {
          position: relative;

          .remove-line {
            top: 10px;
          }
        }

        .remove-line {
          position: absolute;
          right: 15px;
        }
      }

      .data-rows-wrapper {
        .data-row {
          position: relative;
          border-bottom: 1px solid #ededed;

          .remove-line {
            @include centerVElement;
          }
        }
      }
    }
    @for $i from 0 to 10 {
      &.level-#{$i} {
        .actuals-accounts-column:first-child {
          .actuals-accounts-name {
            padding-left: $i * 20px;
          }
        }
      }
    }

    &.focus:not(.collapse) {
      .actuals-accounts-column {
        background-color: $color-weird-green-light;

        input {
          &:focus {
            outline: 1px solid $color-weird-green !important;
          }
        }
      }
    }
  }

  &.bs-accounts {
    .actuals-accounts-row.level-0 {
      .actuals-accounts-column {
        &:first-child {
          .actuals-accounts-name {
            font-weight: 600;
          }
        }
      }
    }
  }
}

body:not(.hiddenscroll) {
  #actuals-accounts-table {
    > .actuals-accounts-header {
      padding-right: 15px;
    }
  }
}
