/* stylelint-disable no-duplicate-selectors, declaration-no-important */
.quick-links {
  display: inline-block;
  float: none;
  margin: 12px auto 0;
  height: 38px;

  &::after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid #e2e2e2;
  }

  li {
    z-index: 1;
    height: 100%;

    &:hover {
      background: none;
    }

    a {
      color: #a0a0a0;
      font-weight: 400;
      font-size: 16px;
      line-height: 35px;
      padding: 0 18px;

      &,
      &:hover,
      &:focus {
        background-color: transparent !important;
        border: 0 !important;
      }
    }

    &.active {
      background: none;
      border-bottom: 3px solid $color-dark-mint;

      a,
      a:focus {
        color: $color-green;
      }
    }

    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 3px solid $color-dark-mint;
    }
  }
}
