.file-drop {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 0;
  background-color: rgba($color-charcoal-grey, 0.9);

  * {
    font-size: 32px;
    line-height: 40px;
    font-weight: 300;
    color: #fff;
  }

  .svg-icon.main-icon {
    svg {
      width: 96px;
      height: 96px;
      fill: #fff;
    }

    transition: transform 0.2s;

    &:hover {
      svg {
        fill: #fff;
      }
    }
  }

  &.over {
    .svg-icon.main-icon {
      svg {
        fill: $color-weird-green;
      }
      @include transform(translateY(-9px));
    }

    .bottom-message {
      width: 225px;
      color: $color-weird-green;
    }
  }

  .upload {
    margin-top: 0;
    line-height: 1;
  }

  button {
    position: relative;
    overflow: hidden;
    font-weight: 600;

    input {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
      opacity: 0;
    }
  }
}
