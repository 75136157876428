.company-details-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .modal-container {
    padding-left: 30px;

    .header-container {
      height: 80px;
      justify-content: start;
      flex-direction: row;

      &.title-next-to-prefix {
        gap: 10px;
      }

      .header-text {
        font-weight: 400;
        font-size: 30px;
      }
    }
  }

  .disable-modal {
    .body-container {
      .complete-delete {
        padding-bottom: 10px;
      }

      .textarea-container {
        textarea {
          width: 500px;
          height: 38px;
          resize: none;
        }
      }
    }
  }

  .preview-modal {
    .modal-container {
      min-height: 330px;
      max-height: 400px;
      padding-right: 20px;

      .body-container {
        .are-you-sure {
          font-weight: 500;
        }

        .user-scroll {
          max-height: 100px;
          overflow-x: hidden;
          padding-left: 20px;
        }
      }
    }
  }

  .company-body {
    display: flex;

    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .sibp-link {
        max-width: 150px;
      }
    }

    .details-div {
      display: flex;
      flex-direction: column;
      min-width: 200px;
      flex: 1;
      margin-right: 100px;
      @media screen and (min-width: 1400px) {
        flex-direction: row;
        margin-right: 0;

        & > div {
          flex: 1;
          margin-right: 100px;
          min-width: 0;
        }

        .right-details-div {
          margin-top: 70px;
        }
      }
    }

    .counter-div {
      flex: 2;
      @media screen and (min-width: 1400px) {
        flex: 1;
      }
    }

    .input-container {
      margin-bottom: 20px;

      .base-input-container {
        width: 100%;

        input,
        .error-message {
          width: 100%;
        }
      }
    }
  }

  .page-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-left {
      display: flex;
      gap: 20px;

      a {
        &.disabled {
          pointer-events: none;
        }
      }
    }

    .cancel-btn {
      margin-right: 10px;
    }
  }
}
