/* stylelint-disable declaration-block-no-shorthand-property-overrides */
.data-mapping-intro-body-container {
  margin-bottom: 4px;

  > svg {
    margin-left: 170px;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .description {
    padding: 0 1vw;
    gap: 20px;
    width: 400px;
    height: 150px;
    margin-top: 25px;
    margin: auto;
  }
}
