/* stylelint-disable max-nesting-depth, declaration-no-important */
.dashboard {
  .scrollable {
    padding: 5px !important;
    position: absolute !important;
    width: 100%;
    height: 100% !important;
    background: $color-light-mid-opacity-grey;
    overflow-y: auto;

    .empty-chart-template {
      width: 550px;
      height: 400px;
      border: 3px dashed $color-silver;
      position: relative;
      margin-top: 15px;
      margin-left: 72px;
      text-align: center;

      .svg-icon {
        margin-bottom: 20px;

        .icon {
          fill: $color-steel;
        }
      }
    }
  }

  &.dashboard-body:not(.readonly) {
    .charts-wrapper {
      .chart-header {
        cursor: move;
      }
    }
  }

  .chart-tooltip {
    background-color: rgba(255, 255, 255, 0.75);
    border: 1px solid $color-weird-green;
    @include border-radius(3px);
    @include box-shadow(0 3px 6px rgba(0, 0, 0, 0.25));

    padding: 10px;
    z-index: 100000000;

    &.empty {
      display: none;
    }

    table {
      white-space: nowrap;

      tr {
        white-space: nowrap;

        td {
          white-space: nowrap;
        }
      }
    }
  }

  .charts-wrapper {
    @include flexbox-important;

    flex-wrap: wrap;
    justify-content: space-around;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .chart {
      &.empty-chart {
        display: none;
      }

      background: white;
      margin: 15px;
      position: relative;
      min-width: 550px;
      @include box-shadow(0 3px 6px rgba(0, 0, 0, 0.25));

      height: 400px;

      .chart-header {
        height: 72px;
        position: relative;
        padding: 10px 40px 10px 24px;
        border-bottom: 1px solid $color-silver;
        @include flexbox;

        align-items: center;

        a.expand {
          margin-right: 10px;
        }

        .chart-title {
          @include large-text-grey-blue;

          margin-right: 5px;
        }

        .extra-title {
          @include small-text;

          margin-top: 2px;
          margin-left: 10px;
          margin-right: 10px;
        }

        .select-2-dashboard-filter {
          max-width: 200px;

          .select2-chosen {
            font-size: 16px;
          }
        }

        .right {
          position: absolute;
          right: 15px;
        }

        &.has-filter-option {
          .chart-title {
            max-width: 260px;
          }
        }

        &.has-extra-title {
          .chart-title {
            max-width: 350px;
          }

          .select-2-dashboard-filter {
            max-width: 160px;
          }
        }

        &.has-extra-title.has-filter-option {
          .chart-title {
            max-width: 180px;
          }
        }
      }

      h3 {
        width: 100%;
        text-align: center;
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 0;
      }

      .chart-content {
        text-align: center;
        padding: 10px;

        &.with-legend {
          padding-top: 0;
        }

        .kpi-wrapper {
          position: relative;
          width: 100%;
          text-align: center;
          @include flexbox-important;

          -ms-flex-pack: center;
          justify-content: center;
          flex-wrap: wrap;

          @include flex-flow(column);

          &.full {
            height: 250px;
            align-items: center;
          }

          .kpi {
            width: 170px;
            margin: 10px;

            label {
              font-size: 14px;
              font-weight: 400;
              color: #979797;
              display: block;
              margin-bottom: 0;
            }

            span {
              font-size: 24px;
              font-weight: 400;
              color: black;

              span.scenario {
                color: $color-dark-green;
              }
            }
          }
        }

        .kpi-table {
          height: 282px;
          @include flexbox;
          @include flex-flow(column);

          .kpi-row {
            &.kpi-header-row {
              .col-content {
                @include small-text-steel;
              }
            }

            &:not(:last-child):not(:first-child) {
              border-bottom: 2px solid $color-white;
            }
            @include flexbox;
            @include flex(1 1);

            max-height: 60px;

            .kpi-col {
              position: relative;

              &:first-child {
                @include flex(1.5 1.5);

                .col-content {
                  text-align: left;
                  @include leftElement;
                  @include centerVElement;
                }
              }

              &:not(:first-child) {
                @include flex(1 1);
                @include large_header_dark_black_light;

                .col-content {
                  width: 100%;
                  @include centerVElement;
                }
              }
            }
          }
        }

        .kpi-chart {
          height: 310px;
          @include flexbox;
          @include flex-wrap(wrap);

          .kpi-data {
            text-align: center;
            position: relative;
            @include flex(0 0 49%);

            &:not(:nth-last-child(2)):not(:last-child) {
              border-bottom: 1px solid $color-silver;
            }

            &:nth-child(2n + 1) {
              border-right: 1px solid $color-silver;
            }

            .kpi-data-content {
              width: 100%;

              .kpi-value {
                @include large-text-dark-mint;

                padding-right: 5px;
              }
            }
          }

          &.interactive {
            .kpi-data {
              .kpi-data-content {
                .kpi-value-2 {
                  color: #939393;
                }
              }
            }
          }
        }

        .single-kpi-chart {
          .kpi-value {
            @include large-text-dark-mint;
          }

          &.interactive {
            .kpi-value-2 {
              color: #939393;
            }
          }
        }

        tspan {
          @include small-15-text;
        }
      }

      &[data-id="cash"] {
        .chart-content .kpi-wrapper {
          @include flex-flow(row);

          .kpi {
            margin: 0;
          }
        }
      }

      .expand {
        opacity: 0.7;
        margin-left: 8px;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
