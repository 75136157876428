/* stylelint-disable max-nesting-depth, font-family-no-missing-generic-family-keyword, selector-class-pattern, declaration-no-important */
.top-bar-buttons-line {
  border-top: 1px solid rgba(124, 127, 136, 0.35);
  height: 85px;
  padding: 0 25px;
  width: 100%;
  position: relative;
  background-color: $color-light-mid-opacity-grey;
  z-index: 1;
  display: inline-flex;
  justify-content: space-between;

  .top-bar-buttons {
    position: unset;
    transform: unset;
    padding: 10px 0;
    align-self: center;

    .sheets-dropdown-select {
      display: inline-block;
      max-width: 200px;
      width: auto;

      &.wide {
        max-width: 250px;
      }

      padding: 0 5px;
      height: 26px;
      margin-top: -5px;
    }

    .visibility-filter {
      display: inline-block;
      margin-left: 5px;
    }

    > .visibility-filter {
      position: relative;
      width: 24px;
      height: 24px;

      .svg-icon {
        position: absolute;
        top: 5px;
      }
    }

    > div,
    > span > div {
      &:not(.fix-assumption-live-edit):not(.filter-divider):not(.dashboard-header) {
        display: inline-block;
        margin-right: 10px;
      }

      &.right {
        display: block;
        margin-right: 0;
        right: 0;
        @include flexbox;

        > div {
          margin-left: 20px;
        }

        &.actuals {
          .select2-container {
            margin-right: 15px;
          }

          .import-actuals-dropdown {
            display: inline-block;
            background: $color-weird-green;
            border-radius: 3px;
            margin-left: 0;
            margin-bottom: 1px;
            height: 37px;

            .dropdown-header {
              padding: 5px 10px 8px 14px;
              @include mid-text-white;

              svg {
                fill: white;
                width: 24px;
              }
            }

            .dropdown-body.open {
              width: 190px;
              margin-top: 0;
              padding: 11px 0 7px;

              .dropdown-item {
                @include mid-text;

                padding: 10px 0 10px 13px;

                > * {
                  display: flex;
                  color: $color-steel;
                }

                > div svg {
                  > * {
                    fill: $color-steel;
                  }
                }

                svg {
                  margin-right: 5px;
                }

                &:last-of-type {
                  border-top: 1px solid $color-light-steel-gray;
                  margin-top: 12px;
                  padding-top: 12px;
                }

                > a {
                  color: $color-cerulean;
                  fill: $color-cerulean;
                }

                &:not(:last-of-type):hover {
                  background-color: $color-dropdown-hover;

                  > div {
                    color: $color-dark-grey-blue;

                    svg > * {
                      fill: $color-dark-grey-blue;
                    }
                  }
                }
              }
            }
          }

          .add-actuals.svg-icon {
            height: 37px;
            @include border-radius(3px);

            padding: 5px 15px;

            &:not(.ready) {
              background-color: $color-mid-grey;
              color: $color-steel;

              .icon {
                fill: $color-steel;
                color: $color-steel;
              }
            }

            &.ready {
              background-color: $color-weird-green;
              color: #fff;

              .icon {
                fill: #fff;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .top-bar-right-side {
    width: 150px;
    place-self: center;

    .custom-view-dropdown-container {
      .ember-power-select-trigger {
        white-space: nowrap;
        border: 1px solid $color-gray-1;
        border-radius: 3px;
        padding: 0 8px 0 12px;
        width: 150px;
        background: white;

        .ember-power-select-selected-item {
          color: black;
        }

        &::after {
          top: 3px;
          color: black;
        }
      }
    }
  }

  &.dashboard {
    background-color: #fff;

    &.interactive-dashboard {
      &.interactive-collapse {
        height: 153px;
      }

      &.interactive-height1 {
        height: 198px;
      }

      &.interactive-height2 {
        height: 233px;
      }

      &.interactive-height3 {
        height: 268px;
      }

      &.interactive-max-height {
        height: 250px;
      }

      .top-bar-buttons {
        max-height: 100%;
        @include flexbox;
        @include flex-direction(column);

        > .dashboard-header {
          @include flex(0 0 auto);
        }

        > .fix-assumption-live-edit {
          @include flex(1 1 auto);

          overflow-y: hidden;
          @include flexbox;
          @include flex-direction(column);

          > .panel-heading,
          > .select-assumptions {
            @include flex(0 0 auto);
          }

          > .fix-assumption-list {
            @include flex(1 1 auto);

            overflow-y: auto;
          }
        }
      }
    }

    .top-bar-buttons {
      width: 100%;

      .dashboard-header {
        width: 100%;

        > div {
          float: left;
        }

        .inline-edit {
          line-height: 35px !important;
          height: 35px;
          max-width: 400px;

          .svg-icon {
            top: 5px;
          }

          &.editing {
            border: none;
            border-bottom: 1px dashed $color-silver;

            input {
              line-height: 35px !important;
              font-size: 32px;
            }
          }
        }

        .dashboard-dropdowns {
          padding-top: 5px;
          max-width: calc(100% - 600px);

          > .sheets-dropdown-select,
          > .select-filter-dropdown {
            margin-right: 10px;
          }
        }

        .dashboard-actions {
          position: absolute;
          top: 25px;
          right: 72px;

          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;

            li {
              float: left;

              &:not(:first-child) {
                margin-left: 10px;
              }
            }
          }

          .add_widget {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;

            .svg-icon {
              .icon {
                fill: #fff;
              }

              &:hover {
                .icon {
                  fill: #fff;
                }
              }
            }
          }

          // for space between video-icon and drop-down
          .video-tooltip.tooltip {
            margin-top: 5px;
          }

          // video drop-down
          .video-tooltip.tooltip .tooltip-content {
            max-height: 320px;
            overflow-y: auto;
            padding: 10px 0;

            .menu .menu-item {
              width: 100%;
              margin-left: 0;
              padding: 5px 10px;
              line-height: 30px;

              :hover {
                background-color: rgba(57, 218, 149, 0.25);
                color: $color-dark-blue-grey;
                cursor: pointer;
              }

              span {
                padding: 4px 0;
                color: $color-steel;
                border-bottom: 1px solid #878d94;
              }
            }
          }
        }
      }

      .svg-icon {
        line-height: 35px;

        .icon {
          fill: $color-grey-mouse;
        }

        &:hover {
          .icon {
            fill: $color-grey-light-black;
          }
        }
      }

      .fix-assumption-live-edit {
        border: none;
        margin-bottom: 0;

        &[interactivestatus="interactive-status-4"] {
          .fix-assumption-list {
            height: 97px;
            overflow-y: auto;
          }
        }

        &.collapsed {
          .panel-heading {
            > a .svg-icon {
              @include transform(rotate(-90deg));
            }
          }
        }

        .panel-heading {
          padding: 0;
          background-color: transparent;
          border: none;

          .svg-icon:not(.help-icon) {
            .icon {
              fill: $color-green-sage-logo;
            }
          }

          a:hover {
            color: $color-green-sage-logo;
          }
        }

        &:not([interactivestatus="interactive-status-0"]) {
          .fix-assumption-list {
            padding-bottom: 10px;
          }
        }

        .fix-assumption-list {
          .fix-assumption {
            @include flexbox;

            > div.fix-assumption-content {
              @include flex(1 1);
              @include flexbox;

              > .fix-assumption-name {
                @include flex(1 1);
              }

              > :not(.fix-assumption-name):not(.fix-assumption-comment) {
                @include flex(2 2);
                @include flexbox;
              }

              > .fix-assumption-comment {
                @include flex(1 1);
              }

              input {
                border: none;
                border-bottom: 1px solid $color-silver;
                outline: none;

                &.fix-assumption-name {
                  margin-right: 30px;
                }

                &.fix-assumption-comment {
                  margin-left: 30px;
                }

                &.fix-assumption-name,
                &.fix-assumption-comment {
                  cursor: not-allowed;
                }
              }

              .fix-assumption-value {
                @include flexbox;

                > div {
                  @include flex(1 1);
                }

                .attribute-modifier-content {
                  @include flex(1 1);
                  @include flexbox;

                  input {
                    &:not(.currency) {
                      @include flex(1 1);

                      &:focus {
                        background-color: rgba($color-weird-green, 0.05) !important;
                        border-color: $color-weird-green !important;
                      }

                      &:hover {
                        background-color: rgba($color-weird-green, 0.05) !important;
                        border-color: $color-weird-green !important;
                      }
                    }

                    &.currency {
                      width: 100px;
                      cursor: not-allowed;
                    }
                  }
                }

                span.fixed-original-value:not(.hidden) + div {
                  .attribute-modifier-content {
                    input {
                      @include mid-text-dark-green-bold;
                    }
                  }
                }

                .fixed-original-value {
                  flex: 0.2 1 40px;
                  display: block;
                  position: relative;

                  .svg-icon {
                    position: absolute;
                    margin-left: -20px;

                    svg {
                      fill: $color-green-sage-logo;
                      width: 18px;
                      height: 18px;
                    }
                  }

                  .attribute-modifier-content {
                    display: block;

                    input {
                      width: 100%;
                      text-decoration: line-through;
                      opacity: 0.8;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.custom-view-dropdown-container {
  .ember-power-select-selected-item {
    width: calc(100% - 10px);
    display: inline-flex;

    .custom-view-option {
      text-overflow: ellipsis;
      overflow: hidden;

      .actions-section {
        display: none;
      }
    }
  }
}

#ember-basic-dropdown-wormhole {
  .custom-view-dropdown {
    width: 225px !important;
    white-space: nowrap;
    left: unset !important;
    right: 72px;

    > .ember-power-select-options {
      padding: 0;

      > .ember-power-select-option {
        border-top: 1px solid $color-gray-1;
        padding-top: 10px;
        padding-bottom: 35px;
      }
    }

    .ember-power-select-group {
      padding-top: 11px;

      .ember-power-select-group-name {
        font-weight: 400;
      }

      .ember-power-select-options {
        border-top: 1px solid $color-gray-1;
        margin-top: 14px;
        padding: 7px 0;

        .ember-power-select-option {
          padding-left: 20px;
        }
      }
    }

    .ember-power-select-option .custom-view-option {
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .text-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .svg-icon {
        transform: scale(0.75);
      }

      &.disabled {
        .text {
          color: $color-silver;
        }

        .actions-section {
          :not(.enabled) > .icon > * {
            fill: $color-silver;
          }
        }
      }
    }
  }
}

.top-bar-helper {
  width: 100%;

  &.dashboard {
    border-bottom: 10px solid $color-mid-grey;
  }

  .top-bar-header-line {
    background-color: $color-regular-white;
    border-bottom: 1px solid $color-light-steel-gray;
    position: relative;
    height: 60px;
    z-index: 1;

    .top-bar-header-text {
      > div {
        display: inline-block;
        text-align: center;

        span,
        a {
          font-size: 18px;
          font-weight: 400;
          color: $font-color-black;
          padding: 19px;
          cursor: pointer;

          &:hover {
            background-color: $color-light-gray;
          }

          &.active {
            background-color: $color-light-gray;
            font-weight: 500;
          }
        }
      }
    }

    &.sheets-toolbar {
      .top-bar-header-text {
        > div {
          width: 150px;
        }
      }
    }

    .top-bar-left {
      position: absolute;
      left: 38px;

      .version-scenario-header {
        border-radius: 3px;
        height: 40px;
        padding: 0 8px 0 18px;
        float: left;

        > * {
          line-height: 40px;
        }

        .div-dropdown {
          max-width: 300px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .svg-icon {
          float: right;

          &:hover {
            .icon {
              fill: $color-white;
            }
          }
        }

        &.active {
          .div-dropdown {
            color: $color-white;
            margin-right: 20px;

            &::after {
              color: $color-white;
            }
          }
        }
      }

      .top-bar-version-header {
        &.active {
          background-color: $color-weird-green;

          &,
          * {
            color: $color-white;
          }
        }
      }

      .top-bar-scenario-header {
        &.active {
          background-color: $color-blue-changes;
        }
      }
    }

    .top-bar-right {
      position: absolute;
      right: 0;
      margin-right: 25px;

      .svg-icon {
        color: $font-color-black;
        font-weight: 400;
        font-size: 18px;

        &.disabled {
          cursor: not-allowed;

          &:hover {
            .icon {
              fill: white;
            }
          }
        }
      }

      .create-report,
      .add-widgets {
        border: 1px solid $color-steel;
        border-radius: 3px;
        padding: 5px 16px;

        &:hover {
          border-color: $color-green-sage-darker;
        }

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .top-bar-sides {
      > div {
        &:not(.drop-down) {
          display: inline-block;
        }

        div:not(.drop-down) {
          display: inline-block;
          color: $font-color-black;
          font-size: 18px;
          font-weight: 400;
        }

        &:not(:last-child) {
          margin-right: 24px;
        }
      }

      .select-scenario {
        color: $font-color-black;

        &:hover {
          color: $color-green-sage-darker;
        }
      }

      .select-scenario.displayed {
        color: $color-green-sage-darker;

        &::after {
          color: $color-green-sage-darker;
        }
      }

      .select-revision {
        color: $font-color-black;

        &:hover {
          color: $color-green-sage-darker;
        }
      }

      .svg-icon {
        .icon {
          fill: $color-black-darker;
        }

        &:hover {
          color: $color-green-sage-darker;

          .icon {
            fill: $color-green-sage-darker;
          }
        }
      }

      // video icon
      .reference {
        position: relative;
        top: 8px;

        :hover path {
          cursor: pointer;
          fill: $color-green-sage-darker;
        }
      }
    }
  }

  @media screen and (max-width: 1350px) {
    .top-bar-header-line {
      .top-bar-right {
        right: 36px;
      }
    }
  }

  button.top-bar-button {
    background-color: $color-blue-changes;
    border-radius: 18px;
    color: $color-white;
    padding: 0 22px;
    line-height: 36px;
    text-transform: none;
    font-weight: 400;

    &:hover {
      background-color: $color-blue-changes;
    }

    .svg-icon {
      .icon {
        fill: $color-white;
      }

      &:hover {
        .icon {
          fill: $color-white !important;
        }
      }
    }
  }
}

div.div-dropdown {
  cursor: pointer;
  padding-right: 20px;
  position: relative;
  color: $font-color-black;

  &::after {
    content: "\f107";
    color: $font-color-black;
    font-family: FontAwesome;
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    @include transform(translate(0%, -50%));

    line-height: 24px;
    font-weight: bold;
    font-size: 16px;
  }

  &:hover::after {
    color: $color-green-sage-darker;
  }
}

.checkbox__sheets {
  label {
    display: flex;
  }
}
