/* stylelint-disable selector-class-pattern */
$width-switch: 24px;
$height-switch: $width-switch / 2;

.button-wrapper {
  width: $width-switch;
  overflow: hidden;
  border-radius: $width-switch / 4;
  position: relative;

  .button-content {
    height: $height-switch;
    width: $width-switch * 2;
    display: flex;
    position: relative;
    background-color: rgba(124, 127, 136, 0.25);

    > div {
      height: 100%;
      display: inline-block;
    }

    .right-side {
      width: $width-switch / 2 + $width-switch / 4;
      background-color: rgba(17, 142, 117, 0.25);
    }

    .toggel {
      width: $width-switch / 2;
      margin-left: -$width-switch / 4;
      border-radius: 50%;
    }
  }

  &.switchOff {
    .button-content {
      @include animation(0s, 0.3s, changeSwitch);

      .toggel {
        background-color: $color-steel-grey;
      }
    }
  }

  &.switchOn {
    .button-content {
      @include animation(0s, 0.3s, changeSwitchOff);

      .toggel {
        background-color: $color-topaz;
      }
    }
  }
}

@include keyframes(changeSwitch) {
  0% {
    left: 0;
  }

  100% {
    left: -$width-switch / 2;
  }
}

@include keyframes(changeSwitchOff) {
  0% {
    left: -$width-switch / 2;
  }

  100% {
    left: 0;
  }
}
