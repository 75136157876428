/* stylelint-disable scss/at-extend-no-missing-placeholder */
.multi-select-dropdown {
  min-height: 40px;

  li {
    > div {
      &.single-selection {
        line-height: 40px;
      }
    }

    &.create-new-val {
      border-bottom: 2px solid $color-light-steel;
    }
  }

  .filter-header {
    .selection-buttons {
      .clear {
        float: right;
      }

      span {
        &.disabled {
          color: $color-steel;
        }
      }
    }
  }

  .selection {
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 24px;

    &.placeholder {
      color: $color-green;
    }
  }

  &.wizard-field {
    border-bottom: 1px solid $color-white-light-grey;

    &.active div::after {
      transform: rotate(180deg);
    }

    div {
      @include dropdown-sign;

      &::after {
        right: 10px;
        top: 3px;
      }
    }
  }
}

&.disabled {
  pointer-events: none;
}

.multi-budget-lines-mapping {
  cursor: pointer;

  &.has-multi-selection {
    .selection {
      line-height: 40px;
    }
  }
  @include dropdown-sign;

  &::after {
    top: 50%;
    @include transform(translate(0%, -50%));
  }

  &.with-border {
    border-bottom: 1px solid #eee;
  }

  &.overflow-ellipsis {
    padding-right: 15px;
  }
}

.multi-books-selection {
  cursor: pointer;
  border-bottom: 1px solid $color-white-light-grey;
  @include dropdown-sign;

  &::after {
    top: 50%;
    right: 10px;
    @include transform(translate(0%, -50%));
  }
}
