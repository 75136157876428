.react-section {
  &.table-view {
    .selection-box-final-preview {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 88px;
      right: 40px;
      background: #fff;
      border: 1px solid $color-weird-green;
      border-radius: 3px;
      padding: 15px;
      text-align: center;

      &.error {
        height: 80px;
        width: 360px;

        .not-allowed-counter {
          font-size: 16px;
          color: $heading-border-color;
          font-weight: 500;
        }

        .desc {
          font-size: 16px;
        }
      }

      &.well-done {
        width: 280px;
        height: 50px;

        .desc {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
