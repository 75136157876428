/* stylelint-disable max-nesting-depth, function-url-quotes, no-duplicate-selectors, selector-class-pattern, declaration-no-important */
$forecast-row-height: 45px;
$forecast-table-container-laptop-height: calc(#{$forecast-row-height} * 5.5); // 5.5 rows
$forecast-table-container-height: calc(#{$forecast-row-height} * 9.5); // 9.5 rows
$header-with-sub-height: 60px;

.metrics-view.preview-wrapper {
  .forecast-table-container {
    .forecast-table {
      max-height: $forecast-table-container-height;
      overflow-y: auto;

      @media screen and (max-width: 1200px) {
        max-height: $forecast-table-container-laptop-height;
      }

      .ember-view.ember-list-view.ember-list-view-list {
        .ember-list-item-view.has-comments {
          height: $forecast-row-height !important;
        }
      }
    }

    .forecast-table .ember-list-view > div {
      overflow-x: hidden !important;
    }

    .drag-and-drop-ghost {
      background-color: $font-color-white;
    }

    .drop-target-top {
      border-top: 1px solid $color-black;
    }

    .drop-target-bottom {
      z-index: 1;
      box-shadow: 0 1px 0 0 $color-black;
    }

    .placeholder * {
      background-color: $color-white !important;
      color: $color-white;
      border: 0 !important;
    }
  }
}

.forecast-footer {
  .footer-rows-wrapper,
  .total-column {
    .column {
      background: rgba($color-blue-3, 0.08);
    }
  }

  &:not(.total-column):not(.fixed-columns) {
    .forecast-row:not(.header) {
      border-bottom: 1px solid $color-light-steel-gray;
    }
  }
}

.forecast-view {
  @include user-select(none);

  &.forecast-preview {
    .forecast-wrapper {
      .scrollable {
        .forecast-table {
          .scroll-columns {
            height: 45px;
          }
        }
      }
    }
  }

  &:not(.forecast-preview),
  &.model-view {
    .forecast-wrapper {
      &:not(.show-comments) {
        .forecast-table > .model-list-item-view > .comments {
          display: none;
        }
      }

      &.show-comments {
        .ember-list-item-view.has-comments {
          height: 90px !important;

          .fixed-columns {
            position: relative;
          }

          .fixed-columns::before,
          .fixed-columns::after {
            position: absolute;
            bottom: 0;
            left: 50px;
            content: "";
            height: 15px;
            width: 15px;
            border: 10px solid transparent;
            border-bottom: 10px solid $color-mid-grey-border;
          }

          .fixed-columns::after {
            border-bottom: 10px solid $color-highlight-light-real;
            bottom: -2px;
          }

          .comments {
            position: relative;
            clear: both;
            font-size: 18px;
            line-height: 45px;
            color: $color-steel;
            background-color: $color-highlight-light-real;
            border-bottom: 1px solid $color-light-steel-gray;
            display: block;

            // when you change this property, please change also `commentHeight`
            height: 45px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0 25px;
          }
        }
      }
    }
  }

  .forecast-wrapper:not(.actuals) {
    .ember-list-view.ember-list-view-list > div > div > div {
      [data-type="group-model"],
      [data-id="#"],
      [data-type="group-model-parent"] {
        &.scroll-columns {
          .forecast-row {
            background: rgba($color-blue-3, 0.08);
            border-bottom: 1px solid $color-light-steel-gray;

            span {
              display: none;
            }
          }
        }
      }
    }
  }

  &:not(.applies-to) {
    .forecast-table {
      .ember-list-view.ember-list-view-list > div > div > div {
        .scroll-columns:not([data-type="group-model"]):not([data-id="#"]):not([data-type="group-model-parent"]) {
          [data-hook="forecast-row"],
          .column {
            background: rgba($color-blue-3, 0.08);
            border-bottom: 1px solid $color-light-steel-gray;
          }
        }

        [data-type="group-model"],
        [data-id="#"],
        [data-type="group-model-parent"] {
          &.fixed-columns {
            background: rgba($color-blue-3, 0.08);
          }

          &.scroll-columns {
            .column {
              background: rgba($color-blue-3, 0.08);
              border-bottom: 1px solid $color-light-steel-gray;

              span {
                display: none;
              }
            }
          }

          &.total-column {
            .forecast-row {
              background: rgba($color-blue-3, 0.08);
              border-bottom: 1px solid $color-light-steel-gray;

              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.model-view.forecast-preview {
    .forecast-wrapper {
      .forecast-table,
      .forecast-header,
      .forecast-footer {
        .fixed-columns {
          @for $i from 1 through 15 {
            &.group[data-level="#{$i}"] {
              .column.name {
                margin-left: (($i - 1) * 12px);
              }
            }
          }
        }
        @media screen and (min-width: 1370px) {
          .fixed-columns {
            width: 340px;
          }
        }

        &:not(.forecast-footer) {
          @media screen and (min-width: 1370px) {
            .scroll-columns {
              width: calc(100% - 490px);
            }
          }
        }

        &.forecast-footer {
          @media screen and (min-width: 1370px) {
            .scroll-columns {
              width: calc(100% - 500px);
            }
          }
        }
        @for $i from 1 through 15 {
          .comments {
            &[data-level="#{$i}"] {
              padding-left: (($i - 1) * 12px + 25px);
            }
          }
        }
      }

      .forecast-table {
        .ember-list-view.ember-list-view-list > div > div > div {
          &.calculation-error-line {
            .fixed-columns,
            .total-column {
              background-color: rgba($color-error, 0.5);
            }

            .scroll-columns {
              .forecast-row {
                .column {
                  background-color: rgba($color-error, 0.5);
                }
              }
            }
          }
        }

        .ember-list-view.ember-list-view-list > div > div > div.ui-sortable-handle {
          height: 45px;
          overflow-y: hidden;
        }

        .ember-list-view.ember-list-view-list > div > div > div:last-child {
          .scroll-columns {
            border-bottom: 1px solid $color-light-steel-gray;
          }
        }
      }

      .forecast-footer {
        position: absolute;
        background-color: #fff;
        z-index: 20;
      }

      &.read-only {
        .forecast-footer {
          bottom: 0;
        }
      }
    }
  }

  &:not(.forecast-preview) {
    .forecast-wrapper {
      .forecast-table,
      .forecast-header,
      .forecast-footer {
        .fixed-columns {
          @for $i from 1 through 15 {
            &.group[data-level="#{$i}"] {
              .column.name {
                margin-left: ($i * 12px);
              }
            }
          }
        }
      }

      &:not(.hasInnerScroll) {
        .forecast-table {
          > .ember-list-view-list {
            overflow-y: hidden !important;
          }
        }
      }
    }
  }

  &.forecast-preview.past-data-view {
    @include flexbox;

    .fixed-columns-wrapper {
      @include flexbox;

      flex-direction: column;
      text-align: center;

      .fixed-upper,
      .fixed-lower {
        width: 200px;
        border-right: solid 1px rgba(0, 0, 0, 0.2);
      }

      .fixed-upper {
        height: 106px;
        line-height: 115px;
      }

      .fixed-lower {
        @include flex(1 1);

        line-height: 75px;
        border-top: solid 1px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .forecast-wrapper {
    height: 100%;
    padding: 0;

    &:not(.pnl),
    &.headcount {
      [data-type^="by-"],
      [data-type="group-bs"][data-level="1"] {
        > .forecast-row {
          background: rgba($color-blue-3, 0);
        }

        .forecast-row.empty-row {
          display: none;
        }
      }

      &.by-department {
        .forecast-row.empty-row {
          display: none;
        }
      }
    }

    .scroll-columns,
    .total-column {
      .column,
      .text-header {
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;

        .select2-container {
          display: inline-block;
          z-index: 1;
          border: 0;
          margin-top: -2px;

          * {
            line-height: 26px !important;
            font-size: 16px;
            color: $color-steel;
          }
        }
      }
    }

    .scrollable {
      overflow-y: hidden;
      overflow-x: hidden;
      height: auto !important;
      max-height: calc(100% - 63px) !important;
      padding: 0 !important;

      .forecast-table {
        overflow-y: hidden;

        .ui-sortable-helper {
          background: white;
          @include box-shadow(1px 1px 3px #888);
        }

        .ember-list-view {
          overflow-x: hidden !important;
        }
      }
    }

    .forecast-table,
    .forecast-header,
    .forecast-footer {
      position: relative;
      width: 100%;

      &[data-currency="By currency"] {
        .forecast-row.totals {
          display: none;
        }
      }

      &::after {
        content: " ";
        display: block;
        clear: both;
      }

      &:not(.forecast-footer) {
        .scroll-columns {
          &::-webkit-scrollbar {
            display: none;
          }

          -ms-overflow-style: none;
          overflow: -moz-scrollbars-none;
        }
      }

      .total-column {
        &:not([data-type="group-model"]):not([data-id="#"]):not([data-type="group-model-parent"]) {
          .forecast-row:not(.header):not(.footer) {
            background-color: rgba($color-blue-3, 0.08);
            border-bottom: 1px solid $color-light-steel-gray;
          }
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 6px;
          height: 100%;
          top: 0;
          left: -6px;
          background: rgba($color-mid-grey, 0.25);
          z-index: 1;
        }

        // @include box-shadow(10px 0px 20px 0px rgba(0,0,0,0.24));

        width: 145px !important;
        position: absolute;
        right: 0;
        top: 0;

        .column {
          padding-right: 15px;
        }

        [data-type="per-employee"] .column {
          display: none;
        }
      }

      .fixed-columns {
        width: 286px;
        float: left;
        position: relative;
        border-right: 1px solid rgba(0, 0, 0, 0.15);

        &.no-border {
          border: 0;
        }

        .header {
          .column {
            padding-left: 12px;
            position: relative;
            height: 40px;

            .text-header {
              margin-left: 20px;
            }
          }
        }

        .column.name {
          .column-content {
            @include flexbox-important;

            .svg-icon {
              z-index: 10;
              @include flex(0 0 auto);
            }

            .help-iq {
              .svg-icon {
                svg {
                  fill: #595959 !important;
                }

                &:hover {
                  svg {
                    fill: #595959 !important;
                  }
                }
              }
            }

            i.spacer {
              @include flex(0 0 15px);
            }

            > div:not(.right):not(.svg-icon) {
              @include flex(1 1);

              height: 45px;
            }

            .name-text {
              @include flexbox;

              .name-display {
                display: inline-block;
              }
            }

            &.grouping-enabled {
              .name-text {
                @include text-ellipsis;

                max-width: calc(100% - 30px);
              }
            }

            .inline-edit {
              display: block;
              margin-top: 10px;
            }

            .right {
              @include flex(0 0 15px);

              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              @include transition(width 0.2s);

              float: right;
              font-size: 0.8em;

              .select2-container {
                display: none;
                padding: 4px 0;
                height: 45px;
                border: 0;

                .select2-chosen {
                  height: 35px;
                  line-height: 35px;
                  font-size: 12px;
                }

                .select2-choice .select2-arrow b::after {
                  line-height: 35px;
                }
              }

              .styled-select {
                display: none;
                border: 0;
                background: transparent;

                select {
                  font-size: 0.8em;
                  padding-left: 0;
                  vertical-align: top;
                }
              }

              &::before {
                content: "\3009";
                line-height: 30px;
                display: inline-block;
              }
            }

            .actions {
              position: absolute;
              right: 8px;
              display: none;
            }

            .drag-handle {
              display: none;
              position: absolute;
              left: 3px;
              cursor: grab;
            }

            .model-line-label {
              margin-left: 3px;
              @include flex(0 0 auto !important);

              height: 24px !important;
              margin-top: 10px;
              line-height: 24px;
              padding: 0 12px;
              @include border-radius(3px);

              &.linked {
                background-color: $color-white-light-grey;
                color: $color-steel;
              }

              &.input {
                background-color: rgba($color-blue-changes, 0.6);
                color: white;
              }

              &.error,
              &.reference,
              &.loop {
                background-color: $color-red;
                color: white !important;
                font-size: 18px !important;
              }
            }

            .model-public-toggle {
              position: absolute;
              right: 30px;
              display: none;

              &.model-public {
                display: block;

                svg {
                  fill: $color-weird-green;
                }
              }
            }

            .flag-icon {
              position: absolute;
              right: 10px;

              &.flagged {
                svg {
                  fill: $color-weird-green;
                }
              }
            }

            &:hover .right,
            .right.groupSelected {
              @include flex(0 0 auto);

              background: #f0f0f0;
              overflow: visible;

              &::before {
                display: none;
              }

              .select2-container {
                display: block;
              }

              .styled-select {
                display: inline-block;
              }
            }

            .right.groupSelected {
              @include flex(0 0 auto);

              .select2-container {
                width: 105px !important;
              }
            }
          }
        }

        &.collapsed {
          .column.name {
            .svg-icon {
              @include transform(rotate(-90deg));
            }

            &:not(:hover) {
              &:not(.is-open) .column-content.with-group {
                .name-text {
                  max-width: 100%;
                }
              }

              .right {
                .select2-container:not(.select2-dropdown-open) {
                  display: none;
                }
              }
            }
          }
        }
      }

      .forecast-row:hover {
        .column.name {
          .actions,
          .model-public-toggle,
          .drag-handle {
            display: block;
          }

          .model-line-label {
            display: none;
          }
        }
      }

      .scroll-columns {
        width: calc(100% - 431px);
        float: left;
        overflow-x: auto;
        overflow-y: hidden;
        @include transform(translateZ(0));

        .column-wrapper {
          width: 200000px;
          min-width: 100%;
        }

        .group.has-children {
          > .forecast-row:not(.totals) .column:not(.editable):not(.has-own-actual) {
            opacity: 0.3;
            transition: opacity 0.4s ease;
          }

          &.collapsed {
            > .forecast-row .column {
              opacity: 1 !important;
              font-weight: bold;
            }
          }
        }

        .column {
          height: 45px;
          width: 137px;
          padding-right: 10px;
          min-height: 1px;
          float: left;

          input {
            background: transparent;
          }

          &.has-actual {
            input {
              background: rgba($color-green, 0.1);
            }
          }
        }

        .forecast-row {
          &.header {
            border-bottom: 0;
            float: left;
          }
        }
      }

      &.forecast-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        .scroll-columns {
          height: 40px;
          overflow-x: hidden;
        }
      }

      &.forecast-table {
        .ember-list-view.ember-list-view-list {
          width: 100% !important;
        }

        .scroll-columns {
          overflow-x: hidden;
          overflow: -moz-scrollbars-none;

          .forecast-row:not(.empty-row) {
            @include flexbox;

            .column {
              @include flex(0 0 auto);

              float: none;
            }
          }
        }
      }

      &.forecast-footer {
        border-top: 6px solid rgba($color-mid-grey, 0.25);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        .column.name {
          padding-left: 17px;
          position: relative;

          .svg-icon {
            position: absolute;
            right: 8px;
            top: 0;
          }
        }
      }

      .forecast-row {
        *:not(i) {
          line-height: 45px;
        }

        &:not(.empty-row) {
          height: 45px;
        }

        &.header {
          border-top: 0;

          .column {
            .text-header {
              font-size: 16px;
              font-weight: 400;
              text-transform: uppercase;
              letter-spacing: 1.2px;
              color: $color-steel;
              background-color: $color-regular-white;
            }

            &:not(.total) {
              .sub-header-text-wrapper {
                > div {
                  background-color: $color-regular-white;
                }
              }
            }
          }

          .sub-header {
            &,
            * {
              line-height: 1;
            }

            margin-top: -12px;

            span {
              display: inline-block;
              width: 50%;
              font-size: 0.7em;
            }
          }
        }

        .column {
          &,
          * {
            white-space: nowrap;
          }

          &.editable {
            padding-right: 0;

            input {
              width: 100%;
              line-height: 29px;
              text-align: center;
              border-top: 0;
              border-right: 0;
            }
          }

          &.name {
            .spacer {
              width: 12px;
              height: 12px;
            }

            .name-text > a {
              @include flexbox;
              @include text-ellipsis;

              margin-left: 5px;
              width: 100%;
            }

            i.expand-trigger {
              width: 12px;
              height: 12px;
              line-height: 1;
              align-self: center;
            }

            .svg-icon {
              .icon {
                width: 20px;
                height: 20px;
                fill: $color-steel;
              }

              &:hover .icon {
                fill: $font-color;
              }
            }

            .overflow-ellipsis.has-suffix {
              width: calc(100% - 34px);
              display: inline-block;
            }

            .suffix {
              float: right;
            }
          }
        }

        input.selected-cell {
          background-color: $color-light !important;
        }
      }

      &.forecast-header {
        .forecast-row {
          height: 40px;

          *:not(i) {
            line-height: 40px;
          }
        }
      }

      .group {
        &[data-type="sum"] {
          font-weight: bold;
        }
      }
    }

    &.cf {
      .forecast-footer {
        overflow-y: hidden;
      }

      &:not(.actualView):not(.metrics-view):not(.headcount):not(.no-footer) {
        .scrollable {
          max-height: calc(100% - 138px) !important;
        }

        &.footer-rows-0 {
          &.has-cash-flow-row {
            .scrollable {
              max-height: calc(100% - 138px) !important;
            }
          }

          &:not(.has-cash-flow-row) {
            .scrollable {
              max-height: calc(100% - 98px) !important;
            }
          }
        }

        &.side-by-side,
        &.rolling-forecast {
          &.footer-rows-0 {
            .scrollable {
              max-height: calc(100% - 158px) !important;
            }
          }
        }
      }

      &.footer-rows-2:not(.no-footer):not(.headcount) {
        .scrollable {
          max-height: calc(100% - 138px) !important;
        }
      }

      &.footer-rows-4:not(.no-footer) {
        .scrollable {
          max-height: calc(100% - 178px) !important;
        }
      }

      &.by-currency:not(.actualView) {
        .scrollable {
          max-height: calc(100% - 174px) !important;
        }

        .forecast-footer {
          max-height: 300px;
        }

        &.footer-rows-0 {
          .scrollable {
            max-height: calc(100% - 61px) !important;
          }

          .forecast-footer {
            height: 20px;
          }
        }

        &.footer-rows-2 {
          .scrollable {
            max-height: calc(100% - 228px) !important;
          }

          .forecast-footer {
            height: 187px;
            max-height: 187px;
          }
        }

        &.footer-rows-3 {
          .scrollable {
            max-height: calc(100% - 207px) !important;
          }
        }
      }

      &.actualView {
        .scrollable {
          max-height: calc(100% - 100px) !important;
        }
      }
    }

    &.bs,
    &.booking,
    &.metrics-view {
      &.footer-rows-0 {
        > .forecast-footer {
          height: 20px;
          border-bottom: none;
        }

        &:not(.booking) > .forecast-footer {
          border-top: none;
        }
      }

      &.rolling-forecast.preview-wrapper {
        .forecast-header {
          .header.forecast-row {
            height: $header-with-sub-height;
          }
        }
      }
    }

    &.actualView:not(.preview-wrapper):not(.rolling-forecast) {
      &:not(.edit-mode) {
        .scroll-columns {
          .column:not(.column-has-actual) {
            width: 150px;

            .sub-header {
              width: 150px;
            }

            > span {
              width: 100% !important;
              padding-right: 0 !important;
            }
          }
        }

        .forecast-header {
          .scroll-columns {
            .column:not(.column-has-actual) {
              > span:first-child,
              > span.diff-column,
              .sub-header-text-wrapper:first-child,
              .sub-header-text-wrapper.differrence-header {
                display: none;
              }
            }
          }
        }

        &:not(.calculation-error) {
          .scroll-columns {
            .column:not(.column-has-actual) {
              > span:first-child,
              > span.diff-column,
              .sub-header-text-wrapper:first-child,
              .sub-header-text-wrapper.differrence-header {
                display: none;
              }
            }
          }
        }
      }
    }

    &.actualView:not(.edit-mode) {
      > .scrollable {
        max-height: calc(100% - 82px) !important;
      }
    }

    &.rolling-forecast {
      .header {
        .column {
          height: $header-with-sub-height;

          .sub-header {
            height: 100%;

            .sub-header-text-wrapper {
              margin-top: 9px;

              > div {
                > span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: $color-steel;
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }

    &.actuals:not(.edit-mode),
    &.side-by-side,
    &.headcount.headcount-cost {
      .header {
        .column {
          text-align: center;
          height: $header-with-sub-height;

          > .text-header {
            text-align: left;
          }

          .sub-header {
            @include flexbox-important;

            height: 100%;
            position: absolute;
            top: 0;
            width: 325px;
            margin: 0;
            padding-right: 1px;

            .sub-header-text-wrapper {
              @include flex(1 1);

              position: relative;

              // margin-left: 4px;
              // width: 32%;

              > div {
                height: $header-with-sub-height;
                @include transform(translateY(100%) translateY(-23px));

                > span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  position: relative;
                  color: $color-steel;
                  font-size: 15px;
                  width: 70px;
                  border-radius: 3px;
                  padding: 3px;
                  font-weight: 400;

                  .menu-trigger {
                    cursor: pointer;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    text-align: right;
                    padding-right: 4px;

                    &::before {
                      content: "\2304";
                      display: inline-block;
                      color: $color-steel;
                      font-weight: bold;
                      font-size: 18px;
                      line-height: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .forecast-header > .total-column {
        .header .sub-header {
          padding-right: 15px;
        }
      }

      &.columns-2 {
        .scroll-columns,
        .total-column {
          .column {
            width: 200px;
            padding-right: 0;

            > span {
              display: inline-block;
              width: 48%;
              padding-right: 1%;
            }
          }
        }

        .scroll-columns {
          width: calc(100% - 410px);
        }

        .total-column {
          width: 200px !important;

          .column {
            width: 100%;
          }
        }
      }

      &.actuals:not(.edit-mode),
      &.side-by-side,
      &.headcount.headcount-cost {
        &.columns-3 {
          &:not(.actuals) {
            .scroll-columns,
            .total-column {
              .column {
                > span,
                .compare-format-numbers > span {
                  display: inline-block;
                  width: 32%;
                  padding-right: 1%;
                }

                &.multi-value {
                  > span {
                    width: 49%;
                  }
                }
              }
            }
          }

          .scroll-columns,
          .total-column {
            .column {
              width: 325px;
              padding-right: 0;

              > span,
              .compare-format-numbers > span {
                display: inline-block;
                width: 32%;
                padding-right: 1%;
              }
            }
          }

          &:not(.bs):not(.waterfall) {
            .scroll-columns {
              width: calc(100% - 611px);
            }
          }

          .total-column {
            .column {
              width: 100%;
            }
          }

          .total-column {
            width: 325px !important;
          }
        }
      }

      &.actuals,
      &.headcount:not(.side-by-side) {
        .scrollable,
        .forecast-footer {
          .scroll-columns,
          .total-column {
            .column {
              @include flexbox;

              .cell-content {
                @include flex(1 1);
              }
            }
          }
        }
      }

      .forecast-footer {
        .scroll-columns {
          .footer.cash-flow-total {
            .column > span {
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }

    &.actuals {
      .scrollable {
        &:not(.hide-footer) {
          max-height: calc(100% - 100px) !important;
        }
      }

      .diff-column {
        display: none !important;
      }

      input {
        height: 45px;
      }

      .currency::before {
        line-height: 45px;
      }

      .scroll-columns .column:not(.column-has-actual) {
        width: 120px !important;

        .sub-header {
          width: 120px !important;
        }

        .compare-format-numbers > span:first-child {
          display: none;
        }
      }

      .column-is-closed {
        &,
        .sub-header {
          width: 120px !important;
        }

        .sub-header .sub-header-text-wrapper:nth-child(2),
        .compare-format-numbers > span:nth-child(2) {
          display: none !important;
        }

        .budget-cell {
          display: none;
        }
      }

      .scroll-columns .column.column-has-actual:not(.column-is-closed),
      .ember-list-view.ember-list-view-list
        > div
        > div
        > div
        .scroll-columns
        .column.column-has-actual:not(.column-is-closed) {
        background-color: $color-actuals !important;
      }

      .column-is-closed {
        background-color: $color-topaz-highlight-light !important;
      }

      .column {
        &:not(.column-has-actual):not(.column-is-closed) {
          .actual-cell:not(.has-actual-value) {
            display: none;
          }
        }
      }
    }

    &.waterfall:not(.bs) {
      .scroll-columns .forecast-row {
        .column:nth-child(even) {
          color: $color-dark-mint;

          &.negative {
            color: $color-red;
          }
        }
      }
    }

    &.past-data-forecast {
      width: calc(100% - 200px);

      .past-data-header,
      .forecast-header {
        border-bottom: 1px solid $color-light-steel-gray;

        .header {
          .column {
            height: $header-with-sub-height;

            .sub-header {
              height: 100%;

              .sub-header-text-wrapper {
                margin-top: 9px;

                > div {
                  > span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: $color-steel;
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
      }

      .past-data-footer {
        border-bottom: solid 1px rgba(0, 0, 0, 0.2) !important;

        .scroll-columns {
          overflow-x: hidden;
          overflow-y: hidden;
        }
      }
    }

    .forecast-table,
    .forecast-footer {
      span,
      a {
        font-size: 18px;
        font-weight: 400;
        color: $color-dark-grey-blue;
      }
    }
  }

  .forecast-wrapper.pnl {
    .forecast-table,
    .forecast-header,
    .forecast-footer {
      .scroll-columns,
      .fixed-columns,
      .total-column {
        .group.selected {
          > .forecast-row {
            background-color: inherit !important;
          }
        }
      }
    }
  }

  .forecast-wrapper {
    &.bs,
    &.waterfall {
      .forecast-table,
      .forecast-header,
      .forecast-footer {
        .scroll-columns {
          width: calc(100% - 286px);
        }
      }
    }
  }

  .forecast-wrapper {
    &.actuals.view-mode {
      .total-column::before {
        left: 6px;
      }
    }
  }

  .forecast-wrapper {
    &.rolling-forecast {
      .forecast-header {
        .forecast-row {
          height: $header-with-sub-height;
        }

        .scroll-columns {
          height: $header-with-sub-height;
        }

        .total-column {
          &::before {
            height: $header-with-sub-height;
          }
        }
      }
    }

    &.vs-table,
    &.side-by-side,
    &.headcount.headcount-cost {
      .forecast-header {
        div:not(.fixed-columns) {
          .forecast-row {
            .text-header {
              padding-left: 16px;
            }
          }
        }

        .forecast-row {
          height: $header-with-sub-height;
        }

        .scroll-columns {
          height: $header-with-sub-height;
        }
      }

      .scrollable > .forecast-table,
      .forecast-footer {
        .scroll-columns,
        .total-column {
          .forecast-row {
            .column {
              border-right: 1px solid rgba($color-forecast-border, 0.35);
            }
          }
        }
      }
    }
  }

  .forecast-wrapper {
    .group-bold {
      &[data-hook-name="forecast-row-name"] .name-text,
      &[data-hook="forecast-row"] .column,
      &[data-hook="forecast-row-total"] .column {
        @include font-color-stroke;

        .negative-vs {
          @include negative-red-stroke;
        }

        .positive-vs {
          @include positive-green-stroke;
        }
      }
    }
  }

  .forecast-wrapper:not(.preview-wrapper) .forecast-footer,
  .forecast-wrapper.preview-wrapper .forecast-footer .total-column {
    @include font-color-stroke;

    .negative-vs {
      @include negative-red-stroke;
    }

    .positive-vs {
      @include positive-green-stroke;
    }
  }
}

.preview.details {
  .forecast-wrapper.preview-wrapper {
    .forecast-table,
    .forecast-header,
    .forecast-footer {
      .fixed-columns {
        @for $i from 0 through 15 {
          &[data-level="#{$i}"] {
            .column.name {
              margin-left: (($i - 1) * 45px);
            }
          }
        }
      }
    }

    &:not(.hasInnerScroll) {
      .forecast-table {
        > .ember-list-view-list {
          overflow-y: hidden !important;
        }
      }
    }
  }
}

@-moz-document url-prefix() {
  .forecast-wrapper {
    .forecast-header,
    .forecast-footer,
    .scrollable {
      overflow-y: scroll;
    }
  }
}

:not(.preview) > .forecast-view {
  .forecast-table,
  .forecast-header,
  .forecast-footer {
    .scroll-columns,
    .fixed-columns,
    .total-column {
      &.fixed-columns {
        .column.name {
          .column-content {
            .name-text {
              width: calc(100% - 20px);
              margin-right: 5px;

              .shrink {
                flex-shrink: 100000;
              }
            }
          }
        }
      }

      .group.selected {
        > .forecast-row {
          background-color: $tree-background-hover !important;
        }
      }
    }
  }
}

body.hiddenscroll {
  .inner-content > .forecast-view {
    .forecast-wrapper {
      &.no-footer {
        &:not(.metrics-view):not(.cf) {
          .forecast-footer {
            height: 15px;
          }
        }

        .forecast-footer {
          .forecast-row {
            height: 15px;

            .column {
              display: none;
            }
          }
        }
      }

      &.bs {
        &.hasInnerScroll {
          > .scrollable,
          > .forecast-header {
            .scroll-columns {
              width: calc(100% - 286px - 15px);
            }

            .empty-total-column {
              width: 15px;
            }
          }
        }
      }
    }
  }

  .model-preview .forecast-wrapper {
    &.metrics-view {
      .forecast-footer {
        height: 15px;

        .forecast-row {
          height: 15px;

          .column {
            display: none;
          }
        }
      }
    }
  }

  .forecast-view {
    .forecast-wrapper.forecast-salary-details.hasInnerScroll {
      .forecast-footer,
      .forecast-header {
        .total-column {
          margin-right: 0;
        }
      }
    }
  }
}

body:not(.hiddenscroll) {
  .inner-content > .forecast-view {
    .forecast-wrapper {
      &.hasInnerScroll {
        .forecast-header,
        .forecast-footer {
          .total-column {
            margin-right: 17px;
          }

          .scroll-columns {
            width: calc(100% - 447px);
          }
        }

        &.side-by-side,
        &.headcount.headcount-cost {
          &:not(.hide-total-column) {
            .scrollable {
              .total-column {
                width: 314px !important;
              }

              .scroll-columns {
                width: calc(100% - 600px);
              }
            }

            .forecast-header,
            .forecast-footer {
              .total-column {
                width: 315px !important;
              }

              .scroll-columns {
                width: calc(100% - 615px);
              }
            }
          }
        }

        &.bs {
          .forecast-footer {
            .scroll-columns {
              width: calc(100% - 286px);
            }
          }
        }
      }

      &.cf {
        &:not(.metrics-view):not(.headcount):not(.no-footer):not(.actuals) .scrollable {
          max-height: calc(100% - 152px) !important;
        }

        &.actualView {
          .scrollable {
            max-height: calc(100% - 128px) !important;
          }
        }

        &.by-currency:not(.actualView):not(.headcount) {
          &.footer-rows-2 {
            .scrollable {
              max-height: calc(100% - 245px) !important;
            }

            .forecast-footer {
              height: 203px;
              max-height: 203px;
            }
          }

          &.footer-rows-3 {
            .scrollable {
              max-height: calc(100% - 208px) !important;
            }
          }
        }
      }

      &.pnl {
        .scrollable {
          max-height: calc(100% - 108px) !important;
        }
      }

      &.no-footer {
        .forecast-footer {
          .forecast-row {
            border-top: 1px solid $color-mid-grey-border;
            height: 2px;
            overflow-y: hidden;
          }
        }
      }

      &.bs,
      &.by-currency.no-footer {
        .forecast-footer {
          border: none;

          * {
            border: none;
          }

          .total-column {
            display: none;
          }
        }

        .scrollable {
          max-height: calc(100% - 56px) !important;
        }
      }

      &.bs {
        &:not(.hasInnerScroll) {
          .forecast-footer {
            .scroll-columns {
              width: calc(100% - 286px);
            }
          }
        }

        .forecast-header {
          .scroll-columns {
            width: calc(100% - 301px) !important;
          }
        }
      }
    }
  }

  .model-preview .forecast-wrapper {
    &.metrics-view {
      &:not(.hasInnerScroll) {
        .forecast-footer {
          .scroll-columns {
            max-width: calc(100% - 450px);
          }
        }
      }

      .forecast-footer {
        border: none;

        * {
          border: none;
        }

        .forecast-row {
          border-top: 1px solid #ededed;
          height: 2px;
          overflow-y: hidden;
        }

        .total-column {
          display: none;
        }
      }
    }

    .forecast-header {
      width: calc(100% - 15px);

      &.stuck {
        .scroll-columns {
          width: calc(100% - 446px);
        }

        .total-column {
          margin-right: 15px;
        }
      }
    }

    &.hasInnerScroll {
      .forecast-footer {
        width: 100%;

        .scroll-columns {
          width: calc(100% - 490px);
        }
      }
    }
  }

  .forecast-view {
    .forecast-wrapper.forecast-salary-details.hasInnerScroll {
      .forecast-footer,
      .forecast-header {
        .scroll-columns {
          width: calc(100% - 445px);
        }

        .total-column {
          margin-right: 14px;
        }
      }
    }
  }
}

:not(.preview) > .forecast-view {
  .forecast-wrapper:not(.bs) .scrollable > .forecast-table,
  .forecast-footer {
    .total-column {
      .forecast-row {
        .column {
          .negative-vs {
            * {
              color: $color-red;
            }
          }

          .positive-vs {
            * {
              color: $color-dark-mint;
            }
          }
        }
      }
    }

    .forecast-row {
      .column {
        .negative-vs {
          * {
            color: $color-red;
          }
        }

        .positive-vs {
          * {
            color: $color-dark-mint;
          }
        }
      }
    }
  }

  .side-by-side,
  &.headcount.headcount-cost {
    .column {
      span.strike {
        margin-right: -5px;
      }
    }

    .scroll-columns {
      .column {
        width: 325px;
        padding-right: 5px;
      }
    }

    .total-column {
      .forecast-row:not(.footer) {
        .column {
          > span {
            display: inline-block;
            margin-left: 10px;
          }
        }
      }
    }
  }

  &.forecast-preview {
    .forecast-wrapper {
      .disable-scroll {
        overflow-x: hidden;
      }
    }

    .ember-list-view.ember-list-view-list > div {
      overflow-x: hidden !important;
    }
  }
}

body.non-mac {
  .forecast-view {
    &.model-view.forecast-preview {
      .forecast-wrapper {
        &.hasInnerScroll {
          .scrollable {
            padding-bottom: 10px !important;
          }
        }
      }
    }
  }
}
