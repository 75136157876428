/* stylelint-disable font-family-no-missing-generic-family-keyword, selector-class-pattern */
.power-select-lazy {
  &.power-select-lazy-highlight {
    border-bottom: 1px solid $color-silver;

    &:hover {
      background-color: rgba(56, 218, 149, 0.05);
      border-bottom: 1px solid $color-weird-green;
    }
  }

  .ember-power-select-trigger {
    .selected-text {
      padding-right: 0;
    }
  }

  .selected-text {
    position: relative;
    padding-right: 40px;
    display: block;
    height: 100%;
    line-height: 40px;

    &.inactive {
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;

      &::after {
        content: "\f107";
        color: $font-color;
        font-family: FontAwesome;
        font-weight: bold;
        font-size: 16px;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        pointer-events: none;
        transition: all 0.4s ease;
        transform: rotate(0);
      }

      &.readOnly {
        &::after {
          content: "";
        }
      }
    }
  }
}
