/* stylelint-disable max-nesting-depth, declaration-no-important */
.modal-container.modal-reports {
  .modal-dialog {
    width: 780px;

    .modal-content {
      .modal-body {
        padding-bottom: 0;
        max-height: 390px;
        overflow: auto;

        @media screen and (min-height: 900px) {
          max-height: 620px;
        }

        label {
          margin-bottom: 0;
        }

        .report-type {
          margin-top: 12px;

          .report-type-title {
            width: 184px;
            height: 21.6px;
            font-size: 18px;
          }

          .budgeta-checkbox {
            display: inline-block;

            &.disabled {
              opacity: 0.5;
              pointer-events: none;
            }
          }
        }

        .radio-label {
          &:not(.full) {
            width: 80px;
          }

          height: 27px;
          font-size: 21px;
          margin-right: 36px;
        }

        .currency-wrapper {
          margin-bottom: 0;
          margin-top: 20px;

          .currency-label {
            width: 184px;
            height: 21.6px;
            font-size: 18px;
          }
        }

        .selection-label {
          width: 276px;
          height: 21.6px;
          font-size: 18px;
          margin-bottom: 14px;
        }

        hr {
          margin-top: 36px;
          margin-bottom: 36px;
        }

        .full.radio-label {
          margin-bottom: 18px;
        }

        .full {
          height: 27px;
          font-size: 21px;
          margin-bottom: 18px;
        }

        .right {
          float: none;
          margin-bottom: 22px;
          margin-left: 25px;
          margin-top: -10px;
          @include flexbox;
          @include flex-wrap(wrap);

          .dropdown {
            margin-right: 24px;

            &:not(.select2-container) {
              height: 39px;
              @include flex(0 0 160px);

              padding: 4px 5px;
            }
          }

          .report-dropdown {
            .dropdown {
              height: 39px;
              width: 160px !important;

              span:first-child {
                margin-right: 33px;
              }
            }
          }
        }

        .dashboard-charts {
          margin-left: 167px;

          .budgeta-checkbox {
            display: inline-block;
            margin-bottom: 18px;
            width: 49%;

            .full.checkbox {
              margin: 0;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
