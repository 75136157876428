.elegant-modal.wizard-modal.group-company-intacct {
  .step-year-over-year {
    .scrollable {
      height: calc(100% - 80px);
      width: 365px;

      .flex {
        input {
          width: 140px;
          padding-right: 30px;
        }

        span {
          line-height: 39px;
          margin-left: -16px;
          color: $color-steel;
        }
      }
    }
  }
}
