/* stylelint-disable max-nesting-depth, function-url-quotes, selector-class-pattern */
$nav-height: 69px;

.budget-structure-container {
  height: 100%;
  width: 100%;

  * {
    box-sizing: unset;
  }

  .hierarchy-nav-container {
    height: $nav-height;
    border-bottom: 1px solid $color-light-steel-gray;
    display: flex;
    gap: 10px;
    padding-left: 22px;
    align-items: center;
  }

  .body-container {
    height: calc(100% - #{$nav-height});

    .hierarchy-tree-container {
      background: url(/assets/images/bg_partial_squares_and_circles.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: right;
      height: calc(100% - #{$nav-height});
    }

    input:not([type="checkbox"]):not([type="radio"]) {
      height: unset;
    }

    .tree-structure-container {
      height: 100%;
      box-sizing: border-box;
      overflow-y: auto;

      .branch-container {
        .collapse,
        .expand {
          display: block;

          &::before {
            box-sizing: content-box;
          }
        }

        .node-select {
          .select__menu {
            .select__option--is-disabled {
              color: $color-light-steel-gray;
            }

            .select__option--is-selected {
              background-color: white;
            }

            .select__option--is-focused {
              background-color: $color-mint-tulip;
            }

            .select__option {
              width: auto;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      &::before {
        left: -19px;
        top: 5px;
      }
    }
  }

  .footer-container {
    margin-right: 75px;
    height: 77px;
    min-height: 77px;
  }
}
