/* stylelint-disable max-nesting-depth, function-url-quotes, selector-class-pattern */
$nav-height: 69px;
$input-hight: 45px;

.dimension-tree-container {
  height: 100%;
  width: 100%;

  * {
    box-sizing: unset;
  }

  .hierarchy-nav-container {
    height: $nav-height;
    border-bottom: 1px solid $color-light-steel-gray;
    display: flex;
    gap: 10px;
    padding-left: 22px;
    align-items: center;

    .arrow-hierarchy {
      color: $font-color;
      font-size: 24px;
    }

    .dimension-select {
      .select__control {
        border: none;
        outline: unset;
        box-shadow: unset;
        cursor: pointer;

        .select__indicator {
          padding-left: 0;
        }

        .select__indicator-separator {
          display: none;
        }
      }

      .select__option {
        width: auto;
        cursor: pointer;
      }

      .select__option--is-selected {
        background-color: white;
      }

      .select__option--is-focused {
        background-color: $color-mint-tulip;
      }

      .select__value-container {
        padding-left: 0;
        padding-right: 0;
        margin-right: -15px;

        input {
          width: 0;
        }

        .select__single-value {
          @include large-text;

          display: contents;
        }
      }
    }

    .walk-through-tooltip {
      position: absolute;

      .popup-container {
        position: relative;
        width: 200px;
        height: auto;
        padding: 10px 15px;
        z-index: 1;
        left: -88px;

        .arrow-tooltip {
          right: 50%;
        }

        .popup-content {
          button {
            display: block;
            margin-left: auto;
          }
        }
      }

      &.popup-min-left {
        .popup-container {
          left: -40px;
        }
      }
    }
  }

  .body-container {
    height: calc(100% - #{$nav-height});

    .hierarchy-tree-container {
      background: url(/assets/images/bg_partial_squares_and_circles.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: right;
      height: calc(100% - #{$nav-height} - #{$input-hight});

      .filter-section {
        display: flex;
        align-items: flex-end;

        .input-container {
          width: 420px;
          margin-left: 10px;

          .base-input-container {
            width: inherit;
            height: 45px;
            display: flex;
            align-items: flex-end;

            input {
              width: inherit;
              height: 35px;
            }
          }
        }

        > svg {
          position: relative;
          right: 30px;
          bottom: 5px;
          fill: $color-light-steel-gray;

          &:hover {
            cursor: pointer;
            fill: $color-dark-grey-blue;
          }
        }
      }
    }

    input:not([type="checkbox"]):not([type="radio"]) {
      height: unset;
    }

    .tree-structure-container {
      height: 100%;
      box-sizing: border-box;
      overflow-y: auto;

      .branch-container {
        .collapse,
        .expand {
          display: block;

          &::before {
            box-sizing: content-box;
          }
        }

        .node-option__menu-list {
          > .node-option {
            &.selected-node {
              background-color: $color-mint-tulip;
            }
          }
        }
      }

      &::before {
        left: -19px;
        top: 5px;
      }
    }

    .sibp-checkbox {
      label {
        display: flex;
        margin-bottom: 0;
        font-weight: 400;
        margin-left: 0;

        &::before {
          min-width: 20px;
          height: 20px;
        }
      }

      .label {
        color: $font-color;
        display: flex;
        padding: 0;
        font-size: 18px;
        font-weight: unset;
      }
    }
  }

  .footer-container {
    margin-right: 75px;
    height: 77px;
    min-height: 77px;
  }
}
