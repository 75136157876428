/* stylelint-disable declaration-no-important */
.currency {
  &.has-currency-selection {
    input:first-child.ember-text-field {
      float: left;
      width: calc(100% - 135px) !important;
      padding-right: 0;
    }

    .select2-container {
      float: left;
      width: 95px;
      padding: 4px 0;
    }
  }

  &.has-formula-preview {
    input:first-child.ember-text-field {
      padding-left: 10px;
    }

    &.has-currency-selection {
      input:first-child.ember-text-field {
        width: calc(100% - 180px) !important;
      }
    }

    label.formula-preview {
      &.formula-error {
        color: $color-error !important;
      }

      width: 100px;
    }

    &.in-focus {
      label.formula-preview.formula-error {
        color: transparent !important;
      }
    }
  }

  > div:not(.select2-container):not(.table-filter) {
    height: 35px;
    margin-top: -1px;
  }
}
