/* stylelint-disable max-nesting-depth, font-family-no-missing-generic-family-keyword, declaration-no-important */
.month-picker-control {
  float: left;

  .month-wrapper *,
  .top * {
    @include user-select(none);
  }

  &:first-child {
    border-right: 1px solid $color-light-steel;
  }

  width: 264px;
  text-align: center;

  .top {
    position: relative;
    height: 64px;
    text-align: center;

    .top-inner {
      width: 100%;

      > * {
        display: inline-block;
      }

      .svg-icon {
        .icon {
          fill: $color-steel;

          &:hover {
            fill: $color-dark-grey-blue;
          }
        }
      }

      .year {
        width: 50%;
        display: inline-block;
        color: $color-steel;
        font-size: 23px;
      }

      .top-inner-content {
        height: 100%;
        width: 100%;
        border-bottom: 2px solid $color-white-light-grey;
      }
    }
  }

  &:not(.range-month-picker-control) {
    .top {
      margin: 0 12px;
      border-bottom: 2px solid $color-white-light-grey;

      .top-inner {
        .left-arrow {
          float: left;
          margin-left: 6px;
        }

        .right-arrow {
          float: right;
          margin-right: 6px;
        }
      }
    }

    :not(.disable-month) {
      &:hover.reverse-colors {
        cursor: pointer;

        div.month-wrapper {
          > div {
            color: #fff;
          }

          background-color: $color-dark-sky-blue;
        }

        &.past-data-month {
          div.month-wrapper {
            background-color: $color-dark-mint;
          }
        }
      }

      &.selected.reverse-colors {
        div.month-wrapper {
          background-color: $color-dark-sky-blue;
          @include border-radius(50%);

          > div {
            color: #fff;
          }
        }

        &.past-data-month {
          div.month-wrapper {
            background-color: $color-dark-mint;
          }
        }
      }
    }
  }

  ul {
    margin: 0 auto;
    width: 264px;

    li {
      text-align: center;
      font-weight: normal;
      float: left;
      width: 66px;
      height: 66px;
      position: relative;

      div.month-wrapper {
        border-radius: 50%;
        width: 44px;
        height: 44px;

        > div {
          @include mid-text-steel;
        }
      }

      &:hover:not(.reverse-colors) {
        cursor: pointer;

        div.month-wrapper {
          > div {
            color: #fff;
          }

          background-color: $color-dark-mint;
        }
      }

      &.selected:not(.reverse-colors) {
        div.month-wrapper {
          background-color: $color-dark-mint;
          @include border-radius(50%);

          > div {
            color: #fff;
          }
        }
      }

      &.disable-month {
        div.month-wrapper {
          background-color: $color-softer-grey;

          > div {
            color: $color-dark-grey-blue;
          }
        }

        cursor: not-allowed;
      }
    }
  }
}

.month-picker-wrapper {
  max-width: 100%;
  @include flexbox-important;

  .month-picker {
    @include flex(1 1);

    &.show-calender-icon {
      position: relative;

      &::after {
        content: "\f073";
        font-family: FontAwesome;
        font-weight: bold;
        font-size: 16px;
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        @include transform(translate(0%, -50%));

        transition: all 0.4s ease;
        pointer-events: none;
      }
    }

    &:not(.in-grid) {
      min-width: 90px;
      width: 100px;
    }

    input {
      max-width: 100%;
    }

    .input-error {
      top: -15px;
      bottom: auto;
    }
  }

  &.show-to-text,
  &.show-from-text {
    .show-text-content {
      @include flex(0 0 50px);

      line-height: 39px;
      border-bottom: 1px solid #eee;
    }

    .month-picker {
      input {
        width: 100%;
      }
    }
  }
}

.month-picker.drop-down {
  border-top: 0;
  width: 100%;
  padding: 0;

  .header {
    padding: 2px 5px;
    width: 100%;
  }

  &.month-picker-double {
    width: 528px;
  }

  .date-picker-bottom {
    clear: both;

    a {
      display: block;
      width: 100%;
      line-height: 46px;
      background-color: #f8f8f8;
      color: $color-steel;
      font-size: 18px;
    }

    .svg-icon {
      margin: 0 10px;

      svg {
        fill: $color-steel;
      }
    }
  }
}

.range-month-picker-control {
  width: 528px;

  &.month-picker-control:first-child {
    border-right: none;
  }

  .top {
    > div {
      position: relative;
      height: 64px;
      float: left;
      display: inline-block;

      .year {
        width: auto;
        margin: 0 !important;
      }
    }

    .left-top-inner {
      left: 0;
      width: calc(50% - 1px);
      border-right: 2px solid $color-dark-grey;

      .left-arrow {
        float: left;
        margin-left: 18px;
      }
    }

    .right-top-inner {
      right: 0;
      width: 50%;

      .right-arrow {
        float: right;
        margin-right: 18px;
      }
    }

    .top-inner {
      height: 100%;
      padding: 0 12px;

      .top-inner-content {
        width: 100%;
        height: 100%;
      }
    }
  }

  .year {
    float: left;
  }

  .month-list-wrapper {
    width: 263px;
    float: left;

    &.left-month-list-wrapper {
      border-right: 2px solid $color-dark-grey;
    }

    ul {
      li {
        &.selected {
          div.month-wrapper {
            background-color: $color-duck-egg-blue;
            @include border-radius(50%);
          }

          &:not(.start-month):not(.end-month) {
            &:not(:nth-child(4n + 1)) {
              &::before {
                width: 11px;
                height: 44px;
                content: " ";
                @include centerVElement;

                left: 0;
                background-color: $color-duck-egg-blue;
              }
            }

            div.month-wrapper {
              @include border-radius(0);

              > div {
                color: $color-steel;
              }
            }

            &:nth-child(4n + 1) {
              div.month-wrapper {
                @include border-radius(50% 0 0 50%);
              }
            }

            &:nth-child(4n) {
              div.month-wrapper {
                @include border-radius(0 50% 50% 0);
              }
            }

            &:not(:nth-child(4n)) {
              &::after {
                width: 11px;
                height: 44px;
                content: " ";
                @include centerVElement;

                right: 0;
                background-color: $color-duck-egg-blue;
              }
            }
          }
        }

        &:not(.disable-month) {
          &.start-month,
          &.end-month {
            div.month-wrapper {
              border: 2px solid $color-duck-egg-blue;
            }
          }

          &.start-month {
            &:nth-child(4n)::after {
              height: 0;
            }

            div.month-wrapper {
              z-index: 2;
              background-color: $color-dark-mint;

              div {
                @include mid-text-white;
              }
            }

            &::after {
              width: 33px;
              height: 44px;
              content: " ";
              @include centerVElement;

              right: 0;
              background-color: $color-duck-egg-blue;
              z-index: 1;
            }
          }

          &.end-month {
            &:nth-child(4n + 1)::before {
              height: 0;
            }

            &::before {
              width: 33px;
              height: 44px;
              content: " ";
              @include centerVElement;

              left: 0;
              background-color: $color-duck-egg-blue;
              z-index: 1;
            }

            div.month-wrapper {
              z-index: 2;
              background-color: $color-dark-mint;

              div {
                @include mid-text-white;
              }
            }
          }

          &:hover {
            &.selected:not(.start-month):not(.end-month) {
              div.month-wrapper {
                > div {
                  color: $color-dark-grey-blue;
                }
              }
            }

            &:not(.selected) {
              div.month-wrapper {
                > div {
                  color: #fff;
                }
              }
            }
          }
        }

        &.disable-month {
          div.month-wrapper {
            background-color: $color-softer-grey;

            > div {
              color: $color-dark-grey-blue;
            }
          }

          cursor: not-allowed;
        }

        &.past-data-month {
          &.start-month,
          &.end-month {
            div.month-wrapper {
              background-color: $color-dark-sky-blue !important;
              border: 2px solid $color-dark-sky-blue;
            }
          }

          &.start-month {
            &::after {
              background-color: rgba($color-dark-sky-blue, 0.5);
            }
          }

          &.end-month {
            &::before {
              background-color: rgba($color-dark-sky-blue, 0.5);
            }
          }

          &:hover:not(.selected) {
            div.month-wrapper {
              background-color: $color-dark-sky-blue;
            }
          }

          &.selected {
            div.month-wrapper {
              background-color: rgba($color-dark-sky-blue, 0.5);
            }

            &:not(.start-month):not(.end-month) {
              &:not(:nth-child(4n + 1)) {
                &::before {
                  background-color: rgba($color-dark-sky-blue, 0.5);
                }
              }

              &:not(:nth-child(4n)) {
                &::after {
                  background-color: rgba($color-dark-sky-blue, 0.5);
                }
              }
            }
          }
        }
      }
    }
  }
}
