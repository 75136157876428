/* stylelint-disable selector-class-pattern */
.locales-container {
  display: flex;
  gap: 10px;
  align-items: baseline;

  .title {
    color: #777;
  }

  .labelWithDropdownContainer {
    .dropdown-header {
      padding: 0;
      font-size: 18px;
      line-height: 1px;
      color: $color-dark-grey-blue;
    }
  }

  .dropdown-body {
    .dropdown-item:hover {
      background-color: rgba($color-weird-green, 0.25);
    }
  }
}
