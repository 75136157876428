/* stylelint-disable keyframes-name-pattern */
.budgeta-progress-bar {
  height: 20px;
  width: 100%;

  &:not(.ignore-bar) {
    @include border-radius(10px);

    background-color: $color-light-mid-grey;
  }

  &.small-value {
    .progress-wrapper {
      span.small-value {
        position: absolute;
        top: -2px;
        left: 30px;
        font-weight: 500;
      }
    }
  }

  .progress-wrapper {
    animation: changeWidth 1s ease-out;

    div {
      height: 20px;
      @include border-radius(10px);

      padding: 0 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      .ignore {
        width: 100%;
      }

      &:not(.empty):not(.ignore) {
        text-align: right;
        color: white;

        &:not(.main) {
          background-color: $color-weird-green;
        }

        &.main {
          background-color: $color-dark-sky-blue;
        }
      }
    }
  }

  .progress-wrapper-title {
    color: $color-steel;
  }
}

@keyframes changeWidth {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}
