/* stylelint-disable max-nesting-depth, declaration-no-important */
.elegant-modal.intacct-wizard.wizard-modal .export-missing-mapping {
  &.integration-wizard-dimension-values-mapping-table {
    &.intacct-account-list {
      .table-header {
        @include flexbox;

        .column {
          &.dimension-name {
            width: 40%;
          }

          &.dimension-value-mapping {
            width: 60%;
          }

          &.checkbox-column {
            @include flex(0 0 40px);
          }

          &.name-status-column {
            @include flex(0 0 240px);
          }

          &.mapping {
            position: relative;
          }
        }
      }

      .table-body {
        .ember-list-view {
          > div > div > div {
            input {
              letter-spacing: 0.2px;
            }

            .mapping-column {
              .select2-chosen {
                font-weight: 300;
                letter-spacing: 0.2px;
              }
            }

            .status-column {
              width: 70px;

              input {
                font-weight: 500;
              }
            }

            .name-column,
            .mapping-column {
              input,
              .select2-chosen {
                font-weight: 300;
              }
            }
          }

          .table-edit-line {
            .table-scrollable {
              span.twitter-typeahead input:first-child {
                background-color: transparent !important;
              }
            }
          }
        }
      }
    }
  }

  &.integration-wizard-account-mapping-table {
    .multi-select-dropdown {
      border: solid 0 transparent !important;

      .selection {
        padding: 0 20px 0 8px !important;
        font-weight: 400 !important;
      }
    }
  }
}
