.input-box-container {
  border: 1px solid $color-gray-2;
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  align-content: center;
  height: 40px;
  width: 100%;
  min-width: 55px;

  .base-input-box-container {
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    align-self: flex-start;
    padding: 0 15px;

    * {
      margin: 0;
    }
  }

  &.checked {
    border-color: $color-dark-mint;
  }

  &.input-box-footer {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 10px 0;
  }
}
