/* stylelint-disable prettier/prettier, scss/at-mixin-pattern, function-linear-gradient-no-nonstandard-direction, keyframes-name-pattern, selector-pseudo-class-no-unknown, selector-pseudo-element-no-unknown, declaration-no-important, font-family-no-missing-generic-family-keyword */
@mixin long-text($width) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: $width;
  display: inline-block;
}
@mixin text-ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin flexbox {
  display: flexbox;
  display: flex;
  display: flex;
}

@mixin sticky {
  position: sticky;
  bottom: 0;
}

@mixin centerElements {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexbox-important {
  display: flexbox !important;
  display: flex !important;
  display: flex !important;
}
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  flex: $values;
}
@mixin flex-important($values) {
  -webkit-box-flex: $values !important;
  -moz-box-flex: $values !important;
  flex: $values !important;
}

@mixin flex-direction($value) {
  flex-direction: $value;
}
@mixin flex-v {
  display: flex;
  align-items: center;
}

@mixin centerElement {
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
}

@mixin full-flex-values($value, $basis) {
  -webkit-box-flex: $value;
  -moz-box-flex: $value;
  flex: $value;
  -webkit-box-flex-basis: $basis;
  -moz-box-flex-basis: $basis;
  flex-basis: $basis;
}

@mixin flex-flow($value) {
  flex-flow: $value;
}

@mixin flex-wrap($value) {
  flex-wrap: $value;
}

@mixin flex-order($order) {
  -ms-flex-order: $order;
  order: $order;
}

@mixin flex-justify($type) {
  justify-content: $type;
}

@mixin box-shadow($shadow) {
  box-shadow: $shadow;
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin border-radius-top($radius) {
  -moz-border-radius-topleft: $radius;
  -moz-border-radius-topright: $radius;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-radius-bottom($radius) {
  -moz-border-radius-bottomleft: $radius;
  -moz-border-radius-bottomright: $radius;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-radius-bottom-right($radius) {
  -moz-border-radius-bottomright: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-radius-right($radius) {
  -moz-border-radius-topright: $radius;
  -moz-border-radius-bottomright: $radius;
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin transform($transform) {
  transform: $transform;
}

@mixin transition($transition) {
  transition: $transition;
}

@mixin user-select($arg) {
  user-select: $arg;
}

@mixin gradient($start, $end, $stop) {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, $start),
    color-stop($stop, $start),
    color-stop($stop, $end),
    color-stop(100%, $end)
  );
  background: linear-gradient(left, $start 0%, $start $stop, $end $stop, $end 100%);
  background: linear-gradient(to right, $start 0%, $start $stop, $end $stop, $end 100%);
}

@mixin placeholder-color($color) {
  ::input-placeholder {
    color: $color;
  }

  :placeholder {
    /* Firefox 18- */
    color: $color;
    opacity: 1;
  }

  ::placeholder {
    /* Firefox 19+ */
    color: $color;
    opacity: 1;
  }

  :input-placeholder {
    color: $color;
  }
}
@mixin placeholder-color-and($color) {
  &::input-placeholder {
    color: $color;
  }

  &:placeholder {
    /* Firefox 18- */
    color: $color;
    opacity: 1;
  }

  &::placeholder {
    /* Firefox 19+ */
    color: $color;
    opacity: 1;
  }

  &:input-placeholder {
    color: $color;
  }
}

@mixin placeholder-font-size($size) {
  ::input-placeholder {
    font-size: $size;
  }

  :placeholder {
    /* Firefox 18- */
    font-size: $size;
  }

  ::placeholder {
    /* Firefox 19+ */
    font-size: $size;
  }

  :input-placeholder {
    font-size: $size;
  }
}
@mixin keyframes($animationName) {
  @keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation($delay, $duration, $animation) {
  /* this prevents the animation from restarting! */
  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: forwards;
}

@mixin structure-summary($color, $fontSize, $fontWeight, $borderTop: null) {
  color: $color;
  font-size: $fontSize;
  font-weight: $fontWeight;
  padding: 0 32px 0 18px;
  line-height: 32px;

  @if $borderTop {
    border-top: 1px solid $borderTop;
  }
}

@mixin descending-z-index($count: 100) {
  $target: 0;
  $index: 0;
  @while $index < $count {
    &:nth-child(#{$target}) {
      z-index: #{-$index};
    }

    $target: $target + 1;
    $index: $index + 1;
  }
}

@mixin force-mac-scroll() {
  &::-webkit-scrollbar-thumb,
  &::scrollbar-thumb {
    border-radius: 8px;
    border: 4px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar,
  &::scrollbar {
    appearance: none;
  }
}

@mixin center-sign {
  &::after {
    top: 50%;
    @include transform(translate(0%, -50%));
  }
}

@mixin dropdown-sign {
  position: relative;

  &::after {
    content: "\f107";
    color: #adadad;
    font-family: FontAwesome;
    font-weight: bold;
    font-size: 16px;
    display: block;
    position: absolute;
    right: 5px;
    top: 8px;
    pointer-events: none;
  }

  .center-sign {
    @include center-sign;
  }
}

@mixin ignore-hover {
  cursor: default;
}

@mixin white-icon {
  .icon {
    fill: #fff;
    color: #fff;
  }
}

@mixin centerVElement {
  position: absolute;
  top: 50%;
  @include transform(translate(0%, -50%));
}

@mixin font-sidebar {
  font-size: 18px;
  line-height: 24px;
}

@mixin font-sub-header {
  font-size: 18px;
  font-weight: 400;
}

@mixin font-content {
  font-family: Metric, sans-serif;
  @include mid-text-grey-blue;

  line-height: 24px;
}

@mixin overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin highlight-value-border {
  border-color: $color-weird-green !important;

  * {
    border-color: $color-weird-green !important;
  }
}

@mixin highlight-self-value {
  cursor: pointer;
  background-color: rgba($color-weird-green, 0.05) !important;
}

@mixin font-header {
  font-size: 19px;
  line-height: 45px;
  font-weight: 400;
  color: #ddd;
}

@mixin centerElementsRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin highlight-value {
  cursor: pointer;

  input,
  .select2-container,
  textarea,
  div.account-mapping-menu,
  span.select-2-lazy-inactive-label,
  .multi-select-dropdown.wizard-field div {
    background-color: rgba($color-weird-green, 0.05) !important;

    input,
    .select2-container,
    textarea,
    div.account-mapping-menu,
    span.select-2-lazy-inactive-label {
      background-color: transparent !important;
    }
  }
}

@mixin add-icon-list {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 50%;
  background-color: $color-weird-green;
  @include flex(0 0 40px !important);

  .svg-icon {
    .icon {
      fill: $color-white !important;
    }
  }
}

@mixin size-2-6 {
  @media screen and (min-width: 1500px) {
    @include full-flex-values(0, calc(33% - 33px));
  }
}

@mixin size-2-7 {
  @media screen and (min-width: 1500px) {
    @include full-flex-values(0, auto);
  }
}

@mixin size-3-6 {
  @media screen and (min-width: 1500px) {
    @include full-flex-values(0, calc(50% - 36px));
  }
}

@mixin size-4-6 {
  @media screen and (min-width: 1500px) {
    @include full-flex-values(0, calc(66% - 26px));
  }
}

@mixin size-6-6 {
  @media screen and (min-width: 1500px) {
    @include full-flex-values(0, calc(100% - 36px));
  }
}

@mixin size-2-4 {
  @media screen and (max-width: 1500px) {
    @include full-flex-values(0, calc(50% - 31px));
  }
}

@mixin size-3-4 {
  @media screen and (max-width: 1500px) {
    @include full-flex-values(0, calc(75% - 36px));
  }
}

@mixin size-4-4 {
  @media screen and (max-width: 1500px) {
    @include full-flex-values(0, calc(100% - 26px));
  }
}

@mixin size-4-4-small {
  @media screen and (max-width: 1350px) {
    @include full-flex-values(0, calc(100% - 26px));
  }
}
