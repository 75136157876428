/* stylelint-disable max-nesting-depth */
.input-grid-view {
  .budget-lines-grid {
    .scroller {
      overflow-y: scroll;
    }

    .grid-header {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      background-color: $color-light-mid-opacity-grey;
      border-bottom: solid 1px $color-light-steel-gray;

      .grid-filters {
        width: calc(100% - 140px);
      }

      .grid-control {
        height: inherit;
        width: 140px;

        .grid-toggle-area {
          float: right;
          padding-top: 20px;
          padding-right: 25px;

          label {
            margin-bottom: 0;
          }
        }
      }
    }

    .grid-main {
      display: flex;
      flex-direction: column;

      .backdrop {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
      }

      .grid-container {
        .rg-header-cell {
          border-color: white;
        }

        .grid-header-title {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 1.2px;
          color: $color_steel;
        }

        .grid-sub-header-title {
          font-size: 15px;
          font-weight: 400;
          padding-bottom: 10px;
          color: $color_steel;
        }

        .top-level {
          -webkit-text-stroke: 0.5px;
          letter-spacing: 0.2px;
        }

        .rg-cell-blue {
          .rg-currency-wrapper {
            color: $color-dark-sky-blue;
          }
        }

        .rg-celleditor {
          padding: 0;
          border-width: 1px;

          // override sibp style
          input {
            height: initial;
            border: inherit;
            padding: 0;
            width: inherit;
            outline: 0;
          }
        }

        .rg-cell-highlight {
          border-right: 0;
          border-left: 0;
        }
      }
    }
  }

  .grid-editable-budget-line-input {
    // setting display:none will cause grid values to get deleted
    // so we hide it on screen
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
}
