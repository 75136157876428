// template-lint-disable prettier
// Rebrand 2023
.profile-user-menu-wrapper {
  display: none; /* required because of qtip behavior */
}

// need to remove the nesting when qtip.scss will be deleted
.qtip.qtip .qtip-content .profile-user-menu-wrapper {
  padding: 0;
}

.profile-user-menu {
  .profile-user-menu-wrapper {
    .profile-menu-list {
      padding: 8px 0;
      margin-bottom: 0;
      width: 250px;
      max-height: 210px;
      overflow-y: auto;
      overflow-x: hidden;

      .xpna-menu-item {
        & > div {
          display: flex;
          align-items: center;
        }

        .xpna-sign-in {
          margin-left: 4px;
        }
      }

      li {
        display: flex;
        cursor: pointer;
        line-height: var(--list-item-line-height);
        font-size: var(--list-item-font-size);
        color: var(--list-item-text-color);
        padding: 10px 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;

        &.my-profile a,
        .my-profile {
          display: flex;
          flex-grow: 1;
        }

        &:hover {
          background-color: var(--list-item-hover-color);
        }
      }

      .icon {
        margin-right: 4px;

        svg {
          fill: var(--list-item-icon-color);
        }
      }
    }
  }

  .sub-menu {
    display: flex;
    align-items: flex-end;
    padding: 8px 0;
    border-top: 1px solid var(--color-gray-101);

    .sub-menu-heading {
      cursor: pointer;
      line-height: var(--list-item-line-height);
      font-size: var(--list-item-font-size);
      color: var(--color-green-101);
      font-weight: 500;
      padding: 8px 8px 8px 16px;
    }

    .icon {
      cursor: pointer;

      svg {
        fill: var(--color-green-101);
      }
    }

    &:hover {
      background-color: var(--list-item-hover-color);
    }
  }
}
