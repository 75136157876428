/* stylelint-disable selector-class-pattern */
.app-header-container {
  @include flexbox;

  align-items: center;
  justify-content: space-between;
  height: $header-height;
  background-color: $color-black-1;
  padding: 0 30px 0 25px;

  .company-logo {
    margin-top: 5px;
  }

  .right-side {
    display: flex;
    color: $color-white-1;
    margin-top: -3px;
  }

  .header-company {
    margin-right: 20px;
    color: $color-white;
  }

  .header-username {
    .help-link {
      text-decoration: none;
      color: inherit;
    }
  }

  .dropdown-header {
    color: $color-white-1;

    &:hover {
      color: $color-green-8;
    }

    svg {
      fill: $color-white-1;

      &:hover {
        fill: $color-green-8;
      }
    }
  }

  .dropdown-header.open {
    color: $color-green-8;

    svg {
      fill: $color-green-8;
    }
  }

  .dropdown-body.open {
    padding: 10px 0;
    right: 10px;
    min-width: 190px;
  }

  .dropdown-item {
    padding-left: 20px;

    &:hover {
      background-color: rgba($color-green-1, 0.25);
    }

    &:last-of-type {
      border-top: 1px solid $color-gray-7;
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 10px;
      margin-top: 10px;
    }
  }

  .label-with-dropdown .dropdown-body {
    // prevent exist style that comes from sibp for rebrand_2023
    padding-bottom: 0;
  }
}
