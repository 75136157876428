/* stylelint-disable max-nesting-depth, selector-class-pattern */
.currencies-mapping-body-container {
  .sibp-checkbox {
    label {
      display: flex;
      margin-left: 10px;
      margin-bottom: 22px;
      font-weight: 400;

      &::before {
        min-width: 20px;
        height: 20px;
      }
    }

    .label {
      color: $font-color;
      display: flex;
      padding: 0;
      font-size: 18px;
      font-weight: unset;
    }
  }

  .values {
    .currencies {
      height: 375px;
      width: 570px;
      margin-left: -5px;
    }

    .add-remove-label {
      display: flex;
      height: 45px;
      border-bottom: 1px solid $color-light-steel-gray;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 1px;
      padding-left: 15px;
      align-items: center;

      > svg {
        margin-left: 5px;
        margin-right: 10px;
        fill: $font-color;
      }
    }

    .currency-value {
      display: flex;
      margin-bottom: 30px;
      margin-top: 30px;

      .select-container ~ div {
        left: 250px;
        top: inherit;
        position: absolute;
      }

      .name {
        width: 190px;
        @include text-ellipsis;

        line-height: 44px;
        margin-right: 42px;
      }

      .select__menu {
        margin-top: 0;
        margin-left: 8px;

        .select__menu-list {
          div:first-child {
            background-color: $font-color-white;
          }

          max-height: 180px;

          .select-clear-option {
            .clear-all,
            .select-all {
              cursor: pointer;
            }
          }
        }
      }

      .select__multi-value {
        background-color: $color-gray-4;
      }

      .select__control {
        width: 300px;
        border: 0;
        border-bottom: 1px solid $color-gray-2;
        border-radius: 0;
        box-shadow: none;
      }

      .select__indicator-separator {
        display: none;
      }

      .select__option--is-focused {
        background-color: $color-dropdown-hover;
      }

      .select__option--is-selected {
        background-color: $font-color-white;
      }

      .select__option--is-disabled,
      .select__option--is-selected {
        color: $color-gray-2;
        cursor: not-allowed;
      }
    }
  }

  .add-remove-select.currency-value {
    margin-left: 30px;
    margin-bottom: 0;
    margin-top: 0;
    position: absolute;

    .all-actions {
      display: flex;
      margin-bottom: 15px;
    }

    .select__menu-list {
      .select-clear-option {
        margin-top: 9px;
        margin-bottom: 22px;

        .clear-all {
          cursor: pointer;
        }

        .select-all {
          cursor: pointer;
          margin-left: 16px;
          margin-right: 0;
        }
      }
    }

    .select__control {
      border: 1px solid $color-light-steel-gray;
      width: 197px;
      height: 40px;
      top: -5px;
      z-index: 2;
      box-shadow: 0 -2px 6px 0 rgba(204, 204, 204, 0.5), 0 0 0 1px rgba(204, 204, 204, 0.5);
      border-top: 1px;
      border-bottom: 1px;
      border-right: 1px;
      border-left: 1px;

      .select__value-container {
        height: 40px;
      }

      .select__placeholder {
        color: $color-steel;
      }
    }

    .select__indicator {
      > svg {
        visibility: hidden;
      }
    }

    .select__menu {
      margin: 0;
      top: 35px;
      width: 197px;
      border-top: 1px;
      border-bottom: 1px;
      z-index: 1;
    }

    .select-all {
      cursor: pointer;
      margin-left: 10px;
      margin-right: 8px;
    }

    .remove-all {
      cursor: pointer;
      margin-left: 8px;
    }

    > svg {
      margin-left: 21px;
    }
  }
}
