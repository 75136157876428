.user-edit-container {
  .input-container {
    margin-bottom: 20px;
  }

  .page-footer {
    display: flex;
    justify-content: space-between;

    .cancel-btn {
      margin-right: 10px;
    }

    .left-side {
      display: flex;
      align-items: center;
    }
  }
}
