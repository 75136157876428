/* stylelint-disable declaration-no-important */
body > .app-view > header.no-header {
  background: transparent;

  nav {
    display: none;
  }
}

.user-input-with-label {
  margin: 10px 0;

  * {
    line-height: 50px;
  }

  label {
    width: 200px;
    text-align: left;
  }

  input,
  .select2-container {
    @include border-radius(3px);

    border: solid 1px #ced3d8;
    height: 50px !important;
    width: 250px;
  }
}

.user-input-checkbox {
  text-align: center;

  div {
    line-height: 55px;
  }

  > div {
    margin: 0 auto;
    width: 450px;

    > div {
      text-align: left;
      float: left;
      width: 250px;

      &.budgeta-checkbox {
        width: 200px;
      }

      &.silver {
        color: $color-silver;
      }
    }
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.icon-input {
  width: 100%;
  @include flexbox;
  @include border-radius(3px);

  border: solid 1px #ced3d8;

  * {
    line-height: 50px;
  }

  input {
    &[type="text"],
    &[type="email"],
    &[type="password"],
    &[type="file"] {
      border-bottom: 0 !important;
      border-left: solid 1px #ced3d8 !important;
      line-height: 50px !important;
      height: 50px !important;
      font-size: 18px !important;
      padding: 0 20px !important;
    }
  }

  .svg-icon {
    width: 50px;

    .icon {
      fill: #9da0a3;
    }

    &:hover {
      cursor: default;
    }
  }

  &.has-error {
    border: solid 1px $color-salmon;

    .svg-icon {
      .icon {
        fill: $color-salmon;
      }
    }

    input {
      &[type="text"],
      &[type="email"],
      &[type="password"],
      &[type="file"] {
        border-left: solid 1px $color-salmon !important;
      }
    }
  }
}
