/* stylelint-disable max-nesting-depth */
.elegant-modal.intacct-wizard.wizard-modal .export-missing-mapping {
  .scrollable {
    .flex {
      .checkbox-inline {
        @include flex(0 0 35px!important);

        padding-left: 0;

        &.with-hover:not(.selected) {
          .budgeta-checkbox {
            display: none;
          }
        }

        &.with-hover.selected {
          .budgeta-checkbox {
            display: block;
          }
        }
      }

      &:hover {
        .checkbox-inline.with-hover {
          .budgeta-checkbox {
            display: block;
          }
        }
      }
    }
  }

  .multi-select-dropdown {
    > div {
      line-height: 40px;

      &.placeholder {
        font-weight: 300;
      }
    }

    border-bottom: 1px solid #eee;
  }
}
