.company-user-list-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .users-list-header {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    h2 {
      padding: 0;
    }

    .right-side {
      display: flex;
    }

    .input-container {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}
