/* stylelint-disable max-nesting-depth, font-family-no-missing-generic-family-keyword, no-duplicate-selectors, declaration-no-important */
.intacct-budget-structure-edit {
  .removed-budgets-list {
    border-top: solid 1px $color-silver;
    border-bottom: solid 1px $color-silver;
    background-color: $color-light-white;
    height: 303px;
    position: relative;
  }

  .error-container {
    border-top: solid 0.5px $color-silver;
    border-bottom: solid 0.5px $color-silver;
    background-color: $color-light-white;
    height: inherit;
    width: inherit;
    position: relative;

    &.intacct-wizard {
      height: 440px;
    }

    .error-content {
      .alert-container {
        margin: 32px;
        width: inherit;
        height: inherit;

        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 40px 0 0;
          border-radius: 0;
        }

        .alert-text {
          padding: 12px 24px;
          border-radius: 0;
        }
      }
    }
  }

  .ember-list-view.ember-list-view-list {
    > div.ember-view {
      padding-top: 15px;
      height: calc(100% - 40px);
      border-bottom: solid 1px $color-silver;
    }

    > div > div > div {
      @for $i from 1 through 9 {
        .budget-structure-line[data-level="#{$i}"] {
          &:not(.disable-delete) {
            margin-left: (($i - 1) * 30px);
          }
        }
      }
      @for $i from 2 through 9 {
        .budget-structure-line[data-level="#{$i}"].budget-structure-line-row .collapse-expend-icon.svg-icon {
          left: ($i * 20px);
        }
      }

      .budget-structure-line {
        &.disable-delete {
          .budget-structure-line-content {
            .name,
            .actuals-number {
              font-size: 16px;
              font-weight: 500;
              text-transform: uppercase;
            }
          }
        }

        &[data-level="0"] {
          .name,
          .actuals-number {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
          }

          .budget-structure-line-content {
            grid-template-columns: 30px auto 180px;
            align-items: center;
            height: 36px;

            .collapse-expend-icon {
              position: absolute;
              left: 8px;
              margin-top: 8px;

              &.expend {
                margin-top: 5px;
                left: 10px;
              }
            }
          }
        }

        &:not([data-level="0"]) {
          .name,
          .actuals-number {
            font-size: 18px;
          }
        }

        .budget-structure-line-content {
          width: 100%;
          display: grid;

          .actuals-number {
            padding-left: 20px;
          }
        }

        &.budget-structure-line-row {
          .budget-structure-line-content {
            grid-template-columns: 30px auto 180px;
          }

          .collapse-expend-icon {
            position: absolute;
            left: 8px;
            margin-top: 3px;

            &.expend {
              margin-top: 0;
              left: 10px;
            }
          }
        }

        &.show-line-border {
          @include flexbox;

          align-items: center;
        }
      }

      .name {
        letter-spacing: 0.2px;
      }

      .deleted.has-value {
        text-decoration: line-through;
      }

      .budget-structure-line {
        height: 36px;

        .line-border {
          height: 1px;
          width: 100%;
          background-color: $color-silver;
        }

        .repeat {
          height: 20px;
          margin: 2px 10px 0 12px;
          @include border-radius(50%);

          position: relative;

          &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));

            font-family: FontAwesome;
            font-size: 14px;
            display: block;
            transition: all 0.4s ease;
          }

          @include transform(rotateY(180deg));

          &::after {
            font-size: 18px;
            content: "\f01e";
          }
        }

        .svg-icon:not(.collapse-expend-icon),
        .placholder {
          height: 24px;

          .icon {
            margin: 0 0 3px 3px;
            fill: $color-red-dark;
          }
        }

        &:hover {
          .svg-icon:not(.collapse-expend-icon) {
            display: block;
          }

          .placholder {
            display: none;
          }
        }

        &:not(:hover) {
          .svg-icon:not(.collapse-expend-icon) {
            display: none;
          }

          .placholder {
            display: block;
          }
        }
      }
    }
  }

  .total {
    line-height: 52px;
    color: $color-dark-mint;
    font-size: 16px;
    font-weight: 500;
    margin-left: auto;
    margin-right: 50px;

    &.info-popup-shown {
      margin-right: 5px;
    }
  }

  .total-budget-lines {
    line-height: 52px;
    color: $color-dark-mint;
    font-size: 16px;
    font-weight: 500;
  }

  .exceeded-total-budget-lines-warning {
    color: $color-orange;
  }

  .exceeded-total-budget-lines {
    color: $color-red-dark;
  }

  &.no-actuals-data
    .ember-list-view.ember-list-view-list
    > div
    > div
    > div
    .budget-structure-line.budget-structure-line-row
    .budget-structure-line-content
    .name {
    @include flex(0 0 100%);
  }

  .remove-empty-lines .budgeta-checkbox {
    position: absolute;
    bottom: 0;
    padding: 0 0 4px 11px;
  }

  .budget-structure-tabs {
    border-top: 1px solid $color_silver;
    border-bottom: 1px solid $color_silver;
    display: flex;
    flex-direction: row;
    align-items: center;

    .tab {
      margin-top: 11px;
      margin-bottom: 11px;

      &.budget-lines,
      &.statistical-lines {
        margin-left: 16px;
      }
    }

    .help-icon {
      margin-left: 135px;
    }

    .popup-with-label-container {
      width: 20px;
      margin-right: 30px;
    }

    .popup-container {
      width: 240px;
      height: auto;
      right: -23px;
      z-index: 2;
    }

    .message {
      margin: 17px 17px 20px;

      .link {
        cursor: pointer;
        color: $color-blue-changes;

        &.a {
          text-decoration: none;
        }
      }
    }
  }
}
