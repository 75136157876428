/* stylelint-disable max-nesting-depth, scss/at-import-partial-extension, selector-class-pattern */
@import "sip-components/styles/radio-group-styles/number-in-row";

.budget-settings-body-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  margin: 40px auto;
  width: 400px;

  .width-inherit .base-input-container {
    width: inherit;
  }

  .no-margin-radio {
    * {
      margin: 0;
    }

    label {
      position: relative;
      bottom: 1px;
    }
  }

  .complex-input-container {
    display: flex;
    align-items: center;
    gap: 30px;
    min-height: 60px;
    width: 100%;

    &.one-line-container {
      min-height: 40px;
    }

    .input-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: inherit;
      width: inherit;

      > span {
        color: $color-steel;
      }

      &.one-line-section {
        flex-direction: row;
        width: auto;

        span {
          width: 131px;
          line-height: 40px;
        }

        > .input-container {
          margin-left: 59.5px;
          width: 224.5px;
        }
      }

      .input-container {
        width: inherit;

        .select__value-container {
          .select__placeholder {
            margin-top: 3px;
            color: $font-color;
          }
        }

        input.no-padding {
          padding: 0;
        }

        .base-input-container {
          width: 100%;

          input[value=""]::placeholder {
            color: $font-color;
          }
        }
      }

      .width-inherit {
        width: inherit;
      }

      .input-select-dropdown {
        .name {
          width: 190px;
          @include text-ellipsis;

          line-height: 44px;
        }

        .select__dropdown-indicator {
          > svg {
            margin-bottom: -5px;
          }
        }

        .select__single-value {
          margin-top: 3px;
        }

        .select__value-container {
          padding-left: 0;
          margin-left: -2px;
        }

        .select__menu-list {
          max-height: 180px;
        }

        .select__multi-value {
          background-color: $color-gray-4;
        }

        .select__control {
          border: 0;
          border-bottom: 1px solid $color-gray-2;
          border-radius: 0;
          box-shadow: none;
        }

        .select__indicator-separator {
          display: none;
        }

        .select__option--is-selected {
          background-color: $font-color-white;
        }

        .select__option--is-focused {
          background-color: $color-dropdown-hover;
        }

        .select__placeholder {
          color: $color-light-steel;
        }
      }
    }

    .prefix {
      align-self: end;
    }

    &.budget-type {
      min-height: 70px;
    }
  }

  #budget-date-range-picker.calendar-range-picker {
    width: inherit;

    > section {
      position: absolute;
    }

    button {
      abbr,
      span {
        color: $color-steel;
      }

      &.react-calendar__tile--hasActive > * {
        color: white;
      }
    }

    section.open-down {
      z-index: 1;
      right: 0;
    }

    .react-calendar__viewContainer {
      button {
        &.react-calendar__tile--rangeStart > *,
        &.react-calendar__tile--rangeEnd > *,
        &:hover > * {
          color: white;
        }

        &.react-calendar__tile--active:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd) {
          &:hover > * {
            color: $color-dark-grey-blue;
          }
        }
      }
    }
  }
}
