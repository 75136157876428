/* stylelint-disable max-nesting-depth, scss/at-mixin-pattern, scss/double-slash-comment-whitespace-inside, font-family-no-missing-generic-family-keyword, no-duplicate-selectors, selector-class-pattern, scss/percent-placeholder-pattern, declaration-no-important */
.ml40 {
  margin-left: 40px;
}

.mr40 {
  margin-right: 40px;
}

.mt20 {
  margin-top: 20px !important;
}

.mt16 {
  margin-top: 16px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.padding-top48 {
  padding-top: 48px !important;
}

.center-text {
  text-align: center;
}

.centerElement {
  @include centerElement;
}

.centerElements,
%centerElements {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerElementsV {
  display: flex;
  align-items: center;
}

.centerHElement {
  position: absolute;
  left: 50%;
  @include transform(translate(-50%, 0%));
}

.centerVElement {
  @include centerVElement;
}

.rightElement {
  position: absolute;
  right: 0;
}

@mixin leftElement {
  position: absolute;
  left: 0;
}

.leftElement {
  @include leftElement;
}

.right-top {
  position: absolute;
  right: 10px;
  top: 10px;
}

.add-performance-logger-container {
  right: 500px;
}

.displayNone {
  display: none;
}

::selection {
  background-color: $color-highlight;
}

::-webkit-selection {
  background-color: $color-highlight;
}

::selection {
  background-color: $color-highlight;
}

.normalWeight {
  font-weight: normal;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input:disabled {
  background-color: #f1f1f1;
}

div.budgeta-focus {
  @include highlight-value-border;
  @include highlight-value;
}

.section-dimensions {
  .selectize-input.full {
    input {
      // important is required to override inline styles
      position: absolute !important; //required to not affect parent outerHeight
      width: 0 !important; //required to not display the cursor when the input is focues
    }
  }
}

.selectize-control {
  width: 100%;
  height: 40px;

  .selectize-input {
    background-color: transparent !important;
    border: 0;
    padding: 0;
    @include box-shadow(0 0 0);

    background-image: none !important;

    .item {
      width: 100%;
      line-height: 40px;
      @include big-mid-text-grey-blue;
      @include overflow-ellipsis;
    }

    &.disabled {
      opacity: 1;

      &::after {
        display: none;
      }
    }
  }

  &.single {
    .selectize-input {
      @include box-shadow(none);

      &::after {
        content: "\f107";
        font-family: FontAwesome;
        font-weight: bold;
        font-size: 16px;
        line-height: 40px;
        height: 40px;
        width: 10px;
        border-width: 0 !important;
        top: 0 !important;
        margin-top: 0 !important;
        @include transition(all 0.2s ease);

        // @include transform(translate(-50%, -50%));
      }

      &.dropdown-active::after {
        @include transform(rotate(-180deg));
      }
    }
  }

  .selectize-dropdown {
    @include box-shadow(0 4px 11px rgba(0, 0, 0, 0.3));

    border: 1px solid #dfdfdf !important;

    .active {
      background-color: rgba($color-weird-green, 0.25) !important;
    }

    [data-selectable],
    .optgroup-header {
      padding: 8px 20px;
    }

    .selectize-dropdown-content {
      width: 100%;
      padding: 16px 0;

      .create {
        width: 100%;
      }

      .option {
        display: block !important;

        span.highlight {
          display: inline !important;
          padding: 0;
          background: transparent;
        }
      }
    }
  }
}

.highlight-value-hover {
  &:hover {
    @include highlight-value;
  }
}

.highlight-value-border {
  @include highlight-value-border;
}

.overflow-ellipsis {
  @include overflow-ellipsis;
}

.overflow-hidden {
  overflow-y: hidden;
}

.overflow-auto {
  overflow-y: auto;
}

ul,
ol {
  list-style: none;
  padding: 0;
}

@include placeholder-color(#d0d0d0);

.select2-container-multi .select2-choices .select2-search-field input {
  font-family: Metric, sans-serif;
}

button.no-style {
  border: 0;
  background: none;
}

button:not(.close):not(.no-style):not(.pika-button):not(.pika-prev):not(.pika-next):not(.calculator-btn):not(
    [class*="react-calendar"]
  ):not(.round),
.btn {
  &:not(.btn-no-fill) {
    background-color: $color-weird-green;
    border: 0;
  }

  &.btn-no-fill {
    border: 2px solid #fff;
  }

  &.btn-hollow {
    background-color: transparent;
    color: $color-weird-green;
    border: 1px solid $color-weird-green;

    &:hover {
      background-color: $color-weird-green;
      color: #fff;
    }
  }

  &,
  * {
    @include mid-text-white;
  }

  outline: 0;
  line-height: 37px;
  padding: 0 15px;

  &:hover {
    background-color: $color-dark-mint;
  }
  @include mid-text-white;
  @include border-radius(3px);

  &.cancel,
  &.inactive {
    background: transparent;
    border: 0;
    @include mid-text-steel;

    &:hover {
      color: $color-grey-dark-black;
    }
  }

  &.back {
    background: transparent;
    border: 0;
    @include mid-text-steel;

    &:hover {
      color: $font-color;
    }
  }

  &.disabled {
    background: $color-mercury;
  }

  &.with-border {
    border: 1px solid $color-dark-grey-blue;
    color: $color-dark-grey-blue;

    &.disabled {
      border: 1px solid $color-light-steel-gray;
      background: $color-regular-white;
      color: $color-light-steel-gray;
    }
  }

  &.big-button {
    line-height: 40px;
    border-radius: 3px;
    min-width: 80px;
  }

  &.round-button {
    display: inline-block;
    margin-right: 10px;
    color: $color-steel;
    @include border-radius(100px);

    border: 2px solid transparent;
    height: 40px;
    padding: 0 18px;
    line-height: 36px;
    cursor: pointer;

    &:hover {
      border: 2px solid $color-dark-mint;
    }

    &:not(.active) {
      background-color: transparent;
    }

    &.active {
      color: $color-white;
      border: 2px solid $color-dark-mint;
      background-color: $color-dark-mint;
    }
  }

  &.btn-left {
    float: left;
  }
}

a {
  outline: 0;
  color: $font-color;

  &:hover,
  &:focus {
    color: $font-color;
    cursor: pointer;
    text-decoration: none;
  }
}

span.deletable {
  background: #ebebeb;
  padding: 2px 10px;
  margin: 0 2px;

  &::after {
    content: "\2715";
    display: inline-block;
    margin-left: 5px;
    font-weight: 400;
    color: #717171;
    font-size: 12px;
  }

  &:hover {
    &::after {
      color: #000;
    }
  }
}

:not(form)[data-ember-action] {
  cursor: pointer;
}

input[type="submit"]:not(.btn) {
  outline: 0;
  border: 0;
  background: none;
  padding: 0;
  font-weight: 300;
}

input[type="file"] {
  width: 100%;
  border: 0;
  line-height: 1;
  margin: 10px 0;
}

.ember-application > div.app-view {
  height: calc(100% - 32px);
  overflow: visible;

  > button.open-log-button {
    width: 200px !important;
    top: 10px;
    border-radius: 1px !important;
  }

  > header > div.centerHElement {
    margin-top: 5px;

    > span.maintnance {
      margin-right: 5px;
      line-height: 37px;
      animation: color-change 1.5s infinite;
      letter-spacing: 1px;
      @keyframes color-change {
        0% {
          color: #fff;
        }

        50% {
          color: #39da95;
        }

        100% {
          color: #fff;
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.add-performance-logger-container {
    width: calc(100% - 500px);
  }

  &.app-view-iframe {
    border-radius: 3px;

    > header {
      display: none;
    }

    > .main-content {
      height: 100%;
    }
  }

  > .ember-view.main-view {
    height: calc(100% - 50px);

    .launch-wizard-wrapper {
      position: fixed;
      top: 0;
      left: 50%;
      @include transform(translate(-50%, 0%));
      @include border-radius(0 0 3px 3px);

      height: 48px;
      width: 524px;
      background-color: $color-dark-sky-blue;

      .launch-wizard-content {
        text-align: center;
        width: 100%;

        span {
          @include small-text-white;

          &.relaunch-btn {
            padding-left: 12px;
            text-decoration: underline;
          }
        }

        .svg-icon {
          right: 12px;

          svg.icon {
            fill: #fff;
          }
        }
      }
    }
  }
}

body,
html,
* {
  box-sizing: border-box;
}

body > div.ember-view {
  position: fixed;
  height: 100%;
  width: 100%;
  background: $color-light-grey;

  &.login-container {
    background: $color-black-darker;
  }
}

label {
  @include font-content;

  &[for="remember_me"] {
    width: auto;
    font-weight: normal;
    font-size: 0.8em;
    line-height: 1;
  }

  &.error {
    @include small-text-red;

    position: absolute;
    margin-top: -3px;
  }

  &.green {
    color: $color-dark-mint !important;
    font-weight: 500 !important;
  }
}

p.input-error {
  position: absolute;
  height: max-content;
  @include small-text-red;
}

input,
textarea,
select,
.typeahead,
.tt-query,
.tt-hint {
  @include font-content;
  @include border-radius(0);

  &:not(.no-padding) {
    padding: 0 10px;
  }

  &:not(.underline) {
    border: 1px solid #e8e8e7;
  }

  &.no-border {
    border: none;
  }

  width: auto;
  height: auto;

  &.underline {
    border-bottom: 1px solid $color-silver;
    border-top: none;
    border-right: none;
    border-left: none;
  }

  &:not(.no-outline) {
    &:focus {
      outline: #3f84b5 solid 1px;
    }
  }

  &.no-outline {
    &:focus {
      outline: none;
    }
  }

  &.invalid {
    outline: $color-error solid 1px;
    outline-offset: -2px;
  }

  &:required {
    box-shadow: none;
  }
}

.textarea-auto-height {
  padding: 0;
  border: none;
  resize: none;
  outline: 0;
  min-height: 24px;
  overflow-y: hidden;
}

.has-error {
  input.enable-invalid {
    outline: $color-error solid 1px;
    outline-offset: -2px;
  }
}

input:not([type="checkbox"]):not([type="radio"]) {
  height: 35px;
}

textarea {
  resize: none;
  width: 100%;
}

.error {
  &.min-height {
    min-height: 30px;
  }
  @include small-text-red;
}

header {
  position: relative;
  width: 100%;
  height: 48px;
  padding: 0 15px 0 25px;
  background: $color-black-darker;

  &.front-order {
    z-index: 999999;
  }

  a {
    &,
    * {
      color: #ddd;
      font-weight: 400;

      &:hover {
        color: #ddd;
        text-decoration: none;
      }
    }
  }

  span.beta {
    display: inline-block;
    text-transform: uppercase;
    line-height: 22px;
    width: 42px;
    background-color: $color-dark-green;
    @include border-radius(1px);

    text-align: center;
    @include small-14-white;
  }

  h1 {
    float: left;
    margin: 0;
    height: 100%;

    &,
    * {
      vertical-align: middle;
      @include font-header;
    }
  }

  .headerLogo {
    margin-top: 4px;
  }

  nav {
    float: right;
    @include font-sub-header;

    height: 100%;

    > * {
      float: left;
      line-height: 45px;
    }

    > a {
      color: $color-white;
      margin-right: 30px;

      &:hover {
        color: $color-green-sage-logo;
      }
    }

    .dropdown {
      border: 0;

      &.user-menu {
        float: left;

        > a {
          line-height: 45px !important;

          &::after {
            line-height: 45px !important;
            top: 3px;
            right: 15px;
            color: $color-white;
          }

          &:hover::after {
            color: $color-green-sage-logo;
          }
        }
      }
    }

    > .svg-icon {
      svg.icon {
        fill: $color-silver !important;
      }

      &:hover {
        svg.icon {
          fill: $color-weird-green !important;
        }
      }
    }
  }

  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.ignore-hover {
  @include ignore-hover;
}

.svg-store {
  display: none;
}

.svg-icon {
  display: inline-block;
  cursor: pointer;

  &.elegant-close-button span {
    font-size: 14px;
    color: #1b1b1b;
    font-weight: 500;
  }

  &.empty-svg-icon {
    width: 24px;
  }

  .icon {
    pointer-events: none;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    fill: $color-grey-light-black;
  }

  &.icon-error {
    .icon {
      fill: $color-red;
    }

    &:hover {
      * {
        fill: $color-red;
      }
    }
  }

  &.icon-warning {
    position: absolute;
    right: 5px;

    .icon {
      fill: $color-warning-orange;
    }

    &:hover {
      * {
        fill: $color-warning-orange;
      }
    }
  }

  &.big-icon {
    .icon {
      width: 48px;
      height: 48px;
    }
  }

  &.mid-icon {
    .icon {
      width: 36px;
      height: 36px;
    }
  }

  span.icon {
    display: inline-block;
    line-height: 14px;
  }

  &.add {
    svg {
      fill: $color-green;
    }
  }

  &.green {
    svg {
      fill: $color-weird-green;
    }
  }

  &:not(.ignore-hover) {
    &:hover {
      &,
      * {
        font-weight: 400 !important;
      }

      svg.icon {
        fill: #000;
      }
    }
  }

  &.help-icon {
    svg.icon {
      fill: $color-silver;
      width: 20px;
      height: 20px;
    }

    &:hover:not(.no-grouping) {
      opacity: 1;

      svg.icon {
        fill: $color-dark-sky-blue !important;
      }
    }
  }

  &.large {
    svg.icon {
      width: 25px;
      height: 25px;
    }
  }

  &.disabled {
    @include ignore-hover;

    background-color: $color-mid-grey;
    border-radius: 3px;
    color: white;
    padding: 5px 15px;

    .icon {
      fill: white;
    }
  }

  &.disabled-icon {
    @include ignore-hover;

    color: $color-mid-grey;

    .icon {
      fill: $color-mid-grey;
    }

    cursor: not-allowed;
  }

  &.disabled-not-allowed {
    cursor: not-allowed;
  }

  &.collapse-expend-icon {
    @include transition(all 0.2s ease);
    @include transform(rotate(-90deg) translateX(3px) translateY(2px));

    &.expend {
      @include transform(rotate(0deg));
    }
  }

  &.white-icon {
    @include white-icon;
  }

  &.green-icon {
    background-color: $color-weird-green;
    color: #fff;

    &:not(.round-icon) {
      border-radius: 3px;
      padding: 5px 15px;
    }

    .icon {
      fill: #fff;
      color: #fff;
    }

    &:hover {
      .icon {
        fill: #fff;
        color: #fff;
      }
    }
  }

  &.round-icon {
    @include border-radius(50%);

    width: 27px;
    height: 27px;
    @include centerElements;
  }
}

div.highlight-field {
  &:hover {
    @include highlight-value-border;
    @include highlight-value;
  }
}

.indicator {
  color: $color-strong;
  margin-left: 5px;
  vertical-align: middle;
  font-weight: bold;
}

.formula-editor-button {
  position: absolute;
  margin-left: -20px;

  .svg-icon {
    margin-left: 2px;

    .icon {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.grid-row .formula-editor-button {
  display: none !important;
}

.ember-list-view.ember-list-view-list > div > div > div {
  position: absolute;
  width: 100%;
}

.extra-bold-text {
  font-weight: 800;
}

.bold-text {
  font-weight: bold;
}

.multi-selectors-wrapper {
  width: 100%;
  border: 1px solid $color-silver;
  border-radius: 3px;
  background-color: rgba($color-white-soft-grey, 0.5);
  @include flexbox;

  .multi-select-type {
    text-align: center;

    @include flex(1 1);

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:not(:last-child) {
      border-right: 1px solid $color-silver;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &.active {
      color: $color-dark-grey-blue;
      background-color: rgb(255, 255, 255);
    }
  }

  &.large-size {
    .multi-select-type {
      line-height: 36px;
    }
  }

  &.transparent-color {
    .multi-select-type {
      &:not(.active) {
        background-color: $color-soft-grey;
        color: $color-silver;
      }

      &.active {
        color: $color-dark-grey-blue;
        background-color: transparent;
      }
    }
  }
}

.note-editor {
  .note-toolbar {
    button.note-btn {
      padding: 0 2px;
      min-width: 40px;
      margin-right: 2px;
    }
  }
}

a,
button {
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.budgeta-checkbox {
  &.header-column {
    label::before {
      margin-top: 2px;
    }
  }

  &.disabled {
    &.disabled-gray {
      pointer-events: none;

      label {
        color: $color-silver;
      }
    }

    label {
      &::before {
        background: $color-white !important;
      }

      cursor: not-allowed !important;
    }
  }

  [type="checkbox"],
  [type="radio"] {
    position: absolute;
    left: -9999px;

    & + label {
      position: relative;
      padding-left: 25px;
      cursor: pointer;
      min-height: 20px;

      &::before {
        @include centerVElement;

        content: "";
        position: absolute;
        left: 0;
        width: 18px;
        height: 18px;
        border: 1px solid rgba(#000, 0.25);
        background: transparent;
        @include border-radius(3px);
      }
    }

    &[type="radio"] {
      & + label::before {
        @include border-radius(9px);
      }

      &[checked] + label::before {
        border-color: $color-dark-mint;
      }

      &[checked] + label::after {
        @include centerVElement;

        content: "";
        background: $color-dark-mint;
        display: block;
        height: 12px;
        width: 12px;
        position: absolute;
        left: 3px;
        @include border-radius(6px);
      }
    }

    &[type="checkbox"] {
      & + label::after {
        @include centerVElement;

        content: url("/assets/icons/check_white.svg");
        position: absolute;
        left: -3px;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        transition: all 0.2s;
      }

      &:not(:checked):not(.inheritChecked) + label::after {
        opacity: 0;
        transform: scale(0) translate(0%, -50%);
      }

      &:checked,
      &.inheritChecked {
        & + label::after {
          opacity: 1;
          transform: scale(0.65) translate(0%, -65%);
        }
      }

      &[checked],
      &:checked,
      &.inheritChecked {
        & + label::before {
          background: $color-dark-mint;
          border: 0;
        }
      }

      &.inheritChecked + label::before {
        opacity: 0.5;
      }
    }
  }

  &.small-checkbox {
    [type="checkbox"] {
      & + label {
        &::before {
          height: 15px;
          width: 15px;
        }

        &::after {
          left: -4px;
        }
      }
    }
  }
}

.hollow-button {
  border: 1px solid $color-mid-grey;
  @include border-radius(3px);

  color: $color-steel;
  height: 40px;
  line-height: 38px;
  padding: 0 20px;
  font-size: 18px;

  svg {
    fill: $color-steel;
  }

  &:hover {
    border-color: $color-dark-grey-blue;
    color: $color-dark-grey-blue;

    svg {
      fill: $color-dark-grey-blue;
    }
  }
}

.flip {
  transform: scaleX(-1);
  filter: fliph;
  filter: "FlipH";
}

header[data-path="tutorials"] {
  display: none;
}

.user-select-none {
  @include user-select(none);
}

.blue-link {
  color: $color-cerulean;
  text-decoration: underline;

  &:hover {
    color: $color-cerulean;
  }
}

.gray-border-bottom {
  border-bottom: 1px solid #eee;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-hidden {
  > div::-webkit-scrollbar {
    display: none;
  }

  > div {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

footer#app-footer {
  span {
    color: $font-color-white;
    font-size: 13px;
    line-height: 10px;

    a {
      color: $font-color-white;
      font-size: 13px;
      line-height: 10px;

      &:hover {
        color: $color-green-sage-logo;
        cursor: pointer;
      }
    }
  }
}
