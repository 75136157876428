.data-format-container {
  margin: 30px 90px 0;

  .description {
    text-align: center;
    margin: 0;
  }

  .options {
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: space-around;

    .option {
      width: 200px;
    }
  }
}
