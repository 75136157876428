/* stylelint-disable declaration-no-important, scss/at-mixin-pattern, selector-class-pattern, scss/percent-placeholder-pattern */
@mixin large-header {
  font-size: 32px;
  font-weight: 400;
  color: $color-dark-grey-blue;
}

@mixin large_header_dark_black_light {
  @include large-header;

  font-weight: 300;
  color: $color-grey-dark-black;
}

@mixin large_header_light {
  font-size: 40px;
  font-weight: 300;
  color: $color-dark-grey-blue;
}

.large_header_light {
  @include large_header_light;
}

@mixin large-header-bold {
  font-size: 26px;
  font-weight: 500;
  color: $color-dark-grey-blue;
}

.large-header-bold {
  @include large-header-bold;
}

@mixin large-text {
  font-size: 23px;
  font-weight: 400;
}

.large-text {
  @include large-text;
}

@mixin large-text-grey-blue {
  @include large-text;

  color: $color-dark-grey-blue;
}

.large-text-cool-grey {
  @include large-text;

  color: $color-cool-grey;
}

.large-text-steel {
  @include large-text;

  color: $color-light-steel !important;
}

@mixin large-text-soft-steel {
  @include large-text;

  color: $color-light-steel;
}

@mixin large-text-dark-mint {
  @include large-text;

  color: $color-dark-mint;
}

@mixin big-mid-text {
  font-size: 20px;
  font-weight: 400;
}

.big-mid-text {
  @include big-mid-text;
}

@mixin big-mid-text-grey-blue {
  @include big-mid-text;

  color: $color-dark-grey-blue;
}

@mixin big-mid-text-steel {
  @include big-mid-text;

  color: $color-steel;
}

.big-mid-text-steel {
  @include big-mid-text-steel;
}

.big-mid-dark-mint {
  @include big-mid-text;

  color: $color-dark-mint;
}

@mixin big-mid-text-white-bold {
  @include big-mid-text;

  color: #fff;
  font-weight: 600;
}

@mixin big-mid-text-white {
  @include big-mid-text;

  color: #fff;
}

@mixin mid-text {
  font-size: 18px;
  font-weight: 400;
}

.mid-text {
  @include mid-text;
}

@mixin mid-text-grey-blue {
  @include mid-text;

  color: $color-dark-grey-blue;
}

.mid-text-grey-blue {
  @include mid-text-grey-blue;
}

@mixin mid-text-dark-blue-grey {
  @include mid-text;

  color: $color-dark-blue-grey;
}

@mixin mid-text-white {
  @include mid-text;

  color: #fff;
}

@mixin mid-text-dark-green {
  @include mid-text;

  color: $color-dark-mint !important;
}

.mid-text-dark-green {
  @include mid-text-dark-green;
}

@mixin mid-text-dark-green-bold {
  @include mid-text;

  color: $color-dark-mint;
  font-weight: 600;
}

@mixin mid-text-green {
  @include mid-text;

  color: $color-weird-green;
}

@mixin mid-text-steel {
  @include mid-text;

  color: $color-steel;
}

.mid-text-steel {
  @include mid-text-steel;
}

@mixin mid-text-silver {
  @include mid-text;

  color: $color-silver;
}

.mid-text-silver {
  @include mid-text-silver;
}

@mixin section-header {
  @include mid-text-dark-green;

  letter-spacing: 1px;
}

.section-header {
  @include section-header;
}

@mixin small-text {
  font-size: 16px;
  font-weight: 400;
}

.small-text {
  @include small-text;
}

@mixin small-text-red {
  @include small-text;

  color: $color-red !important;
}

.small-text-red {
  @include small-text-red;
}

@mixin small-text-steel {
  @include small-text;

  color: $color-steel;
}

.small-text-steel {
  @include small-text-steel;
}

.small-text-steel-light {
  // TODO Might not be used
  @include small-text;

  font-weight: 300;
  color: $color-steel;
}

.small-text-dark-green-bold {
  @include small-text;

  color: $color-dark-mint;
  font-weight: 600;
}

@mixin small-text-white {
  @include small-text;

  color: #fff;
}

@mixin small-15-text {
  font-size: 14px;
  font-weight: 400;
}

@mixin small-14-text {
  font-size: 14px;
  font-weight: 400;
}

.small-14-light-steel {
  font-size: 14px;
  font-weight: 600;
  color: $color-light-steel;
}

@mixin small-14-white {
  @include small-14-text;

  color: #fff;
}

@mixin smallest-text {
  font-size: 12px;
  font-weight: 400;
}

@mixin smallest-text-white-bold {
  @include smallest-text;

  font-weight: 600;
  color: #fff;
}

@mixin letter-spacing-2 {
  letter-spacing: 0.2px;
}

.letter-spacing-2 {
  @include letter-spacing-2;
}

.text-uppercase {
  text-transform: uppercase;
}

@mixin negative-red-stroke {
  -webkit-text-stroke: 0.5px $color-red;
  @include letter-spacing-2;
}

@mixin positive-green-stroke {
  -webkit-text-stroke: 0.5px $color-dark-mint;
  @include letter-spacing-2;
}

@mixin font-color-stroke {
  -webkit-text-stroke: 0.5px $font-color;
  @include letter-spacing-2;
}
