.profile {
  .header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .field {
    margin-bottom: 10px;
  }
}
