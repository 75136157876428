/* stylelint-disable max-nesting-depth, declaration-block-no-shorthand-property-overrides, declaration-no-important */
.select2-drop {
  @include border-radius(0);
  @include box-shadow(0 4px 11px rgba(0, 0, 0, 0.3));

  border: 1px solid #dfdfdf !important;

  &:not(.ignore-budgeta-style) {
    .select2-results {
      margin: 0;
      padding: 16px 0;

      li {
        border: none;
        cursor: pointer;
        padding: 10px 20px;
        height: 40px;

        &.select2-result-with-children {
          height: auto;
          padding: 0;

          &:not(:first-child) {
            border-top: 1px solid $color-light-steel;
          }

          > .select2-result-label {
            min-height: 0;
            height: auto;
            line-height: 40px;
            color: $color-dark-blue-grey;
            padding-left: 5px;
          }
        }
        @include mid-text-steel;

        &.select2-no-results {
          white-space: nowrap;
        }

        .select2-result-label {
          @include mid-text-steel;

          padding: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &.select2-highlighted,
        &:hover:not(.select2-result-with-children) {
          background-color: rgba($color-weird-green, 0.25);
          background: rgba($color-weird-green, 0.25);
          @include mid-text-grey-blue;

          .select2-result-label {
            @include mid-text-grey-blue;
          }
        }
      }
    }
  }

  &.pricing-select {
    box-shadow: none;

    .select2-results {
      .select2-highlighted {
        background: #efefef;
      }
    }
  }
}

body:not(.hiddenscroll) {
  .select2-drop {
    &:not(.without-padding) {
      .select2-results {
        li {
          padding: 8px 44px 8px 15px;

          .select2-result-label {
            width: calc(100% + 17px);
          }
        }
      }
    }

    &.currency-settings:not(.without-padding) {
      .select2-results {
        li {
          padding: 8px 15px;
        }
      }
    }
  }
}

.select2-drop,
.select2-container {
  &.empty-select {
    border: 1px solid $color-red !important;

    &::after {
      content: attr(data-after);
      color: $color-red;
      position: absolute;
    }
  }

  &:not(.wrap):not(.full-size) {
    width: auto !important;
    max-width: 600px;
  }

  &.full-size {
    width: 100%;
  }

  &.budget-type-drop {
    min-width: 150px;
    width: auto !important;
    max-width: 200px;
  }

  &.add-root-select-currency {
    padding-left: 0;

    > a {
      padding-left: 0;
    }

    > ul.select2-results {
      &:not(.select2-results-sub) {
        > li {
          > div.select2-result-label {
            display: none;
          }
        }
      }
    }
  }

  &.no-left-padding {
    padding-left: 0;

    > a.select2-choice {
      padding-left: 0;
    }
  }

  &.extra-right-margin {
    margin-right: 60px;
  }

  &.light-text-hightlight {
    .select2-choice {
      &.select2-default {
        .select2-chosen {
          font-weight: 300;
        }
      }
    }
  }
}

ul.dropdown-menu {
  @include border-radius(0);
  @include box-shadow(0 4px 11px rgba(0, 0, 0, 0.3));

  border: 1px solid #dfdfdf !important;
  border-color: #dfdfdf !important;
  margin: 0;
  padding: 16px 0;

  li {
    border: none;
    cursor: pointer;
    padding: 10px 24px;
    height: 40px;
    position: relative;
    @include mid-text-steel;

    a {
      @include mid-text-steel;

      line-height: normal;

      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      background-color: rgba($color-weird-green, 0.25);
      @include mid-text-grey-blue;
    }
  }

  &.with-help-iq {
    li {
      padding: 10px 50px 10px 24px;

      .help-iq {
        right: 24px;
      }
    }
  }
}

.select-2-lazy {
  &.with-drop-down-sign {
    height: 40px;
    line-height: 40px;
    min-width: 100%;
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .select-2-lazy-inactive-label {
      @include dropdown-sign;

      display: inline-block;
      width: 100%;
      padding-right: 20px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 40px;

      &::after {
        top: 0;
      }
    }
  }
}
