/* stylelint-disable max-nesting-depth, no-duplicate-selectors, selector-class-pattern, declaration-no-important */
.modal-container {
  .version-item-type span:first-child {
    width: 150px;
  }

  .version-input {
    max-width: 145px;
  }

  &:not(.modal-config) {
    .modal {
      &:not(.edit-line-modal-dialog) {
        .modal-dialog {
          .modal-content {
            .modal-body {
              > div {
                margin-bottom: 10px;
              }
            }

            .tab-content .tab-pane,
            .modal-design {
              > div {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.modal-container {
  .modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .modal-header-inner {
            .modal-title {
              margin-left: 48px;
            }
          }
        }

        .modal-body {
          padding: 0 48px 36px;
        }
      }
    }

    .modal-dialog {
      .modal-content {
        .modal-header {
          height: 75px;
          width: 100%;
          position: relative;
          cursor: move;
          padding: 0;
          border: none;

          .modal-header-inner {
            width: 100%;

            > * {
              display: inline-block;
            }

            .modal-title {
              padding-top: 24px;
              margin-left: 48px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              max-width: calc(100% - 30px);

              .modal-title-text {
                @include large_header_light;

                position: relative;
                white-space: nowrap;
                overflow: hidden;
                padding-right: 30px;
                line-height: 50px;

                &:not(.no-overflow) {
                  &:not(.extra-long-title) {
                    text-overflow: ellipsis;
                  }

                  &.long-title {
                    font-size: 32px;
                  }

                  &.extra-long-title {
                    font-size: 28px;
                    white-space: normal;
                    line-height: 1;
                  }
                }
              }
            }
          }

          .modal-actions {
            padding-right: 12px;
            padding-top: 12px;

            .close-button-wrapper {
              display: inline-block;
            }

            .help-iq {
              display: inline-block;
            }
          }
        }

        .modal-footer {
          clear: both;
          padding: 10px;
          border-top: 0;

          // border-top: 5px solid $color-light-mid-opacity-grey;
          margin-top: 0;
          position: relative;

          #more-btn {
            display: none;
            position: absolute;
            background: white;
            border: 1px solid $color-dark-mint;
            color: $color-dark-mint;
            right: 7px;
            margin-top: 1px;
          }

          #back-btn {
            width: 70px;

            .svg-icon {
              right: 130px;
            }

            div {
              color: $color-steel;
            }

            &.wizard {
              height: 40px;
              width: 70px;
              position: absolute;
              left: 0;

              .svg-icon {
                left: 10px;
              }

              .right {
                right: 0;
              }
            }
          }
        }
      }
    }

    &:not(.edit-line-modal-dialog) {
      .modal-dialog {
        .modal-content {
          .right {
            float: right;
          }

          .styled-select {
            select {
              line-height: 33px;
            }

            &::after {
              line-height: 35px;
              right: 10px;
            }
          }

          .long-text-wrapper
            :not(.budgeta-checkbox):not(.budgeta-type-value)
            > label:not(.content-size):not(.error):not(.ignore-modal-label):not(.budgeta-checkbox) {
            &:not(.long-label) {
              width: 51%;
            }
          }

          :not(.budgeta-checkbox):not(.budgeta-type-value)
            > label:not(.content-size):not(.error):not(.ignore-modal-label):not(.budgeta-checkbox) {
            &:not(.long-label) {
              width: 30%;
            }

            &:not(.ignore-color) {
              color: $color-grey-light-black;
            }

            &.full {
              clear: both;
              width: 100%;
              display: block;
            }
          }

          input {
            &[type="text"],
            &[type="email"],
            &[type="number"],
            &[type="password"] {
              line-height: 40px !important;
              padding-left: 0;
              height: 40px;
              border: none;
              outline: 0;
              border-bottom: 1px solid #eee;
            }
          }

          .dropdown {
            border: none;
            outline: 0;
            border-bottom: 1px solid #eee;

            a {
              border: none;
              outline: 0;
              padding-left: 0;
            }
          }

          div.select2-container {
            border: none;
            outline: 0;
            border-bottom: 1px solid #eee;
            margin-top: 0;
            height: 40px;

            a.select2-choice {
              height: 32px;
              line-height: 32px;

              span.select2-chosen {
                height: 32px;
                line-height: 40px;
              }
            }

            &.select2-container-multi {
              height: auto !important;
              min-height: 40px;
              box-shadow: none;

              ul.select2-choices {
                border: none;
                margin-top: -2px;
                @include box-shadow(none);

                li.select2-search-choice {
                  border: none;
                  background-image: none;
                  background-color: $color-white;
                  height: 35px;
                  line-height: 35px;

                  div {
                    line-height: 35px;
                    @include big-mid-text;
                  }

                  a {
                    @include centerVElement;
                  }
                }

                li.select2-search-field {
                  input {
                    outline: 0;
                    border: none;
                    height: 36px;
                    min-width: 40px;
                    @include box-shadow(none);

                    &:focus {
                      @include box-shadow(none);
                    }
                  }
                }

                &.select2-container-active {
                  outline: 0;
                  @include box-shadow(none);
                  @include transition(none);
                }
              }
            }

            &.short-value-size {
              width: 250px;
            }
          }

          .modal-body {
            button {
              line-height: 33px;
            }

            div.description {
              margin-top: 20px;
              min-height: 100px;
            }

            .upload {
              width: 110px;
              position: absolute;
              bottom: -52px;
              left: 10px;
            }

            .nav-stacked {
              float: left;
            }

            .tab-content {
              min-height: 300px;
            }

            .quick-links {
              border: none;
              width: calc(100% + 40px);
              margin-top: -20px;
              @include flexbox;

              li {
                padding: 0 10px;

                &:first-child {
                  padding-left: 0;
                }

                a {
                  line-height: 50px;
                  border: 0;
                  padding: 0;
                  @include mid-text-steel;
                }

                &.active {
                  border-bottom: none;

                  a {
                    @include mid-text-dark-green;

                    border-bottom: 3px solid $color-dark-mint !important;
                  }
                }

                &:hover {
                  border-bottom: none;

                  a {
                    border-bottom: 3px solid $color-dark-mint !important;
                  }
                }
              }
            }
          }

          .tab-content .tab-pane,
          .modal-design {
            a.top-right {
              position: absolute;
              right: 36px;
              color: $color-dark-mint;
            }
            @include placeholder-color($color-green);

            padding-top: 20px;

            > div {
              margin-bottom: 10px;
            }

            div.dobule-flex {
              @include flexbox;

              div.flex {
                @include flex(1);

                &:first-child:not(:last-child) {
                  margin-right: 20px;
                }
              }
            }

            div.flex {
              &:not(.no-margin) {
                margin-bottom: 15px;
              }

              &.section-head {
                margin-bottom: 0;
                margin-top: 20px;
              }
              @include flexbox;

              position: relative;

              > *:not(.help-iq):not([type="submit"]) {
                @include flex(1 1);

                input[type="text"] {
                  width: 100%;
                }

                &.double-width {
                  @include flex(2 1);
                }

                &.smaller {
                  @include flex(0.7 1);

                  &.svg-icon {
                    line-height: 40px;
                    @include flex(0 1 30px);
                  }
                }
              }

              input[type="submit"] {
                margin-left: 30px;

                &:hover {
                  color: #fff;
                }
              }

              .svg-icon.checkmark {
                position: absolute;
                right: 50px;

                .icon {
                  width: 32px;
                  height: 32px;
                }
              }

              div.remove-line {
                font-weight: bold;
                font-size: 18px;
                line-height: 35px;
                margin-left: 10px;
                max-width: 30px;
              }

              .help-iq {
                position: absolute;
                margin-left: 5px;
                right: 5px;

                &:not(.without-margin-top) {
                  margin-top: 5px;
                }
              }

              .employee-title,
              .row-title {
                flex-basis: 40%;
                line-height: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            div.short-term {
              input,
              label:not(.content-size) {
                float: left;
              }
            }

            :not(.budgeta-checkbox) > label {
              margin-bottom: 0;
              display: inline-block;

              &:not(.error) {
                &:not(.modal-small-title):not(.without-border):not(.ignore-modal-label) {
                  border-bottom: 1px solid #eee;
                  @include mid-text-steel;
                }

                padding-right: 10px;
                line-height: 39px;

                &.full {
                  width: 100%;
                }

                &:not(.content-size):not(.fix-size-180):not(.fix-size-220):not(.fix-size-400):not(
                    .modal-small-title
                  ):not(.ignore-modal-label):not(.flex-1) {
                  @include flex(0 0 165px !important);

                  &.flex-auto {
                    @include flex(0 0 fit-content !important);
                  }
                }
              }

              &.flex-1 {
                @include flex(1 1);
              }

              &.content-size {
                @include flex(0 0 auto !important);

                width: auto;
              }

              &.fix-size-180 {
                @include flex(0 0 180px !important);

                width: auto;
              }

              &.fix-size-220 {
                @include flex(0 0 220px !important);

                width: auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 0.9em !important;
              }

              &.fix-size-400 {
                @include flex(0 0 400px !important);

                width: auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 0.9em !important;
              }

              &.error {
                display: block;
                margin-top: -20px;
              }

              &.narrow {
                max-width: 80px;
              }
            }

            .mini-value-size {
              @include flex(0 0 50px !important);

              width: 50px;
            }

            .short-value-size {
              @include flex(0 0 235px !important);

              &.form-control.select2-container {
                padding-left: 0;

                .select2-choice {
                  padding: 0;
                }
              }
            }

            .mid-value-size {
              @include flex(0 0 290px !important);
            }

            .month-picker-wrapper {
              @include flexbox;

              .year-picker-from,
              .year-picker-to {
                @include flex(1 1);

                .month-picker {
                  width: 100%;
                }
              }
            }

            &.add-root {
              width: 100%;

              label:not(.error):not(.full) {
                max-width: 180px;
              }

              div.flex {
                margin-bottom: 15px;
              }

              .change-settings-text {
                position: absolute;
                bottom: 35px;
              }

              #budget-type-dropdown {
                a {
                  &::after {
                    right: 35px;
                  }
                }
              }
            }

            ul.sortable {
              li {
                margin-bottom: 10px;

                input,
                select {
                  line-height: 24px !important;
                }

                .form-control {
                  padding: 0 5px 0 0;
                }

                .styled-select {
                  &::after {
                    line-height: 26px;
                  }
                }
              }
            }

            label.modal-small-title {
              @include mid-text-dark-green;

              text-transform: uppercase;
            }

            a.link {
              color: $color-dark-sky-blue;
              text-decoration: underline;
            }
          }
        }
      }
    }

    &.confirmation {
      .modal-dialog {
        .modal-content {
          .modal-header {
            color: $color-green;

            .close {
              color: #bebebe;
            }
          }
        }
      }
    }
  }
}

.modal {
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($color-charcoal-grey, 0.9);
  }

  .modal-dialog {
    margin-top: 75px !important;

    .modal-content {
      text-align: left;
      border: 0;
      @include border-radius(3px);
      @include box-shadow(0 0 50px rgba(0, 0, 0, 0.3));
    }
  }
}

.modal {
  &.video-modal-dialog {
    z-index: 2001;
  }
}

.edit-line-modal-dialog {
  .modal-dialog {
    width: 1000px;

    .modal-body {
      max-height: 500px;
      padding: 0 !important;
      @include placeholder-color($color-green);

      > div {
        padding: 0 48px 36px;
      }

      label.error {
        bottom: -34px !important;
        left: 0 !important;
      }
    }
  }
}

.modal-versions {
  .modal-dialog {
    width: 1000px;

    .modal-body {
      padding-bottom: 0 !important;

      hr {
        margin: 10px 0;
      }

      .flex {
        margin-bottom: 10px !important;

        &.filters {
          margin-top: 30px;
        }

        > * {
          height: 40px;

          &.select2-container {
            @include flex(0 0 130px !important);
          }
        }

        > input:not(:first-child),
        > label,
        > .select2-container {
          margin: 0 10px;
        }
      }
    }
  }

  .scrollable {
    height: 300px;
    overflow-y: auto;
  }
}

.modal-config {
  .modal-dialog {
    .modal-body {
      max-height: 500px;
      height: 500px;
      padding-bottom: 0 !important;
      @include flexbox;
      @include flex-direction(column);

      > ul.nav-tabs {
        @include flex(0 0 auto);
      }

      > .tab-content {
        @include flex(1 1);

        // margin-bottom: 0px;
        .tab-pane {
          height: 100%;

          .show-accounts {
            @include flex-important(0 1 240px);

            &.flex-auto {
              @include flex(0 0 auto !important);
            }
          }

          .display-accounts {
            @include flexbox;

            .content {
              margin-right: 20px;
              line-height: 40px;
            }
          }

          > div {
            > .with-transparent-input:first-of-type {
              border-bottom: 0;
            }
          }
        }
      }
    }

    width: 950px;

    ul.sortable {
      max-height: 273px;
      margin-top: 10px;
      margin-bottom: 0;
      overflow-y: auto;

      li {
        .column {
          float: left;
          width: 210px;
          margin-right: 10px;

          input {
            width: 100%;
          }

          .budget-select {
            .select2-container {
              > a {
                padding-left: 0;
              }
            }
          }

          &:first-child {
            width: 18px;
          }
        }

        &::after {
          content: "";
          display: block;
          clear: both;
        }
      }
    }

    div[id="cash"] {
      ul.scrollable {
        max-height: 373px;
        margin-bottom: 0;

        li {
          @include flexbox;

          div.currency {
            @include flex(1 1);
            @include flexbox;
          }

          input.wide {
            width: 300px;
          }
        }
      }
    }

    div[id="fx"] {
      .section {
        margin-top: 20px;
      }

      .table-wrapper {
        overflow-x: auto;
        margin: 0;
        background: none;
        border: 0;

        .table {
          max-width: none;
          margin-bottom: 0;

          .row {
            margin-left: 0;
            clear: both;

            .col {
              position: relative;
              padding: 0 10px;
              border-left: 1px solid $color-soft-grey;
              float: left;
              width: 80px;
              height: 40px;
              line-height: 40px;

              input {
                max-width: 60px;
                padding-right: 0;
              }

              label {
                width: 70%;
              }

              p.input-error {
                font-size: 12px;
                margin-top: -18px;
                z-index: 1;
                width: 500%;
              }
            }
          }
        }
      }

      .scrollable {
        max-height: 396px;
      }
    }

    div[id="dimensions"] {
      &.active {
        @include flexbox;
        @include flex-direction(column);
      }

      .highlight {
        border: 1px solid $color-light-border;
        padding: 5px;
        @include border-radius(5px);
        @include flex(0 0 auto);

        height: auto;

        .help-icon {
          margin-top: -3px;
          margin-left: 10px;
        }
      }

      .left-list-selection {
        @include flex(1 1);

        .selection-list {
          @include flexbox;
          @include flex-direction(column);

          .dimensions-list {
            @include flex(1 1);

            overflow-y: auto;
            padding-bottom: 16px;

            .dimension-display {
              display: flex;
              align-items: center;

              .dimension-display-text {
                line-height: 25px;
                padding-left: 4px;
              }

              .inline-edit {
                font-size: 600;
                width: 100%;

                .svg-icon {
                  @include white-icon;
                  @include ignore-hover;
                }
              }
            }
          }

          li {
            &.active,
            &:hover {
              .inline-edit {
                color: white !important;

                * {
                  color: white !important;
                }
              }

              .dimension-display-text {
                color: white;
              }

              .dimension-display {
                max-width: calc(100% - 60px);
              }

              .svg-icon {
                @include white-icon;
                @include ignore-hover;
              }
            }

            &:not(.active):not(:hover) {
              .dimension-display {
                max-width: 100%;
              }
            }

            .dimension-actions {
              position: relative;
              width: 60px;
            }
          }

          .add-dimension {
            @include flex(0 0 auto);
          }
        }

        .selection-content {
          padding-top: 8px;
          position: relative;
        }
      }
    }

    div[id="benefits"] {
      div.scrollable {
        max-height: calc(100% - 15px);
      }
    }

    div[id="tags"] {
      ul.scrollable {
        max-height: 400px;
      }
    }

    .scrollable {
      overflow-y: auto;
      overflow-x: hidden;

      &.tags-list {
        li {
          border-bottom: 1px solid #eee;
          width: 350px;
          margin-bottom: 10px;

          .inline-edit-tag-wrapper {
            width: calc(100% - 30px);
            display: inline-block;
            float: left;

            .simpleDesign {
              float: left;

              input {
                height: 25px;
                line-height: 23px !important;
                border: none;
              }
            }
          }
        }
      }
    }

    .modifier {
      @include flexbox;

      .select2-container {
        width: 75px;
        min-width: 75px;
      }
    }
  }
}

.modal-container {
  &.dimension-settings {
    .modal .modal-dialog .modal-content {
      .modal-body {
        .budgeta-checkbox:not(:first-child) {
          margin-top: 10px;
        }

        .name-value-input {
          label,
          div {
            display: inline-block;
          }

          > label {
            line-height: 40px;
            color: $color-steel !important;
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
  }
}

.modal-reports {
  .modal-dialog {
    width: 1050px;
  }
}

.before-leaving-modal {
  &.modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .modal-header-inner {
            .modal-title {
              padding-top: 10px;
              margin-left: 30px;
            }
          }
        }

        .modal-body {
          padding: 20px 30px 24px;
        }

        .modal-footer {
          #cancel-btn {
            padding-right: 0;
          }
        }
      }
    }
  }
}

.modal.large-login-modal {
  .modal-dialog {
    width: 800px;
  }
}

.intacct-wizard {
  .modal {
    overflow-y: hidden !important;
  }
}

.edit-line-modal {
  .modal-dialog {
    width: 1000px;

    .scrollable {
      max-height: 500px;
      overflow-y: auto;
    }
  }
}

.modal-container.add-root-modal {
  .modal-body {
    .tab-content {
      .tab-pane {
        width: 100%;
      }
    }
  }
}

.summaryBalanceDetails p {
  margin: 0;
}

.balanceDescription p {
  margin: 10px 0 0;
}
