/* stylelint-disable max-nesting-depth, declaration-block-no-shorthand-property-overrides, selector-class-pattern, declaration-no-important */
.elegant-modal.wizard-modal.group-company-intacct {
  .intacct-budget-structure {
    @include flexbox;
    @include flex-direction(column);

    height: 434px;
    overflow: auto;

    > div:not(.budget-edit-structure-wrapper) {
      @include flex(0 0 40px);
    }

    .budget-edit-structure-wrapper {
      @include flex(1 1);

      overflow: hidden;

      div {
        font-size: 18px;
      }

      @include flexbox;

      &.has-first-level {
        .budget-structure-line {
          &:not([data-level="0"]) {
            padding-left: 24px;
          }

          &[data-level="2"] {
            padding-left: 48px;
          }
        }
      }

      &:not(.has-first-level) {
        .budget-structure-line {
          &:not([data-level="0"]):not([data-level="1"]) {
            padding-left: 24px;
          }
        }
      }

      .budget-edit-structure {
        @include flex(0 0 450px);

        display: grid;
        grid-template-rows: 50px auto 36px;

        .budget-edit-structure-drop {
          padding: 0 32px 18px 18px;
          overflow-y: auto;

          [data-name="first-level-0"] {
            max-width: 320px;
          }

          .budget-edit-structure-drop-content {
            max-width: 320px;
          }

          .budget-structure-line {
            min-height: 30px;
            line-height: 30px;
            width: 100%;
            padding-left: 24px;
            position: relative;

            .budget-structure-line-value {
              position: relative;

              &::before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                @include transform(translate(0%, -50%));

                background: #c0c5cc;
                width: 16px;
                height: 2px;
              }

              @include flexbox;

              .delete {
                @include flex(0 0 30px);
              }

              .budget-structure-line-name {
                @include text-ellipsis;
                @include flex(1 1);

                .group-number-lines {
                  font-size: 14px;
                  position: relative;
                  padding-left: 16px;

                  &::before {
                    content: "|";
                    position: absolute;
                    color: $color-silver;
                    left: 8px;
                    font-size: 18px;
                  }
                }
              }

              .drop-dimension-placeholder {
                line-height: 26px;
                opacity: 0.6;
                border-radius: 2px;
                border: dotted 1px #adadad;
                color: #878d94;
                font-size: 12px;
                padding-left: 4px;
                padding-right: 4px;
                font-style: italic;
                width: 100%;

                input {
                  height: 23px;
                  line-height: 23px !important;
                }
              }
            }

            &::before {
              content: "";
              display: block;
              position: absolute;
              background: #c0c5cc;
              width: 2px;
            }

            &.dropped-budget-structure-line {
              padding-top: 16px;

              .dimension-content {
                width: 100%;
              }

              &::before {
                top: 0;
                left: 4px;
              }

              > .budget-structure-line-value {
                &::before {
                  left: -19px;
                  width: 19px;
                }
              }
            }

            &:not(.dropped-budget-structure-line) {
              padding-top: 8px;

              &::before {
                top: 0;
                left: 0;
              }

              > .budget-structure-line-value {
                &::before {
                  left: -22px;
                }
              }
            }

            &:not(:last-child) {
              &::before {
                height: 100%;
              }
            }

            &:first-child {
              &[data-level="1"],
              &[data-level="0"] {
                &::before {
                  top: 4px;
                }
              }
            }

            &:last-child {
              &::before {
                height: 30px;
              }
            }
          }

          &.drag-to-first-level {
            .budget-edit-structure-drop-content {
              @for $i from 0 to 10 {
                &.num-top-levels-#{$i} {
                  max-width: calc(320px - calc(calc(24px * calc(#{$i} + 1)) + 4px));
                  margin-left: calc(calc(24px * calc(#{$i} + 1)) + 4px);
                }
              }
            }
          }

          &.with-first-level:not(.drag-to-first-level) {
            .budget-edit-structure-drop-content {
              @for $i from 1 to 10 {
                &.num-top-levels-#{$i} {
                  max-width: calc(320px - calc(calc(24px * #{$i}) + 4px));
                  margin-left: calc(calc(24px * #{$i}) + 4px);
                }
              }
            }
          }

          &.drag-to-first-level,
          &.with-first-level {
            > .budget-structure-line {
              &::before {
                height: 26px;
              }

              &.hidden + .budget-edit-structure-drop-content {
                margin-left: 0;
              }
            }
          }
        }

        .budget-edit-structure-name {
          @include text-ellipsis;

          letter-spacing: 0.2px;
          font-size: 16px;
          font-weight: 500;
          padding-top: 16px;
          padding-bottom: 8px;
          padding: 16px 32px 0 18px;
          text-transform: uppercase;
        }

        .budget-edit-structure-totals {
          @include structure-summary($color-dark-mint, 14px, 600, $color-soft-grey);
        }

        .exceeded-total-budget-lines {
          color: $color-red-dark;
        }
      }

      .dimension-content {
        @include flexbox;
        @include flex(1 1);

        overflow: auto;
        cursor: pointer;
        overflow-y: hidden;
        margin-right: 8px;

        .svg-icon.drag-handle {
          @include flex(0 0 10px);

          width: 10px;
          background-color: $color-dark-mint;

          .icon {
            margin-left: -7px;
            fill: white;
          }
        }

        .dimension-content-text {
          @include flex(1 1);

          padding-left: 8px;
          padding-right: 4px;
          font-weight: 300;
        }

        &.can-be-removed {
          &:hover {
            .svg-icon.delete {
              @include flex(0 0 30px);

              line-height: 30px;
              text-align: center;

              span {
                font-size: 14px;
              }
            }
          }

          &:not(:hover) {
            .svg-icon.delete {
              display: none;
            }
          }
        }

        border-radius: 2px;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0.8px 0.7px 0 0 rgba(0, 0, 0, 0.09);
        height: 26px;
        line-height: 26px;
        background-color: white;
      }

      .dimension-draggable {
        &.disable-dimension {
          .dimension-content {
            background-color: $color-mid-grey;
            cursor: not-allowed;

            .dimension-content-text {
              color: $color-steel;
              padding-left: 10px;
            }
          }
        }
      }

      .budget-edit-structure-dimensions-list {
        @include flex(0 0 220px);

        overflow-y: auto;
        background-color: #f7f7f7;
        border-right: 1px solid $color-soft-grey;
        display: grid;
        grid-template-rows: 50px 64px auto;

        .dimension-draggable {
          @include flexbox;
          @include flex(1 1);

          .info_outline {
            @include flex(0 0 32px);

            padding-left: 8px;
          }

          margin-bottom: 6px;
        }

        .budget-edit-structure-dimensions-list-header {
          letter-spacing: 0.2px;
          padding-top: 16px;
          padding: 16px 18px 0 32px;
        }

        .budget-edit-structure-dimensions-list-subheader {
          letter-spacing: 0.2px;
          color: $color-steel;
          font-size: 16px;
          padding: 0 18px 0 32px;
        }

        .dimensions-list-wrapper {
          overflow-y: auto;
          padding: 9px 18px 18px 32px;
        }
      }
    }
  }
}
