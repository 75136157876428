/* stylelint-disable max-nesting-depth, scss/double-slash-comment-whitespace-inside, custom-property-no-missing-var-function, font-family-no-missing-generic-family-keyword, function-url-quotes, no-duplicate-selectors, selector-class-pattern, declaration-no-important */
.import-top {
  @include flexbox;

  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid rgba(#979797, 0.1);
  margin: 0 20px 25px;

  * {
    line-height: 47px !important;
  }

  h1 {
    color: $color-bay-of-many;
    font-size: 21px;
    font-weight: bold;
    margin: 0 !important;
    width: calc(100% - 200px);
    text-align: left;
    @include text-ellipsis;
  }

  .right {
    min-width: 210px;
  }
}

.inner-content {
  > .import.simple {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba($color-charcoal-grey, 0.9);

    .close-import {
      position: absolute;
      top: 14px;
      right: 14px;
      @media screen and (max-height: 680px) {
        top: 4px;
        right: 4px;
      }

      svg.icon {
        fill: #fff;
        width: 28px;
        height: 28px;
      }
    }

    .uploading {
      color: #fff;

      .progress > span {
        background-color: $color-dark-mint;
      }
    }

    .recent-files {
      bottom: 40px;
      width: 500px;
      text-align: left;

      * {
        font-size: 18px;
        font-weight: 400;
        line-height: 50px;
      }

      .recent-files-header {
        color: $color-silver;
      }

      ul {
        li {
          position: relative;
          height: 50px;
          border-top: 1px solid rgba($color-forecast-border, 0.5);

          .svg-icon {
            margin-right: 25px;

            svg {
              fill: rgba(#fff, 0.65);
            }
          }

          .when {
            color: $color-steel;
            position: absolute;
            right: 0;
            top: 0;
          }

          .file-name {
            @include text-ellipsis;

            display: inline-block;
            vertical-align: middle;
            max-width: 330px;
          }
        }
      }
    }

    > .import {
      position: absolute;

      &:not(.import-generic-file-container) {
        top: 48px;
        left: 48px;
        right: 48px;
        bottom: 48px;
        @media screen and (max-height: 680px) {
          top: 28px;
          left: 28px;
          right: 28px;
          bottom: 28px;
        }
      }

      &.import-generic-file-container {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .uploading {
          position: absolute;
          z-index: 9999;
          color: white;
          width: calc(100% - 300px);
          left: 300px;
          height: 100%;
          background: url(/assets/images/bg_squares_and_circles.png) rgba(0, 0, 0, 0.5);
          background-size: cover;
          background-blend-mode: multiply;

          .progress > span {
            background-color: $color-dark-mint;
          }
        }
      }

      background-color: white;
      overflow: hidden;
      @include border-radius(3px);

      @include flexbox;

      .left-side {
        @include transition(max-width 0.1s ease-in-out);

        max-width: 340px;
        position: relative;

        &.hide-banner {
          @include transition(max-width 0.1s ease-in-out);

          max-width: 30px;
        }

        .square {
          height: 30px;
          width: 16px;
          background-color: transparent;
          border-right: 1px solid $color-soft-grey;
          right: 15px;
          top: 15px;
          position: absolute;

          .collapse {
            margin-top: 5px;
            height: 100%;
            width: 100%;

            .icon {
              position: absolute;
              top: 6px;
              border-radius: 3px 0 0 3px;
              border-right: none;
            }
          }

          &.expanded {
            height: 18px;
            border-right: none;
            right: 6px;
            top: 15px;

            .expand {
              .icon {
                border-radius: 0 3px 3px 0;
                margin-bottom: 5px;
              }
            }
          }

          .icon {
            fill: $color-steel;
            border: 1px solid $color-soft-grey;
            width: 16px;
            height: 18px;
          }
        }

        .import-top {
          margin: 0;
          padding: 13px 48px 10px 15px;
          border-bottom: 1px solid $color-soft-grey;

          * {
            line-height: 39px !important;
            font-size: 36px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .quick-links {
          display: block;
          padding: 0 24px;
          border-bottom: 1px solid #eee;

          li {
            float: left;
            margin-right: 24px;

            a {
              padding: 0;
              padding-bottom: 16px;
              font-size: 18px;
              line-height: 1;
            }
          }
        }

        .import-footer {
          position: absolute;
          width: 100%;
          bottom: 12px;
          height: 60px;
          border-top: 1px solid $color-soft-grey;

          .right {
            position: absolute;
            right: 18px;
            top: 15px;

            &.back-btn.no-background {
              color: $color-steel;
              right: 50px;
              top: 17px;

              :hover {
                color: $color-grey-light-black;
              }

              &.extra {
                right: 60px;
              }

              .svg-icon {
                right: 80px;
                top: 17px;

                .icon {
                  fill: $color-steel;
                }
              }
            }
          }

          .cancel-btn {
            top: 15px;
            position: absolute;
          }

          .steps {
            position: absolute;
            top: -1px;
            left: 33%;
          }

          .footer-wrapper {
            display: flex;
            height: 100%;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;

            .footer-back-button {
              display: flex;
              flex-direction: row;
              justify-content: center;
            }

            .inactive.cancel-btn {
              padding: 0 10px;
            }

            button.right {
              margin-right: 5px;
            }

            button.right,
            button.inactive.cancel-btn,
            .footer-back-button .svg-icon.centerVElement,
            .footer-text-button.centerVElement.right.back-btn.no-background,
            .footer-back-button.inactive.right.back-btn.no-background {
              position: static;
              right: 0;
              top: 0;
              transform: none;
            }
          }
        }

        .intro {
          color: $color-steel;
          margin-bottom: 15px;
          @media screen and (max-height: 680px) {
            margin-bottom: 15px;
          }
          @media screen and (max-height: 720px) {
            margin-bottom: 15px;
          }
        }

        ol.numbered-steps {
          padding-left: 0;

          li {
            * {
              font-size: 18px;
            }

            color: $color-light-steel;
            padding-left: 0;
            list-style-type: none;

            label {
              color: $color-dark-grey-blue;

              &.inactive {
                color: $color-light-steel;
              }
            }

            &.budgeta-checkbox,
            .budgeta-checkbox {
              label {
                color: $color-steel;
                font-size: 18px;
                line-height: 35px;

                &.wrap {
                  line-height: 1;
                }
              }
            }

            .column {
              width: 48%;
              float: left;

              &:not(:first-child) {
                float: right;
              }

              .month-picker-wrapper {
                margin-top: 0;
              }
            }

            &::after {
              content: "";
              display: block;
              clear: both;
            }

            a {
              font-size: 18px;
              color: $color-dark-mint;
              margin-left: 6px;
            }

            margin-bottom: 15px;
            @media screen and (max-height: 680px) {
              margin-bottom: 15px;
            }
            @media screen and (max-height: 720px) {
              margin-bottom: 20px;
            }

            .month-picker-wrapper {
              margin-top: 10px;

              input {
                height: 40px;
                font-size: 18px;
              }
            }

            .dropdown,
            .select2-container {
              display: block;
              height: 40px;
              margin-top: 10px;

              * {
                font-size: 18px;
              }

              > a {
                padding-left: 16px;

                &.select2-choice {
                  margin-top: 3px;
                }
              }
              @include border-radius(3px);
            }

            &.header-select,
            &.name-select {
              .dropdown,
              .select2-container {
                border: 0;

                > a,
                > span {
                  &,
                  * {
                    color: white;
                  }

                  &::after {
                    color: white;
                  }

                  .select2-arrow b::after {
                    color: white;
                  }
                }

                &.select-2-lazy {
                  > span {
                    line-height: 40px;
                    padding: 0 30px;
                    width: 100%;
                    @include text-ellipsis;

                    display: inline-block;
                    @include dropdown-sign;
                    @include center-sign;

                    &::after {
                      color: white !important;
                      right: 10px !important;
                    }
                  }
                }
              }
            }

            &.header-select {
              .dropdown,
              .select2-container {
                background-color: $color-weird-green;
              }

              &.dimension-select:not(.import-dimension-by-row) {
                .dropdown,
                .select2-container {
                  background-color: $color-silver;
                }
              }
            }

            &.name-select {
              .dropdown,
              .select2-container {
                background-color: $color-blue-changes;
              }

              &.dimension-select:not(.import-dimension-by-column) {
                .dropdown,
                .select2-container {
                  background-color: $color-silver;
                }
              }
            }

            &.title-select {
              .dropdown,
              .select2-container {
                background-color: $color-white-light-grey;
              }
            }
          }

          &.underlined-select {
            li {
              @media screen and (max-height: 800px) {
                margin-bottom: 24px;
              }

              label {
                color: $color-cool-grey;
              }

              .select2-container {
                border: 0;
                padding-left: 0 !important;
                border-bottom: 1px solid $color-silver;
                margin-top: 0;
                @include border-radius(0);

                .select2-choice {
                  padding-left: 0;
                  margin-left: 0;

                  * {
                    font-size: 23px;
                  }
                }
              }
            }
          }
        }

        .import-step {
          padding: 18px;
          height: 75%;
          overflow-y: auto;

          .step-header {
            font-size: 23px;
            margin-bottom: 15px;
            color: $color-dark-mint;
          }

          .step-subheader {
            color: $color-dark-blue-grey;
            margin-bottom: 15px;
          }

          .banner-highlighted-info {
            position: absolute;
            margin: 3px 0 0 5px;

            p {
              margin: 0;
              background-color: rgba($color-dashboard-yellow, 0.25);
              width: fit-content;
            }
          }
        }
      }

      ul.scroll-list {
        &.top.left {
          border-right: none;
        }

        .scroll-hidden {
          &.import-headers-actual > div {
            left: 360px !important;
          }
        }

        .import-header-zindex-desc > div > div > div {
          @include descending-z-index;
        }

        .import-actuals-top-step5-addition {
          width: 360px;
          @include flexbox;
        }

        &.top {
          background: white;
        }

        &.left {
          background-color: rgba(235, 235, 235, 0.5);
        }

        li {
          position: relative;
          height: 45px;

          * {
            font-size: 18px;
            line-height: 45px;
          }

          .import-font-header *,
          .import-font-header {
            font-size: 14px;
            line-height: 45px;
            font-weight: 600;
            letter-spacing: 1px;
            color: $color-steel;
            text-transform: uppercase;

            .select2-choice *,
            .month-picker * {
              font-weight: normal;
              text-transform: none;
            }
          }

          .selected-row {
            position: absolute;
            left: 0;
            margin-top: -1px;
            width: 100%;
            height: 46px;
            background-color: rgba($color-weird-green, 0.15);
            border-top: 1px solid $color-weird-green;
            border-bottom: 1px solid $color-weird-green;
          }
        }

        &:not(.accounts-mapping):not(.dimension-mapping) {
          li {
            &.ui-selected {
              background-color: rgba($color-weird-green, 0.15) !important;
            }
          }
        }

        &.dimension-mapping {
          li {
            &.ui-selected {
              background-color: transparent;
            }
          }
        }

        &.accounts-mapping {
          li {
            &.ui-selected {
              background-color: transparent !important;
            }

            .column.inline-select {
              .budget-select-lazy {
                width: 150px;
                max-width: none;

                > .select2-container {
                  width: 150px !important;
                  max-width: none !important;
                }

                > .select-2-lazy-inactive-label {
                  width: 150px;
                  max-width: 150px !important;
                  padding-right: 15px !important;
                  padding-left: 5px;
                  @include dropdown-sign;

                  &::after {
                    color: white !important;
                    top: 0 !important;
                  }
                }
              }
            }
          }
        }

        .column {
          &:not(.fixed-additional-column-action) {
            .action-select-container {
              .ember-power-select-trigger {
                &::after {
                  top: 3px;
                }
              }
            }
          }

          .column-content {
            &.import-compare-content {
              padding: 0 10px;
              width: 50%;

              &.cancel-import-text {
                text-decoration: line-through;
              }

              &.mark-different {
                span {
                  background-color: $color-mint-tulip;
                }
              }

              > div {
                @include text-ellipsis;
              }
            }
          }

          .column-content,
          > label:not(.multiply-by) {
            @include text-ellipsis;

            text-align: left;
          }

          label.multiply-by {
            width: 64px;
            line-height: 1;
            font-size: 14px;
            white-space: normal;
            text-align: right;

            &::after {
              content: "%";
              position: absolute;
              right: 4px;
            }
          }

          input.multiply-by {
            background: none;
            border: 0;
            outline: 0;
            width: 30px;
            padding: 0;
            text-align: center;
            line-height: 1;
          }

          * {
            font-size: 18px;
            line-height: 45px;
          }

          justify-content: left;
          text-align: left;
          height: 45px;
          border-right: 1px solid rgba($color-forecast-border, 0.35);
          border-bottom: 1px solid rgba($color-forecast-border, 0.45);
          position: relative;

          .selected-column {
            pointer-events: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color-dark-sky-blue, 0.15);
            border-left: 1px solid rgba($color-dark-sky-blue, 0.5);
            border-right: 1px solid rgba($color-dark-sky-blue, 0.5);

            &.past-date-selected {
              background-color: rgba($color-dark-mint, 0.15);
              border-left: 1px solid rgba($color-dark-mint, 0.5);
              border-right: 1px solid rgba($color-dark-mint, 0.5);
            }
          }

          .action-select-container {
            .ember-power-select-trigger {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding-right: 20px;
              background-color: unset;

              &::after {
                color: $font-color;
              }
            }

            &.power-select-part-size {
              width: 100%;
              min-width: 60px;
              display: inline-flex;

              .ember-power-select-trigger {
                width: inherit;
              }
            }
          }

          &.bulk-selection-checkbox {
            label {
              margin: 0;
            }

            &:not(.ui-selected-column) {
              label {
                &::before {
                  background-color: white;
                }
              }
            }

            &.ui-selected-column {
              padding: 0 9px;
              border: 1px solid $color-blue-changes;
              background: rgba($color-blue-changes, 0.2);

              label::before {
                background: $color-blue-changes;
              }
            }
          }
        }
      }

      &:not(.step-3) ul.scroll-list {
        li {
          &.added {
            background-color: rgba($color-dashboard-yellow, 0.15) !important;
          }
        }

        &.left {
          li {
            &.added {
              &::after {
                content: attr(data-after);
                font-size: 14px;
                background-color: $color-warning-orange;
                position: absolute;
                right: 10px;
                line-height: initial;
                color: $font-color-white;
                margin: 13px 0;
                padding: 0 4px 2px;
                border-radius: 3px;
              }
            }
          }
        }
      }

      &.step-2 ul.scroll-list {
        li {
          &.added.ui-selected {
            .ui-selected-dirty span {
              background-color: $color-warning-orange !important;
            }
          }
        }
      }

      .import-preview {
        position: relative;
        @include transition(max-width 0.1s ease-in-out);

        max-width: calc(100% - 340px);
        background-color: #f8f8f8;

        &.expand {
          @include transition(max-width 0.1s ease-in-out);

          max-width: calc(100% - 30px);
        }

        .import-header-height-double {
          height: 70px;
        }

        .scrollable {
          position: absolute;
          height: calc(100% - 46px);
          top: 46px;
          left: 0;
          right: 0;
          @include border-radius-bottom-right(3px);

          ul.scroll-list {
            position: absolute;
            top: 0;
            bottom: 0;
            max-height: none;
            margin-bottom: 0;

            &.main {
              right: 0;
              overflow: hidden;
              @include border-radius-right(3px);
            }

            &:not(.top).main {
              & > span:nth-child(even) > li,
              & > span:nth-child(odd) > li,
              li:nth-child(even),
              li:nth-child(odd) {
                background-color: transparent;
              }

              .column {
                color: $font-color;
              }
            }

            .column span.percent {
              color: $color-steel;
              margin-left: 4px;
            }

            .column.budget-select-lazy {
              > span {
                display: block;
                width: 100%;
                padding-left: 10px;
                height: 100%;

                &.enabled::after {
                  content: "\f107";
                  color: #adadad;
                  font-family: FontAwesome;
                  display: block;
                  position: absolute;
                  right: 10px;
                  top: 0;
                  transition: all 0.4s ease;
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 45px;
                }
              }
            }

            &:not(.left) {
              &:not(.step-2):not(.step-3) li,
              &.step-2:not(.step-3) li.ui-selected {
                &[data-budget-type="per-employee"],
                &[data-budget-type="per-new-employee"],
                &[data-budget-type="employee-allocated"],
                &[data-budget-type="employee-transferred"],
                &[data-budget-type="expense-allocated"],
                &[data-budget-type="expense-calculated"],
                &[data-budget-type="expense-buffer"],
                &[data-budget-type="subscription-saas"],
                &[data-budget-type^="sales"]:not([data-budget-type="sales"]) {
                  .column:not(.grayDisabled) {
                    &,
                    * {
                      color: $color-silver !important;
                    }

                    background: $color-silver !important;

                    > div {
                      display: none;
                    }
                  }
                }
              }

              .disabled {
                .column {
                  &,
                  * {
                    color: $color-silver !important;
                  }
                }
              }

              &.step-4 {
                &.accounts-import,
                &.dimensions-import {
                  li > .column {
                    width: 220px;
                  }
                }
              }
            }

            &.left {
              li[data-budget-type^="group"],
              li[data-budget-type="per-employee"],
              li[data-budget-type="per-new-employee"],
              li[data-budget-type="employee-allocated"],
              li[data-budget-type="employee-transferred"],
              li[data-budget-type="expense-allocated"],
              li[data-budget-type="expense-calculated"],
              li[data-budget-type="expense-buffer"],
              li[data-budget-type="subscription-saas"],
              li[data-budget-type^="sales"]:not([data-budget-type="sales"]) {
                .column.narrow {
                  background: $color-silver !important;

                  * {
                    display: none;
                  }
                }
              }
            }
          }

          .left-scroll-wrapper {
            height: 100%;
            position: absolute;
            overflow-x: hidden;

            &.step-1 {
              width: 46px;

              ul.scroll-list.left {
                width: 70px;
              }
            }

            &.step-2,
            &.step-4.actuals {
              width: 251px;

              ul.scroll-list.left {
                width: 270px;
              }

              li:not(.ui-selected) {
                .budgeta-checkbox {
                  label {
                    &::before {
                      background-color: white;
                    }
                  }
                }
              }
            }

            &.step-3 {
              width: 251px;

              ul.scroll-list.left {
                width: 270px;
              }

              &.actuals {
                ul.scroll-list.left .ember-list-view {
                  width: 100% !important;

                  &::-webkit-scrollbar {
                    display: none;
                  }
                }
              }
            }

            &.step-4 {
              &:not(.accounts-import) {
                width: 298px;

                ul.scroll-list.left {
                  width: 320px;
                }
              }

              &.accounts-import {
                width: 229px;

                ul.scroll-list.left {
                  width: 231px;
                }
              }

              &.dimensions-import,
              &.model-lines-import {
                width: 229px;

                ul.scroll-list.left {
                  width: 248px;
                }
              }

              &.actuals {
                width: 580px;

                ul.scroll-list.left {
                  width: 580px;
                }

                &.single-column {
                  width: 230px;

                  ul.scroll-list.left {
                    width: 100%;
                  }
                }

                &.extra-column {
                  width: 740px;

                  ul.scroll-list.left {
                    width: 765px;
                  }
                }

                > .actuals-test-4 {
                  > .ember-list-view {
                    width: 100% !important;

                    &::-webkit-scrollbar {
                      display: none;
                    }
                  }
                }
              }
            }

            .column {
              padding: 0 8px;
              text-align: center;
            }
          }

          .scroll-list.main.step-5.actuals {
            .ember-list-view {
              width: 100% !important;
            }
          }
        }

        .top-scroll-wrapper {
          height: 46px;

          &.double {
            height: 72px;

            + .scrollable {
              top: 72px !important;
              height: calc(100% - 72px) !important;
            }
          }

          overflow-y: hidden;

          .scroll-list.top.main {
            width: 100%;

            .header-column {
              padding: 0 10px;

              .column-content {
                padding-right: 25px;
              }

              .header-selected-att {
                letter-spacing: 1px;
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: inherit;

                span {
                  display: block;
                  position: absolute;
                  bottom: 0;
                  height: 24px;
                  text-transform: none;
                  line-height: 24px;
                  background-color: $color-dark-sky-blue;
                  font-weight: 400;
                  color: $font-color-white;
                  padding: 0 5px;
                }
              }
            }
          }

          .scroll-list.top.left {
            .header-column {
              .name-header-label {
                position: initial;
              }
            }
          }

          &.step-1 {
            width: calc(100% - 46px);

            &.top-left {
              width: 46px;
              float: left;
            }
          }

          &.step-2,
          &.step-4.actuals {
            width: calc(100% - 251px);

            &.top-left {
              width: 251px;
              float: left;

              .top {
                &.left {
                  .column {
                    > label {
                      width: inherit;
                    }
                  }

                  li {
                    .budgeta-checkbox {
                      :not([type="checkbox"]:checked) + label {
                        &::before {
                          background-color: white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.step-3 {
            width: calc(100% - 251px);

            &.top-left {
              width: 251px;
              float: left;

              .top {
                &.left {
                  .column {
                    span {
                      width: inherit;
                    }
                  }
                }
              }
            }

            + .scrollable {
              top: 46px;
              height: calc(100% - 46px);
            }
          }

          &.step-3a {
            width: calc(100% - 251px);

            &.top-left {
              width: 251px;
              float: left;

              li:first-child > div {
                border-bottom: none;
              }

              li,
              li > div {
                height: 35px;
              }

              .scroll-list {
                &.top {
                  &.left {
                    .header-column {
                      > span {
                        width: inherit;
                      }
                    }
                  }
                }
              }
            }

            + .scrollable {
              top: 46px;
              height: calc(100% - 46px);

              .scroll-list {
                .import-duplicated {
                  .svg-icon.icon-warning .icon {
                    width: 18px;
                  }
                }

                .import-duplicated:not(.import-duplicated-first):not(.import-duplicated-last) {
                  .fixed-additional-column-action {
                    top: 2px;
                    height: 43px;
                  }
                }

                .import-duplicated-first {
                  border-top: 2px solid $color-light-dark-grey;
                }

                .import-duplicated-last {
                  border-bottom: 3px solid $color-light-dark-grey;

                  .budgeta-checkbox {
                    &.fixed-additional-column-action {
                      height: 41px !important;
                      margin-top: 2px;
                    }
                  }

                  &.import-duplicated-first {
                    .budgeta-checkbox {
                      &.fixed-additional-column-action {
                        margin-top: 0;
                      }
                    }
                  }
                }
              }
            }

            .trx-filter {
              i {
                line-height: 25px;
              }
            }
          }

          &.step-4 {
            width: calc(100% - 300px);

            &.top-left {
              width: 300px;
              float: left;

              .top {
                &.left {
                  .column {
                    span {
                      width: inherit;
                    }
                  }
                }
              }
            }

            &.model-lines-import {
              width: calc(100% - 229px);

              &.top-left {
                width: 229px;
              }
            }

            &.accounts-import,
            &.dimensions-import {
              width: calc(100% - 229px);

              &.top-left {
                width: 229px;
              }

              &:not(.top-left) {
                > ul.scroll-list {
                  > li > .column {
                    width: 220px;
                  }
                }
              }
            }

            &.actuals {
              width: calc(100% - 580px);

              &.top-left {
                width: 580px;
              }

              &.extra-column {
                width: calc(100% - 740px);

                &.top-left {
                  width: 740px;
                }
              }

              &.single-column {
                width: calc(100% - 230px);

                &.top-left {
                  width: 230px;
                }
              }

              ul.scroll-list.main {
                width: 100%;
              }
            }
          }

          .trx-filter {
            margin-right: 5px;
            width: 75%;

            &::before {
              right: 4px;
              top: 58%;
            }

            i {
              position: relative;
              margin-right: 0;
              top: -2px;

              &.fa-long-arrow-down,
              &.fa-long-arrow-up,
              &.fa-filter {
                right: 4px;
              }
            }
          }

          &.top-left {
            .trx-filter {
              margin-right: 24px;
              width: 75%;

              &::before {
                right: 4px;
                top: 58%;
              }

              i {
                position: relative;
                margin-right: 0;

                &.fa-long-arrow-down {
                  top: 1px;
                }

                &.fa-long-arrow-up {
                  top: 1px;
                }

                &.fa-filter {
                  right: 2px;
                }
              }
            }
          }
        }

        &.has-groups {
          ul.scroll-list.left:not(.top) {
            li.indent {
              .column {
                > label {
                  padding-left: 50px;
                }

                .column-content {
                  padding-left: 30px;
                }
              }
            }
          }
        }

        &.import-generic-file {
          .top-scroll-wrapper.double + .scrollable {
            height: calc(100% - 84px) !important;
          }

          .scrollable {
            height: calc(100% - 60px);
          }
        }
      }

      > div {
        @include flex(1 1 auto);
      }

      &.step-1 {
        .import-preview {
          .scroll-list {
            &:not(.top).main > span:nth-child(odd) > li,
            &:not(.top).main .ember-list-view.ember-list-view-list > div > div > div:nth-child(odd) > li {
              background-color: rgba(235, 235, 235, 0.5) !important;
            }

            &.left {
              width: 46px;

              .column {
                width: 45px;
              }
            }

            &.main {
              width: calc(100% - 46px);
              left: 46px;
            }
          }
        }
      }

      &.step-2.import-budget-lines,
      &.step-2.import-generic-file-container,
      &.step-3:not(.actuals-import),
      &.step-3a:not(.actuals-import) {
        .import-preview {
          .scroll-list {
            &.top {
              &:not(.left) {
                li {
                  .column:not(.fixed-additional-column-action) {
                    [type="checkbox"]:checked + label::before {
                      background: $color-dark-sky-blue;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.step-2.import-budget-lines,
      &.step-2.import-generic-file-container,
      &.step-3:not(.actuals-import),
      &.step-3a:not(.actuals-import),
      &.actuals-import.step-2 {
        .import-preview {
          .scroll-list {
            &.main .column {
              width: 100%;
            }

            &.main .wide-column {
              width: 360px;
            }

            &.top {
              li {
                height: 70px;

                .import-column-resizer::after {
                  font-size: 2rem;
                  margin-top: 10px;
                }

                .column:not(.fixed-additional-column-action) {
                  text-align: left;

                  .column-content {
                    width: 100%;
                  }

                  .header-label {
                    @include text-ellipsis;

                    width: calc(100% - 20px);
                    left: 20px;
                    position: relative;
                    height: fit-content;
                  }

                  justify-content: flex-start;
                  height: 70px;

                  label {
                    position: absolute;
                    height: 30px;
                    top: 5px;
                    left: 10px;
                  }

                  &.budgeta-checkbox .header-label {
                    left: 25px;
                  }

                  [checked] + label::before {
                    background: $color-dark-sky-blue;
                  }

                  [type="checkbox"]:checked + label::after {
                    transform: scale(0.65) translate(0%, -55%);
                  }

                  > .month-picker-wrapper {
                    .month-picker {
                      line-height: 24px;

                      input {
                        color: white;
                        background-color: $color-dark-sky-blue;
                        border: 0;
                        height: 24px;
                        line-height: 24px;
                        font-size: 14px;

                        &.duplicate-dates {
                          background-color: $color-error;
                        }
                      }
                    }
                  }

                  .select2-container,
                  > .month-picker-wrapper {
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    border: 0;
                    height: 24px;
                    padding: 0;

                    &.select2-dropdown-open {
                      .select2-arrow {
                        display: none;
                      }
                    }

                    a.select2-choice {
                      * {
                        color: white !important;
                        line-height: 24px;
                      }

                      background-color: $color-dark-sky-blue !important;

                      & > .select2-chosen {
                        margin-right: 18px;
                      }

                      .select2-arrow {
                        b::after {
                          color: white;
                          content: "›";
                        }
                      }
                    }
                  }

                  &.past-date {
                    a.select2-choice {
                      background-color: $color-weird-green !important;
                    }

                    [checked] + label::before {
                      background: $color-weird-green;
                    }

                    .month-picker-wrapper .month-picker input {
                      background-color: $color-weird-green;

                      &.duplicate-dates {
                        background-color: $color-error;
                      }
                    }
                  }
                }
              }
            }
          }

          .scrollable {
            height: calc(100% - 70px);
            top: 70px;
          }
        }
      }

      &.step-2.import-budget-lines,
      &.step-2.import-generic-file-container,
      &.step-3.actuals-import {
        .import-preview {
          .scroll-list {
            &.top {
              &.left {
                .column {
                  span {
                    width: inherit;
                  }
                }
              }

              li {
                .column:not(.fixed-additional-column-action) {
                  label {
                    position: relative;
                    height: 35px;
                    top: 0;
                    left: 0;
                  }
                }
              }

              .column {
                .trx-filter {
                  width: 20px;
                }
              }
            }
          }

          .scrollable {
            .scroll-list {
              &.left {
                .column {
                  &.missing {
                    > span {
                      &::after {
                        content: attr(data-after);
                      }
                    }
                  }

                  &.mismatch {
                    > span {
                      &::after {
                        content: attr(data-after);
                      }
                    }
                  }

                  &.not-allowed {
                    > span,
                    &.budgeta-checkbox > label {
                      color: $heading-border-color;
                      text-decoration: line-through;
                    }

                    > span {
                      &::after {
                        font-size: 14px;
                        background-color: $color-weird-green;
                        position: absolute;
                        right: 10px;
                        line-height: initial;
                        color: $font-color-white;
                        margin: 13px 0;
                        padding: 0 4px 2px;
                        border-radius: 3px;
                      }
                    }
                  }
                }
              }

              &.main {
                .not-allowed {
                  .column {
                    &,
                    * {
                      color: $heading-border-color;
                      text-decoration: line-through;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.step-3a:not(.actuals-import) {
        .import-preview {
          .scroll-list {
            &:not(.top):not(.left) {
              &.main {
                .column {
                  display: flex;
                }

                .import-compare-rows-colored {
                  .import-duplicated:not(.import-duplicated-first) {
                    .import-compare-existing {
                      background-color: $font-color-white;

                      > span {
                        display: none;
                      }
                    }
                  }

                  .import-duplicated {
                    &.import-duplicated-last {
                      * {
                        height: 42px;
                      }
                    }

                    &.import-duplicated-first {
                      * {
                        height: 43px;
                      }

                      &.import-duplicated-last {
                        * {
                          height: 40px;
                        }
                      }
                    }

                    &:not(.ui-selected) {
                      .column {
                        border-bottom: none;

                        .import-compare-existing {
                          background-color: $font-color-white;
                        }
                      }
                    }
                  }

                  .ui-selected {
                    .column-content:first-child {
                      border-right: 1px solid rgba(151, 151, 151, 0.35);
                    }

                    .import-compare-content {
                      &.mark-different {
                        span {
                          background-color: $font-color-white !important;
                        }
                      }
                    }
                  }
                }
              }

              .column {
                text-overflow: unset;
                padding: 0;
              }

              .import-compare-existing {
                &.wide {
                  width: 100%;
                }
              }

              .import-compare-rows-colored > div > div > div:nth-child(odd) {
                .import-compare-new {
                  background-color: $color-mid-grey;
                }

                .import-compare-existing {
                  background-color: $color-light-mid-opacity-grey;
                }
              }

              .import-compare-rows-colored > div > div > div:nth-child(even) {
                .import-compare-new {
                  background-color: $color-white;
                }

                .import-compare-existing {
                  background-color: $font-color-white;
                }
              }
            }

            &.top li ul {
              li {
                height: 35px;
                @include flexbox;

                text-align: left;

                .column-content {
                  width: inherit;
                  text-align: left;
                }
              }

              li:last-child {
                .import-header-compare {
                  height: 35px;
                  width: 50%;
                  line-height: 35px;
                  padding: 0 10px;

                  &.new-header {
                    .trx-filter {
                      width: 50%;
                      height: 35px;
                      margin-right: 0;

                      &::before {
                        margin-right: 5px;
                      }
                    }
                  }
                }

                .existing-header {
                  color: $color-light-steel;

                  .trx-filter {
                    left: 0;
                    width: 50%;
                    height: 35px;
                    margin-right: 0;

                    &::before {
                      margin-right: 5px;
                    }
                  }

                  &.existing-only {
                    .trx-filter {
                      width: inherit;
                    }
                  }
                }
              }
            }

            &.top.left {
              li {
                height: 35px;

                .column:not(.fixed-additional-column-action) {
                  height: 35px;
                  line-height: 35px;

                  label {
                    line-height: 35px;
                    top: 0;
                    font-weight: 100;
                    text-transform: none;
                    font-size: 16px;
                  }
                }
              }

              li:last-child {
                @include flexbox;

                > div:last-child {
                  font-weight: 100;
                }
              }
            }

            &.left {
              .import-duplicated {
                &.import-duplicated-first {
                  .column {
                    height: 43px;
                  }
                }
              }
            }
          }

          .scroll-list {
            &.left {
              .column {
                &.fixed-additional-column-action {
                  width: 73px;
                  text-align: center;
                  background-color: $font-color-white;

                  .action-select-container {
                    height: 30px;
                    line-height: 30px;
                    border-radius: 3px;
                    background-color: $color-budget-line-border !important;
                    display: block;
                    position: absolute;
                    right: 6px;
                    max-width: 80%;
                    top: 7px;
                    width: 100%;

                    * {
                      line-height: 24px;
                    }

                    .ember-power-select-trigger {
                      &::after {
                        line-height: 14px;
                        color: white;
                      }

                      .ember-power-select-selected-item {
                        margin-left: 5px;

                        .svg-icon {
                          .icon,
                          &:hover use {
                            fill: white;
                          }
                        }

                        .power-option-name {
                          display: none;
                        }
                      }
                    }

                    &.not-allowed {
                      background-color: $color-red !important;

                      &:hover {
                        background-color: $color-error-dark !important;
                      }

                      .ember-basic-dropdown-trigger--below {
                        background-color: $color-error-dark;
                        border-radius: 3px;
                      }
                    }

                    &:hover {
                      background-color: $color-dark-mint !important;
                    }
                  }

                  &.fixed-additional-column-action-wide {
                    width: 95px;
                    text-align: right;
                    padding: 0 9px 0 5px;

                    .action-select-container {
                      max-width: 55%;
                    }

                    &.budgeta-checkbox {
                      label {
                        padding-left: 30px;

                        &::before {
                          margin-top: 0;
                          left: 5px;
                        }

                        &::after {
                          left: 2px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .scrollable {
            .left {
              .select2-container {
                .select2-chosen {
                  .svg-icon {
                    .icon {
                      fill: $font-color-white;
                    }

                    &:hover {
                      * {
                        fill: $font-color-white;
                      }
                    }
                  }
                }
              }

              .fixed-additional-column-action-wide {
                &.budgeta-checkbox {
                  label {
                    left: 5px;
                    position: absolute;
                    top: 12px;
                  }
                }
              }
            }
          }
        }
      }

      &:not(.step-1) {
        .import-preview {
          .scroll-list {
            .column.variable-width {
              width: 100% !important;
            }

            li {
              .column {
                .duplicate-label {
                  background-color: $color-error !important;
                  color: white;
                  position: absolute;
                  right: 0;
                  line-height: 23px;
                  bottom: 0;
                  padding: 0 5px;
                  border-radius: 5px 0 0;
                }
              }

              .power-select-lazy {
                width: inherit;
                min-width: 60px;

                .inactive {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  padding-right: 20px;

                  &::after {
                    right: 7px;
                    top: 3px;
                  }
                }
              }
            }

            li.ui-selected {
              .column.inline-select {
                .column-content {
                  text-align: left;
                }

                .multi-inline,
                > .select2-container,
                > .power-select-lazy,
                > .budget-select-lazy.active,
                > .budget-select-lazy span.enabled,
                > .budget-select-lazy > div.select2-container {
                  display: block;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  max-width: 80%;

                  &.budget-select-lazy {
                    width: 100%;
                  }
                }

                > .power-select-lazy {
                  > span::after {
                    right: 7px;
                    color: white;
                  }
                }

                > .power-select-lazy {
                  display: block;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  padding: 0;
                  background-color: $color-weird-green !important;
                  line-height: 24px;
                  min-height: 24px;
                  max-width: 178px;
                  text-overflow: ellipsis;
                  color: white;

                  &::after {
                    color: white;
                    top: 0;
                  }

                  > span {
                    padding: 0 22px 0 10px;
                  }

                  span {
                    height: 24px;
                    min-height: 24px;
                    line-height: 24px;
                    top: 0;
                    color: white;
                    font-weight: 400;
                    max-width: inherit;
                    @include text-ellipsis;
                  }

                  .ember-power-select-trigger {
                    display: block;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    padding: 0;
                    background-color: $color-weird-green !important;
                    line-height: 24px;
                    min-height: 24px;

                    &::after {
                      color: white;
                      top: 0;
                    }

                    > span {
                      padding: 0 20px 0 10px;
                    }

                    span {
                      height: 24px;
                      min-height: 24px;
                      line-height: 24px;
                      top: 0;
                      color: white;
                    }
                  }
                }

                > .account-mapping-menu {
                  color: white;
                  line-height: 24px;
                  background-color: $color-weird-green !important;
                  display: block;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  height: 24px;
                  width: 150px;
                  padding: 0;

                  .mapping-display {
                    color: white;
                    width: 100%;
                    padding-right: 15px;
                    padding-left: 5px;
                    line-height: 24px;
                    @include text-ellipsis;

                    &::after {
                      color: white;
                    }
                  }
                }

                &.has-category {
                  .multi-inline,
                  > .select2-container,
                  > .power-select-lazy,
                  > .budget-select-lazy.active,
                  > .budget-select-lazy span.enabled {
                    max-width: 48%;
                    overflow: hidden;

                    &:not(.select-category) {
                      left: 0;
                    }
                  }
                }

                .budget-select-lazy span.enabled {
                  display: inline-block;
                  vertical-align: top;
                }

                .multi-inline {
                  @include flexbox;

                  .select2-container,
                  .power-select-lazy,
                  .budget-select-lazy span.enabled {
                    margin-left: 20px;
                  }

                  .budget-select-lazy {
                    max-width: 200px;
                    overflow: hidden;
                  }

                  > .select2-container {
                    max-width: 150px;
                  }
                }

                .multi-inline,
                .select2-container,
                .power-select-lazy,
                .budget-select-lazy span.enabled {
                  border: 0;
                  height: 24px;
                  padding: 0;

                  a.select2-choice,
                  &.enabled {
                    &.enabled {
                      padding: 0 5px;
                    }

                    &,
                    * {
                      color: white !important;
                      line-height: 24px;
                    }

                    background-color: $color-weird-green !important;

                    & > .select2-chosen {
                      margin-right: 20px;
                    }

                    .select2-arrow {
                      b::after {
                        color: white;
                      }
                    }
                  }
                }
              }
            }

            li:not(.ui-selected) {
              .column.inline-select {
                .select2-container,
                .power-select-lazy,
                .budget-select-lazy {
                  display: none;
                }
              }
            }

            &.left {
              width: 251px;

              .column {
                width: 249px;
                text-align: left;

                .select2-container {
                  border: 0;
                  height: 100%;

                  .select2-choice {
                    height: 34px;
                  }
                }
              }
            }

            &.main {
              width: calc(100% - 251px);
              left: 251px;
            }
          }

          &.wide {
            ul.scroll-list {
              &.left {
                width: 580px;

                .column {
                  width: 119px;

                  &.wide {
                    width: 228px;
                  }
                }
              }

              &.main {
                left: 580px;
              }
            }

            &.extra-column {
              ul.scroll-list {
                &.left {
                  width: 740px;

                  .column {
                    width: 109px;

                    &.wide {
                      width: 209px;
                    }
                  }
                }

                &.main:not(.top) {
                  width: calc(100% - 740px);
                  left: 740px;
                }
              }
            }

            &.single-column {
              ul.scroll-list {
                &.left {
                  width: 230px;
                }

                &.main:not(.top) {
                  width: calc(100% - 230px);
                  left: 230px;
                }
              }
            }

            &.has-extra-column {
              ul.scroll-list {
                &.left {
                  width: 470px;
                }

                &.main {
                  width: calc(100% - 470px);
                  left: 470px;
                }
              }
            }
          }

          &.wide-quad {
            ul.scroll-list {
              &.left {
                width: 760px;

                .column {
                  width: 119px;

                  &.wide {
                    width: 198px;
                  }
                }
              }

              &.main {
                width: calc(100% - 760px);
                left: 760px;
              }
            }
          }

          &.wide-double {
            ul.scroll-list {
              &.left {
                width: 570px;

                .column {
                  width: 119px;

                  &.wide {
                    width: 228px;
                  }
                }
              }

              &.main {
                width: calc(100% - 570px);
                left: 570px;
              }
            }
          }
        }
      }

      &.step-4:not(.actuals-import):not(.accounts-import):not(.dimensions-import):not(.model-lines-import) {
        ul.scroll-list {
          &.left {
            width: 300px;

            .column {
              width: 229px;

              &.narrow {
                width: 68px;
              }

              &.fixed-additional-column {
                width: 120px;
                text-align: center;
                background-color: white;

                > div {
                  display: block;
                  border-bottom: 2px solid lightgray;
                  width: 50px;
                  height: 36px;
                  margin: auto;
                }
              }
            }
          }

          &.main:not(.top) {
            width: calc(100% - 300px);
            left: 300px;
          }
        }
      }

      &.step-4 {
        &.accounts-import,
        &.model-lines-import,
        &.dimensions-import {
          ul.scroll-list {
            &.left {
              width: 229px;

              //border-right: 1px solid rgba($color-forecast-border, 0.35);
              .column {
                width: 228px;
              }
            }

            &.main {
              width: calc(100% - 229px);
              left: 229px;
            }
          }
        }
      }

      > .import-generic-file {
        --left-side-size: 300px;

        top: 0;
        left: --left-side-size;
        right: 0;
        bottom: 45px;
        position: absolute;

        &.import-preview {
          width: calc(100% - var(--left-side-size));
          max-width: unset;

          .scrollable.infinite-scroll {
            .left-scroll-wrapper {
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.import-modal {
  .modal-dialog {
    width: 900px;

    .modal-content {
      .modal-body {
        min-height: 450px;
        padding: 0;

        .left,
        .right {
          display: inline-block;
        }

        .right {
          float: right;
        }

        a.green {
          color: $color-dark-green;
        }

        ul.scroll-list {
          &:not(.top) {
            li:not(.selected) {
              opacity: 0.7;
            }
          }

          li {
            &.selected {
              background: $color-light-green;
              @include box-shadow(none);
            }
          }
        }

        .import {
          .wizard-steps-container {
            margin: 0 20px 20px 70px;
          }

          .import-step {
            .row {
              margin-bottom: 20px;
            }
          }
        }

        .import-preview {
          height: 220px;
        }

        .import.step-3 {
          .import-preview {
            height: 410px;

            .scrollable {
              height: 300px;

              &::before {
                left: 296px;
              }

              ul.scroll-list.left .column {
                padding: 0;

                &:first-child {
                  padding: 0 10px;
                }
              }
            }

            ul.scroll-list.main {
              width: 598px;
            }

            label.full {
              margin-left: 20px;
            }
          }
        }

        .import.step-2 .import-step .scrollable {
          overflow-y: auto;
          height: 160px !important;
        }

        .scrollable {
          position: relative;
        }
      }
    }
  }
}

.import-lines-modal {
  .modal {
    left: 340px;

    .overlay {
      display: none;
    }

    .modal-dialog {
      width: 760px;
    }
  }
}

.modal-content {
  z-index: 10000;

  .import-success-modal {
    position: absolute;
    width: 100%;
    left: 0;

    hr {
      border-color: $color-light-mid-opacity-grey;
    }

    span {
      position: relative;
      left: 48px;
    }
  }
}

body:not(.hiddenscroll) {
  .inner-content {
    > .import.simple {
      > .import {
        .import-preview {
          .top-scroll-wrapper {
            .scroll-list.top.main.has-scroll {
              width: calc(100% - 15px);
            }
          }

          .scrollable {
            .left-scroll-wrapper {
              height: calc(100% - 15px);

              &.step-4 {
                &.actuals {
                  ul.scroll-list.left {
                    width: 600px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.non-mac {
    .inner-content {
      > .import.simple {
        > .import {
          .import-preview {
            .top-scroll-wrapper {
              .scroll-list.top.main.has-scroll {
                width: calc(100% - 17px);
              }
            }

            .scrollable {
              .left-scroll-wrapper {
                height: calc(100% - 17px);
              }
            }
          }
        }
      }
    }
  }
}
