.import-column-resizer {
  position: fixed;
  width: 16px;
  height: inherit;
  top: 0;
  background-color: transparent;
  z-index: 0;
  right: -8px;

  &:hover {
    cursor: col-resize;
  }
}

.import ul.scroll-list.top.left .import-column-resizer {
  position: absolute;
}

.import-resize-line {
  background: $color-weird-green;
  max-height: calc(100% - 112px);
  width: 1px;
  position: fixed;
  z-index: 1;
  top: 49px;

  &:hover {
    cursor: col-resize;
  }
}
