/* stylelint-disable max-nesting-depth, selector-class-pattern */
.account-category-body-container {
  margin-top: 40px;
  height: 450px;

  .intro {
    text-align: center;
    margin-bottom: 25px;
  }

  .headers {
    display: flex;
    flex: 1 2;
    border-bottom: 1px solid $color-gray-2;

    .category,
    .sibp-mapping {
      color: $color-steel;
      font-weight: 600;
      font-size: 14px;
      display: inline-block;
      text-transform: uppercase;
      margin-bottom: 10px;
      letter-spacing: 1px;
    }

    .sibp-mapping {
      margin-left: 20px;
    }

    .category {
      margin-left: 140px;
      max-width: 270px;
      @include text-ellipsis;
    }

    .error-container {
      position: absolute;
      right: 30px;

      .popup-label {
        .reference {
          > svg {
            > path {
              fill: $color-red;
            }
          }
        }
      }

      .popup-container {
        position: fixed;
        width: 210px;
        height: auto;
        z-index: 1;

        .message {
          padding: 10px 15px;
          max-width: 250px;
          min-height: 120px;
          max-height: 250px;
          overflow: auto;

          .missing-msg {
            margin-bottom: 10px;

            .header {
              font-weight: 500;
            }
          }

          .mismatch {
            .header {
              font-weight: 500;
            }

            .option {
              color: $heading-border-color;
            }
          }

          .jump {
            color: $color-cerulean;
            cursor: pointer;

            > svg {
              position: relative;
              top: 7px;
              margin-right: 5px;
            }

            .name {
              color: $color-cerulean;
            }
          }
        }
      }
    }
  }

  .values {
    padding: 0 15px;
    width: 100%;
    height: 420px;
    margin: auto;

    .profit-and-loss,
    .balance-sheet {
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $color-gray-2;

      > svg {
        padding-top: 6px;
        margin-right: 5px;
        fill: $color-gray-3;
      }
    }

    .expanded {
      margin-left: 20px;

      .category-value {
        display: flex;
        margin: 15px 0;

        .select-container ~ div {
          left: 250px;
          top: inherit;
          position: absolute;
        }

        .name {
          width: 180px;
          @include text-ellipsis;

          line-height: 44px;
          margin-left: 20px;
          margin-right: 20px;
        }

        .select__menu {
          margin-top: 0;

          .select__menu-list {
            div:first-child {
              background-color: $font-color-white;
            }

            max-height: 180px;

            .select-clear-option {
              .clear-all,
              .select-all {
                cursor: pointer;
              }
            }
          }
        }

        .select__multi-value {
          background-color: $color-gray-4;
        }

        .select__control {
          width: 300px;
          border: 0;
          border-bottom: 1px solid $color-gray-2;
          border-radius: 0;
          box-shadow: none;
          background: transparent;
        }

        .select__indicator-separator {
          display: none;
        }

        .select__option--is-focused {
          background-color: rgba(56, 218, 149, 0.25);
        }

        .select__option--is-selected {
          background-color: $font-color-white;
        }

        .select__option--is-disabled,
        .select__option--is-selected {
          color: $color-gray-2;
          cursor: not-allowed;
        }
      }
    }
  }
}
