/* stylelint-disable  */
.modal.select-assumptions-modal {
  .modal-dialog {
    .modal-body {
      padding: 0 !important;
      border-bottom: 2px solid $color-softer-grey;
      border-top: 2px solid $color-softer-grey;
      @include flexbox;

      > div {
        height: 100%;
        @include flex(1);

        max-width: 50%;
      }

      ul.assumption-list {
        height: 100%;
        overflow-y: auto;
        margin: 0;

        li {
          padding: 0 24px;
          height: 38px;

          &:hover {
            background-color: rgba($color-weird-green, 0.24);
          }

          .assumption-content {
            @include flexbox;

            .level-icon {
              margin: 0 5px;
              letter-spacing: 3px;
            }

            .model-name {
              @include flex(1);

              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &:not(:hover):not(.selected) {
              .add-assumption {
                display: none;
              }
            }

            .budgeta-checkbox {
              max-width: 100%;

              label {
                margin: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          div.assumption-content * {
            line-height: 38px;
          }
        }
      }

      .model-select {
        background-color: $color-white;
        border-right: 6px solid $color-softer-grey;

        ul.assumption-list {
          width: calc(100% + 6px);

          li {
            &.active {
              background-color: rgba($color-dark-mint, 0.84);
            }
          }
        }
      }

      .assumptions-select {
        .missing-assumptions-name {
          font-style: italic;
          text-align: center;
        }

        ul.assumption-list {
          li {
            &.selected {
              background-color: rgba($color-weird-green, 0.24);
            }
          }
        }
      }
    }

    @media screen and (max-width: 1500px) {
      .modal-body {
      }
    }
  }
}
