/* stylelint-disable max-nesting-depth, font-family-no-missing-generic-family-keyword, no-duplicate-selectors, declaration-no-important */
.wizard-modal {
  &:not(.group-company-intacct):not(.intacct-wizard):not(.intacct-login-modal):not(.add-root-modal) {
    .modal-dialog {
      .modal-content {
        .modal-body {
          &,
          .wizard-question.wizard-question-single .wizard-question-selection > label,
          .wizard-question.wizard-question-single .wizard-question-selection > * > label {
            font-size: 23px;
            line-height: 22px;
            color: $color-dark-grey-blue;
          }
        }
      }
    }
  }
}

.modal-container.wizard-modal {
  &:not(.group-company-intacct) {
    .modal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            padding-bottom: 0;

            .wizard-top-section-part {
              height: 80px;
            }

            .tab-content {
              margin-bottom: 0;

              .tab-pane {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }
}

.modal-container.wizard-modal:not(.intacct-wizard) {
  &:not(.group-company-intacct) {
    .modal-dialog {
      @media screen and (max-width: 1450px) {
        margin-left: 420px;
      }
    }
  }

  .modal-dialog {
    width: 650px;

    .modal-content {
      .modal-body {
        .name-add-delete {
          position: relative;

          &.indented {
            margin-left: 30px;
          }

          .svg-icon {
            svg {
              fill: $color-steel;
              height: 20px;
              width: 20px;
            }

            &:hover {
              svg {
                fill: $color-weird-green;
              }
            }

            position: absolute;
            right: 12px;
            top: 8px;
          }

          &.has-type-select {
            input {
              height: 45px;
              line-height: 45px;
              padding-right: 220px;
            }

            .svg-icon {
              top: 0;
              right: 0;
              max-width: 50px;
              height: 45px;
              padding-left: 18px;
              line-height: 40px;

              &.drag-handle {
                left: -25px;

                svg {
                  fill: $color-light-steel;
                }
              }
            }

            .select2-container,
            .budget-type-dropdown {
              position: absolute;
              margin-top: 4px;
              right: 30px;
              top: 0;
              border: 0;
              @include box-shadow(none);

              background: $color-weird-green;
              @include border-radius(3px);

              height: 36px;
              padding-top: 2px;

              .svg-icon {
                line-height: 36px;
                height: 36px;
                right: 6px;

                svg {
                  fill: white;
                }
              }

              .select2-choice,
              .dropdown-value-text {
                &.dropdown-value-text {
                  margin: 0 30px 0 18px;
                  line-height: 32px !important;
                }

                &,
                * {
                  font-size: 18px;
                  color: white;
                }

                .select2-arrow b::after {
                  color: white;
                  top: 5px;
                }
              }
            }
          }

          input {
            font-size: 23px;
            width: 100%;
            margin-bottom: 10px;
            padding-left: 12px;
            padding-right: 40px;

            &:focus {
              color: $color-dark-mint;
              background-color: $color-duck-egg-blue;
              border-bottom: 1px solid $color-weird-green;

              & + .svg-icon {
                svg {
                  fill: $color-weird-green;
                }
              }
            }
          }

          p.input-error {
            left: 0;
            bottom: -20px;
          }
        }

        &,
        div,
        .wizard-question.wizard-question-single .wizard-question-selection > label,
        .wizard-question.wizard-question-single .wizard-question-selection > * > label {
          font-size: 23px;
          line-height: 22px;
          color: $color-dark-grey-blue;
        }

        .wizard-question.wizard-question-single .wizard-question-selection > label,
        .wizard-question.wizard-question-single .wizard-question-selection > * > label {
          font-size: 23px !important;
        }
      }
    }
  }
}

.wizard-modal {
  &:not(.group-company-intacct) {
    .modal-dialog {
      @media screen and (max-width: 1450px) {
        margin-left: 420px;
      }
    }
  }

  .modal-dialog {
    width: 650px;

    .modal-content {
      .modal-header {
        .modal-actions {
          .close-button-wrapper {
            display: none;
          }
        }
      }

      .modal-body {
        padding-bottom: 0;

        .wizard-top-section-part {
          height: 80px;
        }

        .tab-content {
          margin-bottom: 0;

          .tab-pane {
            padding-top: 0;
          }
        }

        .wizard-question.wizard-question-single {
          &:not(.no-margin) {
            padding-top: 30px;
            margin: 0;
          }

          .wizard-question-selection {
            margin: 0 0 8px;
          }
        }

        button,
        .btn {
          margin-right: 18px;

          &:not(.inactive) .svg-icon {
            svg {
              fill: #fff;
            }
          }

          &.manual {
            min-width: 106px;
          }

          &.inactive {
            border: 1px solid rgba($color-forecast-border, 0.5) !important;
          }
        }

        .subtitle,
        .left-subtitle {
          &,
          *:not(.link) {
            color: $color-steel !important;
            font-size: 16px !important;
            line-height: 1;
          }
        }

        .wizard-question {
          &:not(.no-margin) {
            margin-bottom: 30px;
          }

          &.error {
            font-size: 18px;
          }

          small,
          .wizard-question-after {
            color: $color-steel;
            font-size: 18px;
          }

          .wizard-question-after {
            display: none;
          }

          &[data-depends]:not([data-depends=""]) {
            display: none;
          }

          .wizard-question-selection {
            margin-top: 10px;

            label {
              border-bottom: 0 !important;
              font-size: 18px;
              width: auto;
              margin-left: 1px;
            }

            .wizard-question-selection-option {
              display: inline-block;

              label {
                padding-right: 0;
              }

              padding-right: 15px;
            }
          }

          .setup-guide-intro {
            line-height: 30px;

            span {
              cursor: pointer;
              color: $color-weird-green;
              line-height: 30px;
              font-size: 23px;
            }
          }
        }

        .toggle-line {
          label {
            width: 100%;
            @include flex(0 0 auto !important);

            border-bottom: 0 !important;
          }

          &.in-non-profit.unavailable-in-non-profit-budget {
            display: none;
          }
        }

        .add-multi-row {
          margin-top: 12px;
          color: $color-light-steel;

          &.selected {
            color: $color-dark-grey-blue;
          }

          input.add-multi {
            border: 1px solid rgba($color-forecast-border, 0.41);
            width: 40px;
            text-align: center;
            font-size: 18px;
            padding-right: 0;
            margin-right: 20px;
            @include border-radius(3px);
          }
        }

        .scrollable {
          &:not(.no-margin) {
            margin-top: 30px;
          }

          max-height: 700px;
          @media screen and (max-height: 1000px) {
            max-height: 600px;
          }
          @media screen and (max-height: 900px) {
            max-height: 500px;
          }
          @media screen and (max-height: 800px) {
            max-height: 400px;
          }
          @media screen and (max-height: 750px) {
            max-height: 350px;
          }
          @media screen and (max-height: 700px) {
            max-height: 300px;
          }
          @media screen and (max-height: 660px) {
            max-height: 250px;
          }
          @media screen and (max-height: 620px) {
            max-height: 200px;
          }

          overflow-y: auto;

          &.short-list {
            max-height: 350px;
          }
        }

        .data-populate {
          margin-top: -15px;
        }

        a.add {
          font-size: 23px;
          margin-top: 10px;
          margin-left: 10px;
          display: inline-block;
          color: $color-light-steel !important;

          &::before {
            content: "+";
            display: inline-block;
            margin-right: 20px;
          }

          &:hover {
            color: $color-weird-green !important;
          }
        }
      }

      .modal-footer {
        @include transition(all 0.25s ease);
        @include box-shadow(0 -14px 19px 0 rgba(0, 0, 0, 0));

        border-top: 1px solid $color-mid-grey;
      }
    }
  }

  &.wide {
    .modal-dialog {
      width: 860px;
    }
  }

  &:not(.elegant-modal) {
    .modal.step-accounts,
    .modal.step-account-groups,
    .modal.step-accounts-dep {
      .modal-dialog {
        width: 860px;
      }
    }
  }

  &.intacct-wizard .modal .modal-dialog .modal-content .modal-body {
    .tab-content .tab-pane div.flex {
      &:not(.no-margin) {
        margin-bottom: 8px;
      }

      * {
        font-size: 18px;
      }

      &.with-spaces {
        > label {
          color: $color-dark-grey-blue;

          &.wide {
            min-width: 50%;
          }
        }

        > :not(:first-child) {
          margin-left: 20px;
        }
      }

      div.select2-container.select2-container-multi ul.select2-choices {
        margin-top: 0;
      }
    }
  }

  .modal.step-accounts,
  .modal.step-accounts-dep,
  .modal.step-account-groups,
  &.intacct-wizard .modal {
    .modal-dialog {
      margin-left: auto;

      .table-editor {
        .table-header {
          .column {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 40px;
          }
        }

        .table-left {
          float: left;
        }

        .table-right {
          width: 550px;
          overflow-x: auto;
        }

        .table-body.scrollable {
          margin-top: 0;
        }

        .select-2-lazy-inactive-label {
          line-height: 40px;
          display: inline-block;

          &::after {
            content: "\f107";
            color: #adadad;
            font-family: FontAwesome;
            font-weight: bold;
            font-size: 16px;
            display: block;
            position: absolute;
            right: 8px;
            top: 0;
            transition: all 0.4s ease;
            pointer-events: none;
          }

          &.placeholder {
            color: $color-green;
          }
        }

        .budgeta-checkbox {
          label {
            border-bottom: 0;
            display: block;
            margin-left: 4px;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .modal {
    &.step-expenses,
    &.step-geos,
    &.step-employees,
    &.step-populate-budget {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .wizard-question {
              margin-top: 30px;

              .wizard-question-selection {
                margin-top: 30px;

                label {
                  display: block;

                  &.budgeta-checkbox {
                    label {
                      margin: 0;
                    }
                  }

                  &:not(.budgeta-checkbox) {
                    margin: 20px 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.step-dimensions {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .wizard-question {
              margin-top: 30px;

              .wizard-question-selection {
                margin-top: 30px;
              }
            }
          }
        }
      }
    }
  }

  &.group-payroll {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .scrollable {
            margin-top: 10px;
            max-height: 400px;
            @media screen and (max-height: 800px) {
              max-height: 300px;
            }
            @media screen and (max-height: 750px) {
              max-height: 250px;
            }
            @media screen and (max-height: 700px) {
              max-height: 200px;
            }
            @media screen and (max-height: 660px) {
              max-height: 150px;
            }
            @media screen and (max-height: 620px) {
              max-height: 100px;
            }
          }
        }
      }
    }
  }

  &.has-scrollbar {
    .modal-dialog {
      .modal-content {
        .modal-footer {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            height: 6px;
            background: rgba(#000, 0.05);
            top: -6px;
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }

  &.has-group-children {
    .wizard-question {
      .wizard-question-selection {
        .child-normal {
          margin-left: 30px;
        }
      }
    }
  }
}
