/* stylelint-disable selector-class-pattern */

.users-container {
  height: 24px;
  display: flex;
  transform: translateY(50%);

  .extra-users-container {
    display: flex;
    margin-left: -15px;

    &:hover,
    &.open {
      cursor: pointer;

      .dropdown-arrow {
        svg {
          fill: $color-green-sage-logo;
        }
      }

      .extra-users.circle-container {
        color: $color-green-sage-logo;
        border: 0.75px solid $color-green-sage-logo;
      }
    }

    .extra-users {
      color: $color-regular-white;
      background-color: inherit;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }

    .dropdown-arrow {
      scale: 0.8;
      transition: all 0.4s ease;
      transform: rotate(0deg);

      svg {
        fill: $color-regular-white;
      }
    }

    &.open {
      .dropdown-arrow {
        @include transform(rotate(180deg));

        transition: all 0.4s ease;
      }
    }
  }

  .displayed-users {
    display: flex;
  }

  .displayed-user {
    &:hover {
      outline: 2px solid $color-weird-green;
      z-index: 10000;
    }
  }
}

.circle-tooltip {
  display: flex;
  width: 97px;
  padding: 8px 12px;
  justify-content: center;
  border-radius: 4px;
  background-color: $color-white;
  margin-top: -20px;
}

.extra-users-list {
  .select__control {
    display: none;
  }

  .select__menu {
    z-index: 1000;
    width: 220px;
    box-shadow: 0 5px 5px rgba(0, 20, 30, 0.2), 0 10px 10px rgba(0, 20, 30, 0.1);
    border-radius: 8px;
    margin-top: 30px;

    .user-option {
      display: flex;
      padding: 9px 12px 10px;
      align-items: center;
      gap: 12px;
      height: 40px;
      cursor: default;

      &:hover {
        background-color: $color-mint-tulip;
      }

      .users-list {
        font-size: 14px;

        &:hover {
          cursor: default;
        }
      }
    }
  }
}
