/* stylelint-disable max-nesting-depth */
$icon-gap: 8px;
$list-font-size: 23px;

.file-upload-intro-body-container {
  .lottie-animation {
    margin-bottom: 20px;
  }

  .description {
    &.description-center {
      margin: 0 auto;
      width: fit-content;
    }

    .intro {
      font-size: 24px;
      width: fit-content;
      margin: 0 auto;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 18px;
      width: fit-content;
      margin: 20px auto 0 0;

      > li {
        display: flex;
        justify-content: flex-start;
        gap: $icon-gap;
        font-size: $list-font-size;

        .popup-with-label-container .popup-container {
          z-index: 1;
          margin-top: -60px;
          right: unset;
          left: 35px;
          height: 100px;
          width: 180px;
          display: flex;
          align-items: center;
          justify-content: space-around;

          .arrow-tooltip {
            transform: rotate(-45deg);
            left: -8px;
            top: 40px;
            right: unset;
            z-index: -1;
          }
        }

        &.download-template-link {
          margin-top: 25px;
        }

        &.more-details-link,
        &.download-template-link {
          a {
            color: $color-cerulean;
            fill: $color-cerulean;
            display: flex;
            gap: $icon-gap;
            font-size: $list-font-size;
          }
        }
      }
    }
  }
}
