@import "sip-components/styles/reset";
@import "sip-components/styles/utils";
@import "sip-components/styles/variables";
@import "sip-components/styles/colors";
@import "sip-components/styles/fonts";
@import "sip-components/styles/badge";
@import "../../styles/global";
@import "../../styles/variables";
@import "../../styles/page-container";
@import "../components/header/header";
@import "../components/body/body";
@import "../../components/nav-bar/nav-bar";
@import "../pages/userList/userList";
@import "../pages/profile/profile";
@import "../pages/userEdit/userEdit";
@import "../pages/auditLoggingList/auditLoggingList";

body {
  min-height: 100vh;
  padding: 0;
  @include font(18);

  font-family: $default-font-family, sans-serif;
  color: $color-blue-3;
}

.user-admin-container {
  height: 100vh;
}

.app-footer {
  line-height: 10px;
}
