/* stylelint-disable max-nesting-depth, font-family-no-missing-generic-family-keyword, no-duplicate-selectors, selector-class-pattern, declaration-no-important */
.main-content {
  &[data-path="main.budget.trx"],
  &[data-path="main.budget.accounts"] {
    aside.secondary {
      display: none;
    }

    .table-editor.dimensions-table-editor {
      .scrollable-wrapper {
        .scrollable {
          @include flexbox;

          .column {
            @include flex(auto 1 1);
          }
        }
      }
    }

    .table-editor:not(.dimensions-table-editor) {
      .table-body:not(.accounts-table-body) {
        max-height: calc(100% - 30px - 16px - 18px);
      }

      .scrollable-wrapper {
        .scrollable {
          width: 3020px;
        }
      }

      > .table-footer {
        border-top: 1px solid #f8f9f9;
      }
    }

    .column {
      min-height: 1px;
      line-height: 40px;

      &.no-mapping {
        background-color: rgba(234, 234, 234, 0.35);

        input {
          width: calc(100% - 100px);
          background-color: transparent !important;
        }
      }

      .red-label {
        background-color: $color-red;
        color: white;
        @include border-radius(3px);

        padding: 0 5px;
        float: right;
        margin: 8px 2px 0;

        + input {
          max-width: 160px;
        }
      }

      &.narrow {
        width: 30px;
        text-align: center;
      }

      &.wide {
        width: 160px !important;
      }

      &.amount {
        width: 120px;
      }

      &.amount-currency {
        width: 240px;
      }

      &.currency {
        width: 120px;
      }

      &.account {
        width: 180px;
        max-width: 180px !important;
      }

      &.wider {
        width: 200px;
        max-width: 200px !important;

        .month-picker {
          width: 200px;
          max-width: 200px !important;

          input {
            width: 100%;
          }
        }
      }

      &.wider-level-2 {
        width: 300px;
        max-width: 300px !important;
      }

      &.widest {
        width: 400px;
        max-width: 400px !important;
      }

      &.wide-month {
        width: 250px;
        max-width: 250px !important;

        .month-picker {
          width: 250px;
          max-width: 250px !important;

          input {
            width: 100%;
          }
        }
      }

      &.month {
        width: 130px;
      }

      &.month,
      &.wide-month {
        .month-picker-wrapper {
          border-bottom: 0;

          .year-picker-from {
            width: 100%;

            .month-picker {
              width: 100%;
            }
          }
        }
      }

      &.narrow-actions {
        &:not(.dual-actions) {
          width: 90px;
        }

        padding: 0;

        &::before {
          margin-left: 0 !important;
        }

        .svg-icon {
          vertical-align: initial;
        }
      }

      &.dual-actions {
        width: 75px;
      }

      &.center {
        text-align: center;
      }

      &.checkbox-column {
        .budgeta-checkbox {
          label {
            &::before {
              left: 10px;
            }

            &::after {
              left: 7px;
            }
          }
        }
      }
    }

    .ember-list-view.ember-list-view-list > div > div > div {
      width: 100%;

      .trx-row {
        height: 40px;

        &.manual {
          background-color: rgba($color-topaz-highlight, 0.1);
        }

        > * {
          float: left;
        }

        .trx-row-content {
          width: 100%;
        }

        .month-year-picker-lazy {
          .fake-value {
            height: 40px;
            line-height: 38px;
            padding: 0 10px;
          }

          .fake-value {
            .missing-value {
              color: $color-green;
              height: 40px;
              line-height: 38px;
            }
          }

          .fake-value {
            &.disabled {
              cursor: not-allowed;
            }
          }
        }

        .budget-select-lazy {
          height: 40px;

          > span {
            height: 100%;
            line-height: 40px;
            padding-left: 8px;
            display: block;
            width: calc(100% - 27px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.prompt {
              color: $color-red;
            }
          }

          &:not(.active):not(.read-only) {
            position: relative;

            &::after {
              content: "\f107";
              color: #adadad;
              font-family: FontAwesome;
              font-weight: bold;
              font-size: 16px;
              display: block;
              position: absolute;
              right: 5px;
              top: 8px;
            }
          }
        }

        .column {
          &.amount-currency {
            > .currency {
              > div {
                @include flex(0 0 120px);

                height: 40px;
              }
            }
          }

          &.checkbox-column {
            .budgeta-checkbox {
              label {
                height: 40px;
              }
            }
          }
        }
      }

      input:not([type="radio"]):not([type="checkbox"]) {
        &:not([disabled]) {
          background-color: transparent;
        }

        &:disabled {
          background-color: transparent;
        }
      }

      .trx-row .column {
        > span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding: 0 20px 0 10px;
          display: block;
          line-height: 40px;
          border: 0 solid #e8e8e7;
          position: relative;

          &.enabled::after {
            content: "\f107";
            color: #adadad;
            font-family: FontAwesome;
            display: block;
            position: absolute;
            right: 10px;
            top: 0;
            line-height: 33px;
            transition: all 0.4s ease;
            font-weight: bold;
            font-size: 16px;
          }
        }
      }

      .currency {
        @include flexbox;

        width: 240px;

        &.has-currency-selection input[type="text"] {
          width: calc(100% - 80px) !important;
        }
      }
    }

    .trx-table {
      &:not(.full-size-table-editor) {
        height: calc(100% - 50px);
      }

      &.trx-table-readonly {
        height: calc(100% - 85px);
      }

      overflow-y: hidden;

      .trx-table-header {
        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;
        overflow-x: auto;

        .inner-wrapper {
          width: 100%;
        }

        .column {
          &::after {
            position: absolute;
            right: 10px;
          }
        }
      }

      .trx-table-body {
        overflow-x: hidden;
      }

      &.trx-table-readonly {
        .trx-table-footer {
          .footer-scrollable-wrapper {
            float: right;
          }
        }
      }

      &.multi-company {
        .scrollable-wrapper {
          .scrollable {
            width: 2860px;
          }
        }
      }
    }
  }
}

body.hiddenscroll {
  .main-content {
    &[data-path="main.budget.trx"] {
      .table-editor {
        &:not(.trx-table-readonly) {
          .scrollable-wrapper {
            width: calc(100% - 40px - 300px - 90px);
          }
        }

        &.trx-table-readonly {
          .scrollable-wrapper {
            width: calc(100% - 300px);
          }
        }
      }
    }
  }
}

body:not(.hiddenscroll) {
  .main-content {
    .table-editor:not(.accounts-table-editor) {
      .trx-table-header {
        .column.narrow-actions {
          padding-right: 13px;
        }
      }
    }

    .table-editor {
      &:not(.accounts-table-editor) {
        .table-body {
          max-height: calc(100% - 30px - 16px - 18px);
        }
      }

      .table-body,
      .table-header,
      .table-footer {
        .scrollable-wrapper {
          width: calc(100% - 40px - 300px - 90px - 18px);
        }

        .column.narrow-actions.dual-actions {
          width: 75px;
        }

        .column.narrow-actions:not(.dual-actions) {
          width: 105px;
        }
      }

      &.trx-table-readonly {
        .table-body,
        .table-header,
        .table-footer {
          .scrollable-wrapper {
            width: calc(100% - 300px);
          }
        }
      }

      .table-body {
        .trx-row {
          .column.narrow-actions.dual-actions {
            width: 75px;
          }

          .column.narrow-actions:not(.dual-actions) {
            width: 105px;
          }
        }
      }

      &.hasInnerScroll {
        .trx-table-header,
        .trx-table-footer {
          margin-right: 15px;
        }
      }
    }
  }

  > .app-view > .main-view > .main-content .table-editor.hasInnerScroll {
    .trx-table-header,
    .trx-table-footer {
      margin-right: 15px;
    }
  }
}
