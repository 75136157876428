/* stylelint-disable declaration-no-important */
@import "sip-components/styles/fonts";

.audit-logging-container {
  .tr {
    height: auto !important; // it overrides row style that react-table adds
    min-height: 45px;
  }

  svg {
    padding: 5px 3px;
  }

  .page-header {
    .right-side {
      @include flexbox;

      margin-right: 20px;
    }
  }

  .page-body {
    .disable-details {
      display: flex;
      gap: 5px;

      .reference {
        svg {
          padding: 0;
        }
      }
    }
  }
}
