/* stylelint-disable declaration-no-important */
.modal.model-store-dialog {
  overflow-y: hidden;

  .modal-dialog {
    width: 800px;
  }
}

.model-store-item {
  .budgeta-checkbox label {
    border-bottom: 0;
  }

  > div {
    float: left;
    position: relative;

    &.model-store-item-version {
      float: right;
      font-size: 14px !important;
      color: $color-steel !important;
    }
  }

  .model-store-item-description {
    clear: both;
    float: none;
    margin: 0 0 20px 25px;
    padding-top: 10px;
    font-size: 18px !important;
  }

  .model-store-item-title {
    font-size: 20px;
  }
}
