.licenses-container {
  .license {
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 60px;
  }

  .licenses-total {
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    padding: 20px 70px 20px 20px;
  }
}
