.budget-search-container {
  .right-side {
    @include flexbox;

    .base-input-container {
      width: auto;
      margin-right: 10px;
    }
  }

  .budget-details {
    padding: 30px;

    > * {
      margin-top: 10px;
    }

    .warn {
      background-color: $color-orange-1;
    }
  }

  .share {
    @include flexbox;

    span {
      padding-right: 20px;
    }
  }

  .action-wrapper {
    @include flexbox;

    align-items: center;
    border-top: 1px solid $color-gray-4;

    & > * {
      margin-right: 10px;
    }

    .dropdown-container {
      margin-bottom: 7px;
    }

    .sibp-button {
      background-color: $color-orange-1;
      color: $color-white-1;
    }
  }
}
