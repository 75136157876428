.company-add-container {
  .page-body {
    display: flex;

    & > div {
      flex: 1 50%;
    }
  }

  .page-footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 20px;
    }
  }

  .input-container {
    margin-bottom: 20px;
  }
}
