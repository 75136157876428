.breadcrumbs-bar {
  margin-left: 43px;
  margin-top: 15px;
  max-width: 66%;
  height: 26px;

  .breadcrumbs-separator {
    color: $color-steel;
    font-size: 14px;
  }

  .breadcrumbs-element {
    color: $color-steel;
    display: inline-block;
    font-size: 14px;

    &.active,
    &.current {
      color: inherit;
      cursor: default;
    }

    &.current {
      &.clickable {
        cursor: pointer;

        &:hover {
          border-bottom: 1px solid $color-dark-grey-blue;
        }
      }

      &.disable {
        pointer-events: none;
      }
    }

    &:hover:not(.active):not(.current) {
      border-bottom: 1px solid $color-steel;
    }
  }
}
