/* stylelint-disable scss/double-slash-comment-whitespace-inside, font-family-no-missing-generic-family-keyword, no-duplicate-selectors, selector-class-pattern, declaration-no-important */
.qtip .qtip-content {
  li {
    &.path-list-element {
      line-height: 40px;
    }
  }
}

//general list path element
.path-list-element {
  list-style-type: none;
  direction: rtl;
  text-align: left;
  width: 100%;
  @include text-ellipsis;

  color: $color-steel;

  .path-item-name,
  .display-account-ids > span {
    color: $font-color !important;
  }

  .display-account-ids {
    position: relative;
    direction: ltr;
    unicode-bidi: bidi-override;
  }
}

.path-list-element-lazy {
  width: 100%;
  height: 100%;
  position: relative;
  outline: 0;

  > div {
    outline: 0;

    > div {
      outline: 0;
    }
  }

  * > {
    width: 100%;
    height: 100%;
  }

  display: inline-block;

  .path-list-element {
    color: $color-dark-grey-blue;
  }

  .placeholder,
  &.placeholder {
    color: $color-green;
    width: 100%;
    height: 100%;
    line-height: 32px;
    display: inline-block;
  }

  &:not(.active) {
    padding-right: 40px;

    &::after {
      content: "\f107";
      color: #adadad;
      font-family: FontAwesome;
      font-weight: bold;
      font-size: 16px;
      display: block;
      position: absolute;
      right: 7px;
      top: 8px;
      transition: all 0.4s ease;
      pointer-events: none;
    }
  }
}

.ember-basic-dropdown-content {
  > .ember-power-select-search::after {
    content: "\f002";
    color: #adadad;
    font-family: FontAwesome;
    display: block;
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 16px;
    line-height: 43px;
  }
}

//power-select
.ember-power-select-trigger {
  border-color: transparent;
  border-radius: 0;
  height: 100%;
  overflow: hidden;
  background-color: transparent;
  padding-right: 40px;
  text-overflow: clip;

  &:hover {
    background-color: transparent;
  }

  &::after {
    content: "\f107";
    color: #adadad;
    font-family: FontAwesome;
    font-weight: bold;
    font-size: 16px;
    display: block;
    position: absolute;
    right: 7px;
    top: 8px;
    pointer-events: none;
    transition: all 0.4s ease;
    transform: rotate(0);
  }

  .ember-power-select-selected-item {
    * {
      color: $color-dark-grey-blue;
    }
  }

  &.ember-basic-dropdown-trigger--below {
    background-color: $color-dropdown-hover;

    &::after {
      transform: rotate(180deg);
    }
  }

  .ember-power-select-status-icon {
    display: none;
  }

  .ember-power-select-multiple-option--disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.ember-power-select-trigger:focus,
  &.ember-power-select-trigger--active {
    border-color: transparent;
  }

  .ember-power-select-multiple-option--disabled .ember-power-select-multiple-remove-btn {
    display: none;
  }

  input:focus {
    outline: 0;
  }
}

.ember-power-select-dropdown {
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.3);
  border: 1px solid $color-soft-grey;

  ul {
    padding: 16px 0;
  }

  .ember-power-select-option[aria-current="true"] {
    background-color: $color-dropdown-hover;
  }

  .ember-power-select-option[aria-selected="true"] {
    background-color: transparent;

    &:hover {
      background-color: $color-dropdown-hover;
    }
  }

  .ember-power-select-option {
    height: 40px;
    padding: 8px 15px;

    &.ember-power-select-option--no-matches-message {
      background-color: rgba($color-silver, 0.2);
      color: $color-steel;
    }
  }
}

.budgeta-type-value {
  .ember-power-select-multiple-trigger {
    min-height: 39px;

    .ember-power-select-placeholder {
      font-size: 20px;
      line-height: 40px;
    }
  }
}

.ember-power-select-multiple-option {
  max-width: 250px;
  height: 35px;
  @include flexbox;

  border-color: transparent;
  border-radius: 3px;
  font-size: 20px;
  width: 100%;
  background-color: $color-white;

  > * {
    color: $color-dark-grey-blue;
    line-height: 30px;
  }

  .ember-power-select-multiple-remove-btn {
    margin-right: 6px;
    font-size: 22px;
    font-weight: 500;
  }
}

.multi-select-dropdown .selection {
  margin-right: 5px;

  .path-list-element {
    color: $color-dark-grey-blue;
  }
}

.ember-power-select-selected-item {
  margin-left: 0;
}

.multi-budget-lines-mapping {
  .ember-power-select-trigger {
    .ember-power-select-selected-item {
      margin-left: 0;
    }
  }

  .ember-power-select-trigger[aria-expanded="true"] {
    background-color: rgba(56, 218, 149, 0.25);
    border: solid 1.5px $color-weird-green;
  }
}

.table-editor {
  .budget-line-mapping .multi-budget-lines-mapping {
    .ember-power-select-selected-item,
    .selection,
    .path-list-element-lazy,
    .path-list-element,
    .placeholder {
      line-height: 40px;
    }

    .path-list-element-lazy {
      padding-left: 10px;
    }

    .ember-power-select-selected-item .path-list-element {
      padding-left: 9px;
    }

    .ember-power-select-trigger,
    .path-list-element-lazy {
      &::after {
        top: 1px;
      }
    }
  }

  :not(.trx-table-body) .budgetLines .multi-budget-lines-mapping .selection {
    .path-list-element,
    .placeholder {
      padding-right: 20px;
      line-height: 40px;
    }
  }

  .trx-table-body {
    .budgetLines .multi-budget-lines-mapping .selection {
      .path-list-element,
      .placeholder {
        padding-right: 20px;
        line-height: 34px;
      }
    }
  }
}

.employee-edit-line {
  .ember-power-select-selected-item,
  .path-list-element-lazy,
  .path-list-element,
  .placeholder {
    line-height: 40px;
  }

  .ember-power-select-trigger,
  .path-list-element-lazy {
    &::after {
      top: 0;
    }
  }

  .path-list-element-lazy {
    padding-right: 42px;
  }
}

.budget-module-employee {
  .path-list-element-lazy li,
  .path-list-element,
  .placeholder {
    padding-left: 10px;
    line-height: 40px;
    font-size: 20px;

    > * {
      font-size: 20px;
    }
  }

  .ember-power-select-trigger {
    &:hover {
      border-color: transparent !important;
    }
  }

  .ember-power-select-trigger,
  .path-list-element-lazy:not(.active) {
    &::after {
      right: 10px;
    }

    outline: 0;

    &::after {
      color: $color-dark-grey-blue;
    }

    &:hover {
      background-color: rgba($color-weird-green, 0.05);
    }
  }

  .budgeta-type-dropdown > div {
    min-width: 0;
    width: 100%;

    .empty-select,
    .ember-power-select-trigger,
    .path-list-element-lazy {
      width: 100%;
      display: block !important;
    }
  }
}

.modal-share {
  .path-list-element-lazy {
    // height: auto;
    line-height: 45px;
    border-bottom: 1px solid $color-white-light-grey;

    .placeholder,
    &.placeholder {
      color: $color-silver;
    }
  }

  .ember-power-select-trigger {
    input {
      border-color: transparent;
    }

    &.ember-basic-dropdown-trigger--below {
      background-color: transparent;
    }
  }
}

.export-missing-budget-line-account,
.export-missing-mapping .multi-budget-lines-mapping:not(.single-mapping) {
  .path-list-element {
    top: 9px;
  }
}

.export-missing-budget-line-account,
.export-missing-mapping {
  label .path-list-element {
    line-height: 40px;
  }
}

.budget-attributes-wrapper:not(.employee-inputs) {
  .ember-power-select-trigger {
    input,
    &[aria-expanded="true"] {
      background-color: transparent;
    }
  }

  .path-list-element-lazy {
    .placeholder,
    .path-list-element,
    &.placeholder {
      margin-left: 15px;
      line-height: 41px;
      display: inline-block;
    }

    &.placeholder,
    .placeholder {
      color: $color-silver;
    }
  }

  .path-list-element-lazy,
  .ember-power-select-trigger {
    .path-list-element,
    .placeholder {
      width: 100%;
      @include mid-text-grey-blue;
    }

    &::after {
      color: $color-dark-grey-blue;
    }

    &[aria-disabled="true"] {
      background-color: unset;

      &::after {
        content: "";
      }
    }

    &:hover {
      background-color: rgba($color-weird-green, 0.05);
    }
  }

  .ember-power-select-multiple-options {
    input::placeholder {
      color: $color-silver;
    }
  }
}

.add-model-dashboard-modal {
  .path-list-element-lazy {
    height: auto;
    border-bottom: 1px solid $color-white-light-grey;

    .empty-select {
      border: 1px solid $color-red !important;

      &::after {
        content: attr(data-after);
        color: $color-red;
        position: absolute;
      }
    }
  }

  .ember-power-select-trigger,
  .path-list-element-lazy:not(.active) {
    &:hover {
      background-color: rgba($color-weird-green, 0.05);
    }
  }

  .ember-power-select-trigger {
    input,
    &[aria-expanded="true"] {
      border-color: transparent;
      background-color: transparent;
    }
  }
}

.add-actual-accounts {
  .path-list-element,
  .placeholder {
    line-height: 38px;
  }
}

.budget-without-highlight-value-border {
  &:hover {
    border-color: $color-weird-green !important;
  }
}
