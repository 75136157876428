/* stylelint-disable max-nesting-depth, selector-class-pattern */
.intacct-match-stat-accounts-to-dimensions {
  .short-subtitle,
  .top-level-structure,
  .table-header,
  .table-row {
    padding-right: 29px;
    padding-left: 29px;
  }

  .top-level-structure {
    @include flexbox;

    background-color: rgba($color_mint_tulip, 0.2);
    border-top: solid 1px $color_light_steel_gray;
    border-bottom: solid 1px $color_light_steel_gray;
    padding-top: 1px;
    padding-bottom: 6px;

    .top-level-label {
      display: flex;
      align-items: center;
    }

    .help-iq {
      margin-left: 5px;

      svg {
        fill: $color_steel;
      }
    }

    .top-level-dimensions-selector {
      margin-left: 15px;
      border-bottom: solid 1px $color_light_steel_gray;
      @include flexbox;

      align-items: flex-end;
      height: 42px;

      .ember-power-select-trigger {
        @include flex(1 1);

        border-left-width: 0;

        .ember-power-select-selected-item {
          font-size: 18px;
          align-items: center;
          width: auto;
          max-width: 250px;
          height: 35px;
          @include flexbox;

          border-color: transparent;
          border-radius: 0 3px 3px 0;
          background-color: $color-white;
          color: $color-black;
          padding: 0 4px 0 0;
          line-height: 1.45;
          float: left;
          margin: 2px 0;
        }
      }

      .remove-dimension {
        font-size: 35px;
        font-weight: 400;
        display: flex;
        align-items: center;
        padding-right: 6px;
        border-color: transparent;
        background-color: $color-white;
        height: 35px;
        margin-bottom: 3px;
        border-radius: 3px 0 0 3px;
        padding-left: 5px;
        margin-left: 3px;
        line-height: 33px;
        color: rgba($font_color, 0.5);

        &:hover {
          color: $font_color;
        }
      }

      &.dropdown-open {
        background-color: $color_dropdown_hover;

        .ember-basic-dropdown-trigger--below {
          background-color: transparent;
        }
      }
    }
  }

  .table-editor {
    .table-header {
      display: grid;
      grid-template-columns: 185px auto;
      border-bottom: solid 1px $color_light_steel_gray;

      .dimension-column-name {
        margin-left: 15px;
      }
    }

    .table-body {
      .table-row {
        display: grid;
        grid-template-columns: 183px auto;
        padding-top: 15px;
        padding-bottom: 12px;

        .account-columns {
          @include flexbox;

          align-items: center;

          .account-id {
            font-weight: 600;
            width: auto;
            margin-right: 10px;
          }

          .account-title {
            @include text-ellipsis;
          }
        }

        .dimensions-selector {
          margin-left: 15px;
          border-bottom: solid 1px $color_light_steel_gray;
          min-height: 42px;

          .ember-power-select-trigger::after {
            top: 8px;
          }

          .ember-power-select-multiple-option {
            font-size: 18px;
            align-items: center;

            > span {
              font-size: 35px;
              font-weight: 400;
              line-height: 33px;
            }
          }
        }
      }
    }
  }
}
