/* stylelint-disable selector-class-pattern, declaration-no-important */
.collapse_expand_wrapper {
  line-height: 14px !important;
  height: auto !important;

  .collapse-expand-component {
    display: inline-block;
  }
}

.collapse_expand_wrapper + span.budget-name {
  padding-left: 20px;
}

.collapse-expand-component {
  cursor: pointer;

  &:hover {
    .svg-icon {
      .icon {
        fill: $color-dark-grey-blue !important;
      }
    }
  }

  .svg-icon {
    display: block;
    line-height: 9px !important;

    .icon {
      width: 15px;
      height: 15px;
    }

    &:not(:first-child) {
      margin-top: -5px;
    }
  }
}
