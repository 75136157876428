.download-options-modal-wrapper {
  .modal-container {
    top: 100px;
    transform: translateX(-50%);
  }

  .versions-body-container {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;

    &.show {
      opacity: 1;
      max-height: 800px;
      overflow-y: auto;
    }
  }

  .header-text {
    font-size: 32px;
    font-weight: 300;
    display: inline-block;
    height: auto;
    padding-left: 30px;
    margin-bottom: 25px;
  }

  .header-container {
    padding: 25px 0;
    flex-direction: row;
    display: block;
  }

  .seperator {
    width: auto;
    border-bottom-width: 1px;
  }

  .description {
    margin-bottom: 10px;
  }

  .filter {
    display: flex;
    margin-bottom: 45px;
    margin-top: 20px;

    & > * {
      margin-right: 30px;
    }
  }

  .body-container {
    padding: 10px 25px;
    flex: 1;
  }

  .footer-container {
    padding: 8px 10px;
    display: block;
    height: auto;
    min-height: auto;
  }

  .footer-buttons {
    display: flex;
    justify-content: space-between;
  }

  // radio
  .radio-container {
    margin-bottom: 10px;

    input {
      margin: 0;
      left: -9999px;
      position: absolute;
    }

    label {
      margin-bottom: 0;
      position: relative;
      padding-left: 25px;

      &::before {
        top: 50%;
        transform: translate(0, -50%);
        content: "";
        position: absolute;
        left: 0;
        width: 18px;
        height: 18px;
        border: 1px solid $color-dark-mint;
        border-radius: 9px;
      }
    }

    &.checked label::after {
      top: 50%;
      transform: translate(0, -50%);
      content: "";
      background: $color-dark-mint;
      display: block;
      height: 12px;
      width: 12px;
      position: absolute;
      left: 3px;
      border-radius: 6px;
    }
  }

  // checkbox
  .sibp-checkbox {
    line-height: normal;
    position: relative;

    input {
      margin: 0;
      border: 0;
      width: 0;
      height: 0;
    }

    label {
      margin-bottom: 0;
      display: flex;

      .label {
        line-height: normal;
        padding: 0;
        color: $font-color;
        font-weight: normal;
        font-size: 100%;
      }

      &::before {
        border: 1px solid rgba(#000, 0.25);
        content: "";
      }

      &::after {
        top: 55%;
        transform: scale(0.9) translate(0, -50%);
        content: url("/assets/images/ui-components/svg/check_white.svg");
        position: absolute;
        left: 0.5px;
        transition: all 0.2s;
        display: inline-block;
      }
    }
  }

  table {
    width: 100%;

    td:not(:last-of-type),
    th:not(:last-of-type) {
      padding-right: 15px;
    }

    th {
      text-transform: uppercase;
      padding-bottom: 10px;
      color: $color-gray-3;
      font-size: 14px;
      font-weight: 700;
    }

    .clip-text span {
      width: 100%;
      display: inline-block;
      white-space: nowrap; /* Prevent line breaks */
      overflow: hidden; /* Hide the overflowing content */
      text-overflow: ellipsis; /* Show ellipsis (...) for clipped text */
    }

    .table-header {
      border-bottom: 1px solid #ccc;
    }

    .select-version {
      width: 20px;
    }

    .name {
      width: 220px;
      max-width: 220px;
    }

    .type {
      width: 110px;
    }

    .created-date {
      width: 180px;
    }

    .note {
      width: 200px;
      max-width: 200px;
    }

    .created-by {
      width: 200px;
      max-width: 200px;
    }

    .sibp-checkbox label::before {
      margin-right: 0;
    }
  }

  .versions-header {
    margin-bottom: 10px;
  }

  .versions-wrapper {
    overflow-y: auto;
    height: 200px;
  }

  .note {
    display: inline-block;
    font-weight: 600;
  }
}

// buttons
#download-options-modal-wrapper .secondary {
  color: $color-steel;
  background-color: white;
}
